import { createSelector } from 'reselect'

const innerStateSelector = state => state.memberTags;


const memberTagsDataSelector = createSelector(
   innerStateSelector,
   (state) => (state.tagsData)
);

const isEmptyTags = createSelector(
   innerStateSelector,
   state => state.isEmpty
);
const isFetchingTags = createSelector(
   innerStateSelector,
   state => state.isFetching
);

const isEmptyTagsByFilter = createSelector(
   innerStateSelector,
   state => state.isEmptyByFilter
);

const isFetchingTagsByFilter = createSelector(
   innerStateSelector,
   state => state.isFetchingByFilter
);

const isNewFetchingTags = createSelector(
   innerStateSelector,
   state => state.isNewFetching
);

const isFetchingCreateNewTagSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingCreateNewTag
)

const createNewTagErrorsSelector = createSelector(
   innerStateSelector,
   state => state.createNewTagErrors
)

export {
   memberTagsDataSelector,
   isEmptyTags,
   isEmptyTagsByFilter,
   isFetchingTags,
   isFetchingTagsByFilter,
   isNewFetchingTags,
   isFetchingCreateNewTagSelector,
   createNewTagErrorsSelector,
}
