import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import Input from 'common/components/elements/inputs/inputs'
import toast from 'common/utils/toast'
import EmojiContent from 'common/components/modules/emoji-content'
import { addEmojiOnInput, getCurrentPosition, switchShowEmojis } from 'common/utils/utils';
import Icon from 'common/components/elements/icons'
import './style.scss'
import TextArea from 'common/components/elements/inputs/textarea'
import cx from 'classnames'

const InputWrapper = ({
   changeError,
   error,
   value,
   onChange,
   screenWidth,
   placeholder,
   name,
   className,
   title,
   titleClassName,
   inputClassName,
   righticon,
   iconClassName,
   onClickIcon,
   limit,
   minLimit,
   righticonClassName,
   isTextArea,
   minHeight,
   onInputFocus,
   onInputBlur,
   isActive,
   disabled,
   errorMessageClassName,
   clearState,
   uniqueId,
   textAreaClassName,
   emojiContentPosition,
   areWrapperClassName,
   date,
   xPositions,
   otherText,
   otherTextClassName,
   parentId,
   disabledAll,
   showSecondField,
   secondInputClassName,
   secondInputValue,
   secondInputLabel,
   inputLabel,
   secondInputLabelClassName,
   inputLabelClassName,
   secondInputRightText,
   inputType,
   secondInputError,
   hideError,
   showErrorIcon,
   secondInputId,
   secondErrorClassName,
   unavailableSymbols,
   rightIconTooltipText,
   hideEmoji,
   tooltipId,
   parentClassName,
   isAutoFoucus,
   secondFieldRightTextClassName,
   rightTextHelperText,
   secondFieldInputWrapperClassName,
   hideDescription = false,
   handleKeyDown = () => {},
}) => {

   const [cursorPosition, setCursorPosition] = useState(null);
   const [activeMode, setActiveMode] = useState(false);
   let inputRef = useRef(null)

   useEffect(() => {
      if(isTextArea && inputRef && isActive) {
         inputRef.style.height = 'auto'
         inputRef.style.overflowY = 'hidden';
         if(inputRef.scrollHeight > 36) {
            inputRef.style.height = inputRef.scrollHeight + 'px';
         } else {
            inputRef.style.height = inputRef.scrollHeight - 18 + 'px';
         }

         let secondElement = document.getElementById(`${ uniqueId }`)
         if(secondElement) {
            let secondElementRect = secondElement.getBoundingClientRect()
            inputRef.style.height = secondElementRect.height + 'px'
         }
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [inputRef, uniqueId, isActive]);

   useEffect(() => {
      if(inputRef && isAutoFoucus && !isTextArea) {
         inputRef.focus();
         inputRef.setSelectionRange(value?.length, value?.length);
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const secondField = (
      <div id={ secondInputId } >
         <Input
            rightText={ secondInputRightText }
            value={ secondInputValue }
            label={ secondInputLabel }
            labelClasses={ secondInputLabelClassName }
            className={ secondInputClassName }
            errorMessage={ secondInputError }
            descriptionPosition='right'
            onChange={ (_, value) => onChange(value, inputType) }
            height='10'
            type='number'
            onKeyDown={ (e) => {
               ['E', 'e', '+', '-', ...unavailableSymbols].includes(e.key) && e.preventDefault()
            } }
            onInputFocus={ () => {
               if(!!onInputFocus) {
                  onInputFocus(value, 'secondField')
               }
            } }
            onInputBlur={ () => {
               if(!!onInputBlur) {
                  onInputBlur(secondInputValue, 'secondField')
               }
            } }
            hideError={ hideError }
            showErrorIcon={ showErrorIcon }
            errorMessageClassName={ secondErrorClassName }
            isPriceInput={ secondInputRightText === 'USD' }
            maxPrice={ secondInputRightText === 'USD' ? 2000 : 1000 }
            rightTextClassName={ secondFieldRightTextClassName }
            rightTextHelperText={ rightTextHelperText }
            inputWrapperClassName={ secondFieldInputWrapperClassName }
         />
      </div>
   )

   return (
      <div className={ `w-full ${ parentClassName }` }>
         <div
            className={ cx({
               [`${ className } input-wrapper`]: true,
            // 'disabled-input': disabledAll,
            }) }
            id={ parentId }
         >
            {
               !!title && <p className={ `text-major cursor-default flex items-center gap-1 ${ titleClassName }` }>{title}{!!otherText ? <span className={ otherTextClassName }>{otherText}</span> : ''}</p>
            }
            <div className={ `relative ${ inputClassName }` }>
               {
                  !isTextArea ?
                     <Input
                        inputRef={ (ref) => inputRef = ref }
                        id={ uniqueId }
                        placeholder={ placeholder }
                        height='10'
                        label={ inputLabel }
                        labelClasses={ inputLabelClassName }
                        errorMessage={ !!error ? error : null }
                        description={ !!error ? null : `${ value?.length ?? 0  } of ${ limit } characters` }
                        descriptionPosition='right'
                        descriptionFontWeight='normal'
                        descriptionClassName='!mb-0'
                        inputClassName={ cx({
                           '!min-h-10 !h-10': true,
                        // '!bg-grey-main placeholder-disabled': disabledAll,
                        }) }
                        value={ value }
                        hasEmojiPicker
                        onChange={ (_, value) => {
                           if(value.length > limit) {
                              toast.error(`${ limit } character limit has been reached`, {
                                 toastId: `store_${ name }_characters_limit_reached`,
                                 className: 'custom-ams-toast !bg-error dark:!bg-error-dark custom-ams-toast-container',
                              })
                              return
                           }
                           onChange(value)
                        } }
                        onInputFocus={ () => {
                           if(!!onInputFocus) {
                              onInputFocus(value)
                           }
                           if(!!error && !!changeError) {
                              changeError()
                           }
                        } }
                        onInputBlur={ () => {
                           if(!!onInputBlur) {
                              onInputBlur(value)
                           }
                           const positionCursor = getCurrentPosition(inputRef);
                           setCursorPosition(positionCursor);
                        } }
                        errorMessageClassName={ errorMessageClassName }
                        hideDescription={ hideDescription }
                        onKeyDown={ handleKeyDown }
                     />
                     :
                     isActive ?
                        <TextArea
                           TextAreaRef={  (ref) => inputRef = ref }
                           id={ uniqueId }
                           value={ value }
                           description={ !error ? !!minLimit && !value ? `Minimum ${ minLimit } character limit` : `${ value ? value.length : 0 } of ${ limit } characters` : '' }
                           descriptionFontWeight='normal'
                           descriptionPosition='right !min-h-fit'
                           descriptionFontSize='xs'
                           placeholder={ placeholder }
                           onChange={ (_, value) => {
                              if(value.length > limit) {
                                 toast.error(`${ limit } character limit has been reached`, {
                                    toastId: `${ name }_characters_limit_reached`,
                                    className: 'custom-ams-toast !bg-error dark:!bg-error-dark custom-ams-toast-container',
                                 })
                                 return
                              }
                              onChange(value)
                              let scrollTop = window.pageYOffset;
                              inputRef.style.height = 'auto';
                              inputRef.style.overflowY = 'hidden';
                              if(inputRef.scrollHeight > 36) {
                                 inputRef.style.height = inputRef.scrollHeight + 'px';
                              } else {
                                 inputRef.style.height = inputRef.scrollHeight - 18 + 'px';
                              }
                              window.scroll({
                                 top: scrollTop,
                                 left: 0,
                              });
                           } }
                           areaClassName={ `${ textAreaClassName } ${ minHeight } !rounded-none !p-0 !border-none flex` }
                           areWrapperClassName={
                              cx({
                                 [`${ areWrapperClassName } rounded-lg !h-fit py-3 pl-3.5 pr-[38px] border border-divider dark:border-divider-dark dark:bg-black-field`]: true,
                                 [`!px-3.5`]: !isActive,
                                 '!border-error': !!error,
                              // [`${ minHeight }`]: !!minHeight,
                              })
                           }
                           onTextAreaBlur={ () => {
                              const positionCursor = getCurrentPosition(inputRef);
                              setCursorPosition(positionCursor);
                              if(!!onInputBlur) {
                                 onInputBlur(value)
                              }
                           } }
                           height={ `auto` }
                           style={ {
                              boxSizing: 'border-box',
                           } }
                           onTextAreaFocus={ () => {
                              onInputFocus()
                              if(!!error && !!changeError) {
                                 changeError()
                              }
                           }
                           }
                           errorMessage={ error }
                        />
                        :
                        <div className='flex flex-col gap-1.5'>
                           <p
                              id={ uniqueId }
                              role='presentation'
                              className={ `py-[11px] px-3.5 pr-[38px] bg-note text-major text-base leading-[18px] border border-divider dark:border-divider-dark rounded-lg cursor-text ` }
                              onClick={ () => {
                                 if(!activeMode) {
                                    setActiveMode(true)
                                 }
                                 onInputFocus()
                              } }
                           >{value}</p>
                           <p className='text-xs leading-none text-secondary text-end'>{date}</p>
                        </div>
               }
               {
                  !Boolean(error) && isActive && !hideEmoji &&
                  <EmojiContent
                     switchShowEmojis={ () => switchShowEmojis(inputRef, cursorPosition) }
                     addEmoji={ (e) => addEmojiOnInput(e, inputRef) }
                     isMobile={ screenWidth < 701 }
                     className={ cx({
                        'absolute right-1 top-1.5 z-1': true,
                        'top-9': !!inputLabel,
                     }) }
                     id={ `emoji-content-${ !!uniqueId && uniqueId?.toString()?.replaceAll('.', '-') }` }
                     top={ !inputLabel ? emojiContentPosition : '75px' }
                     primaryIconSize='20px'
                     xPositions={ xPositions }
                  />
               }
            </div>
            {
               (screenWidth > 640 && showSecondField) && secondField
            }
            {
               !!righticon &&  (
                  <div
                     className={ cx({
                        [`grid place-items-center w-10 h-10 rounded-lg border border-divider ${ righticonClassName }`]: true,
                        'cursor-pointer': !disabled,
                     }) }
                     role='presentation'
                     onClick={ () => {
                        if(!disabled) {
                           onClickIcon()
                           if(inputRef && clearState) {
                              inputRef.style.height = '18px'
                           }
                        }
                     } }
                     data-tooltip-content={ rightIconTooltipText }
                     data-tooltip-id={ tooltipId }
                  >
                     <Icon
                        name={ righticon }
                        className={ ` z-2 ${ iconClassName }` }
                        size={ 'xl' }
                     />
                  </div>
               )
            }
         </div>
         {
            (screenWidth <= 640 && showSecondField) && secondField
         }
      </div>
   )
}

InputWrapper.propTypes = {
   changeError: PropTypes.func,
   error: PropTypes.object,
   value: PropTypes.string,
   onChange: PropTypes.func,
   screenWidth: PropTypes.number,
   placeholder: PropTypes.string,
   name: PropTypes.string,
   className: PropTypes.string,
   title: PropTypes.string,
   titleClassName: PropTypes.string,
   inputClassName: PropTypes.string,
   onClickIcon: PropTypes.func,
   iconClassName: PropTypes.string,
   righticon: PropTypes.string,
   limit: PropTypes.number,
   righticonClassName: PropTypes.string,
   isTextArea: PropTypes.bool,
   minHeight: PropTypes.string,
   onInputBlur: PropTypes.func,
   onInputFocus: PropTypes.func,
   isActive: PropTypes.bool,
   disabled: PropTypes.bool,
   disabledAll: PropTypes.bool,
   showSecondField: PropTypes.bool,
   clearState: PropTypes.bool,
   errorMessageClassName: PropTypes.string,
   uniqueId: PropTypes.string,
   textAreaClassName: PropTypes.string,
   emojiContentPosition: PropTypes.string,
   areWrapperClassName: PropTypes.string,
   date: PropTypes.string,
   xPositions: PropTypes.object,
   otherText: PropTypes.string,
   otherTextClassName: PropTypes.string,
   minLimit: PropTypes.number,
   parentId: PropTypes.string,
   secondInputClassName: PropTypes.string,
   secondInputValue: PropTypes.number,
   secondInputLabel: PropTypes.string,
   secondInputLabelClassName: PropTypes.string,
   inputLabelClassName: PropTypes.string,
   inputLabel: PropTypes.string,
   secondInputRightText: PropTypes.string,
   inputType: PropTypes.string,
   secondInputError: PropTypes.string,
   secondErrorClassName: PropTypes.string,
   hideError: PropTypes.bool,
   showErrorIcon: PropTypes.bool,
   secondInputId: PropTypes.bool,
   unavailableSymbols: PropTypes.array,
   rightIconTooltipText: PropTypes.string,
   hideEmoji: PropTypes.bool,
   tooltipId: PropTypes.string,
   parentClassName: PropTypes.string,
   isAutoFoucus: PropTypes.bool,
   secondFieldRightTextClassName: PropTypes.string,
   rightTextHelperText: PropTypes.string,
   secondFieldInputWrapperClassName: PropTypes.string,
   hideDescription: PropTypes.bool,
   handleKeyDown: PropTypes.func,
}

InputWrapper.defaultProps = {
   className: 'flex flex-col gap-2',
   inputClassName: '',
   limit: 50,
   minLimit: 0,
   righticonClassName: '',
   isTextArea: false,
   isActive: true,
   disabled: false,
   disabledAll: false,
   clearState: true,
   errorMessageClassName: '',
   uniqueId: '',
   textAreaClassName: '',
   emojiContentPosition: '45px',
   onInputFocus: () => {},
   date: '2 days ago',
   xPositions: {
      right: 0,
   },
   otherText: '',
   otherTextClassName: '',
   parentId: '',
   showSecondField: false,
   secondInputClassName: '',
   secondInputValue: '',
   secondInputLabel: '',
   inputLabel: '',
   secondInputLabelClassName: '',
   inputLabelClassName: '',
   secondInputRightText: 'USD',
   inputType: 'price',
   showErrorIcon: true,
   hasBorder: false,
   secondInputId: '',
   secondErrorClassName: '',
   unavailableSymbols: [],
   rightIconTooltipText: '',
   hideEmoji: false,
   tooltipId: 'ams-top-tooltip',
   parentClassName: '',
   isAutoFoucus: false,
   secondFieldRightTextClassName: '',
}

export default InputWrapper
