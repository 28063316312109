
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import NewMessageField from 'common/components/modules/chat/new-message-field';
import SpinnerAnimation from 'common/components/modules/spinner-animation';
import Message from './message'
import MuteMember from  'admin/views/chat/modules/mute-block-member/notification';
import DateFormatter from 'common/utils/DateFormatter';
import CircleLoader from 'common/components/elements/loader/circle';
import EmptyConversation from 'common/components/modules/chat/new-message/chat';
import Button from 'common/components/elements/buttons/primary';
import ChatHepler from 'common/utils/chat';
import Icon from 'common/components/elements/icons';
import cx from "classnames";
import { getCurrentLanguageByValue } from 'common/utils/utils';
import Router from 'admin/routes/router';
import SitesRouter from 'sites/routes/router';
import { createPortal, flushSync } from 'react-dom';
import classNames from 'classnames';
import MultipleAttachmentModal from '../multiple-attachment-modal';
import Modal from '../../modal';
import ConfirmDialog from '../../confirm-dialog';
import { generateUriSegment } from 'common/utils/mediaRouteHelper';
import SavedRepliesModal from '../saved-replies-modal';
import toast from 'common/utils/toast';
import { isEmpty } from 'lodash';
import { replaceVariables } from 'admin/api/AuthApi';
import { isLocalhost } from 'common/utils/helper';
import WarningModal from '../content-warning-modal';
import ConfirmModal from 'common/components/modules/modal-wrapper';


class Dialogue extends Component {
   static propTypes = {
      isMobile: PropTypes.bool,
      authUser: PropTypes.object,
      messages: PropTypes.array,
      onClickUserAvatar: PropTypes.func,
      unlock: PropTypes.bool,
      mutedInfo: PropTypes.object,
      isAdmin: PropTypes.bool,
      onScroll: PropTypes.func,
      isNewFetching: PropTypes.bool,
      onFetchLikesInfo: PropTypes.func,
      onLikedMemberPagination: PropTypes.func,
      likesInfo: PropTypes.object,
      onUserTyping: PropTypes.func,
      typerText: PropTypes.string,
      onLikeMessage: PropTypes.func,
      onDeleteMessag: PropTypes.func,
      sendMessage: PropTypes.func,
      isEmpty: PropTypes.bool,
      converstionInfo: PropTypes.object,
      emptyState: PropTypes.object,
      unmuteMember: PropTypes.func,
      onReadMessage: PropTypes.func,
      lastSeen: PropTypes.string,
      diff: PropTypes.number,
      setMutedMemberInfo: PropTypes.func,
      isConnected: PropTypes.bool,
      adminChatSettings: PropTypes.object,
      goToChatSettings: PropTypes.func,
      unblockMember: PropTypes.func,
      closeChatPopup: PropTypes.func,
      memberCanOnlyRead: PropTypes.bool,
      selectFileByLiber: PropTypes.func,
      removeSelectedContent: PropTypes.func,
      deleteVaultVideo: PropTypes.func,
      onStream: PropTypes.func,
      onSaveUploadPhotos: PropTypes.func,
      goToContentMessage: PropTypes.func,
      needToJoin: PropTypes.bool,
      maxPrice: PropTypes.number,
      goToJoin: PropTypes.func,
      purchaseMessageId: PropTypes.number,
      paymentButtonLoading: PropTypes.bool,
      offLiveStreamChat: PropTypes.bool,
      isLivstream: PropTypes.bool,
      isMiniChat: PropTypes.bool,
      downloadFile: PropTypes.func,
      onSendVoiceMessage: PropTypes.func,
      adminUuid: PropTypes.string,
      privateAttachmentSettings: PropTypes.object,
      defaultVoicePrice: PropTypes.number,
      onUpdateRecordStatus: PropTypes.func,
      paymentButtonResourceId: PropTypes.string,
      handleUploadImage: PropTypes.func,
      cancelVideoUplaod: PropTypes.func,
      checkVaultByHash: PropTypes.func,
      createVault: PropTypes.func,
      checkContentTypeInTheChat: PropTypes.func,
      getMediaBlurhash: PropTypes.func,
      goTo: PropTypes.func,
      attachments: PropTypes.array,
      attachmentsForShow: PropTypes.array,
      onRemoveMedia: PropTypes.func,
      onClickCancel: PropTypes.func,
      isDemoMode: PropTypes.bool,
      mainMessageId: PropTypes.any,
      screenWidth: PropTypes.number,
      siteInfo: PropTypes.object,
      isMaxAttachmentCountReached: PropTypes.bool,
      repliesModalState: PropTypes.string,
      isChangingRepliesModalState: PropTypes.bool,
      setReplyData: PropTypes.func,
      repliesData: PropTypes.object,
      newReplyData: PropTypes.object,
      currentReplyData: PropTypes.object,
      showSavedReplies: PropTypes.bool,
      setShowSavedReplies: PropTypes.func,
      setDialogueContainerState: PropTypes.func,
      onChangeReply: PropTypes.func,
      isOpenDeleteReplyModal: PropTypes.bool,
      replyForDelete: PropTypes.any,
      getReplies: PropTypes.func,
      repliesFilter: PropTypes.object,
      onChangeRepliesFilter: PropTypes.func,
      handleRepliesModalScroll: PropTypes.func,
      isLoadingReplies: PropTypes.bool,
      isLoadingRepliesNextPage: PropTypes.bool,
      readyToSendReply: PropTypes.bool,
      replyErrors: PropTypes.object,
      isSavingReply: PropTypes.bool,
      isSendingReply: PropTypes.bool,
      sendingReply: PropTypes.object,
      checkStatusData: PropTypes.object,
      isOpenRepliesDiscardModal: PropTypes.bool,
      setQueryParam: PropTypes.func,
      removeQueryParam: PropTypes.func,
      getQueryParam: PropTypes.func,
      isSendingAnyway: PropTypes.bool,
      selectedAttachments: PropTypes.array,
      onReorderEnd: PropTypes.func,
      isRepliesMaxAttachmentCountReached: PropTypes.func,
      // chatSettings: PropTypes.object,
      onSaveDurationData: PropTypes.func,
      isFetchingChatSettingsUpdate: PropTypes.bool,
      durationInfo: PropTypes.object,
      updateCurrentConversation: PropTypes.func,
      onClickNotificationMessageButton: PropTypes.func,
   };
   state = {
      confirmMod: '',
      selectedMessage: '',
      showCopy: false,
      showImageModal: false,
      startScroll: false,
      replyMessage: {},
      updateMessageType: '',
      setShowButton: false,
      keyH: 0,
      isMessageToLong: false,
      showAttachmentModal: false,
      currentMessage: {},
      openDeleteConfirmModal: false,
      attachmentStateForDelete: {},
      openDeleteAttachmentModal: false,
      selectedReply: {},
      repliesModalState: 'list',
   };
   dialogueRef = React.createRef();
   inputRef = React.createRef();
   containerRef = React.createRef();
   timeout = ''
   needScrollChat = false
   calcDiffDay = []
   repliesRef = React.createRef();
   repliesTextFieldRef = React.createRef();

   componentDidMount() {
      const { isMobile, converstionInfo } = this.props
      if(this.inputRef && this.inputRef.current && !isMobile) {
         this.inputRef.current.focus()
      }
      this.timeout = setTimeout(() => {
         this.setState({
            startScroll: true,
         })
         ChatHepler.syncDialogueScroll('smooth', converstionInfo?.chat_room_id)
      }, 500)
      if(this.chatContainerBox) {
         if(this.chatContainerBox.current) {
            this.chatContainerBox.current.scrollTop = this.chatContainerBox.current.scrollHeight - this.chatContainerBox.current.clientHeight;
         }
      }
   }
   componentWillUnmount(){
      const { isMobile } = this.props

      clearTimeout(this.timeout)
      if(this.inputRef && this.inputRef.current && !isMobile) {
         this.inputRef.current.blur()
      }
   }

   componentDidUpdate(prevProps, prevState){
      // eslint-disable-next-line no-unused-vars
      const { setDialogueContainerState, repliesModalState, showSavedReplies, isChangingRepliesModalState, setQueryParam, getQueryParam, removeQueryParam, currentReplyData, newReplyData, selectedAttachments } = this.props

      if(this.dialogueRef?.current) {
         this.dialogueRef.current.style.paddingRight = this.dialogueRef?.current?.offsetHeight < this.dialogueRef?.current?.scrollHeight ? '4px' : '16px'
      }

      if(!!setDialogueContainerState && repliesModalState === 'list' && !showSavedReplies && !isChangingRepliesModalState) {
         setDialogueContainerState({
            isChangingRepliesModalState: true,
         })
         clearTimeout(this.replyTimeout)
      }

      if(setQueryParam && repliesModalState === 'create' && showSavedReplies && (!getQueryParam('repliesModal') || getQueryParam('repliesModal') === 'edit' || getQueryParam('repliesModal') === 'list')) {
         setQueryParam('repliesModal', 'create')
      }

      if(setQueryParam && repliesModalState === 'edit' && showSavedReplies && (!getQueryParam('repliesModal') || getQueryParam('repliesModal') === 'create' || getQueryParam('repliesModal') === 'list')) {
         setQueryParam('repliesModal', 'edit')
      }
      if(setQueryParam && repliesModalState === 'list' && showSavedReplies && (!getQueryParam('repliesModal') || getQueryParam('repliesModal') === 'create' || getQueryParam('repliesModal') === 'edit')) {
         setQueryParam('repliesModal', 'list')
      }

      if(removeQueryParam && !showSavedReplies && !!getQueryParam('repliesModal')) {
         removeQueryParam('repliesModal')
      }

      // if(getQueryParam && showSavedReplies && !!getQueryParam('conversation') && (currentReplyData || newReplyData) && setDialogueContainerState) { // && repliesModalState === 'list'
      //    const currentReply = currentReplyData || newReplyData
      //    const prevReply = prevProps?.currentReplyData || prevProps?.newReplyData

      //    if(!!prevReply?.attachmentsForShow && !currentReply?.attachmentsForShow) {
      //       // console.log({
      //       //    currentReply,
      //       //    prevReply,
      //       // });
      //       // console.log('RESET');

      //       setDialogueContainerState({
      //          selectedAttachments: {
      //             ...selectedAttachments,
      //             [+(getQueryParam('conversation'))]: [],
      //          },
      //       })
      //    }
      // }
      // if(getQueryParam && showSavedReplies && !!getQueryParam('conversation') && (prevProps.repliesModalState !== repliesModalState) && setDialogueContainerState) { // && repliesModalState === 'list'
      //    const currentReply = currentReplyData || newReplyData
      //    const prevReply = prevProps?.currentReplyData || prevProps?.newReplyData

      //    // console.log('RESET 11');

      //    if(!!prevReply?.attachmentsForShow && !currentReply?.attachmentsForShow) {
      //       // console.log({
      //       //    currentReply,
      //       //    prevReply,
      //       // });
      //       // console.log('RESET 22');

      //       setDialogueContainerState({
      //          selectedAttachments: {
      //             ...selectedAttachments,
      //             [+(getQueryParam('conversation'))]: [],
      //          },
      //       })
      //    }
      // }
   }

   onClickLockContent = (type, isOpen) => {
   }
   onReplyMessage = replyMessage => {
      const { isMobile } = this.props
      this.setState({ replyMessage }, () => {
         if(this.inputRe && !isMobile) {
            this.inputRef.current.focus()
         }
      })
   }

   updateMessage = updateMessage => {
      const {
         messageId,
         text,
         updateMessageType,
      } = updateMessage
      this.setErrorToLong(false)
      this.updateMessagID(messageId, text, updateMessageType)
   }

   updateMessagID = (updateMessageId, text, updateMessageType) => {
      this.setState({
         updateMessageId,
         replyMessage: null,
         updateMessageType,
      },
      () => {
         if(this.inputRef && this.inputRef.current) {
            this.inputRef.current.focus()
            this.inputRef.current.innerText = '';
            window.document.execCommand('insertText', false, text);
         }
      })
   }


   onScrollToButton(behavior = 'smooth') {
      if(!this?.dialogueRef?.current){
         return
      }
      const { scrollHeight, offsetHeight, scrollTop } =  this.dialogueRef.current
      if(scrollTop === scrollHeight - offsetHeight) {
         return
      }
      let lastelmH = 32
      const { isMobile } = this.props

      if(this.dialogueRef && this.dialogueRef.current) {
         if(isMobile){
            lastelmH = this.dialogueRef.current.lastChild.offsetHeight
         } else {
            lastelmH = this.dialogueRef.current.lastChild.lastChild.offsetHeight
         }

      }
      const top = (scrollHeight - offsetHeight) + lastelmH + 16
      if(top && typeof top === 'number') {
         this.dialogueRef.current.scrollTo({
            top: (scrollHeight - offsetHeight) + lastelmH + 16,
            behavior: 'auto',
         })
      }
   }

   calcDays(date, callBack){
      const day = DateFormatter.diff(date)
      let res = null
      if(Math.round(day) < 1){
         res = 'Today'
      } else if(Math.round(day) < 2) {
         res = 'Yesterday'
      } else if(day >= 2){
         let format = 'MMMM DD'
         if(DateFormatter.convertToUserTzV2(date, 'YYYY') !==  DateFormatter.currentYears()){
            format = 'MMMM DD, YYYY'
         }
         res = DateFormatter.convertToUserTzV2(date, format)
      }
      if(callBack) {
         callBack(res)
      }
      return res

   }

   setShowButton = (bool) => {
      this.setState({
         setShowButton: bool,
      })
   }
   inputForIosFocus = (bool) => {
      const { userAgent } = window.navigator;
      const isIos = /iP(ad|od|hone)/i.test(userAgent)
      const { isMobile } = this.props
      if(!isMobile) {
         return
      }
      var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(userAgent);
      if(!isIos && !isMacLike) {
         return
      }
   }

   noScroll = (e) => {
      e = e || window.event;
      if(e.preventDefault)
         e.preventDefault();
      e.returnValue = false;
      e.stopPropagation();
      window.scrollTo(0, 0);
   }

   setErrorToLong = bool => {
      this.setState({ isMessageToLong: bool })
   }


   getCanWrite = () => {
      let res = true;
      const { isAdmin, memberCanOnlyRead, adminChatSettings } = this.props
      if(!isAdmin) {
         res = !memberCanOnlyRead
      } else {
         if(adminChatSettings) {
            const { active, type, chatOff } = adminChatSettings;
            res = active
            if('private' === type) res = !chatOff
         }
      }
      return res
   }

   getMembershipStatus = () => {
      const { converstionInfo } = this.props
      let res = 'active'
      if(converstionInfo.member){
         res = converstionInfo.member.membership_status
         if((!res && !converstionInfo.member.subscriber) || converstionInfo.member.membership_status === 'incomplete') {
            res = 'guest'
         }
      }
      return res
   }

   translatedLanguage = () => {
      const { converstionInfo, isAdmin } = this.props
      let language = null;
      if(!!converstionInfo.isGroup) return null
      const memberlanguage = converstionInfo?.member?.translte_language || '';
      if(isAdmin && memberlanguage && 'en' !== memberlanguage.toLowerCase()){
         language = getCurrentLanguageByValue(memberlanguage, true);
         if('Select' === language){
            language = null;
         }
      }
      return language;

   }

   goToAttachmentDetails = (type, id, title, vaultData, currentAttachmentsMessage, isThereOneAttachment = false) => {
      const { isAdmin, goTo } = this.props
      const { showAttachmentModal } = this.state

      if(isAdmin) {
         if(type === 'video') {
            goTo(Router.route('VIDEOS_SHOW').getCompiledPath({ id: generateUriSegment(id, title) }))
         }
         if(type === 'photoset') {
            goTo(Router.route('PHOTOSETS_SHOW').getCompiledPath({ id: generateUriSegment(id, title) }))
         }
         if(type === 'video_vault') {
            goTo(Router.route('VIDEO_VAULT_DETAILS').getCompiledPath({ id }))
         }
         if(type === 'photo_vault') {
            goTo(Router.route('VAULT').getCompiledPath())
         }

         if(!isThereOneAttachment) {
            this.setState({
               showAttachmentModal: !showAttachmentModal,
            })
         }

         return
      }

      if((!!Number(id) || (typeof id === 'string' && id.length === 32)) && ['video', 'photoset', 'photo_vault', 'video_vault'].includes(type)) {
         if(type === 'video') {
            if(currentAttachmentsMessage.unlock_details.unlock_price  === 0) {
               const link = SitesRouter.route('CHAT_UNLCOKED_CONTENT').getCompiledPath({ id: vaultData?.messageId })
               goTo(link + `?resourceId=${ id }&resourceType=${ type }`)
            } else {
               goTo(SitesRouter.route('VIDEOS_INDIVIDUAL').getCompiledPath({ id: generateUriSegment(id, title) }))
            }
         }
         if(type === 'photoset') {
            if(currentAttachmentsMessage.unlock_details.unlock_price  === 0) {
               const link = SitesRouter.route('CHAT_UNLCOKED_CONTENT').getCompiledPath({ id: vaultData?.messageId })
               goTo(link + `?resourceId=${ id }&resourceType=${ type }`)
            } else {
               goTo(SitesRouter.route('PHOTOSETS_INDIVIDUAL').getCompiledPath({ id: generateUriSegment(id, title) }))
            }
         }
         if(
            !!vaultData
            && vaultData.messageId
            && vaultData.pathHash
            && currentAttachmentsMessage && currentAttachmentsMessage.unlock_details
            && ((Number(currentAttachmentsMessage.unlock_details.unlock_price) > 0 && currentAttachmentsMessage.unlock_details.is_unlocked) || (Number(currentAttachmentsMessage.unlock_details.unlock_price) === 0 && !currentAttachmentsMessage.unlock_details.is_unlocked))
         ) {
            if(type === 'photo_vault' || type === 'video_vault') {
               const link = SitesRouter.route('CHAT_UNLCOKED_CONTENT').getCompiledPath({ id: vaultData?.messageId })
               goTo(link + `?hash=${ vaultData.pathHash }`)
            }
         }

         if(!isThereOneAttachment) {
            this.setState({
               showAttachmentModal: !showAttachmentModal,
            })
         }
      }
   }

   getContentLink = (content, status, singleAttachmentMessage = {}) => {
      const { isAdmin } = this.props
      const { currentMessage } = this.state

      const { resource_type: type, resource_id: id, title } = content

      let contentURL

      if(isAdmin) {
         if(type === 'video') {
            contentURL = Router.route('VIDEOS_SHOW').getCompiledPath({ id: generateUriSegment(id, title) })
         }
         if(type === 'photoset') {
            contentURL = Router.route('PHOTOSETS_SHOW').getCompiledPath({ id: generateUriSegment(id, title) })
         }
         if(type === 'video_vault') {
            contentURL = Router.route('VIDEO_VAULT_DETAILS').getCompiledPath({ id })
         }
         if(type === 'photo_vault') {
            let contentId = content?.hashes ? content?.hashes?.[0] : null

            if(content.vaultId) contentId = content.vaultId

            if(!contentId) contentId = id
            contentURL = Router.route('IMAGE_VAULT_DETAILS').getCompiledPath({ id: contentId })
         }
         if(!(this.props?.authUser?.uuid === singleAttachmentMessage?.user?.uuid) && singleAttachmentMessage && singleAttachmentMessage?.type === 'photo_unlock') {
            contentURL = Router.route('CHAT_GET_CONTENT').getCompiledPath({ id: singleAttachmentMessage?.id })
         }

         return contentURL
      }

      if(
         (!!Number(id) || (typeof id === 'string' && id.length === 32))
         && ['video', 'photoset', 'photo_vault', 'video_vault'].includes(type)
      ) {
         const currentAttachmentsMessage = !!Object.keys(currentMessage).length ? currentMessage : singleAttachmentMessage
         const isSingleAttachment = !!Object.keys(singleAttachmentMessage)?.length
         const price = Number(currentAttachmentsMessage?.unlock_details?.unlock_price)
         const vaultData = {
            messageId: currentAttachmentsMessage?.id,
            pathHash: content?.resources?.[0].split('.')[0],
         }
         const contentLink = SitesRouter.route('CHAT_UNLCOKED_CONTENT').getCompiledPath({ id: vaultData?.messageId })
         const linkExtraInfo = `?resourceId=${ id }&resourceType=${ type }`
         const chatUnlockContentLink = contentLink + linkExtraInfo

         if(type === 'video') {
            if(
               (!!currentAttachmentsMessage?.unlock_details?.is_unlocked && price > 0)
               // || (price === 0 && !content?.publish_date)
               || (price === 0 && !isSingleAttachment)
               || (!!content.locked_image_is_blur && !!content.cover && !content?.publish_date)
               || (isSingleAttachment && ['is_free_unlock_content', 'is_hidden_content'].includes(status))
               || (isSingleAttachment && !status && !content?.publish_date)
               || (isSingleAttachment && !content?.publish_date && content?.is_unlocked_for_view)
            ) {
               contentURL = isSingleAttachment ? contentLink : chatUnlockContentLink
            } else {
               contentURL = SitesRouter.route('VIDEOS_INDIVIDUAL').getCompiledPath({ id: generateUriSegment(id, title) })
            }
         }

         if(type === 'photoset') {
            if(
               (!!currentAttachmentsMessage?.unlock_details?.is_unlocked && price > 0)
               // || (price === 0 && !content?.publish_date)
               || (price === 0 && !isSingleAttachment)
               || (!!content.locked_image_is_blur && !!content.cover && !content?.publish_date)
               || (isSingleAttachment && ['is_free_unlock_content', 'is_hidden_content'].includes(status))
               || (isSingleAttachment && !status && !content?.publish_date)
               || (isSingleAttachment && !content?.publish_date && content?.is_unlocked_for_view)
            ) {
               contentURL = isSingleAttachment ? contentLink : chatUnlockContentLink
            } else {
               contentURL = SitesRouter.route('PHOTOSETS_INDIVIDUAL').getCompiledPath({ id: generateUriSegment(id, title) })
            }
         }

         if(
            !!vaultData
            && vaultData.messageId
            && vaultData.pathHash
            && currentAttachmentsMessage && currentAttachmentsMessage?.unlock_details
            && ((!!status && status === 'locked_free_content') || (price > 0 && currentAttachmentsMessage?.unlock_details?.is_unlocked) || (price === 0))
            && (type === 'photo_vault' || type === 'video_vault')
         ) {
            // const link = SitesRouter.route('CHAT_UNLCOKED_CONTENT').getCompiledPath({ id: vaultData?.messageId })
            // goTo(link, `?hash=${ vaultData.pathHash }`)
            contentURL = `${ SitesRouter.route('CHAT_UNLCOKED_CONTENT').getCompiledPath({ id: vaultData?.messageId }) }?hash=${ vaultData.pathHash }`
         }

         return contentURL
      }
   }

   onClickSavedReplies = (bool = true) => {
      const { setShowSavedReplies } = this.props
      setShowSavedReplies(bool)
   }

   getUniqueContentStatuses = (statusesResult, unlockPrice) => {
      const allowedStatuses = ['locked_free_content', 'is_free_unlock_content', 'non_access', 'is_hidden_content', 'already_unlocked', 'locked_for_member']
      const filteredUniqueStatuses = Array.from(new Set(statusesResult.filter(item => item.status && allowedStatuses.includes(item.status)).map(item => JSON.stringify(item)))).map(item => JSON.parse(item)?.status);
      return filteredUniqueStatuses
   }

   sendTextMessage = async (text, callback, sendWithDuration) => {
      const {
         sendMessage,
      } = this.props
      const {
         replyMessage,
      } = this.state

      if(sendMessage){
         let parent = null
         if(replyMessage){
            parent = replyMessage
            if(!isEmpty(replyMessage) && replyMessage.user)
               parent.user = {
                  username: replyMessage.user.username,
               }
         }
         await sendMessage(text, parent, null, () => {
            callback && callback()
         }, null, sendWithDuration);
      }
   }

   sendSingleAttachmentMessage = async (
      text,
      {
         attachments,
         attachmentsForShow,
         unlock_price,
         locked_poster_is_blur,
         allow_download,
      },
      callback,
      skipCheck,
      sendWithDuration
   ) => {
      const {
         converstionInfo,
         isAdmin,
         sendMessage,
         setDialogueContainerState,
      } = this.props
      const {
         replyMessage,
      } = this.state

      const isGroup = !converstionInfo.user_uuid

      let singleAttachment = attachmentsForShow[0]?.type === 'photo' ? attachmentsForShow[0] : attachments[0]

      let type = singleAttachment ? (singleAttachment?.type.includes('photo') ? 'photo_unlock' : 'video_unlock')  : ''// unlockMessageType;

      const currentAttachment = singleAttachment
      const typeKey = singleAttachment && !!singleAttachment?.resource_type ? 'resource_type' : 'type'
      const resourcesArr = singleAttachment ? (singleAttachment.type === 'photo' ? singleAttachment.filesArr  : singleAttachment.resources) : '' // resources
      let attachmentsStatuses = []
      let checkStatusData = {}

      /////////////////////////////

      let currentContent = singleAttachment


      if('upload_video' === currentAttachment.type){
         currentContent = {
            type: 'video_vault',
            id: currentAttachment.videoUpload ?  currentAttachment.videoUpload.id : null,
         }
      }
      try {
         let info = {
            type: currentContent.type,
            resource_id: currentContent.id,
            member_uuid: converstionInfo.user_uuid,
            is_group_chat: isGroup,
         }
         if(!isGroup){
            info = {
               ...info,
               price: +unlock_price,
               member_uuid: converstionInfo.user_uuid,
            }
         }
         if('photo_vault' === currentAttachment.type) {
            info = {
               ...info,
               paths: currentAttachment.resources,
               hashs: [],
            }
         }
         if('photo' === currentAttachment.type){
            const { prevFiles } = currentAttachment;
            let hashs = [];
            if(prevFiles) {
               prevFiles.forEach(element => {
                  hashs.push(element.hash)
               });
            }
            info = {
               ...info,
               type: 'photo_vault',
               paths: currentAttachment.filesArr ?  currentAttachment.filesArr : [],
               hashs: hashs,
            }
         }


         if(singleAttachment) {
            if("photo_vault" === currentContent?.resource_type) {
               info = {
                  ...info,
                  type: currentContent?.resource_type,
                  paths: currentContent?.resources,
                  hashs: currentContent?.hashes || [],
               }

            }

            if("video_vault" === currentContent?.resource_type) {
               info = {
                  ...info,
                  type: currentContent?.resource_type,
                  resource_id: currentContent?.resource_id,
               }
            }

            if((currentContent.resource_type === 'video' || currentContent.resource_type === 'photoset')) {
               info.resource_id = currentContent.resource_id
               info.type = currentContent.resource_type
            }

         }

         let checkResult = {}

         const checkData = await this.props.checkContentTypeInTheChat(info)
         checkResult = checkData.data

         attachmentsStatuses = [checkResult]
         let hash = checkResult.hash || null
         let status = checkResult.status || null

         if(checkResult.status) {
            let statusData = {
               locked_free_content: {
                  text: 'The content you are about to send has already been unlocked by this member, or there is access through content settings. Payment will not be required to view.',
                  icon: 'attention-outline',
                  title: 'No payment required to view',
                  color: 'action',
               },
               is_free_unlock_content: {
                  text: 'You are about to send locked content for free',
                  icon: 'warning',
                  title: 'Locked content for free',
                  color: 'warning',
               },
               is_hidden_content: {
                  text: `You are about to give access to content that is hidden for their current membership`,
                  icon: 'warning',
                  title: 'Hidden content',
                  color: 'warning',
               },
            }

            if(status === 'locked_free_content') {
               attachmentsStatuses = [{ status: null }]
            }

            checkStatusData = {
               // ...checkStatusData,
               unlockPrice: 'locked_free_content' === status ? 0 : unlock_price,
               resources: resourcesArr,
               isBlur: locked_poster_is_blur,
               openWarningModal: true,
               warningText: statusData[status]?.text,
               modalTitle: statusData[status]?.title,
               icon: statusData[status]?.icon,
               color: statusData[status]?.color,
               hash,
               status,
               publish_date: currentAttachment?.publish_date,
               details: {
                  attachments,
                  attachmentsForShow,
                  unlock_price,
                  locked_poster_is_blur,
                  allow_download,
                  text,
               },
               callback,
               sendWithDuration,
            }
            if(!skipCheck) {

               setDialogueContainerState({
                  checkStatusData,
               })
               return
            }

         }

      } catch (error) {
         toast.error('Something went wrong');
      }

      //////////////////////////////

      let data = {
         unlockPrice: +unlock_price  || 0, // +price || 0,
         lockedPosterIsBlur: !!locked_poster_is_blur, //blur,
         resources: resourcesArr,
         type: type,
         resourceId: null,
         text: text,
         allowDownload: allow_download,
         attachmentsStatuses: attachmentsStatuses,
         publish_date: currentAttachment?.publish_date,
      }

      if('locked_free_content' === checkStatusData?.status){
         data.unlockPrice = 0;
      }

      if(currentAttachment && (currentAttachment?.[typeKey] === 'video' || currentAttachment?.[typeKey] === 'photoset' || currentAttachment?.[typeKey].includes('_vault'))) {
         data.resourceId = currentAttachment.id || currentAttachment.resource_id
         data.resourceType = currentAttachment?.[typeKey]
         if('photo_vault' === currentAttachment?.[typeKey] && checkStatusData?.hash)  data.resourceId = checkStatusData?.hash
      }
      if(currentAttachment && 'upload_video' === currentAttachment.type){
         data.resourceId =  currentAttachment.videoUpload.id || null
         data.resourceType = 'video_vault'
      }
      if(currentAttachment && 'photo' === currentAttachment.type){
         data.resourceId = singleAttachment?.hashs?.[0]
         data.resourceType = 'photo_vault'
      }
      if(isAdmin){
         if('photo_unlock' === type){
            try {

               const blurhash = await this.props.getMediaBlurhash(resourcesArr?.[0])
               data.blurhash = blurhash

            } catch (error) {
            }
         } else {
            if('upload_video' === currentAttachment.type) {
               data.coverImage = currentAttachment.videoUpload.video_poster
               data.duration = currentAttachment.videoUpload.video_duration || 1200
               data.resources = [currentAttachment.videoUpload.src]
            } else {
               data.coverImage = currentAttachment.cover_image || currentAttachment.posterImage || currentAttachment.poster || currentAttachment?.resources?.[0]
               data.duration = currentAttachment.duration || currentAttachment.video_duration || 1200
               if(!currentAttachment.resources) {
                  data.resources = [currentAttachment.src]
               }

            }
         }
      }

      if(singleAttachment && singleAttachment?.basename && singleAttachment?.type === 'video_unlock') {
         data.basename = singleAttachment?.basename
      }

      if(sendMessage) {
         let parent = null
         if(replyMessage){
            parent = replyMessage

            if(!isEmpty(replyMessage) && replyMessage.user)
               parent.user = {
                  username: replyMessage.user.username,
               }
         }
         sendMessage(data, parent, null, async () => {

            if(callback) await callback()
         }, null, sendWithDuration);
      }
   }
   sendMultipleAttachmentMessage = async (
      text,
      {
         attachments,
         attachmentsForShow,
         unlock_price,
         locked_poster_is_blur,
         allow_download,
      },
      callback,
      skipCheck,
      sendWithDuration
   ) => {
      const {
         converstionInfo,
         setDialogueContainerState,
         sendMessage,
      } = this.props
      const {
         replyMessage,
      } = this.state

      const isGroup = !converstionInfo.user_uuid


      const attachmentsStatus = []
      let attachmentsStatuses = []
      let checkStatusData = {}


      attachments.forEach(async (ctnt) => {

         try {
            let info = {
               is_group_chat: false,
            }

            if(!isGroup){
               info = {
                  ...info,
                  price: +unlock_price,
                  member_uuid: converstionInfo.user_uuid,
               }
            }

            if("photo_vault" === ctnt?.resource_type) {
               info = {
                  ...info,
                  type: ctnt?.resource_type,
                  paths: ctnt?.resources,
                  hashs: ctnt?.hashes || [],
               }

            }

            if("photoset" === ctnt?.resource_type) {
               info = {
                  ...info,
                  type: ctnt?.resource_type,
                  resource_id: ctnt?.resource_id,
               }
            }


            if("video" === ctnt?.resource_type) {
               info = {
                  ...info,
                  type: ctnt?.title ? ctnt?.resource_type : 'video_vault',
                  resource_id: ctnt?.resource_id,
               }
            }


            if("video_vault" === ctnt?.resource_type) {
               info = {
                  ...info,
                  type: ctnt?.resource_type,
                  resource_id: ctnt?.resource_id,
               }
            }


            attachmentsStatus.push(this.props.checkContentTypeInTheChat(info))

         } catch (error) {
            toast.error('Something went wrong');
         }

      })

      let statusesResult = (await Promise.allSettled(attachmentsStatus)).map(({ value }) => {
         let status  = value?.data?.status
         if(+unlock_price > 0 && !status) {
            status = 'locked_for_member'
         }
         //  else if((!unlockPrice || +unlockPrice === 0) && !status) {
         //    status = 'already_unlocked'
         // }
         return { status }
      })

      attachmentsStatuses = (await Promise.allSettled(attachmentsStatus)).map(it => it.value.data)

      const statuses = this.getUniqueContentStatuses(statusesResult, unlock_price)

      const showAccessWarningPopup = !isGroup && statuses.length > 1
      const isThereOnlyHiddenContent = statuses?.length === 1 && statuses?.[0] === 'is_hidden_content'
      const isThereOnlyLockedContent = statuses?.length === 1 && statuses?.[0] === 'is_free_unlock_content'

      if(isThereOnlyHiddenContent) statusesResult = [{ status: 'is_hidden_content' }]
      if(isThereOnlyLockedContent && !unlock_price) statusesResult = [{ status: 'is_free_unlock_content' }]


      let statusData = {
         locked_free_content: {
            text: 'The content you are about to send has already been unlocked by this member, or there is access through content settings. Payment will not be required to view.',
            icon: 'attention-outline',
            title: 'No payment required to view',
            color: 'action',
         },
         is_free_unlock_content: {
            text: 'You are about to send locked content for free',
            icon: 'warning',
            title: 'Locked content for free',
            color: 'warning',
         },
         is_hidden_content: {
            text: `You are about to give access to content that is hidden for their current membership`,
            icon: 'warning',
            title: 'Hidden content',
            color: 'warning',
         },
         access_warning: {
            text: 'You are about to send content that may already be unlocked, accessible, or hidden, based on the current content access settings',
            icon: 'warning',
            title: 'Access warning',
            color: 'warning',
         },
         is_unpublished: {
            text: 'You are about to send content that may already be unlocked, accessible, or hidden, based on the current content access settings',
            icon: 'warning',
            title: 'Access warning',
            color: 'warning',
         },
      }

      if(!!showAccessWarningPopup) {
         checkStatusData = {
            // ...checkStatusData,
            unlockPrice: unlock_price,
            resources: [],
            isBlur: locked_poster_is_blur,
            openWarningModal: true,
            warningText: statusData['access_warning']?.text,
            modalTitle: statusData['access_warning']?.title,
            icon: statusData['access_warning']?.icon,
            color: statusData['access_warning']?.color,
            hash: null,
            status: 'access_warning',
            details: {
               attachments,
               attachmentsForShow,
               unlock_price,
               locked_poster_is_blur,
               allow_download,
               text,
            },
            callback,
            sendWithDuration,
         }


         if(!skipCheck) {
            setDialogueContainerState({
               checkStatusData,
            })
            return
         }

      }

      if(!isGroup && statusesResult.every(it => it?.status === 'locked_free_content')) {

         checkStatusData = {
            // ...checkStatusData,
            unlockPrice: 0,
            resources: [],
            isBlur: locked_poster_is_blur,
            openWarningModal: true,
            warningText: statusData['locked_free_content']?.text,
            modalTitle: statusData['locked_free_content']?.title,
            icon: statusData['locked_free_content']?.icon,
            color: statusData['locked_free_content']?.color,
            hash: null,
            status: 'locked_free_content',
            details: {
               attachments,
               attachmentsForShow,
               unlock_price,
               locked_poster_is_blur,
               allow_download,
               text,
            },
            callback,
            sendWithDuration,
         }


         if(!skipCheck) {
            setDialogueContainerState({
               checkStatusData,
            })
            return
         }

      }
      else if(statusesResult.every(it => it?.status === 'is_free_unlock_content')) {
         checkStatusData = {
            // ...checkStatusData,
            unlockPrice: unlock_price,
            resources: [],
            isBlur: locked_poster_is_blur,
            openWarningModal: true,
            warningText: statusData['is_free_unlock_content']?.text,
            modalTitle: statusData['is_free_unlock_content']?.title,
            icon: statusData['is_free_unlock_content']?.icon,
            color: statusData['is_free_unlock_content']?.color,
            hash: null,
            status: 'is_free_unlock_content',
            details: {
               attachments,
               attachmentsForShow,
               unlock_price,
               locked_poster_is_blur,
               allow_download,
               text,
            },
            callback,
            sendWithDuration,
         }

         if(!skipCheck) {
            setDialogueContainerState({
               checkStatusData,
            })
            return
         }

      }
      else if(!isGroup && statusesResult.every(it => it?.status === 'is_hidden_content')) {

         checkStatusData = {
            // ...checkStatusData,
            unlockPrice: unlock_price,
            resources: [],
            isBlur: locked_poster_is_blur,
            openWarningModal: true,
            warningText: statusData['is_hidden_content']?.text,
            modalTitle: statusData['is_hidden_content']?.title,
            icon: statusData['is_hidden_content']?.icon,
            color: statusData['is_hidden_content']?.color,
            hash: null,
            status: 'is_hidden_content',
            details: {
               attachments,
               attachmentsForShow,
               unlock_price,
               locked_poster_is_blur,
               allow_download,
               text,
            },
            callback,
            sendWithDuration,
         }

         if(!skipCheck) {
            setDialogueContainerState({
               checkStatusData,
            })
            return
         }

      }

      let data = {
         // resources: resources,
         // type: type,
         // resourceId: null,
         unlockPrice: +unlock_price || 0,
         lockedPosterIsBlur: locked_poster_is_blur,
         text,
         allowDownload: allow_download,
         type: 'multiple_attachment',
         resources: [],
         attachmentsStatuses: attachmentsStatuses,
      }

      data.attachments = (await Promise.allSettled(attachments
         .map(async (media) => {
            if('photo_vault' === media.resource_type || 'photoset' === media.resource_type || (('video' === media.resource_type || 'video_vault' === media.resource_type) && !isLocalhost())){

               let newMedia = {
                  ...media,
               }
               try {
                  const res = await this.props.getMediaBlurhash(
                     media.resource_type.includes('video') ? media.cover_image : media.resources[0],
                     media.resource_type.includes('video') ? 'video' : 'photoset',
                     media.resource_type.includes('video') ? media?.basename || media?.resources[0].split('.')[0] : null
                  )

                  newMedia.blurhash = res

               } catch (error) {
                  console.log(error);
               }

               return newMedia

            } else if('upload_video' === media?.type && media?.status === 'completed'){
               let newMedia = {
                  cover_image: media?.cover_image || media?.videoUpload?.video_poster || media?.resources[0],
                  resource_id: media?.id,
                  resource_type: 'video_vault',
                  resources: media?.resources,
                  src: media?.src,
                  type: 'video_unlock',
                  video_duration: media?.count || media?.video_duration,
               }

               if(!isLocalhost()) {
                  try {
                     const res = await this.props.getMediaBlurhash(
                        newMedia.resource_type.includes('video') ? newMedia.cover_image : newMedia.resources[0],
                        newMedia.resource_type.includes('video') ? 'video' : 'photoset',
                        newMedia.resource_type.includes('video') ? newMedia?.resources[0].split('.')[0] : null
                     )

                     newMedia.blurhash = res

                  } catch (error) {
                     console.log(error);
                  }
               }

               return newMedia

            } else {
               return media
            }
         }))
      ).map(it => it.value)


      let parent = null
      if(replyMessage){
         parent = replyMessage

         if(!isEmpty(replyMessage) && replyMessage.user)
            parent.user = {
               username: replyMessage.user.username,
            }
      }

      if(sendMessage) {
         sendMessage(data, parent, null, async () => {

            if(callback) await callback()
         }, null, sendWithDuration);
      }

   }


   onSendReply = async (reply) => {
      const {
         sendMessage,
         converstionInfo,
         isAdmin,
         setShowSavedReplies,
         showSavedReplies,
         setDialogueContainerState,
         updateCurrentConversation,
      } = this.props
      const {
         replyMessage,
      } = this.state

      let {
         // id,
         name,
         body,
         attachments,
         attachmentsForShow,
         allow_download,
         locked_poster_is_blur,
         unlock_price,
         voice_message_duration,
         voice_message_data,
         voice_message_make_default_price_for_voice_messages,
         voice_message_price,
         show_duration_countdown_to_member,
         duration_in_minutes,
         // body,
      } = reply


      if(!!body) {
         body = (await replaceVariables(body, converstionInfo?.user_uuid)).data
      }

      const sendWithDuration = !!updateCurrentConversation && !!duration_in_minutes

      if(!!name && !attachments && !voice_message_data) {

         if(sendWithDuration) {
            flushSync(() => {
               updateCurrentConversation({
                  durationInfo: {
                     show_duration_countdown_to_member,
                     duration_in_minutes: duration_in_minutes,
                  },
               })
            })
         }

         await this.sendTextMessage(body, () => {
            toast.success('Reply has been sent', {
               toastId: 'reply_sent',
            })

            this.onReplyMessage(null)

            setDialogueContainerState({
               isSendingReply: false,
               sendingReply: null,
               checkStatusData: {},
            })



            setShowSavedReplies(!showSavedReplies)
            this.onScrollToButton()

         }, sendWithDuration)
      }

      if(!!name && !!attachments && !!attachmentsForShow && !voice_message_data) {

         if(attachments.length === 1 && attachmentsForShow.length === 1)  {

            if(sendWithDuration) {
               updateCurrentConversation({
                  durationInfo: {
                     show_duration_countdown_to_member,
                     duration_in_minutes: duration_in_minutes,
                  },
               })
            }

            let singleAttachment = attachmentsForShow[0]?.type === 'photo' ? attachmentsForShow[0] : attachments[0]

            this.sendSingleAttachmentMessage(body, {
               attachments,
               attachmentsForShow,
               unlock_price,
               locked_poster_is_blur,
               allow_download,
            },
            async () => {

               if(isAdmin && 'photo' === singleAttachment.type && singleAttachment.vaultPhotos && singleAttachment.vaultPhotos.length > 0) {
                  try {
                     await this.props.createVault(singleAttachment.vaultPhotos)
                  } catch (error) {

                  }
               }
               this.onReplyMessage(null)

               setDialogueContainerState({
                  isSendingReply: false,
                  sendingReply: null,
                  checkStatusData: {},
                  isSendingAnyway: false,
               })

               setShowSavedReplies(!showSavedReplies)
               this.onScrollToButton()

               toast.success('Reply has been sent', {
                  toastId: 'reply_sent',
               })

            }, false, sendWithDuration)

         }

         if(attachments.length > 1 && attachmentsForShow.length > 1)  {

            if(sendWithDuration) {
               updateCurrentConversation({
                  durationInfo: {
                     show_duration_countdown_to_member,
                     duration_in_minutes: duration_in_minutes,
                  },
               })
            }

            this.sendMultipleAttachmentMessage(body, {
               attachments,
               attachmentsForShow,
               unlock_price,
               locked_poster_is_blur,
               allow_download,
            }, async () => {

               if(attachmentsForShow.filter(ct => ct?.type === 'photo' && ct?.vaultPhotos?.length > 0).length > 0) {
                  try {
                     const newVaults = attachmentsForShow.reduce((prev, curr) => {
                        if(curr?.type === 'photo' && curr?.vaultPhotos?.length > 0) {
                           prev.push(curr?.vaultPhotos?.[0])
                        }
                        return prev
                     }, [])
                     await this.props.createVault(newVaults)
                  } catch (error) {

                  }
               }

               this.onReplyMessage(null)

               setDialogueContainerState({
                  isSendingReply: false,
                  sendingReply: null,
                  checkStatusData: {},
                  isSendingAnyway: false,
               })


               setShowSavedReplies(!showSavedReplies)
               this.onScrollToButton()

               toast.success('Reply has been sent', {
                  toastId: 'reply_sent',
               })

            }, false, sendWithDuration)
         }

      }

      if(!!name && !!voice_message_data && typeof voice_message_data === 'string' && voice_message_duration && !attachments && !attachmentsForShow) {
         const messageData = {
            unlockPrice: voice_message_price || 0,
            lockedPosterIsBlur: null,
            resources: [voice_message_data],
            type: 'voice_message',
            resourceId: null,
            text: '',
            duration: voice_message_duration,
            makeDefaultPrice: !!voice_message_make_default_price_for_voice_messages,
         }

         if(sendMessage) {

            if(sendWithDuration) {
               updateCurrentConversation({
                  durationInfo: {
                     show_duration_countdown_to_member,
                     duration_in_minutes: duration_in_minutes,
                  },
               })
            }

            let parent = null
            if(replyMessage){
               parent = replyMessage
               if(!isEmpty(replyMessage) && replyMessage.user)

                  parent.user = {
                     username: replyMessage.user.username,
                  }
            }

            sendMessage(messageData, parent, null, () => {
               if(replyMessage && replyMessage.parentMessageId) {
                  this.onReplyMessage(null)
               }

               setDialogueContainerState({
                  isSendingReply: false,
                  sendingReply: null,
                  checkStatusData: {},
                  isSendingAnyway: false,
               })

               setShowSavedReplies(!showSavedReplies)
               this.onScrollToButton()
            }, { updateMessageType: this.state.updateMessageType }, sendWithDuration)
         }
      }

   }

   deleteReplyTimeout = null

   replyTimeout = null


   render() {
      const {
         authUser,
         isMobile,
         messages,
         onClickUserAvatar,
         unlock,
         isAdmin,
         onScroll,
         isNewFetching,
         onFetchLikesInfo,
         likesInfo,
         onLikedMemberPagination,
         onUserTyping,
         typerText,
         onLikeMessage,
         onDeleteMessag,
         sendMessage,
         isEmpty,
         converstionInfo,
         mutedInfo,
         unmuteMember,

         emptyState,
         onReadMessage,
         lastSeen,
         diff = 56,
         setMutedMemberInfo,
         isConnected,

         adminChatSettings,
         goToChatSettings,

         unblockMember,
         closeChatPopup,
         selectFileByLiber,
         removeSelectedContent,
         onStream,
         deleteVaultVideo,
         onSaveUploadPhotos,
         goToContentMessage,
         needToJoin,
         goToJoin,
         purchaseMessageId,
         paymentButtonLoading,
         maxPrice,
         offLiveStreamChat,
         isLivstream,
         isMiniChat,
         downloadFile,
         onSendVoiceMessage,
         adminUuid,
         privateAttachmentSettings,
         defaultVoicePrice,
         onUpdateRecordStatus,
         paymentButtonResourceId,
         handleUploadImage,
         cancelVideoUplaod,
         checkVaultByHash,
         createVault,
         checkContentTypeInTheChat,
         getMediaBlurhash,
         goTo,
         attachments,
         attachmentsForShow,
         onRemoveMedia,
         onClickCancel,
         isDemoMode,
         mainMessageId,
         screenWidth,
         siteInfo,
         isMaxAttachmentCountReached,
         setReplyData,
         repliesData,
         newReplyData,
         currentReplyData,
         showSavedReplies,
         setShowSavedReplies,
         repliesModalState,
         setDialogueContainerState,
         isChangingRepliesModalState,
         onChangeReply,
         isOpenDeleteReplyModal,
         replyForDelete,
         getReplies,
         repliesFilter,
         onChangeRepliesFilter,
         handleRepliesModalScroll,
         isLoadingReplies,
         isLoadingRepliesNextPage,
         readyToSendReply,
         replyErrors,
         isSavingReply,
         isSendingReply,
         sendingReply,
         checkStatusData,
         isOpenRepliesDiscardModal,
         isSendingAnyway,
         selectedAttachments,
         getQueryParam,
         onReorderEnd,
         isRepliesMaxAttachmentCountReached,
         durationInfo,
         onSaveDurationData,
         isFetchingChatSettingsUpdate,
         onClickNotificationMessageButton,
      } = this.props;
      const {
         startScroll,
         replyMessage,
         updateMessageId,
         setShowButton,
         keyH,
         isMessageToLong,
         showAttachmentModal,
         currentMessage,
         openDeleteConfirmModal,
         openDeleteAttachmentModal,
         attachmentStateForDelete,
         updateMessageType,
      } = this.state
      const showTypeField = this.getCanWrite();
      const userStatus = this.getMembershipStatus();

      const scrollToView = (
         this?.dialogueRef?.current && setShowButton && (
            <div
               className={ cx({
                  'w-8 h-8 z-50 absolute -top-8 left-[calc(50%-32px)] grid place-items-center group bg-major-20 rounded-full hover:bg-panel duration-300 cursor-pointer backdrop-blur-[10px]': true,
                  '-top-[180px]': !!isAdmin && !!adminChatSettings.type && (!!adminChatSettings.chatOff || !adminChatSettings.active),
               }) }
               role='presentation'
               onClick={ () => {
                  ChatHepler.syncDialogueScroll('smooth', converstionInfo.chat_room_id)
                  if(!isMobile) {
                     ChatHepler.inputState()
                  }
               } }
            >
               <Icon
                  name='downward'
                  size='xl'
                  className='text-major-dark group-hover:text-secondary'
               />
            </div>
         )
      )

      return (
         <>
            <div
               style={ isMobile ? {
                  height: `calc(100% - ${ keyH + diff }px)`,
               } : {
                  overflowY: 'hidden',
               } }
               className={ cx({
                  'flex flex-1 flex-col w-full bg-panel dark:bg-panel-dark chat-dialogue-content relative z-1': true,
                  'notranslate': !siteInfo?.chat_settings?.chat_auto_translator,
               }) }
               ref={ this.containerRef }
            >
               {
                  !!converstionInfo && !!converstionInfo.user_uuid && (
                     <>
                        {
                           converstionInfo.muted_since && converstionInfo.member && !converstionInfo.member.banned && (
                              <div
                                 className='w-full z-10 mb-px'>
                                 <MuteMember
                                    unmuteMember={ () => unmuteMember(converstionInfo.user_uuid) }
                                    info={
                                       {
                                          muted_period_in_seconds: converstionInfo.muted_period_in_seconds,
                                          muted_since: converstionInfo.muted_since,
                                       }

                                    }
                                 />
                              </div>
                           )
                        }
                        {
                           ((converstionInfo.member && !!converstionInfo.member.banned) || !!converstionInfo.user_banned) && (
                              <div
                                 className='w-full z-10'>
                                 <MuteMember
                                    type='block'
                                    unblockMember={ unblockMember }
                                    info={ {} }
                                 />
                              </div>
                           )
                        }
                     </>
                  )
               }
               {
                  isNewFetching && (
                     <>
                        <div className='py-4 mt-8 flex w-full items-center justify-center absolute z-20 top-0'>
                           <CircleLoader />
                        </div>
                     </>
                  )
               }
               {
                  document.getElementById('chat-input-field') && createPortal(
                     scrollToView,
                     document.getElementById('chat-input-field')
                  )
               }
               {
                  !isConnected && (
                     <div className='absolute left-0 w-full flex items-convertToUserTz'>
                        <div
                           style={ isMobile ?  {
                              maxWidth: isAdmin ? '351px' : '100%',
                           }  : {} }
                           className='no-internet-content flex items-center h-9 bg-warning dark:bg-warning-dark z-30 justify-center mx-auto lg:mt-5 mt-4'>
                           <div className='flex h-6 w-6 items-center justify-center mr-2'>
                              <Icon
                                 name='no-internet'
                                 color='major-dark'
                                 size='lg'
                              />
                           </div>
                           <span className='text-major-dark font-medium text-sm leading-none'> No internet connection</span>

                        </div>
                     </div>
                  )
               }
               <div
                  onScroll={ (e) => {
                     if(onScroll && startScroll) {
                        onScroll(e)
                     }
                     const {
                        scrollTop, scrollHeight, offsetHeight,
                     } = e.target
                     if(scrollTop < (scrollHeight - offsetHeight - 300) && !setShowButton) {
                        this.setShowButton(true)
                     }
                     if(scrollTop + 100 >= scrollHeight - offsetHeight && !!setShowButton){
                        this.setShowButton(false)
                     }
                     if((scrollHeight - scrollTop) === offsetHeight) {
                        if(!isAdmin) {
                           onReadMessage()
                        }
                     }
                  } }
                  ref={ this.dialogueRef }
                  id={ converstionInfo.chat_room_id ?  `messages-content-${ converstionInfo.chat_room_id }` : '' }
                  className={ cx({
                     "flex-1 overflow-y-auto overflow-x-hidden px-4 justify-between messages-content ams-custom-scrool relative z-2 !outline-none": true,
                     "pb-2": lastSeen,
                     "pt-16": !isEmpty,
                  }) }
                  role='presentation'
                  onClick={ () => {
                     if(!isAdmin) {
                        onReadMessage()
                     }
                  } }

               >
                  <div className={ `flex flex-1 flex-col relative justify-end ${ isEmpty ? 'h-full' : '' }` }>

                     {
                        updateMessageId && (
                           <div className='absolute top-0 left-0 z-10 opacity-50 bg-panel dark:bg-panel-dark w-full h-full' />
                        )
                     }
                     {
                        isEmpty && (
                           <EmptyConversation
                              data={ emptyState }
                           />
                        )
                     }
                     {
                        Array.isArray(messages) && messages.slice(0).reverse().map((message, index) => {

                           if(message.type && message.type.includes('promo_') && !message.promo_resource){
                              return null
                           }
                           let reverseMessages = messages.slice(0).reverse()
                           const user = message.user ? message.user : {}
                           const nextMessage = reverseMessages[index - 1] ? reverseMessages[index - 1] : {};
                           let restult = null
                           if(message.sent_at) {
                              this.calcDays(message.sent_at, (res) => {
                                 let bool = false
                                 let next = this.calcDays(nextMessage.sent_at)
                                 if(res === next) {
                                    bool = false
                                    restult = null
                                 } else {
                                    bool = true
                                    restult = res
                                 }
                                 return bool
                              })
                           }
                           const parent = message?.parentMessage || message?.parent;
                           return (
                              <Fragment key={ message.id }>
                                 {
                                    restult && !isNewFetching && (
                                       <span className='text-center text-placeholder text-sm my-4 dark:text-placeholder-dark'>
                                          {restult}
                                       </span>
                                    )
                                 }
                                 <Message
                                    unlock={ unlock }
                                    onClickUserAvatar={ onClickUserAvatar ? () => {
                                       onClickUserAvatar(message)
                                    } : null }
                                    isMyMessage={ authUser.uuid === user.uuid }
                                    isMobile={ isMobile }
                                    user={ user }
                                    message={ message }
                                    onClickLockContent={ () => this.onClickLockContent('pay', true) }
                                    nextIsMyMessage={ restult ? false : nextMessage.user ? user.uuid === nextMessage.user.uuid  : false }
                                    parent={ parent }
                                    onFetchLikesInfo={ () => onFetchLikesInfo(message.id) }
                                    onLikedMemberPagination={ (e) => onLikedMemberPagination(e, message.id) }
                                    likes={ likesInfo }
                                    onLikeMessage={ (isLiked) => {
                                       onLikeMessage(message.id, isLiked)
                                    } }
                                    onDeleteMessag={ () => {
                                       onDeleteMessag(message.id, () => {}, null, true, message)
                                    } }
                                    onReplyMessage={ this.onReplyMessage }
                                    updateMessage={ this.updateMessage }
                                    isUpdate={ updateMessageId === message.id }
                                    isGroup={ !converstionInfo.user_uuid }
                                    isAdmin={ isAdmin }
                                    closeChatPopup={ closeChatPopup }
                                    onStream={ (isParent = false) => {
                                       if(onStream) {
                                          let messageData = !isParent ?  { ...message } : { ...parent };
                                          if('voice_message' === messageData.type){
                                             messageData.type = 'voice_unlock';
                                          }
                                          onStream(messageData, message.id);
                                       }
                                    } }
                                    goToContentMessage={ goToContentMessage }
                                    paymentButtonLoading={ mainMessageId === purchaseMessageId &&  paymentButtonLoading && purchaseMessageId === message.id && paymentButtonResourceId && paymentButtonResourceId.includes(message.id) }
                                    isMiniChat={ isMiniChat }
                                    goToJoin={ (id, notifiaction) => {
                                       closeChatPopup()
                                       goToJoin(id, notifiaction)
                                    }  }
                                    downloadFile={ downloadFile }
                                    adminUuid={ adminUuid }
                                    lastElement={ index === messages.length - 1 }
                                    goToDetails={ () => {
                                       if(isAdmin && adminUuid !== user?.uuid && (isMiniChat || !converstionInfo.isGroup)) {
                                          return goTo(Router.route('MEMBERS_SHOW').getCompiledPath({ id: user?.id }))
                                       } else {
                                          return false
                                       }
                                    } }
                                    authUser={ authUser }
                                    replyMessage={ replyMessage }
                                    isLivstream={ isLivstream }
                                    onClickAttachment={ (message) => {
                                       const isThereOneAttachment = message?.unlock_details?.attachments?.length === 1
                                       const content = message?.unlock_details?.attachments?.[0]

                                       if(
                                          isThereOneAttachment &&
                                          (!!Number(content.resource_id) || (typeof content.resource_id === 'string' && content.resource_id.length === 32))
                                          && ['video', 'photoset', 'photo_vault', 'video_vault'].includes(content.resource_type)
                                          && (
                                             (
                                                ['video', 'photoset'].includes(content?.resource_type)
                                                  && message?.unlock_details?.attachmentsStatuses
                                                  && 'status' in message?.unlock_details?.attachmentsStatuses?.[0]
                                                  && message?.unlock_details?.attachmentsStatuses?.[0]?.status !== 'is_hidden_content'
                                             )
                                             ||
                                             (['photo_vault', 'video_vault', 'video', 'photoset'].includes(content.resource_type))
                                          )
                                       ) {
                                          this.goToAttachmentDetails(
                                             content.resource_type,
                                             content.resource_id,
                                             content.title,
                                             {
                                                messageId: message?.id,
                                                pathHash: content?.resources?.[0].split('.')[0],
                                             },
                                             messages?.find(it => it?.id === message?.id),
                                             true
                                          )
                                          return
                                       }

                                       this.setState({
                                          currentMessage: message,
                                          showAttachmentModal: true,
                                       })
                                    } }
                                    onRemoveMedia={ onRemoveMedia }
                                    screenWidth={ screenWidth }
                                    getContentLink={ this.getContentLink }
                                    onClickNotificationMessageButton={ onClickNotificationMessageButton }
                                 />
                                 {
                                    index === messages.length - 1 && (
                                       <div
                                          style={ {
                                             minHeight: lastSeen ? '14px' : '4px',
                                          } }
                                          className='flex items-center justify-end mt-3'>
                                          {
                                             lastSeen &&  DateFormatter.checkIsValid(lastSeen)  && (
                                                <>
                                                   <div
                                                      style={ {
                                                         width: '15px',
                                                         height: '10px',
                                                         marginRight: '6px',
                                                      } }
                                                      className='flex items-center justify-center'
                                                   >
                                                      <Icon
                                                         name='views'
                                                         color='placeholder'
                                                         size='base'
                                                      />
                                                   </div>
                                                   <span
                                                      style={ {
                                                         lineHeight: '14px',
                                                      } }
                                                      className='text-placeholder text-sm dark:text-placeholder-dark'>
                                                      {DateFormatter.convertToUserTzV2(lastSeen, 'MMM DD, YYYY • hh:mm A')}
                                                   </span>
                                                </>
                                             )
                                          }
                                       </div>
                                    )
                                 }
                              </Fragment>
                           )

                        })
                     }
                     {
                        typerText && (
                           <div>
                              <SpinnerAnimation
                                 className='mt-2'
                                 text={ typerText }
                              />
                           </div>
                        )
                     }

                  </div>
               </div>
               {
                  adminChatSettings?.chat_auto_translator && this.translatedLanguage() && (
                     <div className='flex h-[18px] items-center justify-center m-2'>
                        <div className='flex items-center justify-center h-4 w-4 min-[16px] mr-2'>
                           <Icon
                              name='translate'
                              color='placeholder'
                              size='base'
                           />
                        </div>
                        <div className='flex items-center flex-row gap-3'>
                           <span className='text-xs text-placeholder leading-[18px]'>{converstionInfo.isGroup ? "Your messages will be translated according to the member's language preference" : `Your messages will be translated to ${ this.translatedLanguage() }`}</span>
                           <Icon
                              name='settings'
                              color='secondary'
                              size='base'
                              className='cursor-pointer'
                              onClick={ () => goTo(Router.route('CHAT_SETTINGS').getCompiledPath()) }
                              data-tooltip-id='ams-top-tooltip'
                              data-tooltip-content='Chat settings'
                           />
                        </div>
                     </div>
                  )
               }
               {
                  !!isAdmin && !!adminChatSettings.type && (!!adminChatSettings.chatOff || !adminChatSettings.active) && (
                     <div
                        className={ classNames({
                           'shadow dark:shadow-dark flex flex-col bg-panel dark:bg-panel-dark p-4': true,
                           'pb-0': showTypeField,
                        }) }
                     >
                        <span className='text-base font-bold text-major mt-1 dark:text-major-dark'>
                           {
                              'private' === adminChatSettings.type ?
                                 (adminChatSettings.chatOff ?  'Private chat is OFF ' : 'This member cannot reply') :
                                 `${ adminChatSettings.type.charAt(0).toUpperCase() + adminChatSettings.type.slice(1) } chat is OFF`
                           }

                        </span>
                        <span className='mt-1 text-secondary leading-tight dark:text-secondary-dark panel-description'>
                           {
                              'private' === adminChatSettings.type ?

                                 (adminChatSettings.chatOff
                                    ? 'You’ve turned off your private chat. Now it’s not visible on your site and you cannot send messages in private chat.' :
                                    `You have disabled sending private messages for ${ userStatus } members. They can see your messages but they cannot reply.`)
                                 :
                                 ' You’ve turned off your group chat. Now it’s not visible on your site and you cannot send messages in group chat.'
                           }
                        </span>
                        <div className='h-9 inline-flex mr-auto mt-4'>
                           <Button
                              text='Go to chat settings'
                              primaryColor
                              classNames='px-3 h-full'
                              padding='none'
                              textSize='small'
                              borderRadius='large'
                              fontWeight='medium'
                              onClick={ goToChatSettings }
                           />
                        </div>
                     </div>
                  )
               }
               {
                  !showTypeField && !isAdmin && (
                     <div
                        className='p-6 bg-panel dark:bg-panel-dark flex flex-col items-baseline dark:shadow-none not-avalable-shadow'>
                        {
                           needToJoin && (
                              <>
                                 <span className='leading-tight text-major dark:text-major-dark'> Only active members can reply to messages.</span>
                                 <div className='mt-4 h-11'>
                                    <Button
                                       borderRadius='large'
                                       primaryColor
                                       text='Get membership to chat'
                                       onClick={ () => {
                                          closeChatPopup()
                                          goToJoin()
                                       } }
                                       fontWeight='medium'
                                       textSize='base'
                                       classNames='h-full pl-[18px] pr-[10px]'
                                       iconName='membership'
                                       iconPosition='left'
                                       fontIconSize='extraLarge'
                                    />
                                 </div>

                              </>
                           )
                        }
                        {
                           !needToJoin && (
                              <span className='leading-tight text-major dark:text-major-dark'> {isLivstream ? 'Livestream chat is currently disabled.' : 'Private messaging is currently disabled.'}</span>

                           )
                        }
                     </div>
                  )
               }
               {
                  showTypeField && (
                     <NewMessageField
                        mutedInfo={ mutedInfo }
                        isMobile={ isMobile }
                        sendMessage={ (text, parnet, callBack, sendWithDuration) => {
                           if(sendMessage) {
                              sendMessage(text, parnet, updateMessageId, () => {
                                 if(updateMessageId) {
                                    this.updateMessagID(null)
                                 } else {
                                    this.onScrollToButton()
                                 }
                                 if(callBack) {
                                    callBack()
                                 }
                              }, { updateMessageType }, sendWithDuration)
                           }
                        } }
                        isAdmin={ isAdmin }
                        onUserTyping={ () => {
                           if(updateMessageId) {
                              return
                           }
                           if(onUserTyping) {
                              onUserTyping()
                           }
                        }  }
                        replyMessage={ replyMessage }
                        canelReplyMessage={ () => this.onReplyMessage(null) }
                        inputRef={ this.inputRef }
                        onReadMessage={ onReadMessage }
                        inputForIosFocus={ this.inputForIosFocus }
                        setMutedMemberInfo={ setMutedMemberInfo }
                        isConnected={ isConnected && !offLiveStreamChat }
                        isMessageToLong={ isMessageToLong }
                        setErrorToLong={ this.setErrorToLong }
                        selectByLiber={ selectFileByLiber }
                        removeSelectedContent={ removeSelectedContent }
                        deleteVaultVideo={ deleteVaultVideo }
                        isGroup={ !converstionInfo.user_uuid  }
                        userUuid={ converstionInfo.user_uuid  }
                        content={ converstionInfo.content || null }
                        chatRoomId={ converstionInfo.chat_room_id || null }
                        onSaveUploadPhotos={ onSaveUploadPhotos }
                        maxPrice={ maxPrice }
                        isLivstream={ isLivstream }
                        onSendVoiceMessage={ (blob, details, callBack, parent, sendWithDuration) => {
                           onSendVoiceMessage(blob, details, () => {
                              if(callBack){
                                 callBack();
                              }
                              onUpdateRecordStatus();
                              this.onScrollToButton();
                           }, parent, sendWithDuration)
                        } }
                        privateAttachmentSettings={ privateAttachmentSettings }
                        defaultVoicePrice={ defaultVoicePrice }
                        onUpdateRecordStatus={ onUpdateRecordStatus }
                        handleUploadImage={ handleUploadImage }
                        cancelVideoUplaod={ cancelVideoUplaod }
                        checkVaultByHash={ checkVaultByHash }
                        createVault={ createVault }
                        checkContentTypeInTheChat={ checkContentTypeInTheChat }
                        getMediaBlurhash={ getMediaBlurhash }
                        containerRef={ this.containerRef }
                        isUpdateState={ !!updateMessageId }
                        onClickCancelEditMessage={ () => {
                           this.updateMessagID(null)
                        } }
                        isMiniChat={ isMiniChat }
                        showFrequentlyUsedEmojis={ isAdmin }
                        isDemoMode={ isDemoMode }
                        authUser={ authUser }
                        attachments={ attachments }
                        attachmentsForShow={ attachmentsForShow }
                        isMultipleAttachments={ isAdmin }
                        onRemoveMedia={ onRemoveMedia }
                        onClickCancel={ onClickCancel }
                        updateMessageType={ updateMessageType }
                        isMaxAttachmentCountReached={ isMaxAttachmentCountReached }
                        onClickSavedReplies={ this.onClickSavedReplies }
                        durationInfo={ durationInfo }
                        onSaveDurationData={ onSaveDurationData }
                        isFetchingChatSettingsUpdate={ isFetchingChatSettingsUpdate }
                        getQueryParam={ getQueryParam }
                        lastMessage={ messages?.[messages?.length - 1 || 0] || {} }
                     />

                  )
               }

            </div>
            {
               showAttachmentModal &&
                  <MultipleAttachmentModal
                     onClose={ () => {
                        this.setState({
                           showAttachmentModal: !showAttachmentModal,
                        })
                     } }
                     data={ currentMessage }
                     adminMessage={ isAdmin }
                     onRemoveMedia={ (media, index, message) => {
                        if(currentMessage?.unlock_details?.attachments?.length === 1 && index === 0 && (!message?.text || typeof message?.text === 'object')) {
                           this.setState({ openDeleteConfirmModal: true })
                        } else {
                           this.setState({
                              attachmentStateForDelete: { media, index, message, closeAfterUnsend: currentMessage?.unlock_details?.attachments?.length === 1 },
                              openDeleteAttachmentModal: true,
                           })
                        }
                     } }
                     isAdmin={ isAdmin }
                     goToDetails={ (type, id, title, vaultData) => {
                        this.goToAttachmentDetails(type, id, title, vaultData, currentMessage)
                     } }
                     siteInfo={ siteInfo }
                     onCloseChat={ () => {
                        this.setState({
                           showAttachmentModal: !showAttachmentModal,
                        })
                     } }
                     getContentLink={ this.getContentLink }
                  />
            }
            {
               isAdmin && openDeleteConfirmModal && (
                  <Modal
                     contentPosition={ screenWidth > 1024 ? 'center' : 'bottom' }
                     onClose={ () => this.setState({ openDeleteConfirmModal: false }) }
                     isCloseAction={ false }
                     contentWidth={ screenWidth > 1024 ? '375px' : '' }
                  >
                     <ConfirmDialog
                        modalInfo={ {
                           title: 'Unsend message?',
                           description: 'Are you sure you want to unsend this message? This cannot be undone.',
                           actionText: 'Yes, unsend',
                           action: () => {
                              onDeleteMessag(currentMessage.id, () => {
                                 this.setState({ openDeleteConfirmModal: false, showAttachmentModal: !showAttachmentModal })
                              }, null, null, currentMessage)
                           },
                        }  }
                        closeConfirmDialog={ () => this.setState({ openDeleteConfirmModal: false }) }
                        iconName='delete'
                     />

                  </Modal>
               )
            }
            {
               isAdmin && openDeleteAttachmentModal && (
                  <Modal
                     contentPosition={ screenWidth > 1024 ? 'center' : 'bottom' }
                     onClose={ () => this.setState({ openDeleteAttachmentModal: false, attachmentStateForDelete: {} }) }
                     isCloseAction={ false }
                     contentWidth={ screenWidth > 1024 ? '375px' : '' }
                  >
                     <ConfirmDialog
                        modalInfo={ {
                           title: `Unsend ${ ['photo_vault', 'video_vault']?.includes(attachmentStateForDelete?.media?.resource_type) ? attachmentStateForDelete?.media?.resource_type === 'photo_vault' ? 'Vault image' : 'Vault video' : attachmentStateForDelete?.media?.resource_type === 'video' ? 'video' : 'photoset' }?`,
                           description: 'Are you sure you want to unsend this single attachment? This cannot be undone.',
                           actionText: 'Yes, unsend',
                           action: () => {

                              const { media, index, message, closeAfterUnsend } = attachmentStateForDelete

                              onRemoveMedia(media, index, message, (updatedMessage) => {
                                 this.setState({
                                    currentMessage: updatedMessage,
                                    openDeleteAttachmentModal: false,
                                    attachmentStateForDelete: {},
                                    showAttachmentModal: closeAfterUnsend ? !showAttachmentModal : showAttachmentModal,
                                 })
                              }, true)
                           },
                        }  }
                        closeConfirmDialog={ () => this.setState({ openDeleteAttachmentModal: false, attachmentStateForDelete: {} }) }
                        iconName='delete'
                        className={ cx({
                           'notranslate': !siteInfo?.chat_settings?.chat_auto_translator,
                        }) }
                     />

                  </Modal>
               )
            }
            {
               !!showSavedReplies &&
                  <SavedRepliesModal
                     onClose={ () => {
                        setShowSavedReplies(!showSavedReplies)
                     } }
                     isAdmin={ isAdmin }
                     setSelectedReply={ (reply) => {
                        this.setState({
                           selectedReply: reply,
                        })
                     } }
                     onClickDeleteReply={ (reply) => {
                        setDialogueContainerState({
                           replyForDelete: reply,
                        })
                        setDialogueContainerState({
                           isOpenDeleteReplyModal: true,
                        })

                     } }
                     onClickEditReply={ (reply) => {
                        setReplyData({
                           ...reply,
                        }, repliesModalState)
                     } }
                     repliesModalState={ repliesModalState }
                     onChangeModalState={ (type) => {

                        setDialogueContainerState({
                           repliesModalState: type,
                           isChangingRepliesModalState: true,
                        })

                        setDialogueContainerState({
                           replyTimeout: setTimeout(() => {
                              setDialogueContainerState({
                                 isChangingRepliesModalState: false,
                              })
                           }, 500),
                        })

                     } }
                     replies={ repliesData?.data }
                     isChangingRepliesModalState={ isChangingRepliesModalState }
                     changeRepliesModalState={ (bool) => {
                        setDialogueContainerState({
                           isChangingRepliesModalState: bool,
                        })
                     } }
                     isEmptyList={ repliesData?.data?.length <= 0 && repliesModalState !== 'create' && !repliesFilter?.search && !isLoadingReplies && !isChangingRepliesModalState }
                     repliesRef={ this.repliesRef }
                     repliesTextFieldRef={ this.repliesTextFieldRef }
                     onChangeData={ (key, value) => {} }
                     isEditMode={ repliesModalState === 'edit' }
                     setReplyData={ setReplyData }
                     repliesData={ repliesData }
                     newReplyData={ newReplyData }
                     currentReplyData={ currentReplyData }
                     onChangeReply={ onChangeReply }
                     getReplies={ getReplies }
                     repliesFilter={ repliesFilter }
                     onChangeRepliesFilter={ onChangeRepliesFilter }
                     handleRepliesModalScroll={ handleRepliesModalScroll }
                     setDialogueContainerState={ setDialogueContainerState }
                     isLoadingReplies={ isLoadingReplies }
                     isLoadingRepliesNextPage={ isLoadingRepliesNextPage }
                     readyToSendReply={ readyToSendReply }
                     onClickSendReply={ (reply) =>  {
                        this.onSendReply(reply)
                     } }
                     errors={ replyErrors }
                     isSavingReply={ isSavingReply }
                     isSendingReply={ isSendingReply }
                     sendingReply={ sendingReply }
                     selectedAttachments={ selectedAttachments }
                     onReorderEnd={ onReorderEnd }
                     isRepliesMaxAttachmentCountReached={ isRepliesMaxAttachmentCountReached }
                     getQueryParam={ getQueryParam }
                     textFieldElementProps={ {
                        mutedInfo: mutedInfo,
                        isMobile: isMobile,
                        sendMessage: (text, parnet, callBack, sendWithDuration) => {
                           if(sendMessage) {
                              sendMessage(text, parnet, updateMessageId, () => {
                                 if(updateMessageId) {
                                    this.updateMessagID(null)
                                 } else {
                                    this.onScrollToButton()
                                 }
                                 if(callBack) {
                                    callBack()
                                 }
                              }, { updateMessageType }, sendWithDuration)
                           }
                        },
                        isAdmin: isAdmin,
                        onUserTyping: () => {
                           if(updateMessageId) {
                              return
                           }
                           if(onUserTyping) {
                              onUserTyping()
                           }
                        },
                        replyMessage: replyMessage,
                        canelReplyMessage: () => this.onReplyMessage(null),
                        inputRef: this.repliesTextFieldRef,
                        onReadMessage: onReadMessage,
                        inputForIosFocus: this.inputForIosFocus,
                        setMutedMemberInfo: setMutedMemberInfo,
                        isConnected: isConnected && !offLiveStreamChat,
                        isMessageToLong: isMessageToLong,
                        setErrorToLong: this.setErrorToLong,
                        selectByLiber: selectFileByLiber,
                        removeSelectedContent: removeSelectedContent,
                        deleteVaultVideo: deleteVaultVideo,
                        isGroup: !converstionInfo.user_uuid,
                        userUuid: converstionInfo.user_uuid,
                        content: converstionInfo.content || null,
                        chatRoomId: converstionInfo.chat_room_id || null,
                        onSaveUploadPhotos: onSaveUploadPhotos,
                        maxPrice: maxPrice,
                        isLivstream: isLivstream,
                        onSendVoiceMessage: (blob, details, callBack, parent, sendWithDuration) => {
                           onSendVoiceMessage(blob, details, () => {
                              if(callBack){
                                 callBack();
                              }
                              onUpdateRecordStatus();
                              this.onScrollToButton();
                           }, parent, sendWithDuration)
                        },
                        privateAttachmentSettings: privateAttachmentSettings,
                        defaultVoicePrice: defaultVoicePrice,
                        onUpdateRecordStatus: onUpdateRecordStatus,
                        handleUploadImage: handleUploadImage,
                        cancelVideoUplaod: cancelVideoUplaod,
                        checkVaultByHash: checkVaultByHash,
                        createVault: createVault,
                        checkContentTypeInTheChat: checkContentTypeInTheChat,
                        getMediaBlurhash: getMediaBlurhash,
                        containerRef: this.containerRef,
                        isUpdateState: !!updateMessageId,
                        onClickCancelEditMessage: () => {
                           this.updateMessagID(null)
                        },
                        isMiniChat: isMiniChat,
                        showFrequentlyUsedEmojis: isAdmin,
                        isDemoMode: isDemoMode,
                        authUser: authUser,
                        attachments: attachments,
                        attachmentsForShow: attachmentsForShow,
                        isMultipleAttachments: isAdmin,
                        onRemoveMedia: onRemoveMedia,
                        onClickCancel: onClickCancel,
                        updateMessageType: updateMessageType,
                        isMaxAttachmentCountReached: isMaxAttachmentCountReached,
                        onClickSavedReplies: this.onClickSavedReplies,
                        isMassMessage: false,
                        setReplyData: setReplyData,
                        readyToSendReply: readyToSendReply,
                        lastMessage: messages?.[messages?.length - 1 || 0] || {},
                        isFetchingChatSettingsUpdate: isFetchingChatSettingsUpdate,
                        durationInfo: {
                           chat_countdown_default_duration: durationInfo?.chat_countdown_default_duration || 0,
                           chat_countdown_default_duration_checked: durationInfo?.chat_countdown_default_duration_checked,
                           show_duration_countdown_to_member: repliesModalState === 'edit' ? !!currentReplyData?.show_duration_countdown_to_member : !!newReplyData?.show_duration_countdown_to_member,
                           duration_in_minutes: repliesModalState === 'edit' ? currentReplyData?.duration_in_minutes || 0 : newReplyData?.duration_in_minutes || 0,
                        },
                        onSaveDurationData: onSaveDurationData,
                     } }
                  />
            }
            {
               isAdmin && isOpenDeleteReplyModal && (
                  <Modal
                     contentPosition={ screenWidth > 1024 ? 'center' : 'bottom' }
                     onClose={ () => {
                        setDialogueContainerState({
                           isChangingRepliesModalState: false,
                           replyForDelete: null,
                           repliesModalState: 'list',

                        })
                     } }
                     isCloseAction={ false }
                     contentWidth={ screenWidth > 1024 ? '375px' : '' }
                  >
                     <ConfirmDialog
                        modalInfo={ {
                           title: `Delete ${ replyForDelete?.name }?`,
                           description: 'This will permanently delete the reply from your replies list',
                           actionText: 'Yes, delete',
                           action: () => {
                              setDialogueContainerState({
                                 isOpenDeleteReplyModal: false,
                                 isChangingRepliesModalState: true,
                              })

                              onChangeReply({
                                 ...replyForDelete,
                              },
                              'delete',
                              () => {
                                 this.deleteReplyTimeout = setTimeout(() => {

                                    setDialogueContainerState({
                                       isChangingRepliesModalState: false,
                                       replyForDelete: null,
                                       repliesModalState: 'list',
                                       isOpenDeleteReplyModal: false,
                                       selectedAttachments: {
                                          ...selectedAttachments,
                                          [+(getQueryParam('conversation'))]: [],
                                       },
                                    })
                                    clearTimeout(this.deleteReplyTimeout)
                                 }, 0);
                              })


                           },
                        }  }
                        closeConfirmDialog={ () => {
                           setDialogueContainerState({
                              isChangingRepliesModalState: false,
                              isOpenDeleteReplyModal: false,
                              replyForDelete: null,
                           })
                        } }
                        iconName='delete'
                     />

                  </Modal>
               )
            }
            {
               !!checkStatusData?.openWarningModal && (
                  <WarningModal
                     isMobile={ isMobile }
                     info={ checkStatusData.warningText }
                     actionText='Send anyway'
                     action={ () => {
                        const {
                           // unlockPrice,
                           // resources,
                           // isBlur,
                           // hash,
                           // status,
                           details,
                           callback,
                           sendWithDuration,
                        } = checkStatusData
                        if(details?.attachments.length > 1 && details?.attachmentsForShow.length > 1)  {

                           setDialogueContainerState({
                              isSendingAnyway: true,
                           })
                           this.sendMultipleAttachmentMessage(details?.text, { ...details }, callback, true, sendWithDuration)

                        }
                        else if(details?.attachments.length === 1 && details?.attachmentsForShow.length === 1)  {
                           setDialogueContainerState({
                              isSendingAnyway: true,
                           })

                           this.sendSingleAttachmentMessage(details?.text, { ...details }, callback, true, sendWithDuration)
                        }
                        else {
                           setDialogueContainerState({
                              checkStatusData: {
                                 openWarningModal: false,
                              },
                              isSendingReply: false,
                              sendingReply: null,
                              isSendingAnyway: false,
                           })
                        }

                     } }
                     closeModal={ () => {
                        setDialogueContainerState({
                           checkStatusData: {
                              openWarningModal: false,
                           },
                           isSendingReply: false,
                           sendingReply: null,
                           isSendingAnyway: false,
                        })

                     } }
                     showNewStyles
                     hideCloseButton
                     iconColor={ checkStatusData?.color }
                     modalTitle={ checkStatusData?.modalTitle }
                     iconName={ checkStatusData?.icon }
                     isLoading={ isSendingAnyway }
                     disableCancel={ isSendingAnyway }
                  />
               )
            }
            {
               // !!selctFromModal?.openDiscardModal &&
               isOpenRepliesDiscardModal &&
                  <ConfirmModal
                     type='confirm'
                     icon='cancel'
                     iconColor='error'
                     descriptionColorClass='text-secondary'
                     onCloseModal={ () => {
                        setDialogueContainerState({
                           isOpenRepliesDiscardModal: !isOpenRepliesDiscardModal,
                        })
                     }  }
                     title='Discard changes?'
                     description='Changes you have made so far will not be saved'
                     cancelText='Keep editing'
                     nonPrimaryColor
                     contentWidth='375px'
                     isMobile={ screenWidth < 1025 }
                     iconSize='2xl'
                     buttonText='Discard changes'
                     textColor='error'
                     action={ () => {

                        if(['create', 'edit'].includes(repliesModalState)) {
                           setDialogueContainerState({
                              isOpenRepliesDiscardModal: !isOpenRepliesDiscardModal,
                              replyErrors: {},
                              repliesModalState: 'list',
                              isChangingRepliesModalState: true,
                              replyTimeout: setTimeout(() => {
                                 setDialogueContainerState({
                                    isChangingRepliesModalState: false,
                                 })
                              }, 500),
                              selectedAttachments: {
                                 ...selectedAttachments,
                                 [+(getQueryParam('conversation'))]: [],
                              },
                           })

                           setReplyData('initial', 'create')
                           setReplyData('initial', 'edit')

                           return
                        }
                        setDialogueContainerState({
                           isOpenRepliesDiscardModal: !isOpenRepliesDiscardModal,
                        })
                        setShowSavedReplies(!showSavedReplies)

                     } }
                     buttonClassName='mt-8'
                     modalClassName='!z-[100]'
                  />
            }
         </>
      );
   }
}

export default Dialogue;

