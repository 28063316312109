import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Input from 'common/components/modules/store/input'
import MediaChip from 'common/components/modules/media-chip'
import Editor from 'common/components/modules/editor';
import SelectTagsAndCast from 'admin/views/media-modules/general/select-tags-casts/index.jsx';
import Icon from 'common/components/elements/icons';

const VaultItem = ({
   onChangeData = () => {},
   type = 'image',
   title,
   description,
   casts = [],
   tags = [],
   collections = [],
   src,
   duration,
   resources,
   screenWidth,
   tagsError,
   createTagsError,
   videoOptimizationStatus = '',
}) => {

   return (
      <div className='flex flex-col gap-6 rounded-lg border border-divider p-4'>
         <div
            className='w-full aspect-video relative bg-cover bg-center overflow-hidden rounded-lg'
            style={  {
               backgroundImage: `url(${ src })`,
               backdropFilter: 'blur(20px)',
            }  }
         >
            {
               ('in_optimization_queue' === videoOptimizationStatus || 'optimizing' === videoOptimizationStatus) ?
                  <div className='flex flex-col items-center justify-center h-full border border-divider rounded-lg'>
                     <div className='h-8 w-8 flex items-center justify-center' >
                        <Icon
                           name='clock'
                           size='3xl'
                           color='secondary dark:text-secondary-dark'
                        />
                     </div>
                     <span className='text-secondary dark:text-secondary-dark text-sm mt-2 text-center'>
                        In optimization queue
                     </span>
                  </div>
                  :
                  <>
                     {
                        !!duration &&
                        <MediaChip
                           countOrDuration={ duration }
                           type={ 'video' }
                           className='w-fit absolute top-2.5 right-2.5 z-2'
                        />
                     }
                     <img
                        style={  {
                           backdropFilter: 'blur(20px)',
                        }  }
                        className='select-none w-full h-full absolute top-0 right-0 object-contain z-1 rounded-lg' src={ src } alt=''
                     />
                  </>

            }
         </div>
         <div className='flex flex-col gap-6 w-full'>
            <Input
               title='Title '
               titleClassName='font-medium dark:text-major-dark text-major cursor-default'
               otherText='(Optional)'
               otherTextClassName='text-placeholder font-normal text-xs dark:text-placeholder-dark'
               placeholder={ `Enter ${ type } title` }
               onChange={ (value) => onChangeData({ title: value }) }
               value={ title }
               areWrapperClassName='bg-panel dark:bg-black-field'
               minHeight='min-h-[94px]'
               screenWidth={ screenWidth }
               xPositions={ {
                  wisth: '274px',
               } }
               limit={ 100 }
            />
            <div className='flex flex-col gap-2 max-w-[484px]' >
               <p className='text-base font-medium text-major flex items-center gap-1'>Description<span className='font-normal text-xs text-placeholder dark:text-placeholder-dark'>(Optional)</span></p>
               <Editor
                  initialValue={ description }
                  value={ description }
                  handleChange={ (value) => onChangeData({ description: value }) }
                  height={ 160 }
                  isMobile={  screenWidth < 1024 }
                  placeholder='Enter a description...'
                  isShowEmoji
                  limit={ 3000 }
                  descriptionPosition='right'
                  toolbar={ ['blocks', 'bold', 'italic', 'underline', 'numlist', 'link'] }
                  hideAmount={ false }
                  className='!mb-0'
               />
            </div>
            <SelectTagsAndCast
               label=''
               tagsList={ resources?.tags }
               tags={ tags }
               membersList={ resources?.cast_members }
               members={ casts }
               onInputChange={ (type, value) => onChangeData({ [type]: value }) }
               isNewMedia
               errors={ {} }
               tagsError={ tagsError }
               createTagsError={ createTagsError }
               collectionsList={ resources?.collections }
               collections={ collections }
            />
         </div>
      </div>
   )
}

VaultItem.propTypes = {
   onChangeData: PropTypes.func,
   type: PropTypes.string,
   title: PropTypes.string,
   description: PropTypes.string,
   casts: PropTypes.array,
   tags: PropTypes.array,
   collections: PropTypes.array,
   src: PropTypes.string,
   duration: PropTypes.number,
   resources: PropTypes.object,
   screenWidth: PropTypes.number,
   tagsError: PropTypes.object,
   createTagsError: PropTypes.func,
   videoOptimizationStatus: PropTypes.any,
}

export default memo(VaultItem)
