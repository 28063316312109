import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {
   desktopChatDialogueCompleted,
   fetchMemberByUuidAction,
   setBlockMemberAction,
   setChatConversationAction,
   updateConversationMessagesByIdAction,
   updateOnlineUserUsername,
} from "admin/state/modules/chat/actions";
import { createConversationOperation, fetchConversationMessagesOperation } from "admin/state/modules/chat/operations";
import { addNewTagCompleted } from "admin/state/modules/member-tags/actions";
import { changeTagToMember, setActiveMemberData, updateMemberByUuid, updateMemberFailed } from "admin/state/modules/members/actions";
import {
   addFreeMemberOperation,
   expireFreeMemberShipOperation,
   fetchStatisticsData,
   memberFetchDetailsOperation,
   updateMembersOperation,
} from "admin/state/modules/members/operations";
import Button from "common/components/elements/buttons/primary";
import Modal from "common/components/modules/modal";
import Icon from "common/components/elements/icons";
import TruncateText from "common/components/elements/truncate-text";
import OptionsModal from "admin/views/members/options-modal";
import { authUserSelector, screenWidthSelector } from "admin/state/modules/common/selectors";
import cx from 'classnames';
import {
   activeConverstaionSelector,
   conversationsListSelector,
   groupConversationSelector,
   initedConverstionMessagesSelector,
   onlineMembersSelector,
   unreadMessagesCountSelector,
} from "admin/state/modules/chat/selectors";
import {
   addMembersTag,
   addTagsToMembers,
   disableMultipleMemberAllNotifications,
   enableMultipleMemberAllNotifications,
   getMember,
   initMembersTags,
   muteMember,
   unMuteMember,
   unsubscribeEmail,
   updateMember,
} from "admin/api/AuthApi";
import Router, { getCurrentRoute } from 'admin/routes/router';
import { push } from "connected-react-router";
import { openOnlineMembersModal } from "admin/state/modules/common/actions";
import QueryParams from "admin/utils/QueryParams";
import { currentLocationPathSelector } from "admin/state/modules/router/selectors";
import ChatHepler from "common/utils/chat";
import ChatProviderContainer from 'admin/containers/modules/chat';
import { isEmpty } from "lodash";
import { Consumer } from "../chat/context";
import AvatarWithName from "admin/views/chat/modules/avatar-with-name";
import ChatConversationMessagesContainer  from 'admin/containers/pages/chat/index/dialogue';
import ChooseMembership from "admin/views/members/add-free-member/choose-membership";
import { activeMemberSelector, statisticsDataSelector, updateErrorsSelector } from "admin/state/modules/members/selectors";
import UpdateUserName from "common/components/modules/user-info/update-username";
import AddMemberTags from "admin/views/member-tags/add-member-tags";
import { isEmptyTags } from "admin/state/modules/member-tags/selectors";
import toast from "common/utils/toast";
import AddMemberTag from "admin/views/member-tags/add-member-tag";
import intercomMoreInfo, { CLIENT_REQUEST_CONTACT_SUPPORT_ABOUT_MEMBER } from "admin/utils/IntercomMoreInfo";
import { ExpireFreeMembershipModal } from "admin/views/chat/modules/expire-free-membership";
import ConfirmDialog from "common/components/modules/confirm-dialog";
import MuteMember from "admin/views/chat/modules/mute-block-member";
import Avatar from "common/components/modules/avatar";
import MemberCard from "common/components/modules/member-card";
import account from 'assets/images/avatar.svg'
import countriesWithFlags from 'common/utils/countriesWithFlags.json'
import AddEditNicknameModal from "admin/views/members/add-edit-nickname";
import { updateNotificationsByMemberIdAction } from "admin/state/modules/notifications/actions";
import { updateLivestreamMessagesByIdAction } from "admin/state/modules/livestream/actions";
import { updateTopSpendingMember } from "admin/state/modules/statistics/actions";
import { updateTransactionsDataByMemberUsernameAction } from "admin/state/modules/transactions/actions";
import { SubscribersModalOptions } from "common/components/modules/mass-message/subscribers-mail-card";

class OnlineMembersModal extends Component {

   static propTypes = {
      screenWidth: PropTypes.number,
      onlineMembers: PropTypes.array,
      goTo: PropTypes.func,
      openOnlineMembersModal: PropTypes.func,
      createConversation: PropTypes.func,
      activeConverstaion: PropTypes.object,
      groupConversation: PropTypes.object,
      initedConverstionMessages: PropTypes.object,
      conversationsList: PropTypes.object,
      desktopChatDialogue: PropTypes.func,
      fetchMessages: PropTypes.func,
      statistics: PropTypes.object,
      fetchStatisticsData: PropTypes.func,
      addFreeMemberOperation: PropTypes.func,
      fetchMemberById: PropTypes.func,
      updateMembers: PropTypes.func,
      updateErrors: PropTypes.object,
      changeTagToMember: PropTypes.func,
      addNewTagCompleted: PropTypes.func,
      updateMemberByUuid: PropTypes.func,
      blockMemberAction: PropTypes.func,
      expireFreeMemberShip: PropTypes.func,
      setChatConversation: PropTypes.func,
      updateOnlineMemberUsername: PropTypes.func,
      setActiveMember: PropTypes.func,
      activeMember: PropTypes.object,
      type: PropTypes.string,
      onClose: PropTypes.func,
      data: PropTypes.array,
      onEditMemberUsername: PropTypes.func,
      closeOnClickOutside: PropTypes.bool,
      children: PropTypes.any,
      uniqueId: PropTypes.string,
      totalCount: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      useMemberCards: PropTypes.bool,
      modalParam: PropTypes.string,
      wrapperClassName: PropTypes.string,
      modalContentClassName: PropTypes.string,
      emptyStateText: PropTypes.string,
      hideDescription: PropTypes.bool,
      handleScroll: PropTypes.func,
      onCustomAction: PropTypes.func,
      updateConversationMessagesById: PropTypes.func,
      updateNotificationsByMemberId: PropTypes.func,
      locationPath: PropTypes.object,
      updateMemberFailedAction: PropTypes.func,
      updateLivestreamMessagesById: PropTypes.func,
      updateTopSpendingMemberAction: PropTypes.func,
      updateTransactionsDataByMemberUsername: PropTypes.func,
      iconName: PropTypes.string,
      currentMessage: PropTypes.object,
      userTootltipText: PropTypes.string,
      activeTab: PropTypes.oneOf(['recipients', 'subscribers']),
      showTabs: PropTypes.bool,
   }

   static defaultProps = {
      onClose: () => {},
      onEditMemberUsername: () => {},
      closeOnClickOutside: true,
      uniqueId: 'onlineMembersModal',
      title: 'Online members',
      description: '',
      useMemberCards: false,
      wrapperClassName: '',
      modalContentClassName: '',
      emptyStateText: 'No members online',
      handleScroll: () => {},
      onCustomAction: () => {},
      activeTab: 'recipients',
      showTabs: false,
   }

   state = {
      clickCoordinatsX: 0,
      clickCoordinatsY: 0,
      animate: 'up',
      onlineMemberOptionsModal: false,
      isLoadingMember: false,
      member: {},
      mobileChatOpen: false,
      removeUploadVideo: false,
      freeMembershipModal: false,
      selectedMembership: null,
      warningMessage: null,
      isOpenUsernameModal: false,
      isMemberTags: false,
      addCustomTagOpen: false,
      membersTags: [],
      isFetchingMembersTags: false,
      isEmptyMembersTags: false,
      height: null,
      isLoadingAddTagsToMemberButton: false,
      newTagName: '',
      newTagModalOpen: false,
      isButtonLoading: false,
      showCheckbox: false,
      errors: {},
      memberOptionModal: {
         type: null,
         isLoading: false,
         isOpen: false,
      },
      isOpenExpireFreeMembershipConfirmModal: false,
      isOpenNicknameModal: false,
      isOpenSubscribersOptionsModal: false,
      subscriberData: {},
      isOpenUnsubscribeModal: false,

   }

   timeout = null;

   optionValues = [
      {
         value: 'high_roller',
         display_name: 'Total spent is more than 1000 USD',
      },
      {
         value: 'king',
         display_name: 'Total spent 500 - 1000 USD',
      },
      {
         value: 'prince',
         display_name: 'Total spent 250 - 500 USD',
      },
      {
         value: 'duke',
         display_name: 'Total spent 100 - 250 USD',
      },
   ];

   static getDerivedStateFromProps(nextProps){
      const { statistics: { free, free_membership_count } } = nextProps;
      let obj = {
         warningMessage: null,
      }
      if(free >= free_membership_count){
         obj.warningMessage = 'You’ve reached the maximum number of complimentary memberships'
      } else {
         obj.warningMessage = null
      }
      return obj
   }

   componentDidMount() {
      const { fetchStatisticsData, type, modalParam } = this.props
      fetchStatisticsData()
      if(type !== 'mass_message_recipients'){
         QueryParams.setQueryParam((modalParam ? modalParam : 'online-members-modal'), 'true')
      }
      window.addEventListener('resize', () => {
         this.openOptionsModal(false)
      })

      window.addEventListener('click', this.closeOnClickOutside, true);

      this.getMembersTags()
      const height = window.innerHeight;
      this.setState({ height })
   }

   componentWillUnmount() {
      const { modalParam, onCustomAction } = this.props
      window.removeEventListener('resize', () => {
         this.openOptionsModal(false)
      })
      window.removeEventListener('click', this.closeOnClickOutside, true);

      !!onCustomAction && onCustomAction({
         type: 'mass_message_recipients',
         args: {
            activeTab: 'recipients',
            preventRequest: true,
         },
      })
      QueryParams.removeQueryParam(modalParam ? modalParam : 'online-members-modal')
   }

   closeOnClickOutside = (e) => {
      const { screenWidth } = this.props
      const { memberOptionModal, member, onlineMemberOptionsModal } = this.state

      console.log(e, e?.target?.parentNode.parentNode);

      if(onlineMemberOptionsModal && !e.target.closest(`member-options`) && !e.target?.parentNode?.classList.contains('member-options-item') && !e.target?.parentNode?.parentNode?.classList.contains('member-options-item')){
         this.openOptionsModal(false)
      }

      if(screenWidth > 700 && !e.target.closest(`.subscriber-`) && !e.target.closest(`.subscriber-button`)) {
         this.openSubscribersOptionsModal(false)
      }

      const eId = e.target?.id.split('label_')?.[1]

      if(screenWidth > 700 && ['1_hour', '24_hour', '48_hour', 'until_unmute'].includes(eId)) {
         const timeMap = {
            '1_hour': 3600,
            '24_hour': 86400,
            '48_hour': 172800,
            'until_unmute': null,
         }
         if('mute' === memberOptionModal.type) {
            this.onMuteMember(timeMap[eId], member?.uuid)
         } else {
            this.onDisableMemberAllNotifications(timeMap[eId], member?.uuid)
         }
      }
   }

   closeModal = () => {
      const { openOnlineMembersModal, screenWidth, onClose } = this.props
      if(screenWidth < 701) {
         this.setState({
            animate: 'down',
         })
         this.timeout = setTimeout(() => {
            this.setState({
               animate: 'up',
            })
            !!onClose && onClose()
            openOnlineMembersModal(false)
         }, 800)
      } else {
         !!onClose && onClose()
         openOnlineMembersModal(false)
      }
      QueryParams.removeQueryParam('online-members-modal')
   }

   getMemberById = async (id) => {
      this.setState({
         isLoadingMember: true,
      })
      try {
         const { data } = await getMember(id)
         this.setState({
            member: { ...data },
            isLoadingMember: false,
         })
      } catch (e) {
         this.setState({
            isLoadingMember: false,
            member: {},
         })
         console.log(e);
      }
   }

   openOptionsModal = (bool, id) => {
      this.setState({
         onlineMemberOptionsModal: bool,
      })
      if(bool) {
         // fetch member by id
         this.getMemberById(id)
      }
   }

   goToMemberProfile = (id) => {
      this.props.goTo(Router.route('MEMBERS_SHOW').getCompiledPath({ id }));
   }


   goToPrivateChat = (member) => {
      const {
         createConversation,
         activeConverstaion,
         groupConversation,
         initedConverstionMessages,
         screenWidth,
         conversationsList,
         desktopChatDialogue,
         fetchMessages,
      } =  this.props;
      this.setState({
         mobileChatOpen: true,
      })
      let conversation = conversationsList?.data?.find(conv => conv.user_uuid === member.uuid)
      if(!!conversation){
         fetchMessages(conversation, groupConversation, {}, () => {
            desktopChatDialogue({ ...conversation, isOpen: true }, true)
         })
      }
      else {
         createConversation(member, initedConverstionMessages, screenWidth, '', () => {
            ChatHepler.syncDialogueScroll(null, null)
            if(screenWidth < 1025) {
               fetchMemberByUuidAction({ isOpen: false })
            }
            if(screenWidth > 1024){
               ChatHepler.inputState()
            }

            desktopChatDialogue({  isOpen: true, ...activeConverstaion }, true)
         })
      }
   }

   setSelectedMembership = (membership) => {
      this.setState({
         selectedMembership: membership,
      })
   }

   addFreeMember = () => {
      const { addFreeMemberOperation, fetchMemberById, fetchStatisticsData, onCustomAction } = this.props;
      const { selectedMembership, member } = this.state;
      if(selectedMembership && member?.id) {
         const data = {
            membership_period: selectedMembership,
         }
         addFreeMemberOperation(member?.id, data, () => {

            !!onCustomAction && onCustomAction({
               type: 'give_free_membership',
               args: {
                  member,
                  member_status: "free",
                  membership_type: "Complimentary",
               },
            })

            this.setState({
               warningMessage: null,
               isOpenExpireFreeMembershipConfirmModal: false,
               openFreeMemberShipModal: false,
               freeMembershipModal: false,
            })
            fetchMemberById(member?.id)
            fetchStatisticsData();
         });
      }
   }

   setMemberUsernameModal = (bool) => {
      this.setState({
         isOpenUsernameModal: bool,
      })
   }

   setIsMemberTags = (bool) => {
      this.setState({ isMemberTags: bool });
   }

   openAddCustomTagModal = (bool) => {
      const { setActiveMember } = this.props;
      const { member } = this.state;

      let animate = 'down';
      if(!!bool) {
         animate = 'up'
      }

      !!member?.id && getMember(member?.id)
         .then((res) => {
            setActiveMember(res?.data)
         })
         .catch(err => console.log(err))

      this.setState({ addCustomTagOpen: bool, walletHistoryAnimation: animate })
   }

   getMembersTags = () => {
      this.setState({ isFetchingMembersTags: true })
      initMembersTags(['count=all'])
         .then((res) => {
            this.setState({
               isFetchingMembersTags: false,
               membersTags: [...res?.data],
               isEmptyMembersTags: [...res?.data].length === 0,
            })
         })
         .catch(err => {
            this.setState({ isFetchingMembersTags: false })
         })
   }

   onAddTagsToMember = (selectedItem, selectedTags, memberTags) => {
      const { changeTagToMember, onCustomAction } = this.props;
      const { membersTags } = this.state;
      this.setState({ isLoadingAddTagsToMemberButton: true })
      let data = {
         member_ids: [selectedItem],
         tag_ids: [...selectedTags],
      }
      !!onCustomAction && onCustomAction({
         type: 'add_member_tags',
         args: {
            selectedItem, selectedTags, memberTags, membersTags,
         },
      })
      addTagsToMembers(data)
         .then((res) => {
            let addTags = [...membersTags]?.filter(el => memberTags.includes(el.id));
            let data = {
               members_ids: [selectedItem],
               tag_ids: [...selectedTags],
               memberTagIds: memberTags,
               membersTag: addTags,
            }
            changeTagToMember(data);
            this.setState({ isLoadingAddTagsToMemberButton: false, addCustomTagOpen: false })
            toast.success('Your changes have been saved');
            this.getMembersTags()
         })
         .catch((err) => {
            this.setState({ isLoadingAddTagsToMemberButton: false })
         })
   }

   openNewTagModal = (tagName) => {
      QueryParams.pushQueryParam('new-tag-name', tagName)

      this.setState({
         name: tagName,
      })
      this.setState({
         addCustomTagOpen: !this.state.addCustomTagOpen,
      })
      setTimeout(() => {
         this.showAddTagModal()
      }, 250);
   }

   showAddTagModal = () => {
      this.setState({
         newTagModalOpen: !this.state.newTagModalOpen,
      })
   }

   handleMemberClick = (type, id) => {
      this.goToMemberProfile(id)
      if(type !== 'mass_message_recipients'){
         this.closeModal()
      }
   }

   onCloseNewTagModal = () => {
      QueryParams.removeQueryParam('new_tag_name');
      this.setState({
         newTagModalOpen: false,
         newTagName: '',
      });
      this.setState({ errors: {} })

   }

   createNewTag = (data) => {
      const { addNewTagCompleted } = this.props;
      this.setState({ isButtonLoading: true })
      addMembersTag(data)
         .then((res) =>  {
            addNewTagCompleted(res?.data);
            QueryParams.removeQueryParam('new_tag_name');
            this.setState({ isButtonLoading: false, newTagModalOpen: false, newTagName: '' });
            toast.info('Member tag has been created');
            this.getMembersTags();
            if(!res?.data?.rule) {
               this.createAndAddNewTag(res?.data)
            }
            setTimeout(() => {
               this.setState(prev => {
                  return {
                     ...prev,
                     member: {
                        ...prev.member,
                        member_tags: [res.data],
                     },
                     addCustomTagOpen: true,
                  }
               })
            }, 10);
         })
         .catch(err => {
            this.setState({ isButtonLoading: false });
            if(err?.response?.data) {
               let errors = err?.response?.data;
               this.setState({ errors: { ...errors } });
               if(errors?.emoji?.[0]) {
                  toast.error(errors?.emoji?.[0]);
                  return;
               }
            }
         })
   }

   changeCheckbox = (bool) => {
      this.setState({
         showCheckbox: bool,
      })
   }

   onSetMemberOption = type => {
      switch (type) {
         case 'unmute':
            this.onUnMuteMember()
            break;
         case 'unblock':
            this.onBlockMember()
            break;
         case 'enable_notifications':
            this.onEnableMemberAllNotifications()
            break;
         case 'help':
            intercomMoreInfo(CLIENT_REQUEST_CONTACT_SUPPORT_ABOUT_MEMBER)
            break;
         case 'expire_free_membership':
            this.openCloseExpireFreeMembershipModal(true)
            break;
         default:
            this.setState({
               memberOptionModal: {
                  type,
                  isOpen: !!type,
               },
            })
            break;
      }
   }

   onUnMuteMember = async () => {
      const { memberOptionModal, member } = this.state
      this.setState({
         memberOptionModal: {
            ...memberOptionModal,
            isLoading: true,
         },
      })
      try {
         await unMuteMember(member?.uuid)
         const { updateMemberByUuid, onCustomAction } = this.props
         updateMemberByUuid(member?.uuid, { muted_since: null })

         !!onCustomAction && onCustomAction({
            type: 'unmute_member',
            args: {
               member,
               muted_since: null,
            },
         })

         toast.remove(`${ member?.nickname || member?.username } has been unmuted`)
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: false,
            },
         })
      } catch (error) {

      }
   }

   onBlockMember = async () => {
      const { memberOptionModal, member } = this.state
      const { blockMemberAction } = this.props
      const banned = member && !member?.banned ? 1 : 0
      if(banned) {
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: true,
               isOpen: false,
            },
         })
      }
      try {
         await updateMember(member?.uuid, { banned }, 'uuid');
         const { updateMemberByUuid, onCustomAction } = this.props
         updateMemberByUuid(member?.uuid, { banned })

         !!onCustomAction && onCustomAction({
            type: !!banned ? 'block_member' : 'unblock_member',
            args: {
               member,
               banned,
            },
         })

         toast.remove(`${ member?.nickname || member?.username } has been ${ !banned ? 'unblocked' : 'blocked' }`)
         blockMemberAction({ banned, uuid: member?.uuid })
         if(banned){
            this.setState({
               memberOptionModal: {
                  ...memberOptionModal,
                  isLoading: false,
                  type: null,
                  isOpen: false,
               },
            })
         }
      } catch (error) {

      }
   }

   onEnableMemberAllNotifications = async () => {
      const { memberOptionModal, member } = this.state
      this.setState({
         memberOptionModal: {
            ...memberOptionModal,
            isLoading: true,
         },
      })
      try {
         await enableMultipleMemberAllNotifications({ uuids: [member?.uuid] })
         const { updateMemberByUuid, onCustomAction } = this.props
         updateMemberByUuid(member?.uuid, { notifications_disabled_since: null })

         !!onCustomAction && onCustomAction({
            type: 'enable_notifications',
            args: {
               member,
               notifications_disabled_since: null,
            },
         })

         toast.remove('Notifications have been enabled')
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: false,
            },
         })
      } catch (error) {

      }
   }

   onMuteMember = async (mutedPeriod, memberUuid) => {
      const { memberOptionModal } = this.state
      const { member } = this.state
      this.setState({
         memberOptionModal: {
            ...memberOptionModal,
            isLoading: true,
         },
      })
      try {
         await muteMember(memberUuid, { mute_period_in_seconds: mutedPeriod })
         const { updateMemberByUuid, onCustomAction } = this.props
         updateMemberByUuid(member?.uuid, { muted_since: Date.now() })

         !!onCustomAction && onCustomAction({
            type: 'mute_member',
            args: {
               member,
               muted_since: Date.now(),
            },
         })

         toast.remove(`${ member?.nickname || member?.username } has been muted`)
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: false,
               type: null,
               isOpen: false,
            },
         })
      } catch (error) {
         console.log(error)
      }
   }

   onDisableMemberAllNotifications = async (disabledPeriod, memberUuid) => {
      const { memberOptionModal } = this.state
      const { member } = this.state
      this.setState({
         memberOptionModal: {
            ...memberOptionModal,
            isLoading: true,
         },
      })
      try {
         await disableMultipleMemberAllNotifications({ uuids: [memberUuid], notifications_disable_period_in_seconds: disabledPeriod })
         const { updateMemberByUuid, onCustomAction } = this.props
         updateMemberByUuid(member?.uuid, { notifications_disabled_since: Date.now() })

         !!onCustomAction && onCustomAction({
            type: 'disable_notifications',
            args: {
               member,
               notifications_disabled_since: Date.now(),
            },
         })

         toast.remove('Notifications have been disabled')
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: false,
               type: null,
               isOpen: false,
            },
         })
      } catch (error) {
         console.log(error)
      }
   }

   openCloseExpireFreeMembershipModal = (bool) => {
      this.setState({ isOpenExpireFreeMembershipConfirmModal: bool })
   }

   onExpireFreeMemberShip = () => {
      const { expireFreeMemberShip, activeConverstaion, setChatConversation, fetchStatisticsData, onCustomAction } = this.props;
      const { member } = this.state
      expireFreeMemberShip(member?.id, (data) => {
         !!onCustomAction && onCustomAction({
            type: 'expire_free_membership',
            args: {
               member,
               member_status: "incomplete",
               membership_type: "Non member",
            },
         })
         this.setState({ warningMessage: null })
         fetchStatisticsData()
         if(activeConverstaion && member?.id === activeConverstaion?.member?.id) {
            setChatConversation({
               ...activeConverstaion,
               member: {
                  ...activeConverstaion?.member,
                  ...data,
               } });
         }
      })
   }

   getOptionsModalPossitions = () => {
      const { clickCoordinatsX, clickCoordinatsY } = this.state;
      const { screenWidth, type } = this.props;
      return {
         right: screenWidth - clickCoordinatsX - 32 + 'px',
         bottom: ((window.innerHeight - clickCoordinatsY - (type === 'mass_message_recipients' ? 380 : 340)) < 0 ? 0 : (window.innerHeight - clickCoordinatsY - (type === 'mass_message_recipients' ? 380 : 340))) + 'px',
      }
   }

   createAndAddNewTag = (data) => {
      const { changeTagToMember, activeMember, setActiveMember } = this.props;
      // const { id: memberId } = this.state.member;
      const { id } = data
      const { member_tags } = activeMember
      let memberTagData = {
         member_ids: [+activeMember?.id],
         tag_ids: [id],
      }

      addTagsToMembers(memberTagData).then(async (res) => {
         let newData = {
            members_ids: [+activeMember?.id], //+
            tag_ids: [id], //+
            memberTagIds: member_tags?.map(it => it?.id),
            membersTag: [...member_tags, data],
            addDataAfterCreate: data,
         }

         await  changeTagToMember(newData)

         !!activeMember?.id && await getMember(activeMember?.id)
            .then((resp) => {
               console.log(resp);
               this.setState({
                  member: resp?.data,
               })
               setActiveMember(resp?.data)
            })
            .catch(err => console.log(err))

         this.getMembersTags()

      }).catch(err => console.log(err))
   }

   openEditNickname = (id) => {

      this.setState({
         isOpenNicknameModal: true,
      })
   }

   closeEditNickname = () => {

      this.setState({
         isOpenNicknameModal: false,
      })
   }

   openSubscribersOptionsModal = (bool, data) => {
      if(data) {
         this.setState({
            subscriberData: data,
         })
      }
      this.setState({
         isOpenSubscribersOptionsModal: bool,
      })
   }

   onClickUnsubscribe = (bool) => {

      this.setState({
         isOpenUnsubscribeModal: bool,
      })
   }


   getSubscriberOptionsModalPositions = () => {
      const { clickCoordinatsX, clickCoordinatsY } = this.state;
      const { screenWidth } = this.props;
      return {
         right: screenWidth - clickCoordinatsX + 24 + 'px',
         bottom: ((window.innerHeight - clickCoordinatsY - 16) < 0 ? 0 : (window.innerHeight - clickCoordinatsY - 16)) + 'px',
      }
   }
   onUnsubscribe = async (email) => {
      const { onCustomAction } = this.props

      try {
         await unsubscribeEmail({
            type: 'non-members',
            email,
         })

         this.openSubscribersOptionsModal(false)
         this.onClickUnsubscribe(false)

         !!onCustomAction && onCustomAction({
            type: 'mass_message_recipients',
            args: {
               activeTab: 'subscribers',
            },
         })

         toast.success(`${ email } has been unsubscribed`);
      } catch (error) {

         toast.error('Something went wrong');

         this.setState({
            isOpenUnsubscribeModal: true,
         })
      }

   }

   render() {
      let {
         screenWidth,
         onlineMembers,
         activeConverstaion,
         groupConversation,
         updateMembers,
         updateErrors,
         updateOnlineMemberUsername,
         type,
         data,
         onEditMemberUsername,
         closeOnClickOutside,
         uniqueId,
         totalCount,
         title,
         description,
         useMemberCards,
         wrapperClassName,
         modalContentClassName,
         emptyStateText,
         hideDescription,
         handleScroll,
         onCustomAction,
         setChatConversation,
         updateConversationMessagesById,
         locationPath,
         updateNotificationsByMemberId,
         updateMemberFailedAction,
         updateLivestreamMessagesById,
         updateTopSpendingMemberAction,
         updateTransactionsDataByMemberUsername,
         iconName,
         currentMessage,
         userTootltipText,
         activeTab,
         showTabs,
      } = this.props

      const {
         animate,
         onlineMemberOptionsModal,
         member,
         mobileChatOpen,
         removeUploadVideo,
         isLoadingMember,
         freeMembershipModal,
         selectedMembership,
         warningMessage,
         isOpenUsernameModal,
         isMemberTags,
         addCustomTagOpen,
         isFetchingMembersTags,
         membersTags,
         isEmptyMembersTags,
         height,
         isLoadingAddTagsToMemberButton,
         isButtonLoading,
         newTagModalOpen,
         showCheckbox,
         newTagName,
         errors,
         isOpenExpireFreeMembershipConfirmModal,
         memberOptionModal,
         isOpenNicknameModal,
         isOpenSubscribersOptionsModal,
         subscriberData,
         isOpenUnsubscribeModal,
      } = this.state

      let onlineMembersCount

      if(type !== 'mass_message_recipients') {
         onlineMembersCount = onlineMembers?.length;
      } else {
         onlineMembersCount = totalCount || data?.length
         onlineMembers = data?.map(member => ({
            id: member.id,
            uuid: member.uuid,
            avatar: member.avatar_compressed_src,
            username: member.user_name,
            nickname: member.nickname,
            is_unsend: member.is_unsend,
         }))
      }

      if(useMemberCards) {
         onlineMembersCount = totalCount
      }

      if(activeTab === 'subscribers') {
         onlineMembers = data?.map(member => ({
            id: member.id,
            avatar: "https://ams-public-objects.s3.amazonaws.com/images/default-member-logo-new.png",
            username: member.email,
         }))
      }

      const membersModalHeader = document.getElementById('members_modal_header')

      const hideUnsentTooltip = ['draft', 'schedule'].includes(currentMessage?.status)
      return (
         <>
            <Modal
               contentPosition={ screenWidth < 701 ? 'bottom' : 'center' }
               onClose={ this.closeModal }
               contentWidth={ screenWidth < 701 ? '100%' : '375px' }
               animate={ screenWidth < 701 ? animate : null }
               closeOnClickOutside={ closeOnClickOutside }
               otherClassName={ modalContentClassName }
            >
               <div className='min-h-[320px] pb-6 max-h-[80vh] ms:max-h-[90vh] overflow-y-hidden'>
                  <div className='flex p-2 h-14'>
                     <div className='w-10'>
                        <Button
                           iconName='close'
                           fontIconColor={ `${ type === 'mass_message_recipients' ? 'secondary' : 'major' } ` }
                           classNames='w-full h-full'
                           padding='none'
                           backgroundColor='transparent'
                           onClick={ this.closeModal }
                           waveColor='rgba(33, 33, 33, 0.8)'
                           fontIconSize='doubleLarge'
                           waveActive={ false }
                        />
                     </div>
                  </div>
                  <div className='w-full flex flex-col items-center justify-center'>
                     <div id='members_modal_header' className='w-full flex flex-col items-center justify-center gap-3'>
                        <div
                           className='flex justify-center items-center w-10 h-10 rounded-full bg-action-12'
                        >
                           <Icon
                              name={ iconName ? iconName : 'group' }
                              color='action'
                              size='2xl'
                           />
                        </div>
                        { (type === 'mass_message_recipients' && !title)
                           ? <p className='text-center text-xl leading-[30px] text-[#041527] font-semibold'>Recipients</p>
                           :  <span className='text-xl font-semibold text-major'>{title}</span>
                        }
                        {
                           Boolean(onlineMembersCount) && !hideDescription && type !== 'mass_message_recipients' ? (
                              <span className='text-secondary mt-1'>
                                 {
                                    !!description ?
                                       (description)
                                       :
                                       `${ onlineMembersCount }` + (onlineMembersCount === 1 ? ' member is online' : ' members are online') }
                              </span>
                           )
                              : !hideDescription && Boolean(onlineMembersCount) && type === 'mass_message_recipients' ?
                                 <p className='text-base text-secondary text-center my-1'>{onlineMembersCount} recipients</p>
                                 : <></>
                        }
                     </div>
                     {
                        showTabs &&
                        <div className='flex w-full px-6 h-13 mb-4 mt-6'>
                           <div className='flex h-full border border-divider rounded-lg p-1.5 bg-grey-main w-full gap-2'>
                              <div
                                 className={ cx({
                                    [`w-full flex h-10 py-[11px] items-center justify-center text-secondary font-normal hover:cursor-pointer`]: true,
                                    [`!bg-panel rounded-lg !text-action !font-medium`]: activeTab === 'recipients',
                                 }) }
                                 role='presentation'
                                 onClick={ (e) => {
                                    e.stopPropagation()

                                    this.setState({
                                       onlineMemberOptionsModal: false,
                                       isOpenSubscribersOptionsModal: false,
                                    })

                                    !!onCustomAction && onCustomAction({
                                       type: 'mass_message_recipients',
                                       args: {
                                          activeTab: 'recipients',
                                       },
                                    })
                                 } }
                              >
                                 <span className='text-sm leading-[18px]'>
                                    Members
                                 </span>
                              </div>
                              <div
                                 className={ cx({
                                    [`w-full flex h-10 py-[11px] items-center justify-center text-secondary font-normal hover:cursor-pointer`]: true,
                                    [`!bg-panel rounded-lg !text-action !font-medium`]: activeTab === 'subscribers',
                                 }) }
                                 role='presentation'
                                 onClick={ (e) => {
                                    e.stopPropagation()
                                    this.setState({
                                       onlineMemberOptionsModal: false,
                                       isOpenSubscribersOptionsModal: false,
                                    })

                                    !!onCustomAction && onCustomAction({
                                       type: 'mass_message_recipients',
                                       args: {
                                          activeTab: 'subscribers',
                                       },
                                    })
                                 } }
                              >
                                 <span className='text-sm leading-[18px]'>
                                    Mailing list
                                 </span>
                              </div>
                           </div>
                        </div>
                     }
                     <div
                        className={ cx({
                           [`w-full overflow-y-auto mt-1 h-[240px] ams-custom-scrool`]: true,
                           'flex justify-center': !Boolean(onlineMembersCount),
                           [`!h-[336px]`]: showTabs,
                           [`${ wrapperClassName }`]: !!wrapperClassName,
                        }) }
                        id={ uniqueId }
                        onScroll={ handleScroll }
                        style={ {
                           maxHeight: screenWidth > 701
                              ? Math.floor((window?.innerHeight * 0.9) - (membersModalHeader?.clientHeight + 56 + 4 + 24 + 32 + 52 + 44))
                              : Math.floor((window?.innerHeight * 0.8) - (membersModalHeader?.clientHeight + 56 + 4 + 24 + 32 + 52 + 44)),
                        } }
                     >
                        {
                           Boolean(onlineMembersCount)
                           // || type === 'mass_message_recipients'
                              ? (
                                 !!useMemberCards
                                    ?
                                    data.map((item, i) => {
                                       const flagsArr = countriesWithFlags.map(country => {
                                          return {
                                             flag: country["emoji"],
                                             code: country["code"],
                                          }
                                       })
                                       const country = flagsArr.find(item => {
                                          return item?.code === member?.country_code
                                       }) || {};
                                       const isOnline = this.props.onlineMembers.find(it => it.id === item?.id)

                                       return  <a href={ Router.route('MEMBERS_SHOW').getCompiledPath({ id: member?.id }) }>
                                          <MemberCard
                                             key={ i }
                                             // isInsideModal={ isInsideModal }
                                             // isSelected={ isInsideModal ? selectedInsideModal : (selectedBulkEdit || member.isSelected) }
                                             // isBulkEdit={ isBulkEdit }
                                             membershipType={ item.membership_type }
                                             // isSelected={ selectedId === member.id }
                                             username={ item.nickname || item.username }
                                             banned={ !!item.banned }
                                             muted={ !!item.muted_since }
                                             disabledNotifications={ !!item.notifications_disabled_since }
                                             onClick={ bool => {
                                                // this.getMemberById(item?.id)
                                                this.goToMemberProfile(item?.id)
                                             // if(isInsideModal){
                                             //    onSelectModalCards(bool, member.id)
                                             // } else {
                                             //    onSelectCards(bool, member.id)
                                             // }
                                             }  }
                                             member={ item }
                                             id={ item.id }
                                             topPercentage={ item.top_percentage || 0 }
                                             status={ item?.member_status }
                                             avatar={ item.avatar ? item.avatar_compressed_src : account }
                                             isMobile={ screenWidth < 700 }
                                             desktopType={ screenWidth > 700 ? 'descktop' : 'mobile' }
                                             className='!h-[190px] mb-4'
                                             openEditUsernameModal={ (member) => {

                                             }  }
                                             goToPrivateChat={ (member) => {
                                                if(screenWidth > 1025) {
                                                   this.closeModal()
                                                }
                                                this.goToPrivateChat(member)
                                             } }
                                             giveFreeMemberShip={ (member) => [
                                                this.setState({
                                                   freeMembershipModal: true,
                                                   animate: 'up',
                                                }),
                                             ] }
                                             setMemberOptionModal={ this.onSetMemberOption }
                                             getMemberData={ (member) => {
                                                // this.getMemberById(member?.id)
                                                this.setState((prev) => {
                                                   return {
                                                      ...prev,
                                                      member,
                                                   }

                                                })
                                                this.getMembersTags()
                                                // console.log('getMemberData', member);
                                             } }
                                             goToProfile={ (id) => {
                                                this.goToMemberProfile(id)
                                             } }
                                             // onRemoveTagToMember={ onRemoveTagToMember }
                                             addCustomTagModal={ async (...arg) => {
                                                await this.getMemberById(item?.id)
                                                this.setIsMemberTags(false)
                                                this.openAddCustomTagModal(true)

                                             // onOpenTagModal(...arg)
                                             } }
                                             onTagMember={ async () => {
                                                await this.getMemberById(item?.id)
                                                this.setIsMemberTags(false)
                                                this.openAddCustomTagModal(true)

                                             // onOpenTagModal(member)
                                             } }
                                             seeMoreTags={ async () => {
                                                // this.getMembersTags()
                                                await this.getMemberById(item?.id)
                                                this.setIsMemberTags(true)
                                                this.openAddCustomTagModal(true)

                                             // onOpenTagModal(member)
                                             } }
                                             isListCard
                                             // isShowPage
                                             onFilterShowResult={ (tagId, q) => {
                                                // console.log({ tagId, q });
                                                return this.props.goTo(Router.route('MEMBERS').getCompiledPath(), `q={"member_tags":"${ tagId }","filterByTag":"${ true }"}`)
                                             } }
                                             // memberCountryFlag={ memberCountryFlag }
                                             country={ country }
                                             isOnlineMember={ !!isOnline }
                                             // user={ user }
                                             onOptionsClick={ (e, member) => {
                                                this.setState({
                                                   clickCoordinatsX: e.clientX,
                                                   clickCoordinatsY: e.clientY,
                                                })
                                                this.openOptionsModal(!onlineMemberOptionsModal, member.id)
                                             } }
                                             // onCardHover={ () => {
                                             //    if(member?.id !== item?.id) {
                                             //       this.setState((prev) => {
                                             //          return {
                                             //             ...prev,
                                             //             member: item,
                                             //          }
                                             //       })
                                             //       // this.getMemberById(item?.id)
                                             //       // this.getMembersTags()
                                             //    }
                                             // } }
                                             goToDetails={ () => window.open(Router.route('MEMBERS_SHOW').getCompiledPath({ id: member?.id }), '_blank') }
                                          />

                                       </a>
                                    })
                                    :
                                    onlineMembers.map(user => (
                                       <div
                                          key={ user.uuid }
                                          className={ cx({
                                             'h-10 w-full px-2 flex items-center justify-between group pl-6': true,
                                             'pr-6': onlineMembersCount <= 6,
                                             'pr-2': onlineMembersCount > 6,
                                             'pointer-events-none select-none': user?.username?.includes('preview-'),
                                             'hover:bg-hover cursor-pointer': user?.id,
                                             [`h-12 !pl-4`]: showTabs,
                                          }) }
                                          role='presentation'
                                          onClick={ () => {
                                             if(user?.id && activeTab !== 'subscribers'){
                                                this.handleMemberClick(type, user?.id)
                                             }
                                          } }
                                          data-tooltip-content={ user?.is_unsend  && !hideUnsentTooltip ?   userTootltipText || 'Unsent' : null }
                                          data-tooltip-id={ user?.is_unsend && String(user.nickname || user.username).length > 25 && !hideUnsentTooltip  ? 'ams-top-tooltip-clickable' : (user?.is_unsend && !hideUnsentTooltip ? 'ams-top-tooltip-clickable' : '') }
                                       >
                                          <div className='flex items-center gap-2'>
                                             <div className='w-7 h-7 relative min-w-[28px]' >
                                                <Avatar
                                                   avatarSrc={ user.avatar }
                                                   username={ user.nickname || user.username }
                                                   firstLetterClassName='text-sm'
                                                />
                                                {
                                                   type !== 'mass_message_recipients'
                                             &&
                                             <div
                                                className='absolute right-0 bottom-0 w-[8px] h-[8px] rounded-full border border-divider bg-success'
                                             />
                                                }
                                             </div>
                                             <TruncateText
                                                text={ user.nickname || user.username }
                                                textClass={ 'group-hover:underline' }
                                                className={ cx({
                                                   'flex-1': type !== 'mass_message_recipients',
                                                   'text-sm': type === 'mass_message_recipients',
                                                   'text-disabled': type === 'mass_message_recipients' && user?.is_unsend && !hideUnsentTooltip,
                                                   'text-major': type === 'mass_message_recipients' && !user?.is_unsend,
                                                   [`!text-base !leading-5`]: showTabs,
                                                }) }
                                                showTootltipText={ user?.is_unsend && String(user.nickname || user.username).length > 25 ?  user.nickname || user.username : (user?.is_unsend && !hideUnsentTooltip ? userTootltipText || 'Unsent' : '') }
                                                fontWeight='400'
                                                textSize={ showTabs ? '16px' : '14px' }
                                             />
                                          </div>
                                          <div
                                             className='w-2 mx-[6px]'
                                          >
                                             {
                                                user?.id && !user?.username?.includes('preview-') &&
                                                   <Button
                                                      iconName='options-vertical'
                                                      fontIconSize='extraLarge'
                                                      backgroundColor='transparent'
                                                      padding='none'
                                                      waveActive={ false }
                                                      classNames='group w-full overflow-hidden intercom-member-options'
                                                      fontIconColor='secondary'
                                                      onClick={ (e) => {
                                                         e.stopPropagation()
                                                         this.setState({
                                                            clickCoordinatsX: e.clientX,
                                                            clickCoordinatsY: e.clientY,
                                                         })
                                                         if(activeTab === 'subscribers') {
                                                            this.openSubscribersOptionsModal(!isOpenSubscribersOptionsModal, user)

                                                         } else {
                                                            this.openOptionsModal(!onlineMemberOptionsModal, user.id)
                                                         }
                                                      } }
                                                   />
                                             }
                                          </div>
                                       </div>
                                    ))

                              ) : (
                                 <span className='text-secondary mt-[92px] text-center'>{ emptyStateText }</span>
                              )
                        }
                        {
                           this.props.children && this.props.children
                        }
                     </div>
                  </div>
               </div>
               {
                  onlineMemberOptionsModal && (
                     <OptionsModal
                        isMobile={ screenWidth < 701 }
                        onCloseModal={ () => this.openOptionsModal(false) }
                        rightPosition={ this.getOptionsModalPossitions().right }
                        bottomPosition={ this.getOptionsModalPossitions().bottom }
                        goToPrivateChat={ () => {
                           if(screenWidth > 1025) {
                              this.closeModal()
                           }
                           this.goToPrivateChat(member)
                        } }
                        isLoading={ isLoadingMember }
                        giveFreeMemberShip={ () => {
                           this.setState({
                              freeMembershipModal: true,
                              animate: 'up',
                           })
                        } }
                        editUsername={ () => this.setMemberUsernameModal(true) }
                        openCustomTagModal={ (bool) => {
                           this.setIsMemberTags(bool)
                           this.openAddCustomTagModal(true)
                        } }
                        setMemberOptionModal={ this.onSetMemberOption }
                        isMuted={ Boolean(member?.muted_since) }
                        isBlocked={ Boolean(member?.banned) }
                        isDisabledNotifications={ Boolean(member?.notifications_disabled_since) }
                        memberUsername={ member?.username }
                        memberStatus={ member?.membership_status }
                        goToProfile={ () => this.goToMemberProfile(member?.id) }
                        goToDetails={ () => window.open(Router.route('MEMBERS_SHOW').getCompiledPath({ id: member?.id }), '_blank') }
                        onOpenEditNickname={ this.openEditNickname }
                        memberData={ member }
                     />
                  )
               }
               {
                  isOpenSubscribersOptionsModal && (
                     <div
                        className=''
                        style={ {
                           position: 'absolute',
                           right: this.getSubscriberOptionsModalPositions().right,
                           bottom: this.getSubscriberOptionsModalPositions().bottom,
                        } }
                     >
                        <SubscribersModalOptions
                           id={ subscriberData.id }
                           type={ 'non-members' }
                           isMobile={ screenWidth < 701 }
                           onUnsubscribe={ (email) => this.onClickUnsubscribe(true) }
                           onCloseModal={ () => this.openSubscribersOptionsModal(false) }
                           animate={ animate }
                           email={ subscriberData.username }
                           optionsClassName={ `top-0` }
                        />
                     </div>
                  )
               }
            </Modal>
            {
               isOpenUnsubscribeModal &&
                  <Modal
                     contentPosition={ screenWidth < 701 ? 'bottom' : 'center' }
                     onClose={ () => {
                        QueryParams.removeQueryParam('isUnsubscribeModalOpen')
                        this.setState({
                           isOpenUnsubscribeModal: false,
                           subscriberData: {},
                        })
                     } }
                     closeOnClickOutside={ true }
                     contentWidth={  screenWidth < 701 ? null : '375px' }
                     roundedModal='xlg'
                     otherClassName=''
                  >
                     <div className='flex flex-col bg-major-dark p-6 items-center text-center'>
                        <div className='h-10 w-10 mx-auto flex items-center justify-center mb-3 rounded-full ' style={ {
                           backgroundColor: `${ window?.skinPrimaryColor }1F`,
                        } }>
                           <Icon
                              name={ 'message' }
                              size={ '2xl' }
                              className={ `**pl-[1px]` }
                              color={ `action` }
                              primaryColor={ `${ window?.skinPrimaryColor }` }
                           />
                        </div>
                        <p className='text-xl leading-[30px] text-major font-semibold mb-4'>Unsubscribe?</p>
                        <p className='text-base leading-[18px] text-secondary'>Are you sure you want to unsubscribe this recipient?</p>
                        <div className='flex flex-col mt-8 gap-3'>
                           <Button
                              text={ `Yes, unsubscribe` }
                              borderRadius='large'
                              backgroundColor='transparent'
                              textColor='action'
                              textSize={ `small` }
                              onClick={ () => this.onUnsubscribe(subscriberData?.username) }
                              padding='none'
                              classNames='h-full w-full py-2 px-[14px]'
                              lineHeight='leading-5'
                              primaryColor
                           />
                           <Button
                              text={ `Cancel` }
                              borderRadius='large'
                              backgroundColor='transparent'
                              textColor={ `secondary` }
                              textSize={ `small` }
                              onClick={ () => {
                                 this.setState({
                                    isOpenUnsubscribeModal: false,
                                    subscriberData: {},
                                 })
                              } }
                              padding='none'
                              classNames='h-full w-full py-2 px-[14px]'
                              lineHeight='leading-5'
                           />
                        </div>
                     </div>
                  </Modal>
            }
            {screenWidth < 1025 && !isEmpty(activeConverstaion) && mobileChatOpen && (
               <ChatProviderContainer >
                  <Consumer >
                     { ({
                        onDeleteMessag,
                        onUserTyping,
                        groupChatTypersMessage,
                        onLikeMessage,
                        onSendMessage,
                        converstionInfo,
                        onReadMessage,
                        onlineUsers,
                        onSetMemberOption,
                        onUnMuteMember,
                        updateMessageData,
                     }) => {
                        return (
                           <>
                              <Modal
                                 isMobile={ screenWidth < 700 }
                                 contentPosition='full-screen'
                                 onClose={ () => {} }
                                 className={ `overflow-hidden max-h-full h-full flex flex-col mobile-chat-right-content` }
                              >
                                 {
                                    !!activeConverstaion && !isEmpty(activeConverstaion) && (
                                       <AvatarWithName
                                          name={ (activeConverstaion.member_nickname || activeConverstaion.member?.nickname || activeConverstaion.username || activeConverstaion.conversation_name) }
                                          avatarSrc={ (activeConverstaion.user_avatar  || activeConverstaion.avatars) }
                                          leftOptionsIcon={ true }
                                          onClickAvatar={ (!activeConverstaion.avatars) ? () => {
                                             if(screenWidth > 1360) {
                                                return
                                             }
                                             if(!activeConverstaion.member || (activeConverstaion.member && activeConverstaion.member.uuid !== activeConverstaion.uuid)){
                                                this.fetchMemberByUuid(activeConverstaion.uuid, true, !activeConverstaion.user_uuid)
                                             } else {
                                                fetchMemberByUuidAction({ isOpen: true })

                                             }
                                          } : null }
                                          rightGoBackIcon={  screenWidth < 1024 }
                                          goToBack={ () => {
                                             this.setState({
                                                mobileChatOpen: false,
                                             })
                                          } }
                                          isOnline={ activeConverstaion.is_online }
                                          muteTime={ activeConverstaion.muted_period_in_seconds }
                                          isBlocked={ !!activeConverstaion.member && !!activeConverstaion.member.banned }
                                          onSetMemberOption={ onSetMemberOption }
                                          isMobile={  screenWidth < 700 }
                                       />
                                    )
                                 }
                                 <ChatConversationMessagesContainer
                                    onUserTyping={ () => {
                                       onUserTyping() } }
                                    onLikeMessage={ (messageId, isLiked) => {
                                       const data = { messageId, isLiked }
                                       onLikeMessage(data)
                                    } }
                                    onDeleteMessag={ (id) => {
                                       const data = { messageId: id }
                                       onDeleteMessag(data)
                                    } }
                                    sendMessage={ onSendMessage }
                                    converstionInfo={ converstionInfo }
                                    onUnMuteMember={ onUnMuteMember }
                                    groupMembersCount={ groupConversation.members_count }
                                    onReadMessage={ (data) => {
                                       const id = data.chat_room_id
                                       if(id && data.unread_messages_count && data.unread_messages_count > 0) {
                                          onReadMessage(id)
                                       }
                                    } }
                                    fetchMemberByUuid={ null }
                                    onClickUnnblockMember={ () => onSetMemberOption('unblock') }
                                    removeUploadVideo={ removeUploadVideo }
                                    updateMessageData={ updateMessageData }
                                 />
                              </Modal>

                           </>
                        )
                     }}
                  </Consumer>
               </ChatProviderContainer>
            ) }
            {
               freeMembershipModal && (
                  <Modal
                     contentPosition={ screenWidth < 700 ? 'bottom' : 'center'  }
                     onClose={ () => this.setState({
                        freeMembershipModal: false,
                        selectedMembership: null,
                     }) }
                     contentWidth={ screenWidth < 700 ? '100%' : '375px' }
                     animate={ screenWidth < 700 ? animate : null }
                     isCloseAction={ false }
                  >
                     <ChooseMembership
                        warningMessage={ warningMessage }
                        avatar={ member?.avatar_compressed_src }
                        username={ member?.nickname || member?.username }
                        memberships={ [1, 2, 3, 5, 7, 14, 30, 60, 90] }
                        selectedMembership={ selectedMembership }
                        setSelectedMembership={ this.setSelectedMembership }
                        addFreeMember={ this.addFreeMember }
                        onclose={ () => this.setState({
                           freeMembershipModal: false,
                           selectedMembership: null,
                        }) }
                     />
                  </Modal>
               )
            }
            {
               isOpenUsernameModal && (
                  <UpdateUserName
                     username={ member.username }
                     onSaveUserName={ (data, callBack) => {
                        updateMembers(member?.id, data, async () => {
                           callBack()
                           if(type === 'mass_message_recipients'){
                              onEditMemberUsername(member?.id, data.username)
                           }
                           !!onCustomAction && onCustomAction({
                              type: 'edit_username',
                              args: {
                                 member,
                                 username: data.username,
                              },
                           })
                           await setChatConversation({
                              ...activeConverstaion,
                              member: {
                                 ...activeConverstaion?.member,
                                 username: data?.username,
                              },
                              username: data?.username,
                           })
                           await updateConversationMessagesById({
                              activeConversation: activeConverstaion,
                              username: data?.username,
                              memberUUID: member?.uuid,
                           })
                        })
                        this.setMemberUsernameModal(false)
                        updateOnlineMemberUsername({
                           uuid: member?.uuid,
                           newUsername: data.username,
                        })
                     } }
                     errorMessage={ updateErrors.username ? updateErrors.username[0] : null }
                     isMobile={ screenWidth < 701 }
                     closeModal={ () => {
                        this.setMemberUsernameModal(false)
                     } }
                  />
               )
            }
            {
               addCustomTagOpen && (
                  <AddMemberTags
                     onClose={ () => { this.openAddCustomTagModal(false) } }
                     tags={ isMemberTags ? member?.member_tags : membersTags }
                     isMobile={ screenWidth < 700 }
                     // memberCount={ selectedCount }
                     isFetchingMembersTags={ isFetchingMembersTags }
                     isEmptyMembersTags={ isEmptyMembersTags }
                     height={ height }
                     manageMembersTags={ () => {
                        window.open(`${ window.location.origin }${ Router.route('MEMBER_TAGS').getCompiledPath() }`, '_blank', 'noopener,noreferrer');
                     } }
                     onAddTagsToMember={ this.onAddTagsToMember }
                     isLoadingAddTagsToMemberButton={ isLoadingAddTagsToMemberButton }
                     selectedItem={ member }
                     optionValues={ this.optionValues }
                     createNewTag={ this.openNewTagModal }
                     tagsOfCurrentMember={ member?.member_tags || [] }
                  />
               )
            }
            {
               newTagModalOpen && (
                  <AddMemberTag
                     showCheckbox={ showCheckbox }
                     setShowCheckBox={ (bool) => this.changeCheckbox(bool) }
                     onSaveTag={ this.createNewTag }
                     onCloseModal={ this.onCloseNewTagModal }
                     screenWidth={ screenWidth }
                     optionValues={ this.optionValues }
                     isButtonLoading={ isButtonLoading }
                     name={ newTagName }
                     errors={ errors }
                  />
               )
            }
            {
               memberOptionModal.isOpen && (
                  <Modal
                     contentPosition={ screenWidth < 700 ? 'bottom' : 'center' }
                     onClose={ () => {
                        QueryParams.removeQueryParam('manage')
                        this.onSetMemberOption(null)
                     } }
                     isCloseAction={ false }
                     contentWidth={ ('block' === memberOptionModal.type || screenWidth < 700) ? null : '375px' }
                     roundedModal='xlg'
                  >
                     {
                        'block' === memberOptionModal.type && (
                           <ConfirmDialog
                              modalInfo={ {
                                 title: `Block ${ member?.nickname || member?.username }?`,
                                 description: `Are you sure you want to block ${ member?.nickname || member?.username }? This member will be shown an error page instead of your site`,
                                 actionText: 'Yes, block this member',
                                 action: () =>  {
                                    this.onBlockMember()
                                    QueryParams.removeQueryParam('manage')
                                 } }  }
                              closeConfirmDialog={ () => {
                                 this.onSetMemberOption(null)
                                 QueryParams.removeQueryParam('manage')
                              }  }
                              disabled={ memberOptionModal.isLoading }
                              iconName='block'
                           />
                        )
                     }
                     {
                        ('mute' === memberOptionModal.type || 'disable_notifications' === memberOptionModal.type) && (
                           <MuteMember
                              isMobile={ screenWidth < 700 }
                              onCloseModal={ () => {
                                 QueryParams.removeQueryParam('manage')
                                 this.onSetMemberOption(null)
                              } }
                              onMuteTimeChange={ (time) => {
                                 if('mute' === memberOptionModal.type) {
                                    this.onMuteMember(time, member?.uuid)
                                 } else {
                                    this.onDisableMemberAllNotifications(time, member?.uuid)
                                 }
                              } }
                              muteTime={ member?.muted_period_in_seconds }
                              username={ member?.nickname || member?.username }
                              disabled={ memberOptionModal.isLoading }
                              isMute={ 'mute' === memberOptionModal.type }
                           />
                        )
                     }
                  </Modal>
               )
            }
            {
               isOpenExpireFreeMembershipConfirmModal && (
                  <ExpireFreeMembershipModal
                     isMobile={ screenWidth < 701 }
                     expireFreeMemberShip={ () => {
                        this.openCloseExpireFreeMembershipModal(false)
                        this.onExpireFreeMemberShip()
                     } }
                     onClose={ () => this.openCloseExpireFreeMembershipModal(false) }
                  />
               )
            }
            {
               isOpenNicknameModal && (
                  <AddEditNicknameModal
                     username={ member?.username }
                     nickname={ member?.nickname }
                     onSaveNickname={ (data, callBack, hideToast) => {
                        updateMembers(member.id, data, callBack, hideToast)
                        QueryParams.removeQueryParam('manage')
                     } }
                     errorMessage={ updateErrors?.nickname ? updateErrors.nickname[0] : null }
                     isMobile={ screenWidth < 701 }
                     closeModal={ () => {
                        this.closeEditNickname()
                        updateMemberFailedAction({})
                        QueryParams.removeQueryParam('manage')
                     } }
                     onChangeSuccess={ async (data) => {
                        await setChatConversation({
                           ...activeConverstaion,
                           member: {
                              ...activeConverstaion?.member,
                              nickname: data?.nickname,
                           },
                           member_nickname: data?.nickname,
                        })
                        await updateConversationMessagesById({
                           member_nickname: data?.nickname,
                           // memberId: member?.id,
                           username: member?.username || groupConversation?.member?.username,
                        })

                        if(Router.route('MEMBERS_SHOW').getCompiledPath({ id: member?.id }) === window?.location.pathname) {
                           const currentRoute = getCurrentRoute(locationPath);
                           currentRoute.setTitle(data?.nickname || member?.username)
                        }
                        await updateNotificationsByMemberId({
                           member_nickname: data?.nickname,
                           username: member?.username,
                        })

                        await updateLivestreamMessagesById({
                           member_nickname: data?.nickname,
                           username: member?.username,
                        })

                        await updateTopSpendingMemberAction({
                           nickname: data?.nickname,
                           id: member?.id,
                        })

                        await updateTransactionsDataByMemberUsername({
                           member_nickname: data?.nickname,
                           username: member?.username,
                        })

                        !!onCustomAction && onCustomAction({
                           type: 'edit_nickname',
                           args: {
                              member,
                              nickname: data.nickname,
                           },
                        })

                     } }
                  />
               )
            }
         </>
      )
   }
}

const mapStateToProps = (state) => {
   return {
      screenWidth: screenWidthSelector(state),
      onlineMembers: onlineMembersSelector(state),
      unreadChatMessagesCount: unreadMessagesCountSelector(state),
      locationPath: currentLocationPathSelector(state),
      user: authUserSelector(state),
      activeConverstaion: activeConverstaionSelector(state),
      groupConversation: groupConversationSelector(state),
      initedConverstionMessages: initedConverstionMessagesSelector(state),
      conversationsList: conversationsListSelector(state),
      statistics: statisticsDataSelector(state),
      updateErrors: updateErrorsSelector(state),
      isEmptyMembersTags: isEmptyTags(state),
      activeMember: activeMemberSelector(state),
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      goTo: (location) => {
         dispatch(push(location))
      },
      openOnlineMembersModal: (bool) => dispatch(openOnlineMembersModal(bool)),
      createConversation: (member, inited, screenWidth, searchValue, callback) => dispatch(createConversationOperation(member, inited, screenWidth, searchValue,  callback)),
      desktopChatDialogue: (data, bool) => dispatch(desktopChatDialogueCompleted(data, bool)),
      fetchMessages: (chat, group, member, callback) => dispatch(fetchConversationMessagesOperation(chat, group, member, callback, false)),
      fetchMemberByUuidAction: (data) => dispatch(fetchMemberByUuidAction(data)),
      fetchMemberById: (id) => {
         dispatch(memberFetchDetailsOperation(id))
      },
      fetchStatisticsData: params => dispatch(fetchStatisticsData(params)),
      addFreeMemberOperation: (id, data, callBack) => {
         dispatch(addFreeMemberOperation(id, data, callBack));
      },
      updateMembers: (id, data, callBack, hideToast) => {
         dispatch(updateMembersOperation(data, id, callBack, hideToast))
      },
      changeTagToMember: (data) => dispatch(changeTagToMember(data)),
      addNewTagCompleted: (data) => dispatch(addNewTagCompleted(data)),
      updateMemberByUuid: (uuid, data) => dispatch(updateMemberByUuid(uuid, data)),
      blockMemberAction: info => dispatch(setBlockMemberAction(info)),
      expireFreeMemberShip: (id, callBack) => {
         dispatch(expireFreeMemberShipOperation(id, callBack))
      },
      setChatConversation: (data) => dispatch(setChatConversationAction(data)),
      updateOnlineMemberUsername: (data) => dispatch(updateOnlineUserUsername(data)),
      setActiveMember: data => {
         dispatch(setActiveMemberData(data))
      },
      updateConversationMessagesById: (payload) => dispatch(updateConversationMessagesByIdAction(payload)),
      updateNotificationsByMemberId: (payload) => dispatch(updateNotificationsByMemberIdAction(payload)),
      updateMemberFailedAction: (payload) => dispatch(updateMemberFailed(payload)),
      updateLivestreamMessagesById: (payload) => dispatch(updateLivestreamMessagesByIdAction(payload)),
      updateTopSpendingMemberAction: (data) => dispatch(updateTopSpendingMember(data)),
      updateTransactionsDataByMemberUsername: (data) => dispatch(updateTransactionsDataByMemberUsernameAction(data)),
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnlineMembersModal);
