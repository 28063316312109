import * as types from "./types";

const fetchDetails = () => ({
   type: types.FETCH_DETAILS,
});

const fetchDetailsCompleted = (data) => {
   return {
      type: types.FETCH_DETAILS_COMPLETED,
      payload: data,
   }
};

const fetchDetailsFailed = (err) => ({
   type: types.FETCH_DETAILS_FAILED,
   payload: err,
});

const deleteItemCompleted = (data) => {
   return {
      type: types.DELETE_REDIRECT_COMPLETED,
      payload: data,
   }
};

const createNewCompleted = (data) => {
   return {
      type: types.CREATE_NEW_COMPLETED,
      payload: data,
   }
};

const createNewFailed = (err) => ({
   type: types.CREATE_NEW_FAILED,
   payload: err,
});

const updateDataCompleted = (data) => {
   return {
      type: types.UPDATE_DATA_COMPLETED,
      payload: data,
   }
};

const updateDataFailed = (err) => ({
   type: types.UPDATE_DATA_FAILED,
   payload: err,
});

const setModalInfo = data => ({
   type: types.SET_REDIRECT_MODAL,
   payload: data,
});


export {
   fetchDetails,
   fetchDetailsFailed,
   fetchDetailsCompleted,
   deleteItemCompleted,
   createNewCompleted,
   createNewFailed,
   updateDataCompleted,
   updateDataFailed,
   setModalInfo,
};
