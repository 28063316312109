import React from 'react';
import { getSiteName } from 'common/utils/helper';
import PropTypes from 'react';
import { filterBadWords } from 'common/utils/filter-bad-words';

const RecordKeeping = ({
   legalPages,
   isAgeVerificationRequired,
}) => {
   let currentInfo

   if(!!legalPages && !!legalPages?.length) {
      currentInfo = legalPages?.find(it => it.name === 'compliance')
   }

   return (
      currentInfo?.html_content ?
         <>
            <div dangerouslySetInnerHTML={ {
               __html: isAgeVerificationRequired ? filterBadWords(currentInfo?.html_content) : currentInfo?.html_content,
            } } className='text-major dark:text-major-dark policy-content'
            />
         </>
         :
         <>
            <p className='text-2xl text-major dark:text-major-dark mb-3' style={ { lineHeight: '28px' } }>Record-Keeping <br />Requirements Compliance Statement</p>
            <p className='dark:text-secondary-dark '>
               <br />
               <span className='cursor-text select-text'>
                  18 U.S.C. §2257 Record-Keeping Requirements Compliance Statement
                  <br />
                  <br />
                  Exemption Statement – Content Produced by Third Parties: The operator of this website is not the “producer” (as that term is defined at 28 C.F.R. § 75.1(c)) of any visual depictions of actual or simulated sexually explicit conduct that appear hereon. Any such depictions appearing on this website were posted by third party users on areas of this website that are under such users' control. The involvement of the operator of this website, with respect to such depictions, is restricted to the transmission, storage, retrieval, hosting and/or formatting thereof provided, however, that pursuant to Title 18 U.S.C. §2257 (h)(2)(B)(v) and 47 U.S.C. §230 (c)(2) the operator of this website reserves the right to delete any such depictions that the operator deems obscene, lewd, lascivious, filthy, excessively violent, harassing, or otherwise objectionable.
                  <br />
                  <br />
                  Questions regarding this exemption statement should be sent to: <a target='_blank' rel='noreferrer' href='mailto:legal@mymember.site' className='skin-primary-text'> <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /></a>
               </span>
            </p>

         </>
   )
};


RecordKeeping.propTypes = {
   legalPages: PropTypes.array,
   isAgeVerificationRequired: PropTypes.bool,
}

export default RecordKeeping;
