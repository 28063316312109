import React, { memo,
   useEffect,
   //  useState, useEffect,
   // useMemo,
   useState } from 'react';
import PropTypes from 'prop-types';
import TagSelectors from 'common/components/modules/tag-selectors1';
// import cx from 'classnames';
// import QueryParams from 'admin/utils/QueryParams'

// const SelectTagsAndCast = ({
//    tagsList = [],
//    tags = [],
//    membersList = [],
//    members = [],
//    onInputChange = () => {},
//    isNewMedia,
//    bulkOptions = ['tags', 'casts', 'collections'],
//    onSaveEditChange,
//    tagsError,
//    createTagsError,
//    errors = {},
//    onChangeErrors,
//    collectionsList = [],
//    collections = [],
//    label = 'Info',
//    contentType = 'photoset',
// }) => {
//    // const [castsData, setCastsData] = useState([]);
//    // const [selectCast, setSelectCast] = useState([]);
//    // const [tagsData, setTagsData] = useState([]);
//    // const [selectTags, setSelectTags] = useState([]);
//    // const [collectionsData, setCollectionsData] = useState([]);
//    // const [selectCollections, setSelectCollections] = useState([]);
//    // eslint-disable-next-line no-unused-vars
//    const [forceReRender, setForceReRender] = useState({});


//    let selectCast = useMemo(() => {
//       // if(QueryParams.getParam('selectContentModal')) return [];
//       if(members) {
//          let castsId = [];
//          const newAddCasts = members.map(element => {
//             let item = element;
//             if(item.id) {
//                castsId.push(item.id)
//                return item
//             } else {
//                castsId.push(item)
//                let cast = membersList.find(elm => elm.id === item)
//                item = cast
//                return item
//             }
//          });

//          // setSelectCast(newAddCasts)
//          // setCastsData(membersList)
//          return newAddCasts
//       }
//    }, [members, membersList])

//    let selectTags = useMemo(() => {
//       // if(QueryParams.getParam('selectContentModal')) return [];
//       if(tags) {
//          const newAddTags = tags.map(element => {
//             let item = element;
//             if(item.id) {
//                // tagsId.push(item.id)
//                return item
//             } else {
//                // tagsId.push(item)
//                let tag = tagsList.find(elm => elm.id === item)
//                item = tag
//                return item
//             }
//          });
//          return newAddTags
//       }
//    }, [tags, tagsList])

//    let selectCollections = useMemo(() => {
//       // if(QueryParams.getParam('selectContentModal')) return [];
//       if(collections) {
//          const newCollections = collections.map(element => {
//             let item = element;
//             if(item.id) {
//                return item
//             } else {
//                let collection = collectionsList.find(elm => elm.id === item)
//                item = collection
//                return item
//             }
//          });

//          return  newCollections
//       }
//    }, [collections, collectionsList])

//    console.log(selectCollections);
//    // useEffect(() => {
//    //    if(QueryParams.getParam('selectContentModal')) return;
//    //    if(members) {
//    //       let castsId = [];
//    //       const newAddCasts = members.map(element => {
//    //          let item = element;
//    //          if(item.id) {
//    //             castsId.push(item.id)
//    //             return item
//    //          } else {
//    //             castsId.push(item)
//    //             let cast = membersList.find(elm => elm.id === item)
//    //             item = cast
//    //             return item
//    //          }
//    //       });

//    //       // setSelectCast(newAddCasts)
//    //       // setCastsData(membersList)
//    //    }

//    // }, [members, membersList])


//    // useEffect(() => {
//    //    if(QueryParams.getParam('selectContentModal')) return;
//    //    let tagsId = [];
//    //    const newAddTags = tags.map(element => {
//    //       let item = element;
//    //       if(item.id) {
//    //          tagsId.push(item.id)
//    //          return item
//    //       } else {
//    //          tagsId.push(item)
//    //          let tag = tagsList.find(elm => elm.id === item)
//    //          item = tag
//    //          return item
//    //       }
//    //    });
//    //    // setSelectTags(newAddTags)
//    //    // setTagsData(tagsList)
//    // }, [tagsList, tags])


//    // useEffect(() => {
//    //    if(QueryParams.getParam('selectContentModal')) return;
//    //    let collectionsId = [];
//    //    const newCollections = collections.map(element => {
//    //       let item = element;
//    //       if(item.id) {
//    //          collectionsId.push(item.id)
//    //          return item
//    //       } else {
//    //          collectionsId.push(item)
//    //          let collection = collectionsList.find(elm => elm.id === item)
//    //          item = collection
//    //          return item
//    //       }
//    //    });
//    //    // setSelectCollections(newCollections)
//    //    // setCollectionsData(collectionsList)
//    // }, [collections, collectionsList])


//    function onRemoveItem(type, value, bool){
//       let selectNewItem = []

//       switch (type) {
//          case 'tags':
//             selectTags = [...selectTags].filter(element => element.id !== value.id);
//             // const tagNewData = [...tagsData, value];
//             // setSelectTags(selectNewItem);
//             // setTagsData(tagNewData);
//             break;
//          case 'casts':
//             selectCast = [...selectCast].filter(element => element.id !== value.id);
//             // let castNewData = [...castsData, value];
//             // setSelectCast(selectNewItem);
//             // setCastsData(castNewData);
//             break;
//          case 'collections':
//             selectCollections = [...selectCollections].filter(element => element?.id !== value.id);
//             // const collectionsNewData = [...collectionsData, value];
//             // setSelectCollections(selectNewItem);
//             // setCollectionsData(collectionsNewData);
//             break;

//          default:
//             break;
//       }
//       setForceReRender({})
//       if(bool) {
//          const selected = [...selectNewItem].map(item => item?.id)
//          onInputChange(type, selected, !isNewMedia)
//       }
//    }

//    function onSaveChange(type, data) {
//       let selectData = [];

//       switch (type) {
//          case 'tags':

//             [...tagsList].forEach(item => {
//                if(!!data.includes(item.id)) {
//                   selectData.push(item)
//                }
//             })
//             // setSelectTags(selectData)
//             break;
//          case 'casts':
//             [...membersList].forEach(item => {
//                if(!!data.includes(item.id)) {
//                   selectData.push(item)
//                }
//             })
//             // setSelectCast(selectData)
//             break;
//          case 'collections':
//             [...collectionsList].forEach(item => {
//                if(!!data.includes(item.id)) {
//                   selectData.push(item)
//                }
//             })
//             // setSelectCollections(selectData)
//             break;

//          default:
//             break;
//       }

//       onInputChange(type, data)
//    }

//    useEffect(() => {
//       setForceReRender({})
//    }, [collections, tags, members])

//    return (
//       <div>
//          {
//             !!label && <p className='mb-4 text-major panel-header'>{label}</p>
//          }
//          <div className={ cx({
//             'flex flex-col gap-6': true,
//          }) }>
//             {
//                bulkOptions.includes('casts') && (
//                   <div
//                      className='relative'
//                      id='cast-members-container'
//                   >
//                      <TagSelectors
//                         list={ membersList }
//                         selectedList={ selectCast }
//                         onSaveChange={ (data) => onSaveChange('casts', data) }
//                         onRemove={  (value) => onRemoveItem('casts', value, true) }
//                         type='casts'
//                         isNewMedia={ isNewMedia }
//                         onChangeErrors={ () => onChangeErrors({ ...errors, casts: '' }) }
//                         error={ errors?.casts?.[0] }
//                         contentType={ contentType }
//                      />
//                      {
//                         !!errors?.casts?.[0] && <p className='absolute right-0 -bottom-4 text-error text-xs text-end'>At least one cast member must be selected</p>
//                      }
//                   </div>
//                )
//             }
//             {
//                bulkOptions.includes('collections') && (
//                   <TagSelectors
//                      list={ collectionsList  }
//                      selectedList={ selectCollections }
//                      onSaveChange={ (data) => onSaveChange('collections', data) }
//                      onRemove={ (value) => onRemoveItem('collections', value, true) }
//                      type='collections'
//                      onSaveEditChange={ onSaveEditChange }
//                      tagsError={ tagsError }
//                      createTagsError={ createTagsError }
//                      contentType={ contentType }
//                   />
//                )
//             }
//             {
//                bulkOptions.includes('tags') && (
//                   <TagSelectors
//                      list={ tagsList  }
//                      selectedList={ selectTags }
//                      onSaveChange={ (data) => onSaveChange('tags', data) }
//                      onRemove={ (value) => onRemoveItem('tags', value, true) }
//                      type='tags'
//                      onSaveEditChange={ onSaveEditChange }
//                      tagsError={ tagsError }
//                      createTagsError={ createTagsError }
//                      contentType={ contentType }
//                   />
//                )
//             }
//          </div>
//       </div>
//    );
// };

const SelectTagsAndCast = ({
   tagsList = [],
   tags = [],
   membersList = [],
   members = [],
   onInputChange = () => {},
   isNewMedia,
   bulkOptions = ['tags', 'casts', 'collections'],
   onSaveEditChange,
   tagsError,
   createTagsError,
   errors = {},
   onChangeErrors,
   collectionsList = [],
   collections = [],
   label = 'Info',
   contentType = 'photoset',
}) => {
   const [selectCast, setSelectCast] = useState([]);
   const [selectTags, setSelectTags] = useState([]);
   const [selectCollections, setSelectCollections] = useState([]);

   // Update selectCast whenever members or membersList changes
   useEffect(() => {
      if(members) {
         const newAddCasts = members.map((element) => {
            let item = element;
            if(!item.id) {
               const cast = membersList.find((elm) => elm.id === item);
               item = cast;
            }
            return item;
         });
         setSelectCast(newAddCasts);
      }
   }, [members, membersList]);

   // Update selectTags whenever tags or tagsList changes
   useEffect(() => {
      if(tags) {
         const newAddTags = tags.map((element) => {
            let item = element;
            if(!item.id) {
               const tag = tagsList.find((elm) => elm.id === item);
               item = tag;
            }
            return item;
         });
         setSelectTags(newAddTags);
      }
   }, [tags, tagsList]);

   // Update selectCollections whenever collections or collectionsList changes
   useEffect(() => {
      if(collections) {
         const newCollections = collections.map((element) => {
            let item = element;
            if(!item.id) {
               const collection = collectionsList.find((elm) => elm.id === item);
               item = collection;
            }
            return item;
         });
         setSelectCollections(newCollections);
      }
   }, [collections, collectionsList]);

   function onRemoveItem(type, value, bool) {
      let updatedList = [];
      switch (type) {
         case 'tags':
            updatedList = selectTags.filter((element) => element.id !== value.id);
            setSelectTags(updatedList);
            break;
         case 'casts':
            updatedList = selectCast.filter((element) => element.id !== value.id);
            setSelectCast(updatedList);
            break;
         case 'collections':
            updatedList = selectCollections.filter((element) => element?.id !== value.id);
            setSelectCollections(updatedList);
            break;
         default:
            break;
      }
      if(bool) {
         const selectedIds = updatedList.map((item) => item?.id);
         onInputChange(type, selectedIds, !isNewMedia);
      }
   }

   function onSaveChange(type, data) {
      let selectedData = [];
      switch (type) {
         case 'tags':
            selectedData = tagsList.filter((item) => data.includes(item.id));
            setSelectTags(selectedData);
            break;
         case 'casts':
            selectedData = membersList.filter((item) => data.includes(item.id));
            setSelectCast(selectedData);
            break;
         case 'collections':
            selectedData = collectionsList.filter((item) => data.includes(item.id));
            setSelectCollections(selectedData);
            break;
         default:
            break;
      }
      onInputChange(type, data);
   }

   return (
      <div>
         {!!label && <p className='mb-4 text-major panel-header'>{label}</p>}
         <div className='flex flex-col gap-6'>
            {bulkOptions.includes('casts') && (
               <TagSelectors
                  list={ membersList }
                  selectedList={ selectCast }
                  onSaveChange={ (data) => onSaveChange('casts', data) }
                  onRemove={ (value) => onRemoveItem('casts', value, true) }
                  type='casts'
                  isNewMedia={ isNewMedia }
                  onChangeErrors={ () => onChangeErrors({ ...errors, casts: '' }) }
                  error={ errors?.casts?.[0] }
                  contentType={ contentType }
               />
            )}
            {bulkOptions.includes('collections') && (
               <TagSelectors
                  list={ collectionsList }
                  selectedList={ selectCollections }
                  onSaveChange={ (data) => onSaveChange('collections', data) }
                  onRemove={ (value) => onRemoveItem('collections', value, true) }
                  type='collections'
                  onSaveEditChange={ onSaveEditChange }
                  tagsError={ tagsError }
                  createTagsError={ createTagsError }
                  contentType={ contentType }
               />
            )}
            {bulkOptions.includes('tags') && (
               <TagSelectors
                  list={ tagsList }
                  selectedList={ selectTags }
                  onSaveChange={ (data) => onSaveChange('tags', data) }
                  onRemove={ (value) => onRemoveItem('tags', value, true) }
                  type='tags'
                  onSaveEditChange={ onSaveEditChange }
                  tagsError={ tagsError }
                  createTagsError={ createTagsError }
                  contentType={ contentType }
               />
            )}
         </div>
      </div>
   );
};

SelectTagsAndCast.propTypes = {
   tagsList: PropTypes.array,
   tags: PropTypes.array,
   membersList: PropTypes.array,
   members: PropTypes.array,
   onInputChange: PropTypes.func,
   isNewMedia: PropTypes.bool,
   bulkOptions: PropTypes.array,
   onSaveEditChange: PropTypes.func,
   tagsError: PropTypes.object,
   createTagsError: PropTypes.func,
   errors: PropTypes.object,
   onChangeErrors: PropTypes.func,
   collectionsList: PropTypes.array,
   collections: PropTypes.array,
   label: PropTypes.string,
   contentType: PropTypes.string,
};

export default memo(SelectTagsAndCast);
