import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'common/components/modules/modalV2';
import { Tooltip } from 'react-tooltip';
import './style.scss';
import { createPortal } from 'react-dom';

const ModalDropDown = ({
   children,
   isDropDown,
   contentPosition,
   width,
   height,
   bottom,
   right,
   isMobile,
   top,
   left,
   onCloseModal,
   tooltipId,
   zIndex,
   openChatDropDown,
   closeChatDropDown,
   animate,
   isSite,
   version,
   className,
   isOpen,
}) => {
   useEffect(() => {
      if(isMobile && isSite) {
         openChatDropDown()
      }
      return () => {
         if(isMobile && isSite) {
            closeChatDropDown()
         }
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
   const isDarkMode  = window.site_dark_bg
   if(!!tooltipId) {
      return (
         <>
            {
               createPortal(
                  <Tooltip
                     hidden={ !isOpen }
                     openOnClick
                     id={ tooltipId }
                     // content=''
                     // events={ ['click'] }
                     className='chat-tooltip shadow dark:shadow-dark border-divider  dark:border-divider'
                     place='bottom'
                     clickable
                     afterShow={ openChatDropDown }
                     afterHide={ closeChatDropDown }
                     variant={ isDarkMode ? 'dark' : 'light' }
                     isOpen={ isOpen }
                     positionStrategy='fixed'
                  >
                     {children}
                  </Tooltip >,
                  document.getElementById('tooltips')
               )
            }
         </>

      )

   } else if(isMobile) {
      return (
         <Modal
            onClose={ onCloseModal }
            contentPosition={ contentPosition }
            animate={ animate }
         >
            {children}
         </Modal>
      )
   } else if(!!isDropDown) {
      return (
         <>
            {
               'v1' === version && (
                  <div
                     className='h-full chat-modal-drop-down'
                     style={ {
                        position: 'absolute',
                        bottom: `${ bottom }px`,
                        right: `${ right }px`,
                        top: `${ top }px`,
                        left: `${ left }px`,
                        zIndex: zIndex,
                        width: `${ width }px`,
                        height: height,
                     } }
                  >
                     <div
                        className='h-full shadow rounded-lg dark:shadow-dark bg-panel dark:bg-panel-dark border-divider  dark:border-divider modal-drop-down-dk'
                     >
                        {children}
                     </div>
                  </div>
               )
            }
            {
               'v2' === version && (
                  <div
                     className={ `h-full chat-modal-drop-down rounded-lg dark:shadow-dark bg-panel dark:bg-panel-dark border-divider  dark:border-divider modal-drop-down-dk ${ className }` }
                     style={ {
                        position: 'absolute',
                        bottom: `${ bottom }px`,
                        right: `${ right }px`,
                        top: `${ top }px`,
                        left: `${ left }px`,
                        zIndex: zIndex,
                        width: `${ width }px`,
                        height: height,
                     } }
                  >
                     {children}
                  </div>

               )
            }
         </>
      )
   } else {
      return children
   }
}

ModalDropDown.defaultProps = {
   isDropDown: false,
   width: '199',
   height: '220px',
   bottom: '70',
   right: '58',
   onCloseModal: () => {},
   openChatDropDown: () => {},
   closeChatDropDown: () => {},
   zIndex: 15,
   version: 'v1',
   className: 'border dark:border-divider-dark border-divider shadow-2xl',
}

ModalDropDown.propTypes = {
   isDropDown: PropTypes.bool,
   children: PropTypes.any,
   contentPosition: PropTypes.string,
   width: PropTypes.any,
   height: PropTypes.any,
   bottom: PropTypes.any,
   right: PropTypes.any,
   onCloseModal: PropTypes.func,
   zIndex: PropTypes.any,
   openChatDropDown: PropTypes.func,
   closeChatDropDown: PropTypes.func,
   animate: PropTypes.string,
   isMobile: PropTypes.bool,
   top: PropTypes.any,
   left: PropTypes.any,
   tooltipId: PropTypes.any,
   isSite: PropTypes.bool,
   version: PropTypes.string,
   className: PropTypes.string,
   isOpen: PropTypes.bool,
}

export default ModalDropDown

