import React, { memo,
   //  useState, useEffect,
   useMemo } from 'react';
import PropTypes from 'prop-types';
import TagSelectors from 'common/components/modules/tag-selectors1';
import cx from 'classnames';
import QueryParams from 'admin/utils/QueryParams'

const SelectTagsAndCast = ({
   tagsList = [],
   tags = [],
   membersList = [],
   members = [],
   onInputChange = () => {},
   isNewMedia,
   bulkOptions = ['tags', 'casts', 'collections'],
   onSaveEditChange,
   tagsError,
   createTagsError,
   errors = {},
   onChangeErrors,
   collectionsList = [],
   collections = [],
   label = 'Info',
   contentType = 'photoset',
}) => {


   const selectCast = useMemo(() => {
      if(QueryParams.getParam('selectContentModal')) return [];
      if(members) {
         const newAddCasts = members.map(element => {
            let item = element;
            if(item.id) {
               return item
            } else {
               let cast = membersList.find(elm => elm.id === item)
               item = cast
               return item
            }
         });
         return newAddCasts
      }
   }, [members, membersList])

   const selectTags = useMemo(() => {
      if(QueryParams.getParam('selectContentModal')) return [];
      if(tags) {
         const newAddTags = tags.map(element => {
            let item = element;
            if(item.id) {
               return item
            } else {
               let tag = tagsList.find(elm => elm.id === item)
               item = tag
               return item
            }
         });

         return newAddTags
      }
   }, [tags, tagsList])

   const selectCollections = useMemo(() => {
      if(QueryParams.getParam('selectContentModal')) return [];
      if(collections) {
         const newCollections = collections.map(element => {
            let item = element;
            if(item.id) {
               return item
            } else {
               let collection = collectionsList.find(elm => elm.id === item)
               item = collection
               return item
            }
         });

         return  newCollections
      }
   }, [collections, collectionsList])


   function onRemoveItem(type, value, bool){
      let selectNewItem = []

      switch (type) {
         case 'tags':
            selectNewItem = [...selectTags].filter(element => element.id !== value.id);

            break;
         case 'casts':
            selectNewItem = [...selectCast].filter(element => element.id !== value.id);

            break;
         case 'collections':
            selectNewItem = [...selectCollections].filter(element => element?.id !== value.id);

            break;

         default:
            break;
      }

      if(bool) {
         const selected = [...selectNewItem].map(item => item?.id)
         onInputChange(type, selected, !isNewMedia)
      }
   }

   function onSaveChange(type, data) {
      let selectData = [];

      switch (type) {
         case 'tags':

            [...tagsList].forEach(item => {
               if(!!data.includes(item.id)) {
                  selectData.push(item)
               }
            })
            break;
         case 'casts':
            [...membersList].forEach(item => {
               if(!!data.includes(item.id)) {
                  selectData.push(item)
               }
            })
            break;
         case 'collections':
            [...collectionsList].forEach(item => {
               if(!!data.includes(item.id)) {
                  selectData.push(item)
               }
            })
            break;

         default:
            break;
      }

      onInputChange(type, data)
   }

   return (
      <div>
         {
            !!label && <p className='mb-4 text-major panel-header'>{label}</p>
         }
         <div className={ cx({
            'flex flex-col gap-6': true,
         }) }>
            {
               bulkOptions.includes('casts') && (
                  <div
                     className='relative'
                     id='cast-members-container'
                  >
                     <TagSelectors
                        list={ membersList }
                        selectedList={ selectCast }
                        onSaveChange={ (data) => onSaveChange('casts', data) }
                        onRemove={  (value) => onRemoveItem('casts', value, true) }
                        type='casts'
                        isNewMedia={ isNewMedia }
                        onChangeErrors={ () => onChangeErrors({ ...errors, casts: '' }) }
                        error={ errors?.casts?.[0] }
                        contentType={ contentType }
                     />
                     {
                        !!errors?.casts?.[0] && <p className='absolute right-0 -bottom-4 text-error text-xs text-end'>At least one cast member must be selected</p>
                     }
                  </div>
               )
            }
            {
               bulkOptions.includes('collections') && (
                  <TagSelectors
                     list={ collectionsList  }
                     selectedList={ selectCollections }
                     onSaveChange={ (data) => onSaveChange('collections', data) }
                     onRemove={ (value) => onRemoveItem('collections', value, true) }
                     type='collections'
                     onSaveEditChange={ onSaveEditChange }
                     tagsError={ tagsError }
                     createTagsError={ createTagsError }
                     contentType={ contentType }
                  />
               )
            }
            {
               bulkOptions.includes('tags') && (
                  <TagSelectors
                     list={ tagsList  }
                     selectedList={ selectTags }
                     onSaveChange={ (data) => onSaveChange('tags', data) }
                     onRemove={ (value) => onRemoveItem('tags', value, true) }
                     type='tags'
                     onSaveEditChange={ onSaveEditChange }
                     tagsError={ tagsError }
                     createTagsError={ createTagsError }
                     contentType={ contentType }
                  />
               )
            }
         </div>
      </div>
   );
};

SelectTagsAndCast.propTypes = {
   tagsList: PropTypes.array,
   tags: PropTypes.array,
   membersList: PropTypes.array,
   members: PropTypes.array,
   onInputChange: PropTypes.func,
   isNewMedia: PropTypes.bool,
   bulkOptions: PropTypes.array,
   onSaveEditChange: PropTypes.func,
   tagsError: PropTypes.object,
   createTagsError: PropTypes.func,
   errors: PropTypes.object,
   onChangeErrors: PropTypes.func,
   collectionsList: PropTypes.array,
   collections: PropTypes.array,
   label: PropTypes.string,
   contentType: PropTypes.string,
};

export default memo(SelectTagsAndCast);
