/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MediaContentCard from './media-content-card';
import AdminRouter from 'admin/routes/router';
import SiteRouter from 'sites/routes/router';
import { Link } from 'react-router-dom';
import { generateUriSegment } from 'common/utils/mediaRouteHelper';
import UnlockContentMessage from 'common/components/modules/chat/unlock-content-message';
import './style.scss';
import Button from 'common/components/elements/buttons/primary';
import Icon from 'common/components/elements/icons';
import Modal from 'common/components/modules/modal';
import { isArray } from 'lodash';
import AddCalendarButton from 'common/components/modules/add-calendar';
import IconLabel from 'common/components/elements/icon-label';

import cx from 'classnames';
import DateFormatter from 'common/utils/DateFormatter';
import MediaChip from 'common/components/modules/media-chip';

const NotificationMessage = ({
   type,
   data,
   subject,
   body,
   isAdmin,
   closeChat,
   onClickUnlockButton,
   unlockDetails,
   messageId,
   onStream,
   sentAt,
   goToJoin,
   isPromotionExpired,
   payload,
   onClickNotificationMessageButton = () => {},
}) => {

   const isContentBundle = type === 'content_bundle'
   if('notification_message' === type || type === 'order_request_message'){
      const previewSrc = payload.preview_full_src;
      let resourceType = payload?.resource_type;
      const resourceId = payload?.resource_id;
      const count = payload.duration || payload.count;
      const src = payload?.cover_photo;

      const getContentLink = () => {
         let slug = payload?.notification_slug || '';
         const resourceTitle = payload?.resource_title || '';
         let link = ''
         if('membership_new_join_with_public_promotion' === slug && 'promotion' === resourceType){
            return null;
         }
         if(['store_item_sold', 'content_sold', 'tip_on_content', 'unlock', 'tip_during_livestream', 'order-request'].includes(slug) || slug.includes('store_custom_category_') || slug.includes('order')){
            let params = { id: resourceId };
            if(resourceTitle){
               params = { id: generateUriSegment(resourceId, resourceTitle) }
            }
            if('tip_during_livestream' === slug) resourceType = 'livestream';
            if(slug.includes('store_custom_category_')) resourceType = 'store';
            if(slug.includes('order')) {
               params = { id: payload?.order_request_payment_id }
               resourceType = 'order-request'
            }
            if(isAdmin){
               let pages = {
                  'video': 'VIDEOS_SHOW',
                  'photoset': 'PHOTOSETS_SHOW',
                  'store': 'STORE_DETAILS',
                  'recorded-stream': 'RECORDED_VIDEO',
                  'livestream': 'LIVE_STREAM_STUDIO',
                  'order-request': 'ORDER_REQUEST_DETAILS',
               };
               const routeName = pages[resourceType];
               if(routeName){
                  link = AdminRouter.route(routeName).getCompiledPath(params);
               }
            } else {
               let pages = {
                  'video': 'VIDEOS_INDIVIDUAL',
                  'photoset': 'PHOTOSETS_INDIVIDUAL',
                  'store': 'STORE_DETAILS',
                  'recorded-stream': 'LIVE_STREAM_RECORD',
                  'livestream': 'LIVE_STREAM_STUDIO',
                  'order-request': 'ORDER_DETAILS',
               }
               const routeName = pages[resourceType];
               if(routeName){
                  link = SiteRouter.route(routeName).getCompiledPath(params)
               }
            }
         } else if(src){
            if(isAdmin){
               link = AdminRouter.route('CHAT_GET_CONTENT').getCompiledPath({ id: messageId });
            } else {
               link = SiteRouter.route('CHAT_UNLCOKED_CONTENT').getCompiledPath({ id: messageId });
            }
         } else {
            link = null;
         }
         return link
      }

      const contentComponent = (
         <div>
            <div className='relative aspect-video'>
               <div
                  className='absolute w-full h-auto z-0 flex flex-col justify-between inset-0 rounded-lg'
               >

                  <div className='w-full h-full relative z-10 flex overflow-hidden rounded-lg'>
                     <div
                        className='absolute w-full h-full z-10 bg-no-repeat bg-cover inset-0 '
                        style={ {
                           filter: `blur(8px)`,
                           backgroundImage: `linear-gradient(180.46deg, rgba(0, 0, 0, 0) 67.2%, rgba(0, 0, 0, 0.6) 99.3%), url(${ src })`,
                        } }
                     />



                     {
                        previewSrc && (
                           <div className='h-full w-full flex items-center justify-center z-20'>
                              <video autoPlay loop playsInline muted poster={ src } className='rounded-lg h-full w-auto'>
                                 <source src={ previewSrc } type='video/mp4' />
                                 <source src={ previewSrc } type='video/ogg' />
                                 Your browser does not support the video tag.
                              </video>
                           </div>
                        )
                     }
                     {
                        !previewSrc && (
                           <div
                              className='absolute w-full h-full bg-no-repeat bg-contain bg-center z-20'
                              style={ {
                                 backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.44) 0%, rgba(0, 0, 0, 0) 25.83%, rgba(0, 0, 0, 0) 80.32%, rgba(0, 0, 0, 0.4) 100% ), url(${ src })`,
                              } }
                           />
                        )
                     }

                     {
                        ['photoset', 'photo', 'video'].includes(resourceType) && (
                           <div className='flex items-center justify-end w-full p-2 z-30 absolute right-0'>
                              <MediaChip
                                 countOrDuration={ count }
                                 type={ resourceType }
                              />
                           </div>
                        )
                     }
                  </div>
               </div>
            </div>
            {
               payload?.notification_slug.includes('order') &&
               <Button
                  text={ 'See the order' }
                  classNames='!w-fit !h-9  mb-2 mt-6 mx-auto px-3'
                  textMarginX='0'
                  textClassName='order-request-button'
                  borderRadius='label'
                  textSize='small'
                  primaryColor
               />
            }
         </div>
      )
      return (
         <div className='chat-unlocking-content p-2 relative bg-panel rounded-lg border border-divider flex flex-col dark:bg-panel-dark dark:border-divider-dark'>
            <div className='unlocking-content-header mx-1 my-2'>

               <span role='img' className='block text-major text-xl leading-tight mb-1 dark:text-major-dark' aria-label='' > { subject } </span>
               { body && <NotificationMessageBody body={ body } />}
            </div>

            {
               src && (
                  <>
                     {
                        getContentLink() && (
                           <Link
                              to={ getContentLink() }
                              className={
                                 cx({
                                    '!no-underline': payload?.notification_slug.includes('order'),
                                 })
                              }
                           >
                              {contentComponent}
                           </Link>
                        )
                     }
                     {
                        !getContentLink() && (
                           <>{contentComponent}</>
                        )
                     }
                  </>
               )
            }

         </div>
      )
   }
   if(type === 'promo_promotion' || type === 'scheduled_promotion' || type === 'promo_promotion_24_hour_left') {
      return (
         <div className='chat-unlocking-card p-4 relative rounded-lg border dark:border-divider-dark border-divider flex flex-col bg-panel dark:bg-panel-dark select-none'>
            <div
               className='flex items-center gap-2'
            >
               <div
                  className='w-8 h-8 rounded-full flex items-center justify-center bg-pending dark:bg-pending-dark'
               >
                  <Icon
                     name='hero-section'
                     color='major-dark'
                     size='xl'
                  />
               </div>
               <div className='flex flex-col flex-1'>
                  <span className='text-sm text-major dark:text-major-dark font-bold'>{data.name}</span>
                  <span className='text-secondary dark:text-secondary-dark text-xs leading-[18px]'>{ DateFormatter.convertToUserTzV2(sentAt, 'MMM DD, YYYY • hh:mm A') } </span>
               </div>
            </div>

            <div className='w-full aspect-video relative overflow-hidden rounded-lg mt-3'>
               <div
                  style={ {
                     backgroundImage: `url(${ data.cf_thumb_path })`,
                     filter: 'blur(20px)',
                     zIndex: '1',
                  }  }
                  className='w-full h-full absolute top-0 right-0 bg-cover bg-center'
               ></div>
               <img className='w-full h-full absolute  top-0 right-0 object-contain object-center z-1' src={ data.cf_thumb_path } alt='promotion-notification' />
            </div>

            <span className='leading-5 mt-2 font-semibold dark:text-major-dark'>{ subject }</span>
            {
               (type === 'promo_promotion' || type === 'promo_promotion_24_hour_left') && (
                  <>
                     <div className='mt-2'>
                        { body && <NotificationMessageBody body={ body } color='secondary dark:text-secondary-dark' />}
                     </div>
                     {
                        !isAdmin && (
                           <div className='flex justify-center mt-4'>
                              <div
                                 className='h-11'
                                 data-tooltip-id={ isPromotionExpired ? 'ams-top-tooltip' : null }
                                 data-tooltip-content={ isPromotionExpired ? 'Deal ended' : null }
                              >
                                 <Button
                                    text='Get this deal'
                                    classNames='h-full'
                                    primaryColor
                                    borderRadius='large'
                                    textSize='base'
                                    waveActive={ false }
                                    onClick={ () => goToJoin(null, 'promotion') }
                                    disabled={ isPromotionExpired }
                                 />
                              </div>
                           </div>
                        )
                     }
                  </>
               )
            }
         </div>
      )
   }
   if(['promo_poll', 'promo_poll_24_hour_left', 'promo_poll_ended'].includes(type)) {

      const getNotificationState = () => {
         let result = {}

         switch (type) {
            case 'promo_poll':
               result.title = 'Poll has published'

               result.buttonText = 'Vote'

               if('published' === data.status && data.ends_in && data.show_public_countdown) {
                  const formattedEndDate = DateFormatter.getDateLeftInDays(data.ends_in, true)
                  if(formattedEndDate) {
                     result.description = `Ends in ${ DateFormatter.getDateLeftInDays(data.ends_in, true) }`
                  }
               }

               break;
            case 'promo_poll_24_hour_left':
               result.title = '24 hours left to vote'

               result.buttonText = 'Vote'

               if('published' === data.status && data.ends_in) {
                  const formattedEndDate = DateFormatter.getDateLeftInDays(data.ends_in, true)
                  if(formattedEndDate) {
                     result.description = `Ends in ${ DateFormatter.getDateLeftInDays(data.ends_in, true) }`
                  }
               }

               break;
            case 'promo_poll_ended':
               result.title = 'Poll ended'

               result.buttonText = 'See results'

               break;

            default:
               break;
         }

         return result;
      }
      const notificationState = getNotificationState()
      return (
         <div
            className='chat-unlocking-card p-4 relative rounded-lg border dark:border-divider-dark border-divider flex flex-col gap-2 bg-panel dark:bg-panel-dark select-none'
         >
            <div
               className='flex items-center gap-2'
            >
               <IconLabel
                  name='poll'
                  color='action'
                  darkIconColor='action-dark'
               />
               <div
                  className='flex flex-col flex-1'
               >
                  <span
                     className='text-major dark:text-major-dark font-medium leading-5'
                  >
                     {notificationState.title}
                  </span>
                  {
                     notificationState.description && (
                        <span
                           className='text-major dark:text-major-dark text-sm leading-[18px]'
                        >
                           {notificationState.description}
                        </span>
                     )
                  }
               </div>
            </div>
            <div
               className='w-full flex flex-col gap-1 py-1'
            >
               <span
                  className='text-sm font-medium leading-[18px] text-secondary dark:text-secondary-dark'
               >
                  {data.name}
               </span>
               {
                  data.options && Array.isArray(data.options) && (
                     <ul
                        className='flex flex-col gap-1 pl-5 list-disc text-secondary dark:text-secondary-dark text-sm leading-[14px]'
                     >
                        {
                           data.options.map(option => (
                              <li>
                                 {option.name}
                              </li>
                           ))
                        }
                     </ul>
                  )
               }
            </div>
            {
               !isAdmin && (
                  <div
                     className='h-9'
                  >
                     <Button
                        text={ notificationState.buttonText }
                        classNames='h-full'
                        primaryColor
                        borderRadius='large'
                        textSize='base'
                        onClick={ () => onClickNotificationMessageButton({
                           type,
                           promoResource: data,
                        }) }
                     />
                  </div>
               )
            }
         </div>
      )
   }
   return (
      <div className='chat-unlocking-content p-2 relative rounded-lg border dark:border-divider-dark border-divider flex flex-col bg-panel dark:bg-panel-dark'>
         <div className='unlocking-content-header mx-1 my-2'>

            <span role='img' className='block text-major text-xl leading-tight mb-1 dark:text-major-dark' aria-label='' > { subject } </span>
            { body && <NotificationMessageBody body={ body } />}
         </div>
         {
            unlockDetails && (
               <UnlockContentMessage
                  isAdmin={ isAdmin }
                  contentLink={ () => {
                     if(isAdmin)  {
                        return AdminRouter.route('CHAT_GET_CONTENT').getCompiledPath({ id: messageId })
                     }
                     return SiteRouter.route('CHAT_UNLCOKED_CONTENT').getCompiledPath({ id: messageId })
                  }
                  }
                  type='photo_unlock'
                  data={ unlockDetails }
                  onStream={ onStream }
                  closeChat={ ()  => {} }
                  width='100%'
               />
            )
         }
         {
            type !== 'mass_message'  && (

               <div
                  role='presentation'
                  onTouchStart={ (e) => {
                     e.preventDefault()
                     e.stopPropagation()
                  } }
                  onMouseDown={ (e) => {
                     e.preventDefault()
                     e.stopPropagation()
                  } }
                  className={ `unlocking-content-card relative w-full h-full mt-2 ${ data.stream_status === 'unlock' ? '' : 'cursor-pointer' }` }
               >
                  <Link
                     to={ isContentBundle ?
                        !isAdmin ?
                           SiteRouter.route('STORE_DETAILS').getCompiledPath({ id: generateUriSegment(data.id, data.name)  })
                           :
                           AdminRouter.route('STORE_EDIT_BUNDLE').getCompiledPath({ id: generateUriSegment(data.id, data.name)  })
                        : null
                     }
                     role='presentation'
                     onClick={ (e) => {
                        if(!isContentBundle) {
                           e.preventDefault()
                        } else {
                           if(closeChat) {
                              closeChat()
                           }
                        }
                     } }
                  >
                     <MediaContentCard
                        src={ data.cover_photo }
                        blurhash={ data.blurhash }
                        streamStatus={ data.stream_status }
                        streamPrice={ data.stream_price }
                        isAdmin={ isAdmin }
                        data={ data }
                        type={ type }
                     />
                  </Link>
                  {
                     type === 'promo_photoset' && (
                        // data.stream_status === 'unlock' && !isAdmin ? (
                        //    <MediaContentCard
                        //       src={ data.cover_photo }
                        //       blurhash={ data.blurhash }
                        //       streamStatus={ data.stream_status }
                        //       streamState={ data.stream_state }
                        //       streamPrice={ data.stream_price }
                        //       count={ data.photos_count }
                        //       type='photoset'
                        //       onClickUnlockButton={ onClickUnlockButton }
                        //       isAdmin={ isAdmin }
                        //    />
                        // ) :
                        <Link
                           // onClick={ isAdmin ? null :
                           //     () => {
                           //    if(closeChat) {
                           //       closeChat()
                           //    }
                           // } }
                           to={ isAdmin ?
                              AdminRouter.route('PHOTOSETS_SHOW').getCompiledPath({ id: generateUriSegment(data.id, data.title) })
                              :
                              SiteRouter.route('PHOTOSETS_INDIVIDUAL').getCompiledPath({ id: generateUriSegment(data.id, data.title) })
                           }
                        >
                           <MediaContentCard
                              src={ data.cover_photo }
                              id={ data.id }
                              blurhash={ data.blurhash }
                              streamStatus={ data.stream_status }
                              streamState={ data.stream_state }
                              streamPrice={ data.stream_price }
                              count={ data.photos_count }
                              onClickUnlockButton={  onClickUnlockButton }
                              goToJoin={ goToJoin }
                              type='photoset'
                              isAdmin={ isAdmin }
                           />

                        </Link>
                     )
                  }
                  {
                     type === 'promo_video' && (
                        // data.stream_status === 'unlock' && !isAdmin ? (
                        //    <MediaContentCard
                        //       src={ data.poster_src }
                        //       blurhash={ data.blurhash }
                        //       streamStatus={ data.stream_status }
                        //       streamState={ data.stream_state }
                        //       streamPrice={ data.stream_price }
                        //       count={ data.duration }
                        //       type='video'
                        //       previewSrc={ data.system_preview_video_full_src || data.custom_preview_video_full_src }
                        //       onClickUnlockButton={ onClickUnlockButton }
                        //       isAdmin={ isAdmin }
                        //    />
                        // ) :
                        <Link
                           // onClick={ isAdmin ? null : () => {
                           //    if(closeChat) {
                           //       closeChat()
                           //    }
                           // } }
                           to={ isAdmin ?
                              AdminRouter.route('VIDEOS_SHOW').getCompiledPath({ id: generateUriSegment(data.id, data.title) })
                              :
                              SiteRouter.route('VIDEOS_INDIVIDUAL').getCompiledPath({ id: generateUriSegment(data.id, data.title) })
                           }
                        >
                           <MediaContentCard
                              src={ data.poster_src }
                              id={ data.id }
                              blurhash={ data.blurhash }
                              streamStatus={ data.stream_status }
                              streamState={ data.stream_state }
                              streamPrice={ data.stream_price }
                              count={ data.duration }
                              type='video'
                              previewSrc={ data.system_preview_video_full_src || data.custom_preview_video_full_src }
                              onClickUnlockButton={ onClickUnlockButton }
                              isAdmin={ isAdmin }
                              goToJoin={ goToJoin }
                           />
                        </Link>
                     )
                  }
               </div>
            )
         }
      </div>
   );
};

const LiveStreamNotification = ({
   subject,
   body,
   data,
   isAdmin,
   onStream,
   contentLink,
   closeChat,
   membershipLinks,
   paymentButtonLoading,
   isMiniChat,
}) => {
   const [tagIndex, setTagIndex] = useState(3);
   if(Boolean(data.scheduled_livestream_date)){
      return (
         <div
            className={ cx({
               'chat-unlocking-content p-2 relative rounded-lg border dark:border-divider-dark border-divider flex flex-col bg-panel dark:bg-panel-dark': true,
               'livestream-scheduled-notification': !isMiniChat,
            }) }
         >

            <div className='flex'>
               <IconLabel
                  className='w-8 h-8 mr-2'
                  name='online-stream'
                  size='base'
                  color='action'
               />
               <div className='flex flex-col'>
                  <span className='text-major text-sm dark:text-major-dark leading-tight'> Scheduled livestream </span>
                  <span className='text-secondary text-xs dark:text-secondary-dark '> {  DateFormatter.getDateByTimzeonFormat(data.scheduled_livestream_date)} </span>
               </div>
            </div>
            <div
               className='flex flex-col rounded-lg relative overflow-hidden aspect-ratio-padding z-1 w-full my-3'>
               <div
                  className='w-full h-full bg-cover bg-center bg-no-repeat absolute inset-0 z-1'
                  style={ {
                     filter: 'blur(10px)',
                     backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0) 27.86%), url(${ data.cover })`,
                  } }
               />
               <img src={ data.cover } alt='' className='w-full h-full object-contain object-center absolute inset-0 z-2' />
            </div>
            {
               isArray(data.casts) && data.casts.length > 0 && (
                  <div className='text-major leading-tight font-normal flex flex-wrap dark:text-major-dark mb-2 mt-2'>
                     Features:
                     {
                        data.casts.map((cast, index) => (
                           <div
                              key={ cast.id }
                              role='presentation'
                              onClick={ () => {} }
                              className='font-medium cursor-pointer'>
                              { cast.screen_name }{(index !== data.casts.length - 1) && ','}&nbsp;
                           </div>
                        ))
                     }
                  </div>
               )
            }
            <span className='text-major dark:text-major-dark font-semibold leading-[20px]'>{subject || data.title}</span>

            { (body || data.description) && <NotificationMessageBody body={ body || data.description } color='text-secondary dark:text-secondary-dark ' />}
            {
               isArray(data.tags) && data.tags.length > 0 && (
                  <div className='flex flex-wrap mt-2'>
                     {
                        data.tags.map((tag, index) => {
                           if(tagIndex && index > (tagIndex - 1)) return null
                           return (
                              <div
                                 style={ {
                                    paddingTop: '6px',
                                    paddingBottom: '6px',
                                 } }
                                 key={ tag.id }
                                 className='dark:border-divider-dark border-divider rounded-full  text-major dark:text-major-dark
                               flex items-center justify-center px-3 mb-2 mr-2 cursor-pointer bg-panel
                               dark:bg-panel-dark'
                              >
                                 {tag.name}
                              </div>
                           )
                        })
                     }
                     {
                        data.tags.length > 3 && (
                           <div>
                              <Button
                                 text={ tagIndex ? `See more ${ data.tags.length - tagIndex } tags` : 'Hide' }
                                 classNames='h-full px-3'
                                 padding='none'
                                 onClick={ () => {
                                    let value = tagIndex ? null : 3;
                                    setTagIndex(value);

                                 } }
                                 borderRadius='label'
                                 iconName='tag-new'
                                 textSize='small'
                                 fontIconSize='small'
                                 iconPosition='left'
                                 fontIconColor='secondary'
                                 darkIconColor='secondary-dark'
                                 textColor='secondary dark:text-secondary-dark '
                                 backgroundColor='transparent'
                              />
                           </div>
                        )
                     }
                  </div>
               )
            }
            <AddCalendarButton
               title={ subject }
               description={ data.description }
               date={ data.scheduled_livestream_date }
               className='h-5 mt-4 inline-flex mr-auto'
               buttonState={ {
                  textSize: 'small',
                  fontIconSize: 'small',
                  backgroundColor: 'transparent',
               } }
            />
         </div>
      )
   }

   const getState = () => {
      let state = {}
      if(isAdmin){
         state.buttonText = 'Go to livestream';
         state.buttonIcon = null;
         state.onClick = () => {};
         state.link = contentLink;
         state.loading = false;
      } else {
         switch (data.access_type) {
            case 'unlocked':
               state.buttonText = 'Join livestream';
               state.buttonIcon = 'online-stream';
               state.onClick = () => {};
               state.link = contentLink;
               break;
            case 'locked':
               state.buttonText = `Unlock for ${ data.unlock_price } USD`;
               state.buttonIcon = 'play-default';
               state.onClick = () => onStream();
               state.link = isAdmin ? contentLink : null
               state.loading = paymentButtonLoading
               break;

            default:
               state.buttonText = 'Get membership';
               state.buttonIcon = 'membership';
               state.onClick = () => {};
               state.link = isAdmin ? contentLink : membershipLinks
               break;
         }
      }
      return state
   }

   const state = getState();
   const button = (
      <Button
         text={ state.buttonText }
         classNames='h-full px-3'
         padding='none'
         onClick={ state.onClick }
         primaryColor
         borderRadius='label'
         iconName={ state.buttonIcon }
         textSize='base'
         iconPosition='left'
         isLoading={ state.loading }
      />
   )
   return (
      <div className='chat-unlocking-content p-2 relative rounded-lg border dark:border-divider-dark border-divider flex flex-col bg-panel dark:bg-panel-dark'>
         <div className='unlocking-content-header mx-1 my-2'>

            <span role='img' className='block text-major text-xl leading-tight mb-1 dark:text-major-dark' aria-label='' > { subject } </span>
            { body && <NotificationMessageBody body={ body } />}
         </div>
         {
            data && (
               <UnlockContentMessage
                  isAdmin={ isAdmin }
                  contentLink={ () => contentLink }
                  type='live_stream'
                  data={ data }
                  onStream={ onStream }
                  closeChat={ ()  => {} }
                  width='100%'
               />
            )
         }

         <div
            className='h-9 flex justify-center px-3 mt-6 mb-2 items-center'
         >
            {
               state?.link && (
                  <Link
                     onClick={ isAdmin ? null : () => {
                        if(closeChat) {
                           closeChat()
                        }

                     } }
                     to={ state.link  }
                     className='text-decoration-none h-full'
                  >
                     {button}
                  </Link>
               )
            }
            {
               !state?.link && (
                  button
               )
            }
         </div>

      </div>
   )
}


const NotificationMessageBody = ({ body, color }) => {

   const ref = React.useRef()
   const [isReadMore, setIsReadMore] = useState(true);
   const [activeImageSrc, setActiveImageSrc] = useState(null);
   const [showReadMore, setShowReadMore] = useState(false);

   function linkify(text) {
      let result = text;
      // eslint-disable-next-line no-useless-escape
      const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&amp;&@#\/%?=~_|!:,.;]*[-A-Z0-9+&amp;&@#\/%=~_|])/ig;
      const exp2 = /\b(www\.[\S]+)\b/gi;

      result = replaceTxtNotInA(result, exp, false);

      result = replaceTxtNotInA(result, exp2, true);

      return result;
   }


   function replaceTxtNotInA(bodyText, regex, bool) {
      let html = bodyText;
      html = '>' + html + '<';
      html = html.replace(/>([^<>]+)(?!<\/a)</g, function(match, txt) {

         let finallyResult = txt.replace(regex, function(match, text) {
            let result = `<a href=${ text } target='_blank'> ${ text } </a>`;
            if(bool){
               result = `<a href=http://${ text } target='_blank'> ${ text } </a>`
            }
            return result
         });

         return '>' + finallyResult + '<';
      });

      return html.substring(1, html.length - 1);
   }

   useEffect(() => {
      let newBody = linkify(body);
      if(ref.current && newBody) {
         ref.current.innerHTML = newBody;
         let images = ref.current.querySelectorAll('img');
         images.forEach(element => {
            element.classList.add('cursor-pointer');
            element.onclick = () => {
               setActiveImageSrc(element.src);
            }
         });
         let height = ref.current.getBoundingClientRect().height;
         if(height > 110){
            ref.current.classList.add('body-min-h');
            setShowReadMore(true);
         }
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const onSetIsReadMore = () => {
      setIsReadMore(!isReadMore);
      if(!isReadMore) {
         ref.current.classList.add('body-min-h');
      } else {
         ref.current.classList.remove('body-min-h');
      }
   }
   return (
      <>
         <div ref={ ref } className={ `leading-none ql-editor ql-text-major chat-notif-messafe-body ${ color }` } />
         {
            showReadMore && (
               <span
                  onClick={ () => onSetIsReadMore() }
                  className='cursor-pointer text-secondary dark:text-secondary-dark'
                  role='presentation'
               >
                  {isReadMore ? '... more' : ' less'}
               </span>
            )
         }

         {
            !!activeImageSrc && (
               <Modal
                  contentPosition='center'
                  onClose={ () => setActiveImageSrc(null) }
                  contentWidth='320'
               >
                  <div className='relative h-full w-full z-1'>
                     <div
                        className='cursor-pointer w-10 h-10 items-center justify-center flex mx-3 mt-2 absolute z-2 right-0'
                     >
                        <Button
                           backgroundColor='transparent'
                           iconName='close'
                           fontIconColor='major-dark'
                           classNames='h-10 w-10'
                           padding='none'
                           onClick={ () => setActiveImageSrc(null) }
                        />
                     </div>
                     <div role='presentation'
                        className='relative z-1 overflow-hidden'
                        style={ {
                           minHeight: '320px',
                           linearGradient: 'rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0) 27.86%), linear-gradient(rgba(0, 0, 0, 0) 62.09%, rgba(0, 0, 0, 0.65) 100%)',
                        } }
                     >

                        <img className='w-full h-full object-center object-contain z-20 absolute inset-0' src={ activeImageSrc } alt='' />
                        <div className='absolute inset-0 w-full h-full bg-center bg-cover z-10'
                           style={ {
                              backgroundImage: `url(${ activeImageSrc })`,
                              filter: 'blur(20px)',
                           } }>

                        </div>
                     </div>

                  </div>
               </Modal>

            )
         }
      </>
   );
}

NotificationMessage.defaultProps = {
   chat: {},
   cancelNewConversation: () => {},
};
NotificationMessageBody.defaultProps = {
   color: 'text-major dark:text-major-dark',
};
NotificationMessageBody.propTypes = {
   body: PropTypes.string,
   color: PropTypes.string,
};
NotificationMessage.propTypes = {
   data: PropTypes.object,
   payload: PropTypes.object,
   type: PropTypes.string,
   subject: PropTypes.string,
   body: PropTypes.string,
   isAdmin: PropTypes.bool,
   closeChat: PropTypes.func,
   onClickUnlockButton: PropTypes.func,
   unlockDetails: PropTypes.object,
   messageId: PropTypes.number,
   onStream: PropTypes.func,
   sentAt: PropTypes.string,
   goToJoin: PropTypes.func,
   isPromotionExpired: PropTypes.bool,
   onClickNotificationMessageButton: PropTypes.func,
};
LiveStreamNotification.propTypes = {
   data: PropTypes.object,
   subject: PropTypes.string,
   body: PropTypes.string,
   contentLink: PropTypes.string,
   isAdmin: PropTypes.bool,
   closeChat: PropTypes.func,
   membershipLinks: PropTypes.string,
   onStream: PropTypes.func,
   paymentButtonLoading: PropTypes.bool,
   isMiniChat: PropTypes.bool,
};

export default NotificationMessage;
export { LiveStreamNotification, NotificationMessageBody }
