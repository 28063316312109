
//init data
export const FETCH_DETAILS_START = "cast-members/FETCH_DETAILS_START";
export const FETCH_DETAILS_COMPLETED = "cast-members/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "cast-members/FETCH_DETAILS_FAILED";

// filter
export const FETCH_CAST_MEMBER_REQUEST = "cast-members/FETCH_CAST_MEMBER_REQUEST";
export const FETCH_CAST_MEMBER_COMPLETED = "cast-members/FETCH_CAST_MEMBER_COMPLETED";
export const FETCH_CAST_MEMBER_FAILED = "cast-members/FETCH_CAST_MEMBER_FAILED";

//  Add
export const ADD_NEW_CAST_MEMBER_REQUEST = "cast-members/ADD_NEW_CAST_MEMBER_REQUEST";
export const ADD_NEW_CAST_MEMBER_COMPLETED = "cast-members/ADD_NEW_CAST_MEMBER_COMPLETED";
export const ADD_NEW_CAST_MEMBER_FAILED = "cast-members/ADD_NEW_CAST_MEMBER_FAILED";

//  success
export const SUCCESS_CAST_MEMBER_REQUEST = "cast-members/SUCCESS_CAST_MEMBER_REQUEST";
export const SUCCESS_CAST_MEMBER_COMPLETED = "cast-members/SUCCESS_CAST_MEMBER_COMPLETED";
export const SUCCESS_CAST_MEMBER_FAILED = "cast-members/SUCCESS_CAST_MEMBER_FAILED";

//  next page
export const NEW_FETCH_REQUEST = "cast-members/NEW_FETCH_DETAILS";
export const NEW_FETCH_DETAILS_COMPLETED = "cast-members/NEW_FETCH_DETAILS_COMPLETED";
export const NEW_FETCH_DETAILS_FAILED = "cast-members/NEW_FETCH_DETAILS_FAILED";

// filtered
export const FILTER_CAST_MEMBERS_REQUEST = "cast-members/FILTER_CAST_MEMBERS_REQUEST";
export const FILTER_CAST_MEMBERS_COMPLETED = "cast-members/FILTER_CAST_MEMBERS_COMPLETED";
export const FILTER_CAST_MEMBERS_FAILED = "cast-members/FILTER_CAST_MEMBERS_FAILED";

// update
export const UPDATE_CAST_MEMBERS_REQUEST = "cast-members/UPDATE_CAST_MEMBERS_REQUEST";
export const UPDATE_CAST_MEMBERS_COMPLETED = "cast-members/UPDATE_CAST_MEMBERS_COMPLETED";
export const UPDATE_CAST_MEMBERS_FAILED = "cast-members/UPDATE_CAST_MEMBERS_FAILED";

// delete
export const DELETE_CAST_MEMBERS_REQUEST = "cast-members/DELETE_CAST_MEMBERS_REQUEST";
export const DELETE_CAST_MEMBERS_COMPLETED = "cast-members/DELETE_CAST_MEMBERS_COMPLETED";
export const DELETE_CAST_MEMBERS_FAILED = "cast-members/DELETE_CAST_MEMBERS_FAILED";

export const FETCH_ERRORS = "cast-members/FETCH_ERRORS";
export const SET_CAN_NOT_DELETE_MODAL = "cast-members/SET_CAN_NOT_DELETE_MODAL";
