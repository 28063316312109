import PropTypes from 'prop-types';
import ModalWrapper from "common/components/modules/modal-wrapper";
import { useSelector } from 'react-redux';
import { screenWidthSelector } from 'admin/state/modules/common/selectors';

const SfwWarningModal = ({
   onConfirm,
   onCloseModal,
}) => {
   const screenWidth = useSelector(screenWidthSelector)

   return (
      <ModalWrapper
         isMobile={ screenWidth <= 1024 }
         type='confirm'
         icon='warning'
         iconColor='warning'
         iconSize='2xl'
         title='Warning'
         description={ `Content incorrectly marked as "Safe for Work" (SFW) may result in account suspension or closure.` }
         secondDescription='Are you 100% sure this content is SFW?'
         descriptionLeading='leading-5'
         secondDescriptionClassName='leading-5 text-secondary mt-5'
         buttonClassName='mt-8 w-full'
         buttonText='Yes, the content is SFW'
         cancelButtonWrapperClassName='w-full'
         cancelText='No, revert to NSFW'
         action={ onConfirm }
         onCloseModal={ onCloseModal }
      />
   )
}

SfwWarningModal.propTypes = {
   onConfirm: PropTypes.func,
   onCloseModal: PropTypes.func,
}

export default SfwWarningModal;
