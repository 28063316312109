import SfwWarningModal from 'admin/views/media-modules/SFW-warning-modal';
import { arrayMoveImmutable } from 'array-move';
import cx from 'classnames';
import Button from 'common/components/elements/buttons/primary';
import IconLabel from 'common/components/elements/icon-label';
import CheckboxLabel from 'common/components/elements/inputs/checkbox-label';
import WarningModal from 'common/components/modules/photosets-and-videos-warning-modal';
import toast from 'common/utils/toast';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import {
   sortableContainer,
   sortableElement,
} from 'react-sortable-hoc';
import AddNewPhotosetCard from './add-new-photoset-card';
import './style.scss';
import SfwCheckbox from 'admin/views/media-modules/sfw-checkbox';


const SortableItem = sortableElement(({
   thumbnail,
   photo,
   removePhoto,
   makeCover,
}) => {
   return (
      <AddNewPhotosetCard
         thumbnail={ thumbnail }
         className='z-10'
         photo={ photo }
         removePhoto={ removePhoto }
         makeCover={ () => makeCover(photo) }
      />
   )
}
);

const SortableContainer = sortableContainer(({ children }) => {
   return <>{children}</>;
});

const AddNewPhotoset = ({
   photos = [],
   fileUpload = () => {},
   removePhoto,
   makeCover,
   onSortPhotosEnd,
   lockedImageIsBlur,
   onClickUpdate,
   screenWidth,
   isEditPage,
   zipFiles = [],
   uploadZipAgain = () => {},
   isUnlockedBefore = false,
   error = {},
   onChangeErrors = () => {},
   thumbnails = [],
   isDemoMode,
   thumbnailIsSfw,
   onClickSortPhotos,
   isDisableSortButton,
}) => {

   let inputFile = null
   const [inputValue, setInputValue] = useState('')
   const [dragActive, setDragActive] = useState(false)
   const [loadMore, setLoadMore] = useState(false)
   let headerElementRef = useRef(null)
   const [isOpenUploadWarning, setIsOpenUploadWarning] = useState(false)
   const [loadingButtonType, setLoadingButtonType] = useState('')
   const [sortWarning, setSortWarning] = useState({})
   const isNeedToShowUploadWarning = !+localStorage.getItem('dont_show_add_or_delete_photos_warning') && isEditPage && isUnlockedBefore
   const isNeedToShowSortWarning = !+localStorage.getItem('dont_show_photos_sort_warning')
   let deleteableItemRef = useRef(null)
   const [thumbnailForView, setThumbnailForView] = useState([])
   const filesCount = (zipFiles?.filter(it => it?.status !== 'completed')?.length || 0) + photos?.length
   const isUploadingOrEmpty = photos?.length < 2 || photos?.find(file => file?.status === 'loading')

   useEffect(() => {
      if(!!photos?.length){
         let data = []
         thumbnails?.forEach((thumbId, idx) => {
            if(photos?.find(it => it?.id === thumbId)) {
               data.push({ thumbId, text: idx === 0 ? 'Default thumbnail' : `Thumbnail ${ idx + 1 }` })
            }
         })
         setThumbnailForView([...data])
      }
   }, [photos, thumbnails])


   const [isOpenSfwWarningModal, setIsOpenSfwWarningModal] = useState(false)

   useEffect(() => {
      if(isEditPage && photos?.length > 9) {
         setLoadMore(true)
      }
   // eslint-disable-next-line
   },[isEditPage])

   const onSortEnd = ({ oldIndex, newIndex }) => {
      const newData = arrayMoveImmutable(photos, oldIndex, newIndex)
      if(oldIndex !== newIndex){
         onSortPhotosEnd(newData)
      }
   }

   const handleDrag = function(e) {
      e.preventDefault();
      e.stopPropagation();
      if(e.type === "dragenter" || e.type === "dragover") {
         setDragActive(true);
      } else if(e.type === "dragleave") {
         setDragActive(false);
      }
   };

   const handleDrop = function(e) {
      if(isDemoMode) return
      e.preventDefault();
      e.stopPropagation();
      if(e.dataTransfer.files && e.dataTransfer.files[0]) {
         e.dataTransfer.effectAllowed = "all"
         e.dataTransfer.dropEffect = "move"
         fileUpload(e.dataTransfer.files)
      }
      setDragActive(false);
   }

   const onClickSort = (type, bool = true) => {
      if(isNeedToShowSortWarning && bool) {
         setSortWarning({ isOpen: true, type, optionName: type === 'reverse' ? 'Reverse' : 'Alphabetical order' })
         return
      }

      setLoadingButtonType(type)

      const photosArray = photos.map(item => item.id)

      onClickSortPhotos(type, photosArray, (state) => {
         if('succeed' === state){
            toast.success('Files have been sorted')
         } else {
            toast.error('Something went wrong')
         }
         setLoadingButtonType('')
         setSortWarning({})
      })
   }

   return (
      <div className={ cx({
         'relative max-lg:pb-5 flex flex-col gap-4': true,
         'max-tablet:pb-0': !isEditPage,
      }) }>
         <div
            className='flex gap-4 flex-col'
            ref={ headerElementRef }
         >
            <div
               className='w-full flex flex-col gap-4'
            >
               {
                  isEditPage && (
                     <div className='w-full flex flex-wrap gap-4'>
                        <SfwCheckbox
                           label='Default thumbnail is SFW'
                           name='thumbnail_is_sfw'
                           checked={ thumbnailIsSfw }
                           onChange={ (_, value) => {
                              if(value) {
                                 setIsOpenSfwWarningModal(true)
                              } else {
                                 onClickUpdate('thumbnail_is_sfw', value)
                              }
                           } }
                           overrideCheckboxLabelProps={ {
                              labelClassName: '!w-fit',
                           } }
                        />
                        <CheckboxLabel
                           label='Blur thumbnail when photoset access is locked'
                           name='locked_image_is_blur'
                           checked={ lockedImageIsBlur }
                           onChange={ onClickUpdate }
                           labelClassName='!w-fit'
                           padding='0'
                           checkboxSize='5'
                        />
                     </div>
                  )
               }
               <div className='flex items-center justify-between gap-4 flex-wrap'>
                  <p className='text-secondary text-sm leading-5 whitespace-nowrap'>
                     { filesCount } file{filesCount > 1 ? 's' : ''} added
                  </p>
                  <div className='flex gap-3 items-center flex-wrap'>
                     <Button
                        text={ 'Reverse' }
                        backgroundColor='panel'
                        iconName={ 'replace' }
                        iconPosition='left'
                        borderRadius='large'
                        onClick={ () => onClickSort('reverse') }
                        classNames='px-[18px] py-2 h-10 !w-fit border border-divider'
                        textMarginX='0'
                        textClassName={ 'ml-2' }
                        textSize='base'
                        fontIconColor={ 'secondary' }
                        textColor={ 'major' }
                        disabled={ isUploadingOrEmpty || (!!loadingButtonType && loadingButtonType !== 'reverse') || isDemoMode }
                        dataToolTipContent={ isDemoMode ? 'Not available in demo' : '' }
                        isLoading={ loadingButtonType === 'reverse' }
                        loaderColor='secondary'
                     />
                     <Button
                        text={ 'Alphabetical order' }
                        backgroundColor='panel'
                        iconName={ 'alphabetAZ' }
                        iconPosition='left'
                        borderRadius='large'
                        onClick={ () => onClickSort('alphabetical') }
                        classNames='px-[18px] py-2 h-10 !w-fit border border-divider'
                        textMarginX='0'
                        textClassName={ 'ml-2' }
                        textSize='base'
                        fontIconColor={ 'secondary' }
                        textColor={ 'major' }
                        disabled={ isUploadingOrEmpty || isDisableSortButton || (!!loadingButtonType && loadingButtonType !== 'alphabetical')  || isDemoMode }
                        dataToolTipContent={ isDemoMode ? 'Not available in demo' : isDisableSortButton ? 'Not available for this photoset' : '' }
                        isLoading={ loadingButtonType === 'alphabetical' }
                        loaderColor='secondary'
                     />
                  </div>
               </div>
            </div>
         </div>
         <div
            className={ cx({
               'flex flex-col gap-4': true,
               'lg:overflow-y-scroll ams-custom-scrool': isEditPage,
            }) }
            style={ {
               maxHeight: screenWidth > 1024 ? `calc(100vh - 56px - ${ headerElementRef?.current?.clientHeight + 60 }px)` : '',
            } }
         >
            <SortableContainer
               helperClass='sortable-helper'
               useDragHandle
               axis='xy'
               onSortEnd={ onSortEnd }
            >
               <div
                  id='photos-wrapper'
                  className={ cx({
                     'grid gap-5 w-full grid-cols-1 ': true,
                     'md:grid-cols-2 lg:grid-cols-4 xxl:grid-cols-5 tablet:pb-8': !isEditPage,
                     'sm:grid-cols-2 tablet:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4': isEditPage,
                     '!grid-cols-1': screenWidth < 1145 && screenWidth > 1024 && isEditPage,
                     '!grid-cols-2': screenWidth < 1450 && screenWidth > 1250 && isEditPage,
                  }) }
               >
                  <div
                     onDragEnter={ handleDrag } onDragLeave={ handleDrag } onDragOver={ handleDrag } onDrop={ handleDrop }
                     className={ cx({
                        'relative overflow-hidden rounded-lg h-auto': true,
                     }) }
                     data-tooltip-content='Not available in demo'
                     data-tooltip-id={ isDemoMode ? 'ams-top-tooltip' : null }
                  >
                     <div className='aspect-ratio-padding' />
                     <div
                        className={ cx({
                           'absolute p-1 top-0 right-0 h-full w-full bg-panel hover:bg-hover border border-divider rounded-lg': true,
                           '!border-error': !!error?.text,
                           '!bg-hover': !!dragActive,
                           'hover:!bg-disabled-12 !bg-disabled-12': isDemoMode,
                        }) }
                        onClick={ () => {
                           if(!!error?.text) {
                              onChangeErrors({ type: null, text: null })
                           }
                           if(isNeedToShowUploadWarning) {
                              setIsOpenUploadWarning(true)
                              return
                           }
                           inputFile.click()
                        } }
                        role='presentation'
                     >
                        <div className={ cx({
                           'relative items-center justify-center cursor-pointer flex flex-col w-full h-full select-none': true,
                           '!cursor-default !text-disabled': isDemoMode,

                        }) }>
                           <div className={ cx({
                              'absolute w-full h-full rounded-lg border border-dashed border-placeholder': true,
                              '!border-error': !!error?.text,
                           }) } />
                           <div className='flex flex-col justify-center items-center gap-2 z-10 px-2'>
                              <input
                                 type='file'
                                 hidden
                                 multiple
                                 value={ inputValue }
                                 ref={ (input) => { inputFile = input; } }
                                 onChange={ (e) => {
                                    fileUpload(e.target.files);
                                    setInputValue('')
                                 } }
                                 accept='image/png, image/jpeg, image/jpg, image,  application/zip, image/gif'
                                 disabled={ isDemoMode }
                              />
                              <IconLabel
                                 color={ isDemoMode ? 'disabled' : 'secondary' }
                                 name={ error?.type === 'gif' ? 'gif' : 'cloud-upload-new' }
                                 size='2xl'
                                 className='h-10 w-10'
                              />
                              {
                                 !!error?.text ?
                                    <span className='text-sm text-error font-medium text-center'>{error?.text}</span>
                                    :
                                    <span className={ cx({
                                       'text-sm font-medium text-action text-center': true,
                                       '!text-disabled': isDemoMode,
                                    }) }>Click to upload<span className={
                                          cx({
                                             'text-sm font-medium text-secondary': true,
                                             '!text-disabled': isDemoMode,
                                          }) }> {screenWidth > 700 && 'or drag and drop'} multiple images, ZIPs, or GIFs</span></span>
                              }
                           </div>
                        </div>
                     </div>
                  </div>
                  {
                     !!zipFiles?.length && zipFiles.map((it, index) => {
                        if(it.status === 'completed') return null

                        if(it.status === 'error'){
                           return (
                              <div
                                 className={ cx({
                                    'relative overflow-hidden rounded-lg h-auto border border-error': true,
                                 }) }
                              >
                                 <div
                                    onClick={ (e) => e.stopPropagation() }
                                    role='presentation'
                                    className='absolute top-2 right-2 flex items-center z-20'
                                 >
                                    <Button
                                       backgroundColor='transparent'
                                       iconName='cancel'
                                       fontIconColor='secondary'
                                       padding='none'
                                       onClick={ () => removePhoto({ index, id: it.id,  type: 'zip' })  }
                                       dataToolTipContent='Delete'
                                    />
                                 </div>
                                 <div className='aspect-ratio-padding' />
                                 <div
                                    className='absolute p-1 top-0 right-0 h-full w-full bg-panel group hover:bg-hover'
                                    onClick={ (e) => {
                                       e.stopPropagation()
                                       uploadZipAgain({ ...it, index })
                                    } }
                                    role='presentation'
                                 >
                                    <div className='relative items-center justify-center cursor-pointer flex flex-col w-full h-full'>
                                       <div className='absolute w-full h-full rounded-lg border border-dashed border-error' />
                                       <div className='flex flex-col justify-center items-center gap-2 z-10 px-2'>
                                          <IconLabel
                                             color='secondary'
                                             name='warning'
                                             size='2xl'
                                             className='h-10 w-10'
                                          />
                                          <span className={ `text-sm font-medium text-error` }>Error</span>
                                          <span className={ `text-sm font-medium text-secondary` }>Unzipping error, click on button to retry</span>
                                          <span className='text-sm font-medium text-action text-center group-hover:underline mt-2'>Try again </span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           )
                        }

                        return (
                           <AddNewPhotosetCard
                              className='z-10'
                              photo={ { status: it.status === 'uploading' ? 'uploading-zip' : it.status } }
                              removePhoto={ () => removePhoto({ index, id: it.id,  type: 'zip' }) }
                           />
                        )
                     })
                  }
                  {
                     photos.map((photo, index) => {
                        if(isEditPage && !!loadMore && index > 8) return null
                        let thumbnail = thumbnailForView.find(item => item?.thumbId === photo?.id)

                        return (
                           <SortableItem
                              onSortEnd={ onSortEnd }
                              useDragHandle
                              shouldUseDragHandle={ true }
                              key={ `item-${ index }` }
                              index={ index }
                              photo={ photo }
                              thumbnail={ thumbnail }
                              removePhoto={ () => {
                                 if(isNeedToShowUploadWarning) {
                                    setIsOpenUploadWarning(true)
                                    deleteableItemRef.current = photo
                                    return
                                 }
                                 removePhoto(photo)
                              } }
                              makeCover={ () => makeCover(photo) }
                           />
                        );
                     })
                  }
                  {
                     loadMore &&
                     <div
                        className={ cx({
                           'h-full w-full flex items-center justify-center sm:!col-span-2 tablet:!col-span-1 max-tablet:!col-span-1': true,
                           '!col-span-1': (screenWidth >= 1259 && screenWidth <= 1594) || screenWidth > 1919,
                           '!col-span-3': (screenWidth >= 900 && screenWidth < 1024) || (screenWidth > 1449 && screenWidth <= 1919),
                        }) }>
                        <Button
                           text={ 'Show all' }
                           backgroundColor='transparent'
                           borderRadius='large'
                           onClick={ () => setLoadMore(false) }
                           classNames='px-3.5 h-9 !w-fit mx-auto'
                           textMarginX='0'
                           textClassName={ 'text-secondary' }
                           textSize='small'
                           className=''
                        />
                     </div>
                  }
               </div>
            </SortableContainer>
         </div>
         {
            isOpenUploadWarning &&
            <WarningModal
               onClickAction={ (action, value) => {
                  if(action === 'action'){
                     if(!!deleteableItemRef.current) {
                        let photo = deleteableItemRef.current
                        setTimeout(() => {
                           removePhoto(photo)
                        }, 0)
                     } else {
                        inputFile.click()
                     }
                     if(value) {
                        localStorage.setItem('dont_show_add_or_delete_photos_warning', 1)
                     }
                  }
                  setIsOpenUploadWarning(false)
                  deleteableItemRef.current = null
               } }
               isMobile={ screenWidth < 701 }
            />
         }
         {
            sortWarning?.isOpen &&
            <WarningModal
               onClickAction={ (action, value) => {
                  if(action === 'action') {
                     if(value){
                        localStorage.setItem('dont_show_photos_sort_warning', 1)
                     }
                     onClickSort(sortWarning?.type, false)
                  } else {
                     setSortWarning({})
                  }
               } }
               isMobile={ screenWidth < 701 }
               type='photos_sort'
               photosSortOption={ sortWarning?.optionName }
               isLoading={ !!loadingButtonType }
            />
         }
         {
            isOpenSfwWarningModal && (
               <SfwWarningModal
                  onCloseModal={ () => {
                     setIsOpenSfwWarningModal(false)
                  } }
                  onConfirm={ () => {
                     setIsOpenSfwWarningModal(false)
                     onClickUpdate('thumbnail_is_sfw', true)
                  } }
               />
            )
         }
      </div>
   );
};

AddNewPhotoset.propTypes = {
   photos: PropTypes.array,
   fileUpload: PropTypes.func,
   removePhoto: PropTypes.func,
   makeCover: PropTypes.func,
   onSortPhotosEnd: PropTypes.func,
   lockedImageIsBlur: PropTypes.bool,
   onClickUpdate: PropTypes.func,
   screenWidth: PropTypes.number,
   isEditPage: PropTypes.bool,
   zipFiles: PropTypes.array,
   uploadZipAgain: PropTypes.func,
   isUnlockedBefore: PropTypes.bool,
   error: PropTypes.object,
   onChangeErrors: PropTypes.func,
   thumbnails: PropTypes.array,
   isDemoMode: PropTypes.bool,
   thumbnailIsSfw: PropTypes.bool,
   onClickSortPhotos: PropTypes.func,
   isDisableSortButton: PropTypes.bool,
};

export default React.memo(AddNewPhotoset);
