import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';

let reducersMap = {
   [types.SELECT_CARD]: (state, action) => {
      const { payload: { type, value } } = action;
      let receivers = {
         ...state.sendData.receivers,
         [type]: {
            ...state.sendData.receivers[type],
            send: value,
         },
      };
      const sendData = {
         ...state.sendData,
         receivers: {
            ...receivers,
         },
      }
      return {
         ...state,
         sendData: sendData,
      }
   },

   [types.SELECT_MEMBERSHIP]: (state, action) => {
      const { payload: { type, value, isAdd } } = action;
      let receivers = {
         ...state.sendData.receivers,
      }
      if(isAdd) {
         let receiversTypeData = {
            ...receivers[type],
         }
         let memberships = [
            ...receivers[type].memberships,
            value.id,
         ];
         if(memberships.length) {
            receiversTypeData = {
               ...receiversTypeData,
               send: true,
               memberships: memberships,
            }
         } else {
            receiversTypeData = {
               ...receiversTypeData,
               memberships: memberships,
            }
         }
         receivers = {
            ...receivers,
            [type]: {
               ...receiversTypeData,
            },
         }
      } else {
         receivers = {
            ...receivers,
            [type]: {
               ...receivers[type],
               memberships: receivers[type].memberships.filter(item => item !== value.id),
            },
         }
      }
      let sendData = {
         ...state.sendData,
         receivers: receivers,
      }


      return {
         ...state,
         sendData: sendData,
      }
   },

   [types.SET_ACTIVE_MEMBERSHIPS]: (state, action) => {
      const { payload: { data } } = action;
      return {
         ...state,
         activeMemberships: data,
      }
   },

   [types.SET_SEND_DATA]: (state, action) => {
      const { payload: { data, type } } = action;
      const sendData = {
         ...state.sendData,
         [type]: {
            ...state.sendData[type],
            ...data,
         },
      }
      return {
         ...state,
         sendData: sendData,
      }
   },



   [types.SEND_MESSAGE_START]: (state) => {
      return {
         ...state,
         isFetchingSend: true,
      }
   },
   [types.SEND_MESSAGE_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingSend: false,
      }
   },
   [types.SEND_MESSAGE_FAILED]: (state) => {
      return {
         ...state,
         isFetchingSend: false,
      }
   },


   [types.GET_RECEIVERS_STATISTICS_START]: (state) => {
      return {
         ...state,
         isFetchingStatistics: true,
      }
   },
   [types.GET_RECEIVERS_STATISTICS_COMPLETED]: (state, action) => {
      const { payload: { receivers_count } } = action;
      return {
         ...state,
         receiversStatistics: receivers_count,
         isFetchingStatistics: false,
         activeTab: 'access',
      }
   },
   [types.GET_RECEIVERS_STATISTICS_FAILED]: (state) => {
      return {
         ...state,
         isFetchingStatistics: false,
      }
   },


   [types.GET_ACTIVE_MEMBERSHIPS_START]: (state) => {
      return {
         ...state,
         isFetchingActiveMemberships: true,
      }
   },
   [types.GET_ACTIVE_MEMBERSHIPS_COMPLETED]: (state, action) => {
      const { payload: { data, statistics } } = action;
      return {
         ...state,
         activeMemberships: data,
         isFetchingActiveMemberships: false,
         receiversStatistics: statistics,
      }
   },
   [types.GET_ACTIVE_MEMBERSHIPS_FAILED]: (state) => {
      return {
         ...state,
         isFetchingActiveMemberships: false,
      }
   },


   [types.SET_ACTIVE_TAB]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         activeTab: payload,
      }
   },

   [types.GET_INITIAL_DATA]: (state) => {
      const sendData = {
         // receivers: {
         //    active_members: {
         //       send: false,
         //       memberships: [],
         //    },
         //    cancelled_members: {
         //       send: false,
         //    },
         //    expired_members: {
         //       send: false,
         //    },
         //    free_members: {
         //       send: false,
         //    },
         //    guest_members: {
         //       send: false,
         //    },
         //    mailing_list: {
         //       send: false,
         //    },
         // },
         message: {
            subject: "",
            body: "",
            bcc: true,
            media: {},
         },
      };

      return {
         ...state,
         sendData: sendData,
         activeTab: 'compose',
      }
   },


   //               mailing list

   // members
   [types.GET_MAILING_LIST_MEMBERS_START]: (state) => {
      return {
         ...state,
         isFetchingMembersList: true,
      }
   },
   [types.GET_MAILING_LIST_MEMBERS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         membersMailingList: payload,
         isEmptyMembersList: payload.data.length === 0,
         isFetchingMembersList: false,
      }
   },
   [types.GET_MAILING_LIST_MEMBERS_FAILED]: (state) => {
      return {
         ...state,
         isFetchingMembersList: false,
      }
   },

   [types.GET_NEW_MAILING_LIST_MEMBERS_START]: (state) => {
      return {
         ...state,
         isNewFetchingMembersList: true,
      }
   },
   [types.GET_NEW_MAILING_LIST_MEMBERS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         membersMailingList: {
            ...payload,
            data: [...state.membersMailingList.data, ...payload.data],
         },
         isNewFetchingMembersList: false,
      }
   },
   [types.GET_NEW_MAILING_LIST_MEMBERS_FAILED]: (state) => {
      return {
         ...state,
         isNewFetchingMembersList: false,
      }
   },


   [types.GET_FILTER_MEMBERS_MAILING_LIST_START]: (state) => {
      return {
         ...state,
         isFetchingByFilterMembersList: true,
      }
   },
   [types.GET_FILTER_MEMBERS_MAILING_LIST_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         membersMailingList: payload,
         isEmptyByFilterMembersList: payload.data.length === 0,
         isFetchingByFilterMembersList: false,
      }
   },
   [types.GET_FILTER_MEMBERS_MAILING_LIST_FAILED]: (state) => {
      return {
         ...state,
         isFetchingByFilterMembersList: false,
      }
   },

   // non-members
   [types.GET_MAILING_LIST_NON_MEMBERS_START]: (state) => {
      return {
         ...state,
         isFetchingNonMembersList: true,
      }
   },
   [types.GET_MAILING_LIST_NON_MEMBERS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         nonMembersMailingList: payload,
         isEmptyNonMembersList: payload.data.length === 0,
         isFetchingNonMembersList: false,
      }
   },
   [types.GET_NEW_MAILING_LIST_NON_MEMBERS_FAILED]: (state) => {
      return {
         ...state,
         isFetchingNonMembersList: false,
      }
   },

   [types.GET_NEW_MAILING_LIST_NON_MEMBERS_START]: (state) => {
      return {
         ...state,
         isNewFetchingNonMembersList: true,
      }
   },
   [types.GET_NEW_MAILING_LIST_NON_MEMBERS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         nonMembersMailingList: {
            ...payload,
            data: [...state.nonMembersMailingList.data, ...payload.data],
         },
         isNewFetchingNonMembersList: false,
      }
   },
   [types.GET_MAILING_LIST_NON_MEMBERS_FAILED]: (state) => {
      return {
         ...state,
         isNewFetchingNonMembersList: false,
      }
   },

   [types.GET_FILTER_NON_MEMBERS_MAILING_LIST_START]: (state) => {
      return {
         ...state,
         isFetchingByFilterNonMembersList: true,
      }
   },
   [types.GET_FILTER_NON_MEMBERS_MAILING_LIST_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         nonMembersMailingList: payload,
         isEmptyByFilterNonMembersList: payload.data.length === 0,
         isFetchingByFilterNonMembersList: false,
      }
   },
   [types.GET_FILTER_NON_MEMBERS_MAILING_LIST_FAILED]: (state) => {
      return {
         ...state,
         isFetchingByFilterNonMembersList: false,
      }
   },
   [types.TAGS_TO_BE_SENT]: (state, action) => {
      const { payload } = action

      return {
         ...state,
         tagsToBeSent: payload,
      }
   },

   [types.INIT_SENT_MESSAGES_START]: (state) => {
      return {
         ...state,
         isFetchingSentMessages: true,
      }
   },

   [types.INIT_SENT_MESSAGES_COMPLETED]: (state, action) => {
      const { data, filters } = action.payload
      let newData = data.data || []

      if((Object.keys(filters)?.length < 2)) {
         return {
            ...state,
            isFetchingSentMessages: false,
            messagesData: {
               meta: data?.meta,
               links: data?.links,
               data: [...newData],
            },
            isEmpty: newData.length === 0,
            isEmptyByMessagesFilter: newData.length === 0,
            itemForUpdate: null,
         }
      }
      else {
         return {
            ...state,
            isFetchingSentMessages: false,
            messagesData: {
               meta: data?.meta,
               links: data?.links,
               data: [...newData],
            },
            isEmpty: (Object.keys(filters)?.length <= 1) && newData.length === 0,
            isEmptyByMessagesFilter: newData?.length === 0,
            itemForUpdate: null,
         }
      }
   },

   [types.INIT_SENT_MESSAGES_FAILED]: (state) => {
      return {
         ...state,
         isFetchingSentMessages: false,
      }
   },

   // mass message filters

   [types.FETCH_MESSAGES_BY_FILTER_START]: (state) => {
      return {
         ...state,
         isFetchingByFilter: true,
         isEmptyByMessagesFilter: false,
         isEmpty: false,
      }
   },

   [types.FETCH_MESSAGES_BY_FILTER_COMPLETED]: (state, action) => {
      const { data, filters } = action.payload

      if((Object.keys(filters)?.length < 2)) {
         return {
            ...state,
            isFetchingByFilter: false,
            messagesData: {
               ...data,
               data: [
                  ...data.data,
               ],
            },
            // isEmpty: Object.keys(filters)?.length > 2  && data?.data?.length === 0,
            isEmptyByMessagesFilter: data?.data?.length === 0,
            messagesFilters: filters,
         }
      }


      return {
         ...state,
         isFetchingByFilter: false,
         messagesData: {
            ...data,
            data: [
               ...data.data,
            ],
         },
         // isEmpty: data?.data?.length === 0,
         isEmptyByMessagesFilter: data?.data?.length === 0,
         messagesFilters: filters,
      }
   },

   [types.FETCH_MESSAGES_BY_FILTER_FAILED]: (state) => {
      return {
         ...state,
         isFetchingByFilter: false,
      }
   },

   [types.UPDATE_RECIPIENT_USERNAME]: (state, action) => {
      const { messageId, memberId, username } = action.payload;
      return {
         ...state,
         messagesData: state?.messagesData?.map(message => {
            if(message.id === messageId) {
               message.recipients = message.recipients.map(member => {
                  if(member.id === memberId){
                     member.user_name = username
                  }
                  return member
               })
            }
            return message
         }),
      }
   },

   [types.DELETE_PRIVATE_MESSAGE]: (state, action) => {
      const { messageId } = action.payload;

      return {
         ...state,
         messagesData: {
            ...state?.messagesData,
            data: state?.messagesData?.data.filter(it => it.id !== messageId),
         },
         isEmpty: state?.messagesData?.data.filter(it => it.id !== messageId).length === 0,
         isFetchingSentMessages: false,
      }
   },

   [types.FETCH_MESSAGES_NEXT_PAGE_START]: (state) => {

      return {
         ...state,
         isFetchingMessagesNextPage: true,
      }
   },

   [types.FETCH_MESSAGES_NEXT_PAGE_COMPLETED]: (state, action) => {
      const { data, filters } = action.payload

      return {
         ...state,
         messagesData: {
            ...data,
            data: [
               ...state.messagesData.data,
               ...data.data,
            ],
         },
         messagesFilters: filters,
         isFetchingMessagesNextPage: false,
         isFetchingSentMessages: false,

      }
   },

   [types.FETCH_MESSAGES_NEXT_PAGE_FAILED]: (state) => {

      return {
         ...state,
         isFetchingMessagesNextPage: false,
      }
   },

   [types.CLEAR_MESSAGES_DATA]: (state) => {

      return {
         ...state,
         messagesData: {
            data: [],
         },
      }
   },

   [types.UNSEDN_MESSAGE_ACTION]: (state, action) => {

      return {
         ...state,
         isFetchingUnsendMessage: action.payload,
      }
   },
   [types.UNSEDN_MESSAGE_COMPLETED]: (state, action) => {
      const { id } = action.payload;
      const oldMAssMessaegData = [...state?.messagesData?.data];
      const newMassMessageData = [...oldMAssMessaegData].map((item => {
         let { ...elm } = item;
         if(elm?.id === id){
            elm = {
               ...elm,
               ...action.payload,
            }
         }
         return elm
      }))
      return {
         ...state,
         messagesData: {
            ...state?.messagesData,
            data: newMassMessageData,
         },
         isFetchingUnsendMessage: false,
      }
   },
   [types.UPDATE_MASS_MESSAGE_ACTION]: (state, action) => {
      const { id } = action.payload;
      const oldMAssMessaegData = [...state?.messagesData?.data];
      const newMassMessageData = [...oldMAssMessaegData].map((item => {
         let { ...elm } = item;
         if(elm?.id === id){
            elm = {
               ...elm,
               ...action.payload,
            }
         }
         return elm
      }))
      console.log({ oldMAssMessaegData });
      return {
         ...state,
         messagesData: {
            ...state?.messagesData,
            data: newMassMessageData,
         },
         // updateData: action.payload,
      }
   },
};

export default createReducer(initialState)(reducersMap);
