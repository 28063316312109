import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'common/components/elements/icons';
import cx from 'classnames';

const ChatToolsItem = ({
   iconName,
   actionText,
   onActionClick,
   color,
   iconColor,
   disabled,
   tooltipContent,
}) => {
   return (
      <div
         data-tooltip-content={ tooltipContent }
         data-tooltip-id='ams-top-tooltip'
         onClick={ disabled ? null : e => onActionClick(e) }
         role='presentation'
         className={ cx(
            {
               'flex py-2.5 px-4 w-full items-center relative': true,
               'cursor-pointer hover:bg-hover dark:hover:bg-hover-dark': !disabled,
            }
         ) }
      >
         <div className=' flex items-center justify-center mr-3'>
            <Icon name={ iconName } size='lg' color={ disabled ? 'disabled' : iconColor } darkColor={ disabled ? 'disabled-dark' : `${ iconColor }-dark` } />
         </div>
         <span
            className={ cx(
               {
                  'text-base leading-5 font-normal select-none': true,
                  [`text-${ color }  dark:text-${ color }-dark`]: !disabled,
                  'text-disabled dark:text-disabled-dark': disabled,
               }
            ) }
         >{actionText}</span>
      </div>
   )
}

ChatToolsItem.propTypes = {
   iconName: PropTypes.string,
   actionText: PropTypes.string,
   onActionClick: PropTypes.func,
   color: PropTypes.string,
   iconColor: PropTypes.string,
   tooltipContent: PropTypes.string,
   disabled: PropTypes.bool,
}

ChatToolsItem.defaultProps = {
   iconName: 'reply',
   actionText: 'Reply',
   onActionClick: () => {},
   color: 'major',
   iconColor: 'secondary',
}

export default ChatToolsItem

