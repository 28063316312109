import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';

let reducersMap = {
   [types.SET_IS_FETCHING_INIT_INFO]: (state, action) => {

      return {
         ...state,
         isFetchingPollInfo: action.payload,
      }
   },
   [types.FETCH_CREATE_POLL]: (state, action) => {
      return {
         ...state,
         isFetchingCreate: action.payload,
      }
   },
   [types.FETCH_CREATE_EDIT_POLL_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingCreate: false,
         isFetchingUpdate: false,
         createEditErrors: action.payload,
      }
   },
   [types.RESET_ERROR_FIELDS]: (state, action) => {
      const { field, isAll } = action.payload

      let newErrors = {}

      if(!isAll && Boolean(field)) {
         newErrors = { ...state.createEditErrors }

         delete newErrors[field]
      }

      return {
         ...state,
         createEditErrors: newErrors,
      }

   },
   [types.GET_POLLS_START]: (state, action) => {
      const { isFetchingByFilter, isFetchingNextPage } = action.payload

      let newState = {
         isFetchingList: !isFetchingByFilter && !isFetchingNextPage,
         isFetchingByFilter: isFetchingByFilter,
         isFetchingNextPage: isFetchingNextPage,
      }

      return {
         ...state,
         ...newState,
      }
   },
   [types.GET_POLLS_COMPLETED]: (state, action) => {
      const { data, isFetchingNextPage } = action.payload
      let newState = {
         isFetchingList: false,
         isFetchingByFilter: false,
         isFetchingNextPage: false,
      }

      if(isFetchingNextPage) {
         newState.listData = {
            ...data,
            data: [...state.listData.data, ...data.data],
         }
      } else {
         newState = {
            ...newState,
            listData: { ...data },
         }
      }

      return {
         ...state,
         ...newState,
      }
   },
   [types.SET_IS_FETCHING_ACTION_WITH_POLL]: (state, action) => {
      const { isFetching, isSecondaryButtonAction } = action.payload

      return {
         ...state,
         isFetchingActionWithPoll: isFetching && !isSecondaryButtonAction,
         isFetchingSecondButtonAction: isSecondaryButtonAction,
      }
   },
   [types.DELETE_POLL_COMPLETED]: (state, action) => {
      const pollId = action.payload
      return {
         ...state,
         isFetchingActionWithPoll: false,
         isFetchingSecondButtonAction: false,
         listData: {
            ...state.listData,
            data: state.listData.data.filter(item => item.id !== pollId),
         },
      }
   },
   [types.SET_IS_FETCHING_POLL_DETAILS]: (state, action) => {
      return {
         ...state,
         isFetchingPollDetails: action.payload,
      }
   },
   [types.SET_OVERLAPPING_POLL_MODAL_ACTION]: (state, action) => {
      const { isOpen, errorResponseData } = action.payload

      return {
         ...state,
         overlappingModal: {
            isOpen,
            data: errorResponseData,
         },
      }
   },
   [types.CHANGE_POLL_STATUS_COMPLETED]: (state, action) => {
      const { id, data } = action.payload;

      return {
         ...state,
         isFetchingActionWithPoll: false,
         isFetchingSecondButtonAction: false,
         listData: {
            ...state.listData,
            data: state.listData.data.map(item => {
               if(id === item.id) {
                  return data
               }
               return item
            }),
         },
      }
   },
   [types.UPDATE_VOTERS_COUNT]: (state, action) => {

      return {
         ...state,
         createEditPollVotersCount: action.payload,
      }
   },
   [types.FETCH_UPDATE_POLL]: (state, action) => {

      return {
         ...state,
         isFetchingUpdate: action.payload,
      }
   },
}

export default createReducer(initialState)(reducersMap);
