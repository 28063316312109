import React, { useEffect, createRef, useState } from 'react';
import PropTypes from 'prop-types'
import Modal from 'common/components/modules/modal'
import './style.scss'
import Button from 'common/components/elements/buttons/primary';
import Icon from 'common/components/elements/icons';
import cx from 'classnames'

const SelectFromModal = ({
   children,
   closeModal,
   onClickAdd,
   isMobile,
   type,
   disabled,
   showNewStyles,
   count,
   disabledButton,
   childClassName,
   contentType,
   isThereTabSection,
   onChangeTab,
   isMultipleAttachments,
   shownTabs,
   modalTitile = '',
}) => {

   const mainRef = createRef()
   const { current } = mainRef
   const [showChildren, setShowChildren] = useState(false)

   useEffect(() => {
      if(mainRef && mainRef.current && !showChildren) {
         setShowChildren(true)
      }
   }, [mainRef, current, showChildren])

   let headerText = !showNewStyles ? 'Add from Vault' : 'Select from Vault';
   let iconName = 'photosets'

   if('photoset' === type) {
      headerText = !showNewStyles ?  'Select from published photosets' : 'Select from Photosets'
   } else  if('video' === type) {
      headerText = !showNewStyles ? 'Select from published videos' : 'Select from Videos'
      iconName = 'videos'
   } else  if('recorded_stream' === type) {
      headerText = 'Select from Recorded Streams'
      iconName = 'videos'
   } else  if('photo_vault' === type) {
      headerText = 'Select from image Vault'
      iconName = 'vault'
   } else  if('video_vault' === type) {
      headerText = 'Select from video Vault'
      iconName = 'vault'
   } else  if('vault' === type) {
      iconName = 'vault'
   }

   if(isThereTabSection) {
      headerText = 'Select media'
      iconName = 'bundle'
   }

   if(isMultipleAttachments) {
      headerText = 'Select media'
      iconName = 'attach-media'
   }

   if(!!modalTitile){
      headerText = modalTitile
   }

   const tabs = [
      {
         title: 'Photosets',
         type: 'photoset',
      },
      {
         title: 'Videos',
         type: 'video',
      },
      {
         title: 'Vault images',
         type: 'photo_vault',
      },
      {
         title: 'Vault videos',
         type: 'video_vault',
      },
      {
         title: 'Store',
         type: 'store',
      },
      {
         title: 'Recorded streams',
         type: 'recorded_stream',
      },
   ]

   const tabsBar = document?.getElementById('media-tabs')

   const [tabsEL, setTabsEL] = useState({
      offsetWidth: tabsBar?.offsetWidth,
      scrollLeft: tabsBar?.scrollLeft,
      scrollWidth: tabsBar?.scrollWidth,
   })

   const [initialScrollDiff, setInitialScrollDiff] = useState(tabsBar?.scrollWidth - tabsBar?.offsetWidth)

   useEffect(() => {
      const tabsBarAfter = document?.getElementById('media-tabs')
      setInitialScrollDiff(tabsBarAfter?.scrollWidth -  tabsBarAfter?.offsetWidth)

   }, [tabsEL?.scrollWidth, tabsEL, initialScrollDiff])

   return (
      <Modal
         contentPosition={ !isMobile ? 'center' : 'bottom' }
         className='select-from-modal h-full overflow-hidden'
         onClose={ closeModal }
         otherClassName={ 'w-full h-full' }
         closeOnClickOutside={ showNewStyles ? false : true }
      >
         <div className='flex flex-col h-full bg-snow'>
            {
               !showNewStyles ?
                  <header className='flex py-3 px-4 shadow-modal items-center'>
                     <div className='h-6 w-6 flex items-center justify-center mr-4'>
                        <Button
                           classNames='h-full'
                           padding='none'
                           iconName='close'
                           backgroundColor='transparent'
                           fontIconColor='grey-yoda'
                           onClick={ closeModal }
                        />
                     </div>
                     <span className='text-major font-bold leading-tight'> {headerText} </span>
                  </header>
                  :
                  <div className='flex flex-col'>
                     <div className='h-6 w-6 flex items-center justify-center m-4'>
                        <Button
                           classNames='h-full'
                           padding='none'
                           iconName='close'
                           backgroundColor='transparent'
                           fontIconColor='grey-yoda'
                           onClick={ closeModal }

                        />
                     </div>
                     <div className='flex flex-col gap-3 justify-center items-center '>
                        <div className='!w-10 !h-10 rounded-full flex items-center justify-center bg-action-12'>
                           <Icon
                              name={ iconName }
                              className='!text-[25px]'
                              style={ {
                                 color: window.skinPrimaryColor,
                              } }
                           />
                        </div>
                        <p className='text-xl font-semibold select-none text-major'>{headerText}</p>
                     </div>
                     {
                        isThereTabSection &&
                        <div
                           className='w-full pt-6 px-6 relative flex ms:justify-center'
                        >
                           {
                              (typeof tabsEL?.scrollLeft !== 'undefined' && tabsEL?.scrollLeft > 0) &&
                              <div
                                 className='w-[100px] h-13 absolute [@media(max-width:701px)]: items-center justify-start left-0 ms:hidden pt-[3px] z-[30]'
                                 style={ {
                                    background: `linear-gradient(90deg, #FFFFFF 49.98%, rgba(255, 255, 255, 0) 100%)`,
                                 } }
                              >
                                 <Button
                                    text=''
                                    iconName='arrow-left'
                                    classNames='!w-fit'
                                    backgroundColor='transparent'
                                    fontIconColor='secondary'
                                    fontIconSize='doubleLarge'
                                    onClick={ () => {
                                       tabsBar.scroll({
                                          left: tabsBar?.scrollLeft - 120,
                                          behavior: 'smooth',
                                       })
                                    } }
                                 />
                              </div>
                           }
                           <div
                              id='media-tabs'
                              className='border border-divider rounded-lg bg-grey-main p-1.5 flex gap-2 tabs-custom-scrollbar [@media(max-width:701px)]:overflow-x-scroll ms:overflow-x-visible'
                              onScroll={ e => {
                                 setTabsEL({
                                    offsetWidth: e.target?.offsetWidth,
                                    scrollLeft: e.target?.scrollLeft,
                                    scrollWidth: e.target?.scrollWidth,
                                 })
                              } }
                           >
                              {
                                 tabs?.map(tab => {
                                    if(!shownTabs.includes(tab?.type)) return null
                                    return (
                                       <div
                                          key={ tab.type }
                                          role='presentation'
                                          className={ cx({
                                             'flex flex-1 justify-center border border-transparent items-center py-2.5 text-sm text-secondary leading-4 cursor-pointer select-none min-w-39': true,
                                             'bg-panel rounded-lg !text-action border !border-divider': type === tab.type,
                                             // 'flex py-[13px] px-6 h-11 text-sm text-secondary leading-[18px] whitespace-nowrap hover:cursor-pointer select-none': true,
                                             // '': type !== tab.type,
                                             // 'bg-panel !text-action border-b-2  !border-action': type === tab.type,
                                          }) }
                                          onClick={ () => {
                                             if(type === tab.type) return
                                             onChangeTab(tab.type)
                                          } }
                                       >
                                          {tab.title}
                                       </div>
                                    )
                                 })
                              }
                           </div>
                           {
                              // (tabsEL?.scrollWidth - tabsEL?.scrollLeft) !== tabsEL?.offsetWidth &&
                              (typeof tabsEL?.scrollLeft === 'undefined' || (tabsEL?.scrollLeft < initialScrollDiff)) &&
                              <div
                                 className='w-[100px] h-13 absolute right-0 [@media(max-width:701px)]:flex items-center justify-end ms:hidden pt-[3px] z-[30]'
                                 style={ {
                                    background: `linear-gradient(270deg, #FFFFFF 49.98%, rgba(255, 255, 255, 0) 100%)`,
                                 } }
                              >
                                 <Button
                                    text=''
                                    iconName='arrow-right'
                                    classNames='!w-fit'
                                    backgroundColor='transparent'
                                    fontIconColor='secondary'
                                    fontIconSize='doubleLarge'
                                    onClick={ () => {
                                       // alert(`scrollWidth:${ tabsEL?.scrollWidth },scrollLeft:${ tabsEL?.scrollLeft },offsetWidth:${ tabsEL?.offsetWidth }`)
                                       tabsBar.scroll({
                                          left: tabsBar?.scrollLeft + 120,
                                          behavior: 'smooth',
                                       })
                                    } }
                                 />
                              </div>
                           }
                        </div>
                     }
                     {
                        (['store', 'chat', 'related_content'].includes(contentType) || isThereTabSection) &&
                        <div
                           id={ `${ type === 'vaults' ? 'vault' : type }s-filter-content` }
                           className={ cx({
                              'py-6 max-lg:pb-0': true,
                              'pb-0': isThereTabSection && type !== 'recorded_stream',
                              'lg:px-6 px-4': type === 'store',
                           }) }
                        ></div>
                     }
                  </div>
            }

            <main
               ref={ mainRef }
               id={ `select-from-${ type === 'vaults' ? 'vault' : type }s` }
               className={ `overflow-y-auto ams-custom-scrool ${ childClassName }` }>
               {
                  children  && showChildren && (
                     children
                  )
               }
            </main>
            {
               (!disabled || showNewStyles) && (
                  <footer className={ cx({
                     'flex items-center justify-center z-10': true,
                     '!h-[86px]': showNewStyles,
                  }) }>
                     <div className={ cx({
                        'add-selection-button': true,
                        'inline-block h-12': !showNewStyles,
                        ' py-[21px] ': showNewStyles,
                     }) }>
                        <Button
                           // text={ `Add selection${ count > 1 ? 's' : '' }` }
                           text={ `Add selected` }
                           classNames={ cx({
                              'h-full px-3': true,
                              '!w-[fit-content] mx-auto !py-2 !px-[18px] !rounded-lg !h-11': showNewStyles,
                           }) }
                           textMarginX='0'
                           padding='none'
                           primaryColor
                           borderRadius='large'
                           onClick={ onClickAdd }
                           fontWeight='medium'
                           disabled={ disabledButton }
                           textSize='base'
                        />
                     </div>
                  </footer>

               )
            }
         </div>
      </Modal>
   )
}

SelectFromModal.propTypes = {
   children: PropTypes.any,
   onClickAdd: PropTypes.func,
   closeModal: PropTypes.func,
   isMobile: PropTypes.bool,
   disabled: PropTypes.bool,
   type: PropTypes.string,
   showNewStyles: PropTypes.bool,
   count: PropTypes.number,
   disabledButton: PropTypes.bool,
   childClassName: PropTypes.string,
   contentType: PropTypes.string,
   isThereTabSection: PropTypes.bool,
   onChangeTab: PropTypes.func,
   isMultipleAttachments: PropTypes.bool,
   shownTabs: PropTypes.array,
   modalTitile: PropTypes.string,
}

SelectFromModal.defaultProps = {
   showNewStyles: false,
   count: 0,
   disabledButton: false,
   childClassName: '',
   contentType: '',
   isThereTabSection: false,
   shownTabs: ['photoset', 'video', 'photo_vault', 'video_vault'],
}

export default SelectFromModal;
