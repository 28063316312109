export const DELETE_START = "vault/DELETE_START";
export const DELETE_COMPLETED = "vault/DELETE_COMPLETED";
export const DELETE_FAILED = "vault/DELETE_FAILED";

// images
export const FETCH_IMAGES_REQUEST_START = "vault/FETCH_IMAGES_REQUEST_START";
export const FETCH_IMAGES_REQUEST_COMPLETED = "vault/FETCH_IMAGES_REQUEST_COMPLETED";
export const FETCH_IMAGES_REQUEST_FAILED = "vault/FETCH_IMAGES_REQUEST_FAILED";

export const FETCH_IMAGES_BY_FILTER_START = "vault/FETCH_IMAGES_BY_FILTER_START";
export const FETCH_IMAGES_BY_FILTER_COMPLETED = "vault/FETCH_IMAGES_BY_FILTER_COMPLETED";
export const FETCH_IMAGES_BY_FILTER_FAILED = "vault/FETCH_IMAGES_BY_FILTER_FAILED";

export const FETCH_NEW_IMAGES_REQUEST_START = "vault/FETCH_NEW_IMAGES_REQUEST_START";
export const FETCH_NEW_IMAGES_REQUEST_COMPLETED = "vault/FETCH_NEW_IMAGES_REQUEST_COMPLETED";
export const FETCH_NEW_IMAGES_REQUEST_FAILED = "vault/FETCH_NEW_IMAGES_REQUEST_FAILED";

export const UPLOAD_VAULT_IMAGE_START = "vault/UPLOAD_VAULT_IMAGE_START";
export const UPLOAD_VAULT_IMAGE_COMPLETED = "vault/UPLOAD_VAULT_IMAGE_COMPLETED";
export const UPLOAD_VAULT_IMAGE_FAILED = "vault/UPLOAD_VAULT_IMAGE_FAILED";

// videos
export const FETCH_VIDEOS_REQUEST_START = "vault/FETCH_VIDEOS_REQUEST_START";
export const FETCH_VIDEOS_REQUEST_COMPLETED = "vault/FETCH_VIDEOS_REQUEST_COMPLETED";
export const FETCH_VIDEOS_REQUEST_FAILED = "vault/FETCH_VIDEOS_REQUEST_FAILED";

export const FETCH_VIDEOS_BY_FILTER_START = "vault/FETCH_VIDEOS_BY_FILTER_START";
export const FETCH_VIDEOS_BY_FILTER_COMPLETED = "vault/FETCH_VIDEOS_BY_FILTER_COMPLETED";
export const FETCH_VIDEOS_BY_FILTER_FAILED = "vault/FETCH_VIDEOS_BY_FILTER_FAILED";

export const FETCH_NEW_VIDEOS_REQUEST_START = "vault/FETCH_NEW_VIDEOS_REQUEST_START";
export const FETCH_NEW_VIDEOS_REQUEST_COMPLETED = "vault/FETCH_NEW_VIDEOS_REQUEST_COMPLETED";
export const FETCH_NEW_VIDEOS_REQUEST_FAILED = "vault/FETCH_NEW_VIDEOS_REQUEST_FAILED";

export const CHANGE_VAULT_DATA = "vault/CHANGE_VAULT_DATA";
export const REMOVE_FILE = "vault/REMOVE_FILE";

export const ADD_VIDEOS = "vault/ADD_VIDEOS";
export const UPDATE_VIDEO = "vault/UPDATE_VIDEO";
export const SET_INSIDE_MODAL = "vault/SET_INSIDE_MODAL";

// all vault

export const FETCH_ALL_VAULT_REQUEST_START = "vault/FETCH_ALL_VAULT_REQUEST_START";
export const FETCH_ALL_VAULT_REQUEST_COMPLETED = "vault/FETCH_ALL_VAULT_REQUEST_COMPLETED";
export const FETCH_ALL_VAULT_REQUEST_FAILED = "vault/FETCH_ALL_VAULT_REQUEST_FAILED";

export const FETCH_ALL_VAULT_BY_FILTER_START = "vault/FETCH_ALL_VAULT_BY_FILTER_START";
export const FETCH_ALL_VAULT_BY_FILTER_COMPLETED = "vault/FETCH_ALL_VAULT_BY_FILTER_COMPLETED";
export const FETCH_ALL_VAULT_BY_FILTER_FAILED = "vault/FETCH_ALL_VAULT_BY_FILTER_FAILED";

export const FETCH_NEW_VAULTS_REQUEST_START = "vault/FETCH_NEW_VAULTS_REQUEST_START";
export const FETCH_NEW_VAULTS_REQUEST_COMPLETED = "vault/FETCH_NEW_VAULTS_REQUEST_COMPLETED";
export const FETCH_NEW_VAULTS_REQUEST_FAILED = "vault/FETCH_NEW_VAULTS_REQUEST_FAILED";

export const UPDATE_BUNDLE_STATE_IN_DATA = 'vault/UPDATE_BUNDLE_STATE_IN_DATA'
export const UPDATE_VAULT_MESSAGE_ACTION = 'vault/UPDATE_VAULT_MESSAGE_ACTION'

export const CLEAR_STATE = 'vault/CLEAR_STATE'

export const SET_FILTERS = 'vault/SET_FILTERS'
