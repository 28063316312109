import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';

let reducersMap = {
   // all cast members data /
   [types.FETCH_DETAILS_START]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  { list, admin_cast_member } = action.payload;
      return {
         ...state,
         isFetching: false,
         // isEmpty: list.data.length === 0 && admin_cast_member,
         adminCastMember: admin_cast_member,
         castMembersData: {
            ...list,
            data: list.data,
         },
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   // next page data /
   [types.NEW_FETCH_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingNew: true,
      }
   },
   [types.NEW_FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  { list } = action.payload;
      return {
         ...state,
         isFetchingNew: false,
         castMembersData: {
            ...list,
            data: [...state.castMembersData.data, ...list.data],
         },

      }
   },
   [types.NEW_FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingNew: false,
      }
   },

   // castMember data /
   [types.FETCH_CAST_MEMBER_REQUEST]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_CAST_MEMBER_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
         castMember: action.payload,
      }
   },
   [types.FETCH_CAST_MEMBER_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },
   // filtered members data /
   [types.FILTER_CAST_MEMBERS_REQUEST]: (state) => {
      return {
         ...state,
         isFilterFetching: true,
      }
   },
   [types.FILTER_CAST_MEMBERS_COMPLETED]: (state, action) => {
      const  { list } = action.payload;
      return {
         ...state,
         isFilterFetching: false,
         isEmptyByFilter: list.data.length === 0,
         castMembersData: {
            ...list,
            data: list.data,
         },
      }
   },
   [types.FILTER_CAST_MEMBERS_FAILED]: (state, action) => {
      return {
         ...state,
         isFilterFetching: true,
      }
   },

   // add new cast member
   [types.ADD_NEW_CAST_MEMBER_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingAddCastMember: true,
         errors: {},
         isSuccessAddNewCastMember: false,
      }
   },
   [types.ADD_NEW_CAST_MEMBER_COMPLETED]: (state, action) => {
      return {
         ...state,
         castMembersData: {
            ...state.castMembersData,
            data: [...state.castMembersData.data, action.payload],
         },
         isFetchingAddCastMember: false,
         isSuccessAddNewCastMember: false,
      }
   },
   [types.ADD_NEW_CAST_MEMBER_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingAddCastMember: false,
         isSuccessAddNewCastMember: false,
         errors: action.payload,
      }
   },

   // success cast member
   [types.SUCCESS_CAST_MEMBER_REQUEST]: (state) => {
      return {
         ...state,
         isSuccessAddNewCastMember: true,
      }
   },
   [types.SUCCESS_CAST_MEMBER_COMPLETED]: (state, action) => {
      return {
         ...state,
         isSuccessAddNewCastMember: true,
      }
   },
   [types.SUCCESS_CAST_MEMBER_FAILED]: (state, action) => {
      return {
         ...state,
         isSuccessAddNewCastMember: true,
      }
   },

   // update cast member
   [types.UPDATE_CAST_MEMBERS_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingUpdateCastMember: true,
      }
   },
   [types.UPDATE_CAST_MEMBERS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         castMembersData: {
            ...state.castMembersData,
            data: [payload],
         },
      }
   },
   [types.UPDATE_CAST_MEMBERS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingUpdateCastMember: false,
         errors: action.payload,
      }
   },

   // delete cast member
   [types.DELETE_CAST_MEMBERS_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingDelete: true,
      }
   },
   [types.DELETE_CAST_MEMBERS_COMPLETED]: (state, action) => {
      return {
         ...state,
         deleteCastMember: action.payload.status,
         isFetchingDelete: false,
      }
   },
   [types.DELETE_CAST_MEMBERS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingDelete: false,
         deleteCastMember: action.payload.status,

      }
   },
   [types.SET_CAN_NOT_DELETE_MODAL]: (state, action) => {
      return {
         ...state,
         canNotDeleteModal: { ...state.canNotDeleteModal, ...action.payload },
      }
   },


   [types.FETCH_ERRORS]: (state, action) => {
      const { name, data } = action.payload
      let errors = { ...state.errors };
      if(!!data) {
         errors = {}
      } else {
         errors = { ...state.errors, [name]: null };
      }

      return {
         ...state,
         errors: { ...errors },
      }
   },

}

export default createReducer(initialState)(reducersMap);
