import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
   deleteVault,
   cancelUploadOperation,

   fetchVaultVideosOperation,
   getFilterVideosByOperation,
   getNewVaultVideosOperation,

   fetchVaultImagesOperation,
   getFilterImagesByOperation,
   getNewVaultImagesOperation,
   fetchAllVaultOperation,
   getFilterAllVaultByOperation,
   getNewVaultsOperation,
} from 'admin/state/modules/vault/operations';
import { startUploadOperation } from 'admin/state/modules/vault/operations';
import {
   // videos
   vaultVideosDataSelector,
   isEmptyVideosSelector,
   isFetchingVideosSelector,
   isFetchingVideosByFilterSelector,
   isEmptyVideosByFilterSelector,
   isNewFetchingVideosSelector,
   // images
   vaultImagesDataSelector,
   isEmptyImagesSelector,
   isFetchingImagesSelector,
   isFetchingImagesByFilterSelector,
   isEmptyImagesByFilterSelector,
   isNewFetchingImagesSelector,
   allVaultDataSelector,
   isFetchingAllVaultSelector,
   isEmptyAllVaultSelector,
   isEmptyAllVaultByFilterSelector,
   isFetchingAllVaultByFilterSelector,
   isNewfetchingAllVaultsSelector,
   vaultsIntedStateSelector,
   filtersInsideModalSelector,
   filtersSelector,
} from 'admin/state/modules/vault/selectors';
import {
   uploadPhotos,
   updateVideo,
   setInsideModalAction,
   deleteCompleted,
   addVideos as addVideosAction,
   updateBundleStateInData,
   updateVaultMessageAction,
   clearStateAction,
   setFiltersAction,
} from 'admin/state/modules/vault/actions';
import PullRefresh from 'admin/utils/PullRefresh.js';
import QueryParams from 'admin/utils/QueryParams.js'
import PullToRefresh from 'pulltorefreshjs';
import toast from 'common/utils/toast';
import { screenWidthSelector, siteInfoSelector } from 'admin/state/modules/common/selectors';
import Vault from 'admin/views/vault';
import DeleteModal from 'admin/views/vault/modules/modalDelete'
import { makeRandomString } from 'common/utils/utils';
import { uploadPhotosetsImage, createVault, checkVaultByHash,
   // , getVaultById
} from 'admin/api/AuthApi';
import { cancelUplaod } from 'admin/utils/VaultVideoUpload'
import { getImageFileHash, getVideoFileHash } from 'common/utils/utils'
import {
   mediaDownloadButtonSelector,
} from 'admin/state/modules/resources/selectors';
import { downloadMediaOperation, fetchResourcesOperation } from 'admin/state/modules/resources/operations';
import ConfirmModal from 'common/components/modules/modal-wrapper';
import { generateUriSegment } from 'common/utils/mediaRouteHelper';
import Router from 'admin/routes/router';
import { getAllowedVideoExtentions } from 'common/utils/utils';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';


class VaultsContainer extends Component {
   static propTypes = {
      type: PropTypes.string,
      data: PropTypes.object,
      uploadPhotos: PropTypes.func,
      deleteVault: PropTypes.func,
      removeFile: PropTypes.func,
      screenWidth: PropTypes.number,
      isInsideModal: PropTypes.bool,
      handleSelectCard: PropTypes.func,
      updateVideo: PropTypes.func,
      startUpload: PropTypes.func,
      selected: PropTypes.array,

      // videos
      fetchVideosData: PropTypes.func,
      getFilterVideosBy: PropTypes.func,
      getNewVaultVideos: PropTypes.func,
      setInsideModal: PropTypes.func,
      vaultVideosData: PropTypes.object,
      isFetchingVideos: PropTypes.bool,
      isEmptyVideos: PropTypes.bool,
      isEmptyVideosByFilter: PropTypes.bool,
      isFetchingVideosByFilter: PropTypes.bool,
      isNewFetchingVideos: PropTypes.bool,
      mediaDownloadButton: PropTypes.bool,
      downloadFile: PropTypes.func,
      // images
      fetchImagesData: PropTypes.func,
      getFilterImagesBy: PropTypes.func,
      getNewVaultImages: PropTypes.func,
      vaultImagesData: PropTypes.object,
      isEmptyImages: PropTypes.bool,
      isFetchingImages: PropTypes.bool,
      isEmptyImagesByFilter: PropTypes.bool,
      isFetchingImagesByFilter: PropTypes.bool,
      isNewFetchingImages: PropTypes.bool,
      deleteCompleted: PropTypes.func,
      addVideosAction: PropTypes.func,
      // all vault
      showNewStyles: PropTypes.bool,
      allVaultData: PropTypes.array,
      isFetchingAllVault: PropTypes.bool,
      isEmptyAllVault: PropTypes.bool,
      isEmptyAllVaultByFilter: PropTypes.bool,
      isFetchingAllVaultByFilter: PropTypes.bool,
      fetchAllVault: PropTypes.func,
      getFilterAllVault: PropTypes.func,
      isNewfetchingAllVaults: PropTypes.bool,
      getNewVaults: PropTypes.func,
      prevetInsideModalDiscard: PropTypes.func,
      // updateBundleStateInData: PropTypes.func,
      bundleId: PropTypes.any,
      contentType: PropTypes.string,
      onDeleteMessag: PropTypes.func,
      updateMessageUnlockDetails: PropTypes.func,
      updateVaultMessageAction: PropTypes.func,
      conversationId: PropTypes.any,

      isMultipleSelect: PropTypes.bool,

      isMultipleAttachments: PropTypes.bool,
      siteInfo: PropTypes.object,
      vaultsIntedState: PropTypes.object,
      clearState: PropTypes.func,

      isMaxAttachmentCountReached: PropTypes.bool,
      filtersInsideModal: PropTypes.object,
      goTo: PropTypes.func,
      fetchResources: PropTypes.func,

      setFilters: PropTypes.func,
      filters: PropTypes.object,
   };

   constructor(props) {
      super(props);
      this.state = {
         vaultData: [],
         openDeleteModal: false,
         deletedId: null,
         deletedType: 'delete',
         errors: {},
         isUploadVideoState: false,
         uploadingProgress: 0,
         compressionStatus: 'none',
         video: {},
         isCompleted: false,
         id: null,
         videoID: null,
         removingVideo: {},
         hashInfoState: [],
         warningModal: {
            isOpen: false,
            description: null,
            ids: null,
            multiple_bundles: null,
         },
      };
      this.filter = {
         count: 20,
         page: 1,
         type: props.type ? props.type : 'image',
      };
      this.fileUploader = React.createRef();
      this.alreadyFetched = false
   }

   componentDidMount() {
      const {
         isInsideModal,
         fetchImagesData,
         fetchVideosData,
         getFilterVideosBy,
         getFilterImagesBy,
         fetchAllVault,
         vaultsIntedState: {
            vaultVideosIsInited,
            vaultImagesIsInited,
            allVaultIsInited,
         },
         fetchResources,
      } = this.props;

      const { query, isFiltered, type } = this.configurateInitialFilters()

      this.handleEvents()

      PullToRefresh.init({
         ...PullRefresh.init(),
         onRefresh: (done) => {
            this.filter = { ...this.filter, page: 1, type };
            const param = this.generateQuery()
            if(param){
               if(type === 'video') {
                  getFilterVideosBy(param)
               } else {
                  getFilterImagesBy(param)
               }
               done();
            } else {
               if(type === 'video') {
                  getFilterVideosBy()
               } else {
                  getFilterImagesBy()
               }
               done();
            }
            window.isPullRefresh  = false
         },
      });

      fetchResources()

      if(type === 'video'){
         if(!vaultVideosIsInited) {
            fetchVideosData(query, isInsideModal, isFiltered);
         }
      } else {
         if(type === 'all_vault') {
            if(!allVaultIsInited) {
               fetchAllVault(query)
            }
         } else {
            if(!vaultImagesIsInited) {
               fetchImagesData(query, isFiltered);
            }
         }
      }

   }

   componentWillUnmount() {
      const {
         type,
         clearState,
         isInsideModal,
         setInsideModal,
         isMultipleAttachments,
         filtersInsideModal,
         setFilters,
      } = this.props

      window.isPullRefresh  = false
      window.removeEventListener('content-update', this.bindSocketEvents);
      PullRefresh.destroyAll();
      setInsideModal(false);

      clearState({
         vaultVideosIsInited: isMultipleAttachments,
         vaultImagesIsInited: isMultipleAttachments,
         allVaultIsInited: isMultipleAttachments,
         filtersInsideModal: isMultipleAttachments ? {
            ...filtersInsideModal,
            [`${ type }_filters`]: this.filter,
         } : {},
      })

      if(!isInsideModal){
         setFilters({
            [`video_filters`]: {},
            [`image_filters`]: {},
         })
      }

      if(isInsideModal){
         let elementId = type === 'all_vault' ? '#select-from-vaults' : `#select-from-${ type === 'image' ? 'photo' : 'video' }_vaults`
         let element = document.querySelector(elementId)
         if(element) element.removeEventListener('scroll', this.handleScroll);
      } else {
         window.removeEventListener('scroll', this.handleScroll);
      }
   }

   componentDidUpdate(){
      const { isFetchingImages, isFetchingVideos, prevetInsideModalDiscard, isInsideModal, type } = this.props

      if(!!prevetInsideModalDiscard && isInsideModal) {
         prevetInsideModalDiscard(type === 'image' ? isFetchingImages : isFetchingVideos)
      }
   }

   bindSocketEvents = e => {
      const { detail: { payload } } = e
      const video = payload
      const { updateVideo } = this.props;
      const { id, socket_emit_type } = video;
      if('vault_upload' === socket_emit_type) {
         updateVideo(+id, { ...video, id: +video.id });
      }
   }

   configurateInitialFilters = () => {
      const {
         isInsideModal,
         conversationId,
         type,
         isMultipleAttachments,
         filtersInsideModal,
         filters,
      } = this.props

      let params = QueryParams.getFilters()
      let isFiltered = false

      if(isInsideModal){
         if(conversationId){
            this.filter = {
               ...this.filter,
               conversation: conversationId,
               type,
            }
         }
         if(isMultipleAttachments) {
            this.filter = {
               ...this.filter,
               ...filtersInsideModal?.[`${ type }_filters`],
            }
         }

         if(type === 'video') this.filter.status = 'completed'

      } else {
         this.filter = {
            ...this.filter,
            ...filters?.[`${ type }_filters`],
            ...params,
         }

         if(Object?.keys(params)?.length > 1) isFiltered = true
      }

      const query = this.generateQuery();

      return { query, isFiltered, type: this.filter.type }
   }

   handleEvents = () => {
      const { isInsideModal } = this.props
      if(isInsideModal){
         let idByType = `#select-from-${ this.filter.type === 'image' ? 'photo' : 'video' }_vaults`

         if(this.filter.type === 'all_vault') {
            idByType = '#select-from-vaults'
         }
         let element = document.querySelector(idByType)
         if(element) {
            element.scrollTo({ top: 0 })
            element.addEventListener('scroll', this.handleScroll);
         }
      } else {
         window.addEventListener('scroll', this.handleScroll);
      }
      window.addEventListener('content-update', this.bindSocketEvents);
   }

   handleScroll = (e) => {
      const { isInsideModal, isFetchingImages, isFetchingVideos, isFetchingImagesByFilter, isFetchingVideosByFilter } = this.props
      const { scrollHeight, clientHeight, scrollTop } = !!isInsideModal ? e.target : document.documentElement;

      if(this.filter.type === 'image' && (isFetchingImages || isFetchingImagesByFilter)) return
      if(this.filter.type === 'video' && (isFetchingVideos || isFetchingVideosByFilter)) return

      if(((scrollHeight - scrollTop) - clientHeight < 100)) {
         this.nextPage()
      }
   }

   nextPage = async () => {
      const {
         getNewVaultVideos,
         getNewVaultImages,
         isNewFetchingVideos,
         isNewFetchingImages,
         vaultVideosData: {
            current_page: currentVideosPage,
            last_page: videosPages,
         },
         vaultImagesData: {
            current_page: currentImagesPage,
            last_page: imagesPages,
         },
         allVaultData: {
            current_page: currentAllVaultsPage,
            last_page: AllVaultsPages,
         },
         getNewVaults,
         isNewfetchingAllVaults,
      } = this.props;

      let query = [];
      if(this.filter.type === 'video') {
         if(currentVideosPage < videosPages && !isNewFetchingVideos) {
            this.filter = { ...this.filter, page: currentVideosPage + 1 };
            query = this.generateQuery();
            await getNewVaultVideos(query);
         } else {
            return
         }
      } else {
         if(this.filter.type === 'all_vault') {
            if(currentAllVaultsPage < AllVaultsPages && !isNewfetchingAllVaults) {
               this.filter = { ...this.filter, page: currentAllVaultsPage + 1 };
               query = this.generateQuery();
               await getNewVaults(query);
            } else {
               return
            }
         } else {
            if(currentImagesPage < imagesPages && !isNewFetchingImages) {
               this.filter = { ...this.filter, page: currentImagesPage + 1 };
               query = this.generateQuery();
               await getNewVaultImages(query);
            } else {
               return
            }
         }
      }
   }

   onFileChange = async e =>  {
      const { uploadPhotos } = this.props;
      const targetFiles = e.target.files
      let res = [];
      let filesData = [];
      let fackData = [];
      let hashs = []
      for(let index = 0; index < Object.values(targetFiles).length; index++) {
         const file = Object.values(targetFiles)[index];
         const type = file.name.split('.');
         const validFormat = ['jpeg', 'jpg', 'png'];
         const isValid = validFormat.indexOf(type[type.length - 1].toLowerCase()) !== -1 && type.length > 1;
         if(isValid) {
            await getImageFileHash(file).then(
               function(result) {
                  fackData.push({ uploadingStatus: 'loading', id: makeRandomString(8), isFack: true, hash: result, file });
                  filesData.push(file);
                  hashs.push(result)
               },
               function(err) {
               });
         } else {
            toast.error('You may upload only photo files');
         }
      }
      uploadPhotos(fackData, 'loading')
      const { data } = await checkVaultByHash('image', [...hashs])
      let sortItems = data.reduce((acc, curr) => {
         let value =  Object.values(curr)[0]
         let hash =  Object.keys(curr)[0]
         if(value) {
            const { deleteCompleted, vaultImagesData } = this.props
            if(vaultImagesData && vaultImagesData.data && vaultImagesData.data.find(elm => elm.id === value.id)) {
               deleteCompleted(value.id, 'image')
            }
            uploadPhotos({ ...value }, 'update', hash)
         } else {
            acc.push(curr)
         }
         return acc
      }, []);

      for(let index = 0; index < sortItems.length; index++) {
         const element = sortItems[index];
         let hash =  Object.keys(element)[0]
         let current = fackData.find(el => el.hash === hash) || {}
         const data = new FormData();
         data.append('file', current.file || {});

         try {
            const { data: { path } } =  await uploadPhotosetsImage(data);
            uploadPhotos({ ...current, src: path }, 'update')
            const photo = {
               hash: hash,
               type: 'image',
               src: path,
            }
            res.push(photo)

         } catch (error) {
            uploadPhotos({ ...current,  uploadingStatus: 'error' }, 'update')
         }
      }
      if(res.length !== 0){
         try {
            const { data } = await createVault({ data: res })
            uploadPhotos(data, 'done')

         } catch (error) {

         }
      }

   }

   generateUrlQuery = (filters, inited) => {
      if(filters && !isEmpty(filters)) {
         const urlParams = {};
         Object.keys(filters).forEach((key) => {
            if(key){
               if(key !== 'count' && key !== 'page') {
                  urlParams[key] = filters[key]
               }
               if(!filters[key]) {
                  delete urlParams[key]
               }
            }
         });
         QueryParams.defineFilters(urlParams)
      }
      let params = QueryParams.getFilters()
      this.filter = { type: this.props.type, count: 20, page: 1, ...params };
   };

   handleFileChange = (type) => {
      const { goTo } = this.props
      if(type === 'image'){
         goTo(Router.route("NEW_VAULT_IMAGE").getCompiledPath())
         return
      }
      this.fileUploader.current.click();
   };


   onFilterChange = (value, key = 'sortBy') => {
      const { getFilterVideosBy, getFilterImagesBy, getFilterAllVault, isInsideModal, setFilters, filters } = this.props;
      let data = value
      let params = {}

      if(!!Array.isArray(value)) {
         data = value.join(',')
      }

      if(!data) {
         let { [key]: _, ...newFilters } = this.filter
         params = { ...newFilters }
      } else {
         params = { ...this.filter, count: 20, page: 1, [key]: data };
      }

      this.filter = params

      if(!isInsideModal){
         this.generateUrlQuery(params)
         setFilters({
            ...filters,
            [`${ this.filter.type }_filters`]: this.filter,
         })
      }

      const param = this.generateQuery();

      if(this.filter.type === 'video'){
         getFilterVideosBy(param)
      } else {
         if(this.filter.type === 'all_vault') {
            getFilterAllVault(param)
         } else {
            getFilterImagesBy(param)
         }
      }
   };

   onClearAllFilters = () => {
      const { getFilterVideosBy, getFilterImagesBy, getFilterAllVault, conversationId, isInsideModal, setFilters, filters  } = this.props;
      this.filter = {
         count: 20,
         page: 1,
         type: this.filter.type,
      }

      if(!!conversationId) {
         this.filter = {
            ...this.filter,
            conversation: conversationId,
         }
      }
      if(isInsideModal) {
         this.filter = {
            ...this.filter,
            status: this.filter.type === 'image' ? '' : 'completed',
         }
      } else {
         this.generateUrlQuery(this.filter)
         setFilters({
            ...filters,
            [`${ this.filter.type }_filters`]: this.filter,
         })
      }

      let query = this.generateQuery()
      if(this.filter.type === 'video'){
         getFilterVideosBy(query)
      } else {
         if(this.filter.type === 'all_vault') {
            getFilterAllVault(query)
         } else {
            getFilterImagesBy(query)
         }
      }
   }

   onFilterByType = (type) => {
      const { getFilterAllVault } = this.props;
      let filteredTypes = ''
      if(type?.length > 1) {
         filteredTypes = 'all_vault'
      }
      this.filter = {
         ...this.filter,
         type: !!filteredTypes ? filteredTypes : type,
         page: 1,
      }
      const param = this.generateQuery();
      getFilterAllVault(param)
   }

   generateQuery = () => {
      const query = [];
      Object.keys(this.filter).forEach((key) => {
         if(key){
            if(this.filter[key]) {
               key !== 'count' && key !== 'page' && key !== 'sortBy' && key !== 'conversation' ? query.push(`query[]=${ key }=${ this.filter[key] }`) : query.push(`${ key }=${ this.filter[key] }`);
            }
         }
      });
      return query;
   };

   openDeleteConfirmModal  = (id = null, open = false, type, isLoading = false) => {

      this.setState({
         openDeleteModal: open,
         deletedType: type ? type : 'delete',
         deletedId: id,
         isLoadingDeleteButton: isLoading,
      })

   }

   onDeleteVault = async (id) => {
      const { deleteVault, removeFile, vaultImagesData, vaultVideosData, deleteCompleted } = this.props;
      const { deletedType, removingVideo: { data, status } } = this.state;

      const closeModal = (isLoading) => this.openDeleteConfirmModal(null, false, null, isLoading);

      this.setState({ isLoadingDeleteButton: true })
      if(deletedType === 'delete') {
         const type = this.filter.type === 'image' ? 'Photo' : 'Video';

         deleteVault(id, type, (modalData, type) => {
            if(type === 'succeed') {
               deleteCompleted(id, this.filter.type);
               closeModal();
            } else {
               this.setState({ warningModal: modalData });
               setTimeout(() => {
                  this.setState({ openDeleteModal: false, isLoadingDeleteButton: false });
               }, 0)
            }
         });
      } else if(status && (status === 'uploading' || status === 'pending')) {
         cancelUplaod(data, () => removeFile(data.id, status, closeModal));
      } else {
         removeFile(data.id, status, closeModal);
      }

      const vaultData = this.filter.type === 'video' ? vaultVideosData.data : vaultImagesData.data;
      if(vaultData && vaultData.length < 10) {
         this.nextPage();
      }
   }


   onTabChange = (tab) => {
      const {
         fetchVideosData,
         fetchImagesData,
         isInsideModal,
         vaultsIntedState: {
            vaultVideosIsInited,
            vaultImagesIsInited,
         },
         filters,
      } = this.props;

      if(this.filter.type !== tab) {
         QueryParams.removeAllQueryParam()
         QueryParams.defineFilters({ type: tab });
         this.filter = { ...filters?.[`${  tab }_filters`], page: 1, type: tab };

         const param = this.generateQuery();
         if(tab === 'video'){
            if(!vaultVideosIsInited) {
               fetchVideosData(param, isInsideModal);
            }
         } else {
            if(!vaultImagesIsInited) {
               fetchImagesData(param)
            }
         }
      }
   }

   // video upload

   handleVideoFileChange = async (e) => {
      const allowedExtentions = getAllowedVideoExtentions();
      const files = e.target.files;
      const filtered = [];
      const hashs = [];
      Array.from(files).forEach((file) => {
         const { type } = file;
         if(allowedExtentions.indexOf(type.toLowerCase()) !== -1) {
            let hash = getVideoFileHash(file)
            hashs.push(hash);
            filtered.push({ [hash]: file });
         }
      });
      const { data } = await checkVaultByHash('video', [...hashs])
      let sortItems = data.reduce((acc, curr) => {
         let value =  Object.values(curr)[0]
         let hash =  Object.keys(curr)[0]
         if(value) {
            const { addVideosAction, updateVideo } = this.props
            addVideosAction({ vault: [{ uploadingStatus: 'pending', hash, id: hash, type: 'video' }] })
            setTimeout(() => {
               const { deleteCompleted, vaultVideosData } = this.props
               if(vaultVideosData && vaultVideosData.data && !!vaultVideosData.data.find(elm => elm.id === value.id)){
                  deleteCompleted(value.id, 'video')
               }
               updateVideo(hash, value, true)
            }, 300)
         } else {
            let item = filtered.find(element => element[hash]) || {}

            acc.push({ file: item[hash], hash })
         }
         return acc
      }, []);
      this.fileUploader.current.value = '';
      if(0 === filtered.length) {
         toast.error('You may upload only video files')
         return;
      }
      if(sortItems.length > 0){
         const { startUpload } = this.props;
         startUpload(sortItems);
      }
   }

   onActionWarningModal = () => {
      const { warningModal } = this.state;
      this.setState({
         ...this.state,
         warningModal: {
            isOpen: false,
            description: null,
            ids: null,
            multiple_bundles: null,
         },
      })
      if(!['sent_in_chat', 'paid_for_unlock_or_download'].includes(warningModal.reason)){
         if(warningModal?.multiple_bundles){
            window.open(`${ Router.route('STORE').getCompiledPath() }?q={"type":"content_bundle_item"}`)
         } else {
            window.open(Router.route('STORE_EDIT_BUNDLE').getCompiledPath({ id: generateUriSegment(warningModal.ids?.[0]) }))
         }
      }
   }


   render() {
      const {
         screenWidth,
         isInsideModal,
         handleSelectCard,
         selected,

         // images
         vaultImagesData,
         isEmptyImages,
         isFetchingImages,
         isEmptyImagesByFilter,
         isFetchingImagesByFilter,
         isNewFetchingImages,

         // videos
         vaultVideosData,
         isFetchingVideos,
         isEmptyVideos,
         isEmptyVideosByFilter,
         isFetchingVideosByFilter,
         isNewFetchingVideos,
         downloadFile,
         mediaDownloadButton,

         // all vault
         showNewStyles,
         allVaultData,
         isFetchingAllVault,
         isEmptyAllVault,
         isEmptyAllVaultByFilter,
         isFetchingAllVaultByFilter,
         isNewfetchingAllVaults,
         bundleId,
         contentType,
         onDeleteMessag,
         updateVaultMessageAction,
         updateMessageUnlockDetails,
         isMultipleSelect,
         isMultipleAttachments,
         siteInfo,
         isMaxAttachmentCountReached,
         goTo,
      } = this.props;
      const {
         deletedId,
         openDeleteModal,
         warningModal,
         isLoadingDeleteButton,
      } = this.state;

      let allowedExtentions = ['image/png', 'image/jpeg', 'image/jpg'];
      if(this.filter.type === 'video') allowedExtentions = getAllowedVideoExtentions()
      const accept = allowedExtentions.join(',');

      return (
         <div className='h-full'>
            <input
               type='file'
               ref={ this.fileUploader }
               className='hidden'
               multiple
               onChange={ this.filter.type === 'image' ? this.onFileChange : this.handleVideoFileChange }
               accept={  this.filter.type === 'image' ? `${ accept }/*` : `${ accept }`  }
            />
            <Vault
               isInsideModal={ isInsideModal }
               pageType={ this.filter.type }
               desktopType={ screenWidth > 701 ? 'desktop' : 'mobile' }
               handleAddNewVault={ this.handleFileChange }
               onFilterChange={ this.onFilterChange }
               openDeleteConfirmModal={ (id, open, openWarning) => {
                  this.openDeleteConfirmModal(id, open, 'delete')
               } }
               onTabChange={ this.onTabChange }
               removeFile={ (data, status) => {
                  this.setState({ removingVideo: { data, status } });
                  this.openDeleteConfirmModal(data.id, true, 'cancel')
               } }
               onSelectCard={ handleSelectCard }
               selected={ selected }
               // images
               vaultImagesData={ vaultImagesData && vaultImagesData.data }
               isEmptyImages={ isEmptyImages }
               isFetchingImages={ isFetchingImages }
               isEmptyImagesByFilter={ isEmptyImagesByFilter }
               isFetchingImagesByFilter={ isFetchingImagesByFilter }
               isNewFetchingImages={ isNewFetchingImages }
               // videos
               vaultVideosData={ vaultVideosData && vaultVideosData.data }
               isFetchingVideos={ isFetchingVideos }
               isEmptyVideos={ isEmptyVideos }
               isEmptyVideosByFilter={ isEmptyVideosByFilter }
               isFetchingVideosByFilter={ isFetchingVideosByFilter }
               isNewFetchingVideos={ isNewFetchingVideos }
               downloadOriginalFile={ downloadFile }
               isDownloading={ mediaDownloadButton }
               // all vault
               showNewStyles={ showNewStyles }
               allVaultData={ allVaultData }
               isFetchingAllVault={ isFetchingAllVault }
               isEmptyAllVault={ isEmptyAllVault }
               isEmptyAllVaultByFilter={ isEmptyAllVaultByFilter }
               isFetchingAllVaultByFilter={ isFetchingAllVaultByFilter }
               onFilterByType={ this.onFilterByType }
               isNewfetchingAllVaults={ isNewfetchingAllVaults }
               screenWidth={ screenWidth }
               bundleId={ bundleId }
               contentType={ contentType }
               onDeleteMessag={ onDeleteMessag }
               updateVaultMessageAction={ updateVaultMessageAction }
               updateMessageUnlockDetails={ updateMessageUnlockDetails }
               isMultipleSelect={ isMultipleSelect }
               isMultipleAttachments={ isMultipleAttachments }
               isDemoMode={ siteInfo?.site_status === 'demo_mode' }
               isMaxAttachmentCountReached={ isMaxAttachmentCountReached }
               onClearAllFilters={ this.onClearAllFilters }
               allFilters={ this.filter }
               goTo={ (routeCode, id) => goTo(Router.route(routeCode).getCompiledPath({ id })) }
            />
            {
               openDeleteModal && (
                  <DeleteModal
                     onCloseModal={ () => this.openDeleteConfirmModal() }
                     deleteVault={ () => this.onDeleteVault(deletedId) }
                     isMobile={ screenWidth < 701 }
                     isCloseAction
                     type={ this.filter.type }
                     isLoadingButton={ isLoadingDeleteButton }
                  />
               )
            }
            {
               warningModal.isOpen &&
               <ConfirmModal
                  onCloseModal={ () => {
                     this.setState({
                        ...this.state,
                        warningModal: {
                           isOpen: false,
                           description: null,
                           ids: null,
                           multiple_bundles: null,
                        },
                     })
                  } }
                  isCloseAction
                  type='confirm'
                  icon='warning'
                  withoutCloseButton
                  iconColor='warning'
                  title={ warningModal?.title }
                  description={ warningModal?.description }
                  cancelText={ warningModal?.cancelText }
                  buttonText={ warningModal.buttonText }
                  secondaryButtonClassName={ warningModal.secondaryButtonClassName }
                  buttonIconName={ warningModal.buttonIconName }
                  buttonClassName='mt-8'
                  action={ this.onActionWarningModal }
               />
            }
         </div>
      )
   }
}

const mapStateToProps = state => ({
   screenWidth: screenWidthSelector(state),
   // videos
   vaultVideosData: vaultVideosDataSelector(state),
   isFetchingVideos: isFetchingVideosSelector(state),
   isEmptyVideos: isEmptyVideosSelector(state),
   isEmptyVideosByFilter: isEmptyVideosByFilterSelector(state),
   isFetchingVideosByFilter: isFetchingVideosByFilterSelector(state),
   isNewFetchingVideos: isNewFetchingVideosSelector(state),
   mediaDownloadButton: mediaDownloadButtonSelector(state),

   // images
   vaultImagesData: vaultImagesDataSelector(state),
   isFetchingImages: isFetchingImagesSelector(state),
   isEmptyImages: isEmptyImagesSelector(state),
   isEmptyImagesByFilter: isEmptyImagesByFilterSelector(state),
   isFetchingImagesByFilter: isFetchingImagesByFilterSelector(state),
   isNewFetchingImages: isNewFetchingImagesSelector(state),

   // all vault
   allVaultData: allVaultDataSelector(state),
   isFetchingAllVault: isFetchingAllVaultSelector(state),
   isEmptyAllVault: isEmptyAllVaultSelector(state),
   isEmptyAllVaultByFilter: isEmptyAllVaultByFilterSelector(state),
   isFetchingAllVaultByFilter: isFetchingAllVaultByFilterSelector(state),
   isNewfetchingAllVaults: isNewfetchingAllVaultsSelector(state),
   siteInfo: siteInfoSelector(state),
   vaultsIntedState: vaultsIntedStateSelector(state),
   filtersInsideModal: filtersInsideModalSelector(state),
   filters: filtersSelector(state),
});

const mapDispatchToProps = dispatch => ({
   deleteVault: (id, type, callBack) => dispatch(deleteVault(id, type, callBack)),
   // images
   fetchImagesData: (params, isFiltered) => dispatch(fetchVaultImagesOperation(params, isFiltered)),
   getFilterImagesBy: params => dispatch(getFilterImagesByOperation(params)),
   getNewVaultImages: (params) => dispatch(getNewVaultImagesOperation(params)),
   uploadPhotos: (data, status, byHash) => dispatch(uploadPhotos(data, status, byHash)),
   // videos
   fetchVideosData: (params, isInsideModal, isFiltered) => dispatch(fetchVaultVideosOperation(params, isInsideModal, isFiltered)),
   getFilterVideosBy: params => dispatch(getFilterVideosByOperation(params)),
   getNewVaultVideos: (params) => dispatch(getNewVaultVideosOperation(params)),
   removeFile: (id, status, callback) => dispatch(cancelUploadOperation(id, status, callback)),
   startUpload: (files) => dispatch(startUploadOperation(files)),
   updateVideo: (id, data, byHash) => dispatch(updateVideo(id, data, byHash)),
   setInsideModal: (isInsideModal) => dispatch(setInsideModalAction(isInsideModal)),
   deleteCompleted: (data, type) => dispatch(deleteCompleted(data, type)),
   addVideosAction: (data) => dispatch(addVideosAction(data)),
   downloadFile: (id, type) => dispatch(downloadMediaOperation(id, 'vault', type)),
   // all vault
   fetchAllVault: params => dispatch(fetchAllVaultOperation(params)),
   getFilterAllVault: params => dispatch(getFilterAllVaultByOperation(params)),
   getNewVaults: params => dispatch(getNewVaultsOperation(params)),
   updateBundleStateInData: (data, bool) => dispatch(updateBundleStateInData(data, bool)),
   updateVaultMessageAction: data => dispatch(updateVaultMessageAction(data)),
   clearState: data => dispatch(clearStateAction(data)),
   goTo: (pathname, search) => dispatch(push({ pathname, search })),
   fetchResources: () => dispatch(fetchResourcesOperation('vaults')),
   setFilters: data => dispatch(setFiltersAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VaultsContainer);
