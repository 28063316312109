/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import CircleLoader from 'common/components/elements/loader/circle';
import videojs from 'video.js';
import {
   registerIVSQualityPlugin,
   registerIVSTech,
} from 'amazon-ivs-player';
import Button from 'common/components/elements/buttons/primary';
import classNames from 'classnames';

import './style.scss';

const VideoStream = ({
   file,
   cover,
   handleError,
   className = '',
   style = {},
   id = 'videojs-player',
   muted = false,
   controlBar = true,
   showSdQuality = true,
   showCustomVoiceButton,
   onPlay,
   isImageBluredForSfw,
   isVideoBluredForSfw,
   // showTipButton,
}) => {
   const [scriptLoad, setScriptLoad] = useState(true);
   const [isError, setIsError] = useState(true);
   const [muted1, setMuted1] = useState(muted);
   const player = useRef(null);
   const [isMounted, setIsMounted] = useState(false);

   let timeout = null;
   useEffect(() => {
      if(!isMounted){
         setIsMounted(true);
         return
      }
      registerIVSTech(videojs, {
         wasmWorker: process.env.REACT_APP_AWS_IVS_WASM_WORKER_CDN_LINK || process.env.NEXT_PUBLIC_AWS_IVS_WASM_WORKER_CDN_LINK,
         wasmBinary: process.env.REACT_APP_AWS_IVS_WASM_BINARY_CDN_LINK || process.env.NEXT_PUBLIC_AWS_IVS_WASM_BINARY_CDN_LINK,
      });
      // register the quality plugin
      registerIVSQualityPlugin(videojs)
      if(!document.querySelector(`#${ id }`)) return
      // create the player with the appropriate types. We're using @types/video.js VideoJsPlayer, and intersecting our Player and Quality Plugin interface
      player.current = videojs(id, {
         techOrder: ["AmazonIVS"],
         autoplay: 'muted',
         bigPlayButton: false,
         controlBar: controlBar,
         loadingSpinner: true,
         muted: muted,
         aspectRatio: '16:9',
      }, function() {
         console.warn('Player is ready to use')
      });

      // enable the quality plugin
      if(showSdQuality === false) {
         player.current.enableIVSQualityPlugin();
      }

      // listen to IVS specific events
      const events = player.current.getIVSEvents();
      const ivsPlayer = player.current.getIVSPlayer();
      window.player = player.current;
      window.ivsPlayer = ivsPlayer;
      ivsPlayer.addEventListener(events.PlayerState.READY, () => {
         console.log('IVS Player is READY')
         const qualities = ivsPlayer.getQualities();
         if(showSdQuality) {
            const sdQuality = qualities.find(quality => '480p' === quality.name);
            if(sdQuality) {
               ivsPlayer.setAutoMaxQuality(sdQuality);
            }
         }
      });
      ivsPlayer.addEventListener(events.PlayerState.PLAYING, () => {
         console.log('IVS Player is playing')
         setIsError(false);
         setScriptLoad(false);
         setMuted1(window.player.muted())
         if(onPlay){
            onPlay();
         }
      });
      ivsPlayer.addEventListener(events.PlayerState.ENDED, (e) => {
         console.log('IVS ENDED', e)
         setIsError(true);
         loadError(e)
      });
      ivsPlayer.addEventListener(events.PlayerEventType.ERROR, (e) => {
         console.log('IVS ErrorType', e)
         loadError(e)
      });
      player.current.src(file);
      setScriptLoad(false);
      player.current.on('volumechange', (event) => {
         setMuted1(player.current.muted())
      });
      return () => {
         clearTimeout(timeout)
         if(player.current) {
            player.current.dispose();
         }
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isMounted])

   useEffect(() => {
      return () => {
         clearTimeout(timeout)
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const loadError = (e) => {
      if(!isError){
         setIsError(true);
      }
      if(window.streamIsEnd) {
         handleError(e)
      }
      timeout = setTimeout(() => {
         player.current.src(file)
      }, 2000)

   }
   if(!isMounted) return null
   return (
      <div
         style={ {
            ...style,
         } }
         className={ classNames({
            [`relative ${ className } z-1 livestream-videojs`]: true,
            '_livestream_video_blured_for_sfw': isVideoBluredForSfw, // blur video element on css usign this className
         }) }
      >
         <video
            id={ id }
            className='video-js vjs-default-skin vjs-4-3 vjs-big-play-centered z-1 h-auto'
            controls
            autoPlay
            playsInline
            muted={ muted1 }
         ></video>

         {
            showCustomVoiceButton && !scriptLoad && !isError && (
               <div
                  className='absolute flex items-center justify-center top-0 right-0 z-[40] w-8 mr-2 mt-4'>
                  <Button
                     iconName={ muted1 ? 'volume-off' : 'volume-on' }
                     padding='1'
                     onClick={ e => {
                        e.preventDefault()
                        setMuted1(!muted1)
                        player.current.muted(!muted1)
                        window.ivsPlayer.setMuted(!muted1)
                        e.stopPropagation()
                        e.nativeEvent.stopImmediatePropagation();
                     } }
                     fontIconColor='major-dark'
                     backgroundColor='transparent'
                     classNames='h-full w-full items-center justify-center'
                     fontIconSize='doubleLarge'
                     iconPosition='left'
                     waveActive={ false }
                     alignment='end'
                     textSize='base'

                  />
               </div>
            )
         }

         {
            scriptLoad && (
               <div className='flex items-center justify-center absolute top-0 left-0 z-2 h-full w-full'>
                  <div className='aspect-ratio-padding'></div>
                  <div className=' absolute'>
                     <CircleLoader color='blue' />
                  </div>
               </div>

            )
         }
         {
            isError && (
               <div className='flex items-center justify-center z-3 absolute top-0 w-full h-full'>
                  <div className='flex items-center relative h-full w-full z-1 overflow-hidden'>
                     <div className='aspect-ratio-padding'></div>
                     <div
                        style={ {
                           backgroundImage: `url(${ cover })`,
                           filter: 'blur(7px)',
                        } }
                        className='z-1 w-full h-full  absolute top-0 left-0 bg-cover bg-center bg-no-repeat'
                     />

                     <img
                        src={ cover }
                        alt=''
                        className='w-full h-full object-contain object-center z-1  absolute top-0 left-0'
                        style={ {
                           filter: isImageBluredForSfw ? 'blur(32px)' : '',
                        } }
                     />
                  </div>
               </div>
            )
         }

      </div>
   )
}


VideoStream.propTypes = {
   file: PropTypes.string,
   handleError: PropTypes.func,
   cover: PropTypes.string,
   className: PropTypes.string,
   id: PropTypes.string,
   style: PropTypes.object,
   muted: PropTypes.bool,
   controlBar: PropTypes.bool,
   showSdQuality: PropTypes.bool,
   showCustomVoiceButton: PropTypes.bool,
   onPlay: PropTypes.func,
   isImageBluredForSfw: PropTypes.bool,
   isVideoBluredForSfw: PropTypes.bool,
   // showTipButton: PropTypes.bool,
};

export default VideoStream
