import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ImgGallery from 'common/components/modules/img-gallery/index.jsx'
import '../style.scss'

const Photoset = ({
   data,
   galleryProps,
   isDefaultThumbnailSFW,
   isAgeVerificationRequired,
}) => {
   const haveData = Array.isArray(data) && data.length > 0;
   const images = data.map(item => {

      if('string' === typeof item) {
         return { original: item }
      }

      let originalClass = ''
      if(item.is_default && isAgeVerificationRequired) {
         originalClass = isDefaultThumbnailSFW ? '_default_thumbnail_is_sfw' : '_default_thumbnail_blured_for_sfw'
      }

      return {
         original: item.cf_path_photo,
         originalClass: originalClass,
      }

   });
   return (
      <div className='h-full w-full'>
         {
            haveData && (
               <ImgGallery
                  images={ images }
                  {
                     ...galleryProps
                  }
               />
            )
         }
      </div>
   );
};

Photoset.propTypes = {
   data: PropTypes.array,
   galleryProps: PropTypes.object,
   isDefaultThumbnailSFW: PropTypes.bool,
   isAgeVerificationRequired: PropTypes.bool,
};

export default memo(Photoset);
