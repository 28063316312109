import {
   fetchIndexRequest,
   fetchNextRecordsRequest,
   fetchNextRecordsCompleted,
   updateDataCompleted,
   fetchLiveStreamMessagesRequest,
   fetchLiveStreamMessagesRequestCompleted,
   fetchLikedMemberByMessageRequest,
   fetchLikedMemberByMessageCompleted,
   fetchLikedMemberByMessageFailed,
   fetchNewMessagesRequest,
   fetchNewMessagesCompleted,
   fetchNewMessagesFailed,
   setIsConnectedAction,
   updateCurrentData,
   setTipHistoryAction,
   setActiveViewerStates,
   setDeletButtonLoadingState,
   // warningActivityModal,
   updateTipActionData,
   setDeleteUpcomingState,
   setDeleteUpcomingCompleted,
   setValidationError,
   fetchCheckValidationRequest,
   fetchCreateLivestreamRequest,
   pinRecordedStreamCompleted,
   setWarningModal,
} from './actions'

import {
   fetchLiveStreamRecordedStream,
   fetchLiveStream,
   updateLiveStreamDetails,
   fetchLiveStreamDetails,
   liveStreamStartSessionApi,
   liveStreamEndSessionApi,
   getLiveStreamMessages,
   getLiveStreamMessageLikedMembers,
   updateLiveStreamTipAction,
   createLiveStreamTipAction,
   deleteLiveStreamTipAction,
   reorderLiveStreamTipActions,
   updateLiveStreamTipActionOthers,
   getTipHistory,
   removeVideo as removeVideoApi,
   deleteUpcomingStream,
   livestreamCheckValidate,
   createLivestream,
   getMassMessageLimit,
   updateVideo,
   fetchLiveStreamRecordedStreamsForReorder,
} from 'admin/api/AuthApi'
import { fetchResourcesOperation, updateCountStateAfterDelete } from 'admin/state/modules/resources/operations';
import { updateLiveStreamCurrentSessionState, setMassMessageLimit } from 'admin/state/modules/common/actions';
import toast from "common/utils/toast";
import Router from 'admin/routes/router';
import { push } from "connected-react-router";
import { initLiveStreamSocket } from 'admin/utils/socket';

let mediaRecorder = {}
export const changeStreamInfo = (data) => {
   return dispatch => {
      // dispatch(setStreamInfo(data))
   }
}
export const fetchIndexOperation = (query, isFirstLoad, isInsideModal = false, isReorderMode) => {
   return async (dispatch, getState) => {
      let state = {}
      const { livestream } = getState();
      if(!livestream?.inited && !isInsideModal) {
         if(isFirstLoad){
            state = {
               isFetching: true,
            }
         } else {
            state = {
               isFetchingRecordFilter: true,
            }
         }
         dispatch(fetchIndexRequest(state))
      }
      try {
         dispatch(fetchRecordStreamsOperation(query || ['page=5'], false, isInsideModal, isReorderMode))
         dispatch(fetchResourcesOperation('videos_list'));

         if(isInsideModal) return

         const { data } = await fetchLiveStream()

         dispatch(fetchIndexRequest({ isFetching: false, isFetchingRecordFilter: false, initData: data, ivs: data?.ivs, inited: true }))
      } catch (error) {
         console.log(error);
      }
   };
};

export const fetchRecordStreamsOperation = (query, isFiterd, isInsideModal = false, isReorderMode) => {
   return async (dispatch) => {
      let prefix = ''
      if(isFiterd) prefix = 'Filter';
      const state = {
         [`isFetchingRecord${ prefix }`]: true,
         isEmptyByFilterRecord: false,
         isEmptyByRecord: false,
      }
      dispatch(fetchIndexRequest(state))
      try {
         let resp

         if(isReorderMode) {
            const response = (await fetchLiveStreamRecordedStreamsForReorder(query?.filter(it => it?.includes('sortBy'))))?.data
            resp = {
               data: response,
            }
         } else {
            resp = (await fetchLiveStreamRecordedStream(query || ['page=5'], isInsideModal))?.data;
         }

         const state = {
            [`isFetchingRecord${ prefix }`]: false,
            [`isEmptyBy${ prefix }Record`]: resp?.data && resp.data.length === 0,
            recordedStreams: resp,
            isFetching: false,
            inited: true,
         }
         dispatch(fetchIndexRequest(state))
      } catch (error) {

      }
   }
}

export const recoredStreamsNextPageOperation = (query, isReorderMode) => {
   return async (dispatch) => {
      dispatch(fetchNextRecordsRequest(true))
      try {
         let resp

         if(isReorderMode) {
            const response = (await fetchLiveStreamRecordedStreamsForReorder(query?.filter(it => it?.includes('sortBy'))))?.data
            resp = {
               data: response,
            }
         } else {
            resp = (await fetchLiveStreamRecordedStream(query))?.data;
         }

         dispatch(fetchNextRecordsCompleted(resp))
      } catch (error) {
         dispatch(fetchNextRecordsRequest(false))
      }
   };
};
export const deleteRecordStreamOperation = (id, page, callBack) => {
   return async (dispatch) => {
      dispatch(setDeletButtonLoadingState(true))
      try {
         await removeVideoApi(id, '?video_type=livestream');
         if(page === 'list'){
            dispatch(updateCountStateAfterDelete(id, 'streams'));
         }
         dispatch(setDeletButtonLoadingState(false, id))
      } catch (error) {
         if(error.response.status === 409) {
            const warningModal = deleteVideoCallBack(error.response.data);
            dispatch(setWarningModal(warningModal));
         }
         dispatch(setDeletButtonLoadingState(false))
      }
   };
};
const deleteVideoCallBack = (data) => {
   let title = 'Content connected to bundle';
   let buttonText = `Edit bundle${ data?.multiple_bundles ? 's' : '' }`;
   let buttonIconName = 'go-to';
   let cancelText = 'Ok';
   let secondaryButtonClassName = '';

   if('paid_for_unlock_or_download' === data.reason){
      title = 'Content cannot be deleted';
      buttonText = 'Ok';
      buttonIconName = null;
      cancelText = 'Contact support';
      secondaryButtonClassName = 'intercom-content-cannot-be-deleted';
   }

   const warningModal = {
      isOpen: true,
      description: data.message,
      ids: data.bundle_ids,
      multiple_bundles: data.multiple_bundles,
      reason: data.reason,
      buttonText,
      title,
      buttonIconName,
      cancelText,
      secondaryButtonClassName,
      // onCloseModal,
   }
   return warningModal;
}
export const fetchLiveSteramScheduleDataOperation = (key, hasCurrentSession, callBack, isScheduleStream) => {
   return async (dispatch, getState) => {
      const { livestream } = getState();
      if(!livestream?.inited || 'scheduled' === key) {
         dispatch(fetchIndexRequest({
            isFetchingSchedule: true,
            updateErrorsData: null,
            inited: true,
         }));
      }
      try {
         const { data } = await fetchLiveStreamDetails(key);
         let livestreamData = { ...data };
         await dispatch(fetchResourcesOperation('video'));
         if('current' === key && hasCurrentSession) {
            // connect chat socket
            dispatch(fetchLiveStreamMessagesOperation());
            // dispatch(socketConnection());
            updateLiveStreamCurrentSessionState({ isShowBanner: false });
            await dispatch(fetchTipHistoryOperation())
         }
         const { resources: { resources } } = getState();
         let casts = data.casts || [];
         if(casts.length === 0 && !!resources?.cast_members) {
            const { cast_members } = resources;
            const admincasts = cast_members.find(cast => cast.is_admin);
            const adminId = admincasts;
            if(adminId){
               casts = [
                  admincasts.id,
               ]
               livestreamData.casts = casts;
               await updateLiveStreamDetails(key, { details: { casts } });
            }
         }
         dispatch(fetchIndexRequest({
            [`${ key }Data`]: { ...data, casts },
            isFetchingSchedule: isScheduleStream ? true : false,
         }));
         if(callBack) {
            callBack(livestreamData);
         }
      } catch (error) {
         dispatch(fetchIndexRequest({
            isFetchingSchedule: false,
         }));
      }
   };
};
export const saveLiveStreamDataOperation = (data, isGoBack, callBack, key, updateData) => {
   return async (dispatch) => {
      dispatch(fetchIndexRequest({
         isFetchingCreateSchedule: true,
         updateErrorsData: {},
      }));
      try {
         await updateLiveStreamDetails(key, data);
         dispatch(updateDataCompleted(key, updateData));
         if(isGoBack) {
            dispatch(push(Router.route('LIVE_STREAM').getCompiledPath()));
         }
         if(callBack) {
            callBack();
         }
         toast.success('Your changes have been saved');
      } catch (error) {
         if(error && error.response){
            if(error.response?.data?.scheduled_livestream_date) {
               toast.error(error.response.data.scheduled_livestream_date[0]);
            }
            if(error.response?.data?.access) {
               toast.error(error.response.data.access[0]);
            }
         }
         dispatch(fetchIndexRequest({
            isFetchingCreateSchedule: false,
            updateErrorsData: error?.response?.data || null,
         }));
      }
   };
};
export const updateTipActionOperation = (stateData, data, callBack) => {
   return async (dispatch) => {
      dispatch(fetchIndexRequest({
         isFetchingCreateSchedule: true,
         updateErrorsData: {},
      }));
      try {
         dispatch(updateDataCompleted('current', stateData));

         switch (data.action) {
            case 'add':
               const res = await createLiveStreamTipAction(data.data);
               dispatch(updateTipActionData({
                  data: res.data || {},
                  id: data.data?.id,
               }))
               break;
            case 'delete':
               await deleteLiveStreamTipAction(data.id);
               break;
            case 'update':
               await updateLiveStreamTipAction(data.data, data.id);
               break;
            case 'reorder':
               await reorderLiveStreamTipActions(data.data);
               break;
            default:
               await updateLiveStreamTipActionOthers(data.data, data.action);
               break;
         }
         if(callBack) {
            callBack();
         }
         toast.success('Your changes have been saved');
      } catch (error) {
         dispatch(fetchIndexRequest({
            isFetchingCreateSchedule: false,
            updateErrorsData: error?.response?.data || null,
         }));
      }
   };
};
export const startStreamOperation = (details, type, isValidate, errorCallback, callBack) => {
   return async (dispatch, getState) => {
      dispatch(fetchIndexRequest({
         isFetchingStartStream: true,
         updateErrorsData: null,
      }));
      try {
         const { data } = await liveStreamStartSessionApi({ type, details, is_validate: isValidate });
         if('current' === type) {
            if(!!isValidate){
               const value = details.audio_and_video_type;
               let modalType = 'start_stream'; //stream_other_software
               // let modalType = 'start_stream';
               // if('other_software' === value) {
               //    modalType = 'stream_other_software'
               // }
               dispatch(fetchIndexRequest({
                  isFetchingStartStream: false,
                  openStreamStateModal: { isOpen: true, type: modalType, streamType: value  },
               }));
               if(callBack) {
                  callBack(data);
               }
            } else {
               const { common: { siteInfo }, livestream: { currentData } } = getState()
               dispatch(socketConnection(siteInfo));
               if(window.adminLiveStreamSocket){
                  window.adminLiveStreamSocket.emit('admin-activity');
               }
               let modalState = {
                  isOpen: false, type: null,
               }
               if('other_software' === data.audio_and_video_type){
                  modalState = {
                     isOpen: true, type: 'stream_other_software', streamType: data.audio_and_video_type,
                  }
               }
               if(callBack) {
                  callBack(data);
               }
               dispatch(fetchIndexRequest({
                  isFetchingStartStream: false,
                  openStreamStateModal: modalState,
                  currentData: { ...currentData, session_start_time: data.session_start_time },
                  isFetchingSchedule: false,
               }));
               dispatch(fetchLiveStreamMessagesOperation());
               dispatch(updateLiveStreamCurrentSessionState({
                  isShowBanner: false,
                  data,
               }));
               await dispatch(fetchTipHistoryOperation())
            }
         }
         if('scheduled' === type){
            const { data } = await fetchLiveStreamDetails('current');
            dispatch(fetchIndexRequest({
               isFetchingStartStream: false,
               openStreamStateModal: { isOpen: false, type: null },
               currentData: data,
               isFetchingSchedule: false,
            }));
            dispatch(updateLiveStreamCurrentSessionState({
               isShowBanner: false,
               data,
            }));
            dispatch(push({
               pathname: Router.route('LIVE_STREAM_STUDIO').getCompiledPath(),
               search: '?schedule_stream=start',
            }));
         }
      } catch (error) {
         if(error && error.response){
            if(error.response?.data?.['details.scheduled_livestream_date']) {
               toast.error(error.response.data['details.scheduled_livestream_date'][0]);
            }
            if(error.response?.data?.['details.casts']) {
               toast.error(error.response.data['details.casts'][0]);
            }
            if(error.response?.data?.access) {
               toast.error(error.response.data.access[0]);
            }
            if(error.response?.data?.current_session_id) {
               toast.error(error.response.data.current_session_id[0]);
            } else {
               if(errorCallback) {
                  errorCallback()
               }
               toast.error('Please fix errors below')
            }
         }
         dispatch(fetchIndexRequest({
            isFetchingStartStream: false,
            updateErrorsData: error?.response?.data || null,
         }));
      }
   };
};
export const endStreamOperation = (bool, callback) => {
   return async (dispatch) => {
      dispatch(fetchIndexRequest({
         isFetchingEndStream: true,
      }));
      try {
         if(bool) {
            await liveStreamEndSessionApi();
         }
         // window.warningModalIsOpen = false;
         if(callback){
            callback()
         }
         if(window.adminLiveStreamSocket) window.adminLiveStreamSocket.disconnect();
         if(mediaRecorder.state === 'recording') {
            mediaRecorder.stop();
            // wsRef.current.close();
         }

         dispatch(fetchLiveStreamMessagesRequestCompleted({}));
         dispatch(fetchIndexRequest({
            isFetchingEndStream: false,
            openStreamStateModal: {
               isOpen: false,
               type: null,
            },
            tipHistory: {
               data: [],
            },
         }));
         dispatch(updateLiveStreamCurrentSessionState({
            isShowBanner: false,
            data: null,
         }));
         const { data } = await fetchLiveStreamDetails('current');
         dispatch(fetchIndexRequest({
            currentData: data,
         }));
      } catch (error) {
         dispatch(fetchIndexRequest({
            isFetchingEndStream: false,
         }));
      }
   };
};


export const fetchLiveStreamMessagesOperation = _ => {
   return async (dispatch) => {
      // fetchLiveStreamMessagesRequest,
      // fetchLiveStreamMessagesRequestCompleted
      dispatch(fetchLiveStreamMessagesRequest(true));
      try {

         const { data } =  await getLiveStreamMessages();
         dispatch(fetchLiveStreamMessagesRequestCompleted(data));
         // dispatch(fetchConversationMessagesCompleted({ live_stream: { ...data } }));

         // dispatch(setChatConversationAction(conversation))
         // dispatch(desktopChatDialogueCompleted(conversation, true))
      } catch (error) {
         dispatch(fetchLiveStreamMessagesRequest(false))
      }
   };
};

export const fetchNewMessagesOperation = (query = []) => {
   return async (dispatch) => {
      dispatch(fetchNewMessagesRequest());
      try {
         const { data } =  await getLiveStreamMessages(query);
         dispatch(fetchNewMessagesCompleted(data));
      } catch (error) {
         if(error.response) {
            dispatch(fetchNewMessagesFailed(error.response));
         }
      }
   };
};


export const fetchLikedMemberByMessageOperation = (query, id) => {
   return async (dispatch) => {
      dispatch(fetchLikedMemberByMessageRequest(!!query));
      try {
         const { data } =  await getLiveStreamMessageLikedMembers(query, id);
         dispatch(fetchLikedMemberByMessageCompleted({ data, isPagination: !!query }));
      } catch (error) {
         if(error.response) {
            dispatch(fetchLikedMemberByMessageFailed(error.response));
         }
      }
   };
};

export const startScheduleLivestreamOperation = (details, isValidate, callBack) => {
   return async (dispatch) => {
      if(isValidate) {
         await liveStreamStartSessionApi({ type: 'scheduled', details, is_validate: true });
         if(callBack) {
            callBack()
         }
      } else {

         dispatch(startStreamOperation(details, 'scheduled', isValidate));
      }
   };
};

export const socketConnection = siteInfo => {
   return async (dispatch) => {
      const socket = initLiveStreamSocket(siteInfo.site_name);
      socket.removeAllListeners('connect');
      socket.removeAllListeners('connect_error');
      socket.removeAllListeners('disconnect');
      socket.removeAllListeners('ready');
      socket.removeAllListeners('viewer-stats');
      socket.removeAllListeners('livestream:likes-update');

      socket.on('connect', (r) => {
         socket.emit('online')
      });
      socket.on('connect_error', (r) => {
         dispatch(setIsConnectedAction(false))
      });
      socket.on('disconnect', (res) => {
         dispatch(setIsConnectedAction(false))
      });
      socket.on('ready', () => {
         dispatch(setIsConnectedAction(true))
         socket.emit('admin-activity');
      });

      socket.on('livestream:update', (payload) => {
         // console.log(payload, ' LIVESTREAM -> livestream update data')
         const { data, initiator } = payload;
         if(data) {
            const {
               tip_actions,
               details,
               tip_goal,
            } = data;
            const currentData = {
               ...tip_actions,
               ...details,
               ...tip_goal,
            }
            if('tip' === initiator) {
               dispatch(updateCurrentData(currentData, initiator));
            }
         }

      });
      socket.emit('viewer-stats');
      socket.on('viewer-stats', (payload) => {
         const {
            users,
         } = payload;
         dispatch(setActiveViewerStates(users));
      });
      socket.on('livestream:likes-update', (data) => {
         dispatch(updateCurrentData(data))
      });
   };
};

export const deleteUpcomingStreamOperation = (query, id) => {
   return async (dispatch) => {
      dispatch(setDeleteUpcomingState({ isLoading: true }));
      try {
         const data = { scheduled_livestream_date: null };
         await deleteUpcomingStream('scheduled', data);
         dispatch(setDeleteUpcomingCompleted(data));
         dispatch(push(Router.route('LIVE_STREAM').getCompiledPath()));
      } catch (error) {
         dispatch(setDeleteUpcomingState({ isLoading: false }));
      }
   };
};

export const fetchTipHistoryOperation = (params = [], filter = {}, key) => {
   return async (dispatch, getState) => {
      let fetchingName = 'fetching';
      switch (key) {
         case 'sortBy':
            fetchingName = 'fetchingFilter';
            break;
         case 'cursor':
            fetchingName = 'fetchingScroll';
            break;
         default:
            break;
      }
      try {
         dispatch(setTipHistoryAction({ [fetchingName]: true }))
         const { data } = await getTipHistory(params);
         let history = data;
         if('cursor' === key){
            const { livestream: { tipHistory } } = getState();
            const oldData = tipHistory.data || [];
            history.data = [...oldData, ...data.data]
         }

         dispatch(setTipHistoryAction({ ...history, ...filter, [fetchingName]: false }));
      } catch (error) {
         dispatch(setTipHistoryAction({ [fetchingName]: false }));

      }

   };
};
export const checkValidationOperation = (data, callBack, key = 'saved') => {
   return async (dispatch) => {
      dispatch(fetchCheckValidationRequest(true));
      try {
         await livestreamCheckValidate({ ...data, type: key });
         if(callBack) {
            callBack();
         }
         dispatch(fetchCheckValidationRequest(false));
      } catch (error) {
         if(error.response) {
            if(error.response?.data?.['details.scheduled_livestream_date']) {
               toast.error(error.response.data['details.scheduled_livestream_date'][0]);
            }
            if(error.response?.data?.['details.tip_goal_title']) {
               toast.error(error.response.data['details.tip_goal_title'][0]);
            }
            if(error.response?.data?.access) {
               toast.error(error.response.data.access[0]);
            }
            dispatch(setValidationError(error.response?.data || {}));
         } else {
            dispatch(fetchCheckValidationRequest(false));
            toast.error('Something went wrong.');
         }
      }

   };
};
export const createLivestreamOperation = (data, errorCallback) => {
   return async (dispatch) => {
      dispatch(fetchCreateLivestreamRequest(true));
      try {
         const { details } = data;
         if(details?.notify_members_before_live?.isSwitch || details?.notify_members_about_scheduled_livestream?.isSwitch){
            const massMessageLimit =  await getMassMessageLimit();
            if(massMessageLimit?.data && massMessageLimit.data?.is_monthly_limit_reached){
               dispatch(setMassMessageLimit({ ...massMessageLimit.data, openModal: massMessageLimit.data?.is_monthly_limit_reached, type: `livestream_${ data.type }` }))
            }
         }

      } catch (error) {

      }
      try {
         await createLivestream(data);
         dispatch(fetchCreateLivestreamRequest(false));
         if('current' === data.type){
            dispatch(push({ pathname: Router.route('LIVE_STREAM_STUDIO').getCompiledPath(), search: '?steps=completed' }));
         }
         if('scheduled' === data.type){
            dispatch(push({ pathname: Router.route('LIVE_STREAM_SCHEDULE').getCompiledPath() }));
         }

      } catch (error) {
         if(error.response) {
            let tab = null;
            if(error.response?.data?.['details.scheduled_livestream_date']) {
               toast.error(error.response.data['details.scheduled_livestream_date'][0]);
            }
            if(error.response?.data?.access) {
               tab = 'stream_access'
               toast.error(error.response.data.access[0]);
            }
            if(error.response?.data?.['details.casts']) {
               toast.error(error.response.data['details.casts'][0]);
            }
            if(error.response?.data?.['details.title'] || error.response?.data?.['details.cover_image_src'] || error.response?.data?.['details.casts']) {
               tab = 'general'
            }
            if(errorCallback){
               errorCallback(tab)
            }
            dispatch(setValidationError(error.response?.data || {}));
         } else {
            dispatch(fetchCreateLivestreamRequest(false));
            toast.error('Something went wrong.');
         }
      }

   };
};

export function pinRecordedStreamOperations(id, bool){
   return async dispatch => {
      try {
         await updateVideo(id, { 'is_pinned': !bool }, '?video_type=livestream');
         toast.success(`Recorded stream has been ${ bool ? `unpinned` : `pinned` } `)
         dispatch(pinRecordedStreamCompleted({ id, bool }));
      } catch (error) {
      }
   }
}
