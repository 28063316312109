// import Icon from 'common/components/elements/icons';

import PropTypes from 'prop-types';

const Avatar = ({
   className = '',
   size,
   avatarSrc = '',
   username,
   firstLetterClassName = '',
   imageClassName = '',
   styles = {},
   onClick,
   isNextApp,
}) => {
   const getColor = () => {
      let gradients = [
         '#27374D',
         '#526D82',
         '#9DB2BF',
         '#DDE6ED',
         '#11009E',
         '#4942E4',
         '#C4B0FF',
         '#8696FE',
         '#C0EEE4',
         '#F8F988',
         '#FFCAC8',
         '#FF9E9E',
         '#39B5E0',
         '#A31ACB',
         '#FF78F0',
         '#F5EA5A',
      ]
      if(Boolean(username)) {
         let charCodeAmount = 0
         for(let i = 0; i < username.length; i++) {
            charCodeAmount += username.charCodeAt(i)
         }
         const randomIndex = charCodeAmount % gradients.length
         return gradients[randomIndex]
      }
   }
   const isDefaultAvatar = !Boolean(avatarSrc) || avatarSrc.includes('default-member-logo') || avatarSrc.includes('97dfb0a57d96ed6b663099fb97e77763')
   const getStyles = () => {
      if(!isDefaultAvatar){
         return {}
      }
      return {
         background: Boolean(username) ? getColor() : '#9BA1A9',
         width: Boolean(size) ? size : '100%',
         height: Boolean(size) ? size : '100%',
      }
   }
   if(!isDefaultAvatar) {
      return (
         <img
            src={ avatarSrc }
            alt='avatar_src'
            className={ `${ imageClassName } object-cover object-center rounded-full h-full w-full` }
            style={ {
               width: Boolean(size) ? size : '100%',
               height: Boolean(size) ? size : '100%',
               ...styles,
            } }
            role='presentation'
            onClick={ onClick }
         />
      )
   }

   let firstLetter = String(username)[0].toUpperCase()

   const getFirstEmoji = (str) => {
      const emojiRegex = /^([\p{Emoji_Presentation}\p{Emoji}\p{Emoji_Component}])/u;
      const match = str?.match(emojiRegex);
      return match ? match['input']?.length > match?.[1]?.length ? match[1] : match['input']  : null;
   }

   if(!!getFirstEmoji(username)) {
      firstLetter = getFirstEmoji(username)
   }

   return (
      <div
         className={ `${ className } flex rounded-full items-center justify-center relative w-full h-full` }
         style={ {
            ...getStyles(),
            fontSize: '1em',
            lineHeight: '1px',
            ...styles,
         } }
         role='presentation'
         onClick={ onClick }
      >
         {
            Boolean(username) ? (
               <span className={ `${ firstLetterClassName } font-medium select-none text-major-dark` }>{ firstLetter }</span>
            ) :
            // (
               <>
                  <img
                     src={ avatarSrc }
                     isNextApp={ isNextApp }
                     alt='Avatar of the user'
                     className={ `${ imageClassName } object-cover object-center rounded-full h-full w-full` }
                     style={ {
                        width: Boolean(size) ? size : '100%',
                        height: Boolean(size) ? size : '100%',
                        ...styles,
                     } }
                  />
               </>
               // <Icon
               //    name='group'
               //    size='xl'
               //    style={ {
               //       color: '#FFFFFF',
               //    } }
               // />
            // )
         }
      </div>
   )
}


Avatar.propTypes = {
   className: PropTypes.string,
   size: PropTypes.string,
   avatarSrc: PropTypes.string,
   username: PropTypes.string,
   firstLetterClassName: PropTypes.string,
   imageClassName: PropTypes.string,
   styles: PropTypes.object,
   onClick: PropTypes.func,
   isNextApp: PropTypes.bool,
}

export default Avatar;
