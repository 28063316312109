import * as actions from "./actions";
import {
   getVault,
   deleteVaultFile,
   updateVault,
} from 'admin/api/AuthApi'
import toast from "common/utils/toast";
import { uploadingVideo } from 'admin/utils/VaultVideoUpload';

// delete vault
export function deleteVault(id, type, callback) {
   return async dispatch => {
      try {
         await deleteVaultFile(id)
         toast.remove(`${ type } has been deleted`);
         if(callback) {
            callback(null, 'succeed')
         }
      } catch (error) {
         if(callback) {
            if(error.response.status === 409) {
               callback(deleteVautCallBack(error.response.data, 'failed'))
            }
         } else {
            dispatch(actions.deleteFailed(error));
         }
      }
   }
}

// Images operation
export function fetchVaultImagesOperation(params, isFiltered = false) {
   return async dispatch => {
      dispatch(actions.fetchImagesRequest());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchImagesRequestCompleted(data, isFiltered))
      } catch (error) {
         dispatch(actions.fetchImagesRequestFailed(error))
      }
   }
}

export function getFilterImagesByOperation(params) {
   return async dispatch => {
      dispatch(actions.fetchImagesByFilterRequest());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchImagesByFilterCompleted(data))
      } catch (error) {
         if(error.code !== 'ERR_CANCELED') {
            dispatch(actions.fetchImagesByFilterFailed(error))
         }
      }
   }
}

export function getNewVaultImagesOperation(params) {
   return async dispatch => {
      dispatch(actions.fetchNewImagesRequest());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchNewImagesRequestCompleted(data))
      } catch (error) {
         dispatch(actions.fetchNewImagesRequestFailed(error))
      }
   }
}


// Videos operation
export function fetchVaultVideosOperation(params, isInsideModal = false, isFiltered = false) {
   return async dispatch => {
      dispatch(actions.fetchVideosRequest());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchVideosRequestCompleted(data, isInsideModal, isFiltered))
      } catch (error) {
         dispatch(actions.fetchVideosRequestFailed(error))
      }
   }
}

export function getFilterVideosByOperation(params) {
   return async dispatch => {
      dispatch(actions.fetchVideosByFilterRequest());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchVideosByFilterCompleted(data))
      } catch (error) {
         if(error.code !== 'ERR_CANCELED') {
            dispatch(actions.fetchVideosByFilterFailed(error))
         }
      }
   }
}

export function getNewVaultVideosOperation(params) {
   return async dispatch => {
      dispatch(actions.fetchNewVideosRequest());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchNewVideosRequestCompleted(data))
      } catch (error) {
         dispatch(actions.fetchNewVideosRequestFailed(error))
      }
   }
}

export const startUploadOperation = (files) => {
   return (dispatch, getState) => {
      uploadingVideo(files, (id, data) => {
         if(null === id) {
            dispatch(actions.addVideos(data));
         } else {
            dispatch(actions.updateVideo(id, data));
         }
      }, _ => getState().vault.vaultVideosData.data, () => dispatch(startUploadOperation()))
   }
}

export const cancelUploadOperation = (id, status, callback) => {
   return async dispatch => {
      if(status !== 'uploading' && status !== 'pending'){
         await deleteVaultFile(id)
            .then(res => {
               dispatch(actions.removeFile(id));
               if(callback) callback()
            })
      } else {
         dispatch(actions.removeFile(id))
         if(callback) callback()
      }
   }
}

// All vault operation

export function fetchAllVaultOperation(params) {
   return async dispatch => {
      dispatch(actions.fetchAllVaultRequest());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchAllVaultRequestCompleted(data, true))
      } catch (error) {
         dispatch(actions.fetchAllVaultRequestFailed(error))
      }
   }
}

export function getFilterAllVaultByOperation(params) {
   return async dispatch => {
      dispatch(actions.fetchAllVaultByFilterRequest());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchAllVaultByFilterCompleted(data))
      } catch (error) {
         dispatch(actions.fetchAllVaultByFilterFailed(error))
      }
   }
}

export function getNewVaultsOperation(params) {
   return async dispatch => {
      dispatch(actions.fetchNewVaultsStart());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchNewVaultsCompleted(data))
      } catch (error) {
         dispatch(actions.fetchNewVaultsFail())
      }
   }
}


export function updateVaultByIdOperation(id, type, data, callback){
   return async () => {
      try {
         await updateVault(id, { data })
         if(callback)callback('succeed', data)
      } catch (error) {
         if(callback)callback('failed', error.response.data)
      }
   }
}


const deleteVautCallBack = (data) => {
   let title = 'Content connected to bundle';
   let buttonText = `Edit bundle${ data?.multiple_bundles ? 's' : '' }`;
   let buttonIconName = 'go-to';
   let cancelText = 'Ok';
   let secondaryButtonClassName = '';

   if(['sent_in_chat', 'paid_for_unlock_or_download'].includes(data.reason)){
      title = 'Content cannot be deleted';
      buttonText = 'Ok';
      buttonIconName = null;
      cancelText = 'Contact support';
      secondaryButtonClassName = 'intercom-content-cannot-be-deleted';
   }

   const warningModal = {
      isOpen: true,
      description: data.message,
      ids: data.bundle_ids,
      multiple_bundles: data.multiple_bundles,
      reason: data.reason,
      buttonText,
      title,
      buttonIconName,
      cancelText,
      secondaryButtonClassName,
   }
   return warningModal;
}
