import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import Icon from 'common/components/elements/icons';
import './index.scss'
import cx from 'classnames';

const EmptyState = ({
   heightIsFit,
   type,
   result,
   icon,
   noContentText,
   buttonText,
   className,
   linkButtonText,
   onAdd,
   onLinkClick,
   iconColor,
   linkButtonIcon,
   secondButtonText,
   secondButtonIcon,
   mainButtonIcon,
   iconSize,
   onActionClick,
   moreInfoClass,
   buttonisLoading,
   secondButtonisLoading,
   children,
   customMainButtonIcon,
   noContentTextColor,
   newImageSvg,
   mainButtonText,
   // mainButtonClass,
   onMainButtonClick,
   svgClassName,
   hideIcon,
   noResultsClassName,
   titleContentText,
   titleContentTextColor,
   buttonHeight,
   noContentTextClassName,
   linkButtonIconSize,
   mainButtonClassName,
   insideModal,
   emptyStateModalText,
   mainButtonDisabled,
   mainButtonTooltipContent,
   secondButtonClassName,
   iconClass,
   buttonBgColor,
   buttonIconSize,
   titleContentTextClassName,
   thirdButtonText,
   thirdButtonTextAction,
   iconFontSize,
   dontAddIntercomClassName,
   buttonDisabled,
   mainButtonTextMarginX,
   mainButtonClass,
   overrideLinkButtonProps,
   linkButtonWrapperClassName,
}) => {

   const generateIntercomClassName = () => {
      if(!linkButtonText || dontAddIntercomClassName) {
         return ''
      }
      return 'intercom-' + linkButtonText.toLowerCase().split(' ').join('-')
   }

   return (
      <Fragment>
         {
            type !== 'no-content' && (
               <div className={ `h-full w-full flex flex-col items-center justify-center  select-none ${ className }` }>
                  {
                     !insideModal && (hideIcon || <div className='h-8 w-8 flex items-center justify-center'>
                        <Icon name='search' color='placeholder' darkColor='placeholder-dark' style={ { fontSize: '32px' } } />
                     </div>)
                  }
                  {
                     (type === 'search' || (result && result.length > 0)) && !insideModal ? (
                        <>
                           {
                              !!newImageSvg && <img src={ newImageSvg  } alt='empty-state' className={ `h-auto w-auto mt-[24px] sm:mt-[45px] ${ svgClassName }` } />
                           }
                           <div className={ `leading-tight w-full block text-secondary dark:text-secondary-dark  m-4 text-center ${ noResultsClassName }` }>
                              {/* <span className='text-placeholder dark:text-placeholder-dark'>No search results</span> */}
                              <span className='text-placeholder dark:text-placeholder-dark'>No results</span>
                           </div>
                        </>
                     ) : (type === 'search' || (result && result.length > 0)) && insideModal ?
                        <div className='flex flex-col items-center gap-[18px]'>
                           <img src={ newImageSvg } alt='' className={ svgClassName } />
                           <div className='flex flex-col items-center gap-1 text-secondary dark:text-secondary-dark '>
                              <p className='text-base font-medium'>{emptyStateModalText?.primary}</p>
                              <p className='text-sm font-normal'>{emptyStateModalText?.secondary}</p>
                           </div>
                        </div>
                        : (
                           <>
                              {
                                 !!newImageSvg && <img src={ newImageSvg  } alt='empty-state' className={ `h-auto w-auto mt-[24px] sm:mt-[45px] ${ svgClassName }` } />
                              }
                              <span className={ `text-placeholder dark:text-placeholder-dark block mt-3 ${ noResultsClassName }` }>No results</span>
                           </>
                        )
                  }
               </div>
            )
         }

         {
            type === 'no-content' && (
               <div className={ `${ heightIsFit ? 'h-fit' : 'h-full' } w-full flex flex-col items-center justify-${ newImageSvg ? 'start' : 'center' } ${ className } px-4` }>
                  {
                     !!icon && (
                        <div className={ `h-10 w-10 flex items-center justify-center empty-icon  ${ iconClass }` }>
                           <Icon size={ iconSize } color={ iconColor } name={ icon } style={ { fontSize: iconFontSize } } />
                        </div>
                     )
                  }
                  {
                     !!newImageSvg && <img src={ newImageSvg  } alt='empty-state' className={ `h-auto w-auto mt-[138px] sm:mt-[262px] ${ svgClassName }` } />
                  }
                  <span className={ `leading-6 text-${ noContentTextColor ? noContentTextColor : 'secondary dark:text-secondary-dark' } mt-4 empty-text text-center font-medium ${ noContentTextClassName }` }>{noContentText}</span>
                  {
                     titleContentText && (
                        <span className={ `leading-5 text-${ titleContentTextColor ? titleContentTextColor : 'secondary dark:text-secondary-dark ' } mt-1 text-sm text-center font-normal ${ titleContentTextClassName }` }>{titleContentText}</span>
                     )
                  }
                  {
                     !!buttonText && (
                        <div className={ `${ buttonHeight } mt-6 primary-button-content ` }>
                           <Button
                              text={ buttonText }
                              iconName={ mainButtonIcon }
                              padding='none'
                              classNames='h-full px-[18px] py-2.5 gap-2'
                              textMarginX='0'
                              borderRadius='large'
                              iconPosition='left'
                              backgroundColor={ buttonBgColor }
                              textSize='base'
                              onClick={ onAdd }
                              fontWeight='medium'
                              fontIconSize={ buttonIconSize }
                              lineHeight='!leading-6'
                              primaryColor
                              isLoading={ buttonisLoading }
                              disabled={ buttonDisabled }
                              dataToolTipContent={ buttonDisabled ? 'Not available in demo' : '' }
                           />
                        </div>
                     )
                  }
                  {
                     !!thirdButtonText && (
                        <Button
                           text={ thirdButtonText }
                           classNames=' h-11 mt-3 border border-divider whitespace-nowrap px-5 py-2.5 !w-[fit-content] intercom-create-a-bundle'
                           textColor='major'
                           fontIconColor='secondary'
                           fontIconSize='extraLarge'
                           textSize='base'
                           backgroundColor='panel'
                           iconName='bundle'
                           iconPosition='left'
                           borderRadius='large'
                           onClick={ thirdButtonTextAction }
                           textClassName='ml-2'
                           textMarginX='0'
                           darkIconColor='secodary-dark'
                        />
                     )
                  }
                  {children && children}
                  {
                     !!mainButtonText && (
                        // <div className={ `h-11 my-6 primary-button-content ${ mainButtonClass }` }>
                        <div
                           className={ `h-11 ${ mainButtonClassName } primary-button-content` }
                           data-tooltip-id={ mainButtonTooltipContent ? 'ams-top-tooltip' : '' }
                           data-tooltip-content={ Boolean(mainButtonTooltipContent) ? mainButtonTooltipContent : '' }
                        >
                           <Button
                              text={ mainButtonText }
                              padding='none'
                              borderRadius='large'
                              onClick={ onMainButtonClick }
                              primaryColor={ !mainButtonDisabled }
                              // isLoading={ buttonisLoading }
                              iconName={ `${ customMainButtonIcon ? customMainButtonIcon : 'add' }` }
                              iconPosition='left'
                              textMarginX={ mainButtonTextMarginX }
                              textSize={ 'base' }
                              fontIconSize={ 'extraLarge' }
                              fontWeight='medium'
                              classNames={ cx({
                                 'h-11 gap-2 py-[10px] px-[18px]': true,
                                 'skin-primary-border border': !mainButtonDisabled,
                                 [`${ mainButtonClass }`]: mainButtonClass,
                              }) }
                              lineHeight='leading-6'
                              disabled={ mainButtonDisabled }
                              backgroundColor={ mainButtonDisabled ? 'disabled dark:bg-disabled-dark' : null }
                           />
                        </div>
                     )
                  }
                  {
                     !!linkButtonText && (
                        <div className={ `h-9 mt-${ !!mainButtonText ? '0' : '3' } py-2 px-[14px] empty-more-info ${ linkButtonWrapperClassName }` }>
                           <Button
                              padding='none'
                              text={ linkButtonText }
                              backgroundColor='transparent'
                              primaryColor
                              fontIconSize={ linkButtonIconSize }
                              textSize='small'
                              iconName={ linkButtonIcon }
                              iconPosition='left'
                              onClick={ onLinkClick }
                              classNames={ `${ moreInfoClass } ${ generateIntercomClassName() }` }
                              lineHeight={ 'leading-5' }
                              { ...overrideLinkButtonProps }
                           />
                        </div>
                     )
                  }
                  {
                     !!secondButtonText && (
                        <div className='h-9 mt-3'>
                           <Button
                              text={ secondButtonText }
                              iconName={ secondButtonIcon }
                              padding='none'
                              classNames={ `h-full px-3 ${ secondButtonClassName }` }
                              borderRadius='label'
                              iconPosition='left'
                              textSize='small'
                              onClick={ onActionClick }
                              fontIconSize='base'
                              primaryColor
                              backgroundColor='transparent'
                              isLoading={ secondButtonisLoading }
                              lineHeight='leading-5'
                           />
                        </div>
                     )
                  }
               </div>
            )
         }

      </Fragment>
   );
};

EmptyState.defaultProps = {
   heightIsFit: false,
   type: 'no-content',
   iconColor: 'secondary dark:text-secondary-dark ',
   linkButtonIcon: 'settings',
   secondButtonIcon: 'settings',
   mainButtonIcon: 'add',
   iconSize: '5xl',
   className: '',
   moreInfoClass: '',
   buttonisLoading: false,
   secondButtonisLoading: false,
   // mainButtonClass: '',
   noContentTextClassName: '',
   linkButtonIconSize: 'base',
   mainButtonClassName: 'my-6',
   insideModal: false,
   emptyStateModalText: {
      primary: '',
      secondary: '',
   },
   mainButtonDisabled: false,
   iconClass: '',
   buttonBgColor: '',
   buttonIconSize: 'base',
   titleContentTextClassName: '',
   buttonHeight: 'h-9',
   iconFontSize: '40px',
   dontAddIntercomClassName: false,
   mainButtonTextMarginX: '1',
   overrideLinkButtonProps: {},
   linkButtonWrapperClassName: '',
};

EmptyState.propTypes = {
   customMainButtonIcon: PropTypes.string,
   heightIsFit: PropTypes.bool,
   type: PropTypes.string,
   result: PropTypes.string,
   icon: PropTypes.string,
   noContentText: PropTypes.string,
   buttonText: PropTypes.string,
   className: PropTypes.string,
   linkButtonText: PropTypes.string,
   onLinkClick: PropTypes.func,
   onAdd: PropTypes.func,
   iconColor: PropTypes.string,
   iconSize: PropTypes.string,
   linkButtonIcon: PropTypes.string,
   secondButtonText: PropTypes.string,
   secondButtonIcon: PropTypes.string,
   iconFontSize: PropTypes.string,
   mainButtonIcon: PropTypes.string,
   // mainButtonClass: PropTypes.string,
   onActionClick: PropTypes.func,
   moreInfoClass: PropTypes.string,
   buttonisLoading: PropTypes.bool,
   secondButtonisLoading: PropTypes.bool,
   children: PropTypes.any,
   noContentTextColor: PropTypes.string,
   newImageSvg: PropTypes.any,
   mainButtonText: PropTypes.string,
   onMainButtonClick: PropTypes.func,
   svgClassName: PropTypes.string,
   noResultsClassName: PropTypes.string,
   hideIcon: PropTypes.bool,
   titleContentText: PropTypes.string,
   titleContentTextColor: PropTypes.string,
   buttonHeight: PropTypes.bool,
   noContentTextClassName: PropTypes.string,
   linkButtonIconSize: PropTypes.string,
   mainButtonClassName: PropTypes.string,
   insideModal: PropTypes.bool,
   emptyStateModalText: PropTypes.object,
   mainButtonDisabled: PropTypes.bool,
   mainButtonTooltipContent: PropTypes.string,
   secondButtonClassName: PropTypes.string,
   iconClass: PropTypes.string,
   buttonBgColor: PropTypes.string,
   buttonIconSize: PropTypes.string,
   titleContentTextClassName: PropTypes.string,
   thirdButtonText: PropTypes.string,
   thirdButtonTextAction: PropTypes.func,
   dontAddIntercomClassName: PropTypes.bool,
   buttonDisabled: PropTypes.bool,
   mainButtonTextMarginX: PropTypes.string,
   mainButtonClass: PropTypes.string,
   overrideLinkButtonProps: PropTypes.object,
   linkButtonWrapperClassName: PropTypes.string,
};

export default memo(EmptyState);
