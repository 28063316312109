import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from 'common/components/elements/icons';
import TruncateText from 'common/components/elements/truncate-text';

const SelectInputItem = ({
   data,
   borderColor,
   lastItem,
   onClick,
   textKeyName,
   isDefault,
   defaultLabel,
   height,
   onClickDefault,
   iconName,
}) => {
   const name = !!data[textKeyName] ? data[textKeyName] : ''
   return (
      <div
         style={ { maxWidth: '200px' } }
         className={ cx({
            [`border-${ borderColor } border hover:bg-hover dark:bg-hover-dark rounded-full h-${ height } px-3 py-2.5 flex items-center justify-center  bg-panel dark:bg-panel-dark cursor-pointer`]: true,
            // 'mr-3': !lastItem && !isDefault,
         }) }
      >
         { isDefault ? (
            <div
               role='presentation'
               className='flex justify-center items-center relative'
               onClick={ onClickDefault }
            >
               <div className='flex justify-center bg-secondary w-7 h-7 my-1 rounded-full items-center mr-2'>
                  <Icon name={ iconName } className='text-major-dark' />
               </div>
               <span
                  className='line text-major dark:text-major-dark'
                  style={ {
                     lineHeight: 'initial',
                  } }
               >
                  { defaultLabel }
               </span>
            </div>
         ) : (
            <>
               <TruncateText
                  text={ name }
                  textClass='text-sm text-major dark:text-major-dark'
                  textSize='14px'
                  fontWeight='400'
                  width='calc(100% - 24px)'
               />

               {/* TODO css-to-tailwind (fill not working) */}
               <div
                  role='presentation'
                  className='relative flex items-center justify-center ml-2'
                  onClick={ onClick }
                  data-tooltip-content='Remove'
                  data-tooltip-id='ams-top-tooltip'
               >
                  <Icon name='cancel' color='secondary' darkColor='secondary-dark' size='base' />
               </div>
            </>
         ) }
      </div>
   );
};

SelectInputItem.defaultProps = {
   borderColor: 'error',
   textKeyName: 'title',
   iconFill: '#717D88',
   isDefault: false,
   height: 9,
   iconName: 'add',
   data: {},
};

SelectInputItem.propTypes = {
   data: PropTypes.any,
   borderColor: PropTypes.string,
   lastItem: PropTypes.bool,
   onClick: PropTypes.func,
   textKeyName: PropTypes.string,
   isDefault: PropTypes.bool,
   defaultLabel: PropTypes.string,
   height: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
   ]),
   onClickDefault: PropTypes.func,
   iconName: PropTypes.string,
};

export default memo(SelectInputItem);
