import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'common/components/elements/buttons/primary';
import UpcomingLiveStream from '../upcoming-llivestream';
import './style.scss'
import StreamVideo from 'common/components/modules/live-stream/video'
import LiveLabels from 'common/components/modules/live-stream/live-lables'
import AddCalendarButton from 'common/components/modules/add-calendar';
import Draggable from './draggable.js';
import { filterBadWords } from 'common/utils/filter-bad-words';

const NotifiactionCard = ({
   data,
   isAdmin,
   onCloseAction,
   link,
   show,
   goToGetMembership,
   onStreamEnd,
   isEnded,
   isImageBluredForSfw,
   isVideoBluredForSfw,
   isAgeVerificationRequired,
}) => {
   let pressed = false;
   const [linkIsPreventDefault, setLinkIsPreventDefault] = useState(false);
   // const [isEnded, setIsEnded] = useState(false);
   return (
      <Draggable
         className={ `${ show ? 'livestream-notificationcard-shnow' : ' hidden' }` }
         style={ {
            // transition: show ? 'all 2s linear' : 'none',
            // display: show ? 'block' : 'none',
            // opacity: show ? '1' : '0',
            left: isAdmin ? '40px' : 0,
         } }
         onMouseUp={ () => {
            if(!linkIsPreventDefault && pressed){
               setLinkIsPreventDefault(true)
               pressed = false;
            }
         } }
         onMouseMove={ ()  => {
            if(!pressed) pressed = true;
         } }
         onClick={ () => {
            if(!pressed && linkIsPreventDefault){
               setLinkIsPreventDefault(false)
            }
         } }
      >
         <Link
            onClick={ (event) => {
               if(linkIsPreventDefault){
                  event.preventDefault();
               }
            } }
            className=''
            to={ link }
         >
            <div
               className='h-6 w-6 flex items-center justify-center m-2 absolute right-0 top-0 z-10'>
               <Button
                  backgroundColor='transparent'
                  iconName='close'
                  fontIconColor='major-dark'
                  textColor='major-dark'
                  classNames='w-full h-full'
                  padding='none'
                  onClick={ (e) => {
                     e.preventDefault();
                     // e.stopPropagation()
                     onCloseAction();
                  } }
               />
            </div>
            {
               data.is_live_stream_session_active && (
                  <div
                     className='flex flex-col w-auto relative overflow-hidden rounded-t-lg min-h-full h-48'>
                     <div className='absolute top-0 z-2 p-4 flex flex-col'>
                        <LiveLabels
                           viewsCount={ 0 }
                           isStreaming={ show }
                           showViewsCount={ false }
                           startTime={ data.session_start_time }
                           streamEnded={ isEnded }
                        />
                     </div>

                     {
                        isAdmin && (
                           <>
                              <canvas id='live-stream-banner-canvas' className='hidden' />
                              {
                                 'stream_with_ams' === data.audio_and_video_type && (
                                    <ImageContent
                                       fullSrc={ data.cover_full_src }
                                       isImageBluredForSfw={ isImageBluredForSfw } // No need for admin
                                    />
                                 )
                              }
                           </>
                        )
                     }
                     {
                        'unlocked' !== data.stream_access_type && !isAdmin && (
                           <ImageContent
                              fullSrc={ data.cover_full_src }
                              isImageBluredForSfw={ isImageBluredForSfw }
                           />
                        )
                     }
                     {
                        ('unlocked' === data.stream_access_type || (isAdmin && 'stream_with_ams' !== data.audio_and_video_type && data.is_live_stream_session_active)) && (
                           <StreamVideo
                              file={ data.stream_url }
                              goTo={ () => {} }
                              cover={ data.cover_full_src }
                              handleError={ () => {
                                 // setIsEnded(true);
                                 if(onStreamEnd){
                                    onStreamEnd()
                                 }
                              } }
                              className='w-full h-full'
                              id='mini-videojs'
                              muted={ true }
                              controlBar={ false }
                              isImageBluredForSfw={ isImageBluredForSfw }
                              isVideoBluredForSfw={ isVideoBluredForSfw }

                           />
                        )
                     }
                  </div>
               )
            }
            {
               data.scheduled_livestream_date  && !data.is_live_stream_session_active && (
                  <ImageContent
                     fullSrc={ data.cover_full_src }
                     isImageBluredForSfw={ isImageBluredForSfw }
                  >

                     <UpcomingLiveStream date={ data.scheduled_livestream_date } />
                  </ImageContent>
               )
            }
         </Link>
         <div className='flex flex-col p-4'>
            <span className='text-xl leading-tight text-major dark:text-major-dark'>{ isAgeVerificationRequired ?  filterBadWords(data.title) : data.title}</span>
            {
               !isAdmin &&  data.scheduled_livestream_date  && !data.is_live_stream_session_active  && (
                  <>
                     {
                        'not_available' !== data.stream_access_type && (
                           <AddCalendarButton
                              title={ data.title }
                              description={ data.description }
                              date={ data.scheduled_livestream_date }
                              className='h-12 inline-flex live-stream-media-stream-get-membership mt-9 w-full'
                              buttonState={
                                 {
                                    classNames: 'h-full w-full',
                                 }
                              }
                           />

                        )
                     }
                     {
                        'not_available' === data.stream_access_type && (
                           <div className={ `h-12 inline-flex live-stream-media-stream-get-membership mt-9 w-full` }>
                              <Button
                                 text='Get membership'
                                 iconName='membership'
                                 borderRadius='large'
                                 iconPosition='left'
                                 fontWeight='bold'
                                 primaryColor
                                 padding='none'
                                 classNames='border-2 skin-primary-border h-full px-3 stream-join-button inline-flex'
                                 onClick={ goToGetMembership }
                              />
                           </div>
                        )
                     }
                  </>
               )
            }
            {
               !isAdmin && data.is_live_stream_session_active && (
                  <Link
                     className=''
                     to={ link }
                  >
                     <div className={ `h-12 inline-flex live-stream-media-stream-get-membership mt-9 w-full` }>
                        <Button
                           text='Join livestream'
                           iconName='online-stream'
                           borderRadius='large'
                           iconPosition='left'
                           fontWeight='bold'
                           primaryColor
                           padding='none'
                           classNames='border-2 skin-primary-border h-full px-3 stream-join-button inline-flex'
                           onClick={ () => {} }
                        />
                     </div>
                  </Link>
               )
            }
         </div>
      </Draggable>
   )
}

const ImageContent = ({ fullSrc, children, isImageBluredForSfw }) => {
   return (
      <div
         style={ {
            borderRadius: '8px 8px 0px 0px',
         } }
         className='z-1 relative h-48 flex items-end overflow-hidden w-full'>
         <div
            style={ {
               backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 27.37%), url(${ fullSrc })`,
               borderRadius: '8px 8px 0px 0px',
               filter: 'blur(24px)',
            } }
            className='z-1 absolute top-0 left-0 h-full w-full bg-no-repeat bg-center bg-cover flex items-end'>
         </div>
         <img
            alt=''
            src={ fullSrc }
            className='absolute top-0 left-0 object-contain object-center h-full w-full z-2'
            style={ {
               filter: isImageBluredForSfw ? 'blur(32px)' : '',
            } }
         />
         {
            children && children
         }
      </div>
   )
}


NotifiactionCard.propTypes = {
   data: PropTypes.object,
   isAdmin: PropTypes.bool,
   onCloseAction: PropTypes.func,
   goToGetMembership: PropTypes.func,
   link: PropTypes.string,
   show: PropTypes.bool,
   onStreamEnd: PropTypes.func,
   isEnded: PropTypes.bool,
   isImageBluredForSfw: PropTypes.bool,
   isVideoBluredForSfw: PropTypes.bool,
   isAgeVerificationRequired: PropTypes.bool,
};

ImageContent.propTypes = {
   fullSrc: PropTypes.string,
   children: PropTypes.any,
   isImageBluredForSfw: PropTypes.bool,
};

export default NotifiactionCard
