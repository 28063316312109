import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './style.css';

const Switch = ({
   className,
   disabled,
   togglePosition,
   checked,
   label,
   name,
   onChange,
   noHorizontalMargin,
   isBlackandWhite,
   ButtonClassName,
   type,
   hasPointerCursor,
   primaryColor,
   dataTooltipContent,
   labelTextClassName,
   labelClassName,
   switchWrapperClassName,
   switchTooltipText,
   ...rest
}) => {
   return (
      <div
         className={ cx(
            ` flex justify-between items-center ${ !noHorizontalMargin && 'mx-1' } select-none ${ className }`,
            `flex-${ togglePosition === 'right' ? 'row-reverse' : 'row' }`
         ) }
         data-tooltip-content={ dataTooltipContent }
         data-tooltip-id='ams-top-tooltip'
      >
         <label htmlFor={ name }
            className={
               cx({
                  'block relative switch': true,
                  'cursor-pointer': !disabled && hasPointerCursor,
                  [`${ switchWrapperClassName }`]: Boolean(switchWrapperClassName),
               })
            }
            data-tooltip-id={ switchTooltipText ? 'ams-top-tooltip' : null }
            data-tooltip-content={ switchTooltipText ? switchTooltipText : null }
         >
            <input
               { ...rest }
               id={ name }
               type='checkbox'
               className='hidden'
               disabled={ disabled }
               value={ checked }
               checked={ checked }
               onChange={ (e) => { onChange(name, e.target.checked); } }
            />
            <span
               className={
                  cx({
                     [`absolute inset-0 switch-slider`]: true,
                     'disabled bg-disabled dark:bg-disabled-dark': disabled,
                     'bg-hover dark:bg-hover-dark': checked && !disabled,
                     'bg-grey-main dark:bg-black-field': !checked && !disabled,
                     'shadow-inner': !checked || disabled,
                  })
               }
            />
            <span
               className={
                  cx({
                     [`switch-circle`]: true,
                     'bg-grey-hover dark:bg-grey-hover-dark': disabled,
                     'skin-primary-background': checked && !disabled,
                     'bg-panel dark:bg-panel-dark': !checked && !disabled,
                  })
               }
            ></span>
         </label>
         {label && (
            <label
               htmlFor={ name }
               className={ cx({
                  [`${ labelClassName }`]: Boolean(labelClassName),
                  'mx-1': !noHorizontalMargin,
               }) }
            >
               <span className={ cx({
                  'text-disabled dark:text-disabled-dark': disabled,
                  'text-major dark:text-major-dark': !disabled,
                  [`${ labelTextClassName }`]: Boolean(labelTextClassName),
               }) }
               >
                  {label}
               </span>
            </label>
         )}
      </div>
   );
};

Switch.defaultProps = {
   className: '',
   togglePosition: 'left',
   noHorizontalMargin: false,
   isBlackandWhite: false,
   hasPointerCursor: true,
   dataTooltipContent: '',
};

Switch.propTypes = {
   className: PropTypes.string,
   disabled: PropTypes.bool,
   togglePosition: PropTypes.oneOf([
      'left', 'right',
   ]),
   checked: PropTypes.bool.isRequired,
   name: PropTypes.string,
   onChange: PropTypes.func.isRequired,
   label: PropTypes.string,
   noHorizontalMargin: PropTypes.bool,
   isBlackandWhite: PropTypes.bool,
   ButtonClassName: PropTypes.string,
   type: PropTypes.string,
   hasPointerCursor: PropTypes.bool,
   primaryColor: PropTypes.bool,
   dataTooltipContent: PropTypes.string,
   labelTextClassName: PropTypes.string,
   labelClassName: PropTypes.string,
   switchWrapperClassName: PropTypes.string,
   switchTooltipText: PropTypes.string,
};

export default Switch;
