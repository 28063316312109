import React from 'react';
import PropTypes from 'prop-types';
import Card from '../card';
import CircleLoader from 'common/components/elements/loader/circle';
import EmptyState from 'common/components/modules/empty-states';
import '../style.scss'
import  cx from 'classnames'
import { createPortal } from 'react-dom';
import Intercom from 'common/utils/Intercom';
import { ARTICLE_ID_ON_THE_VAULT } from 'common/utils/intercom-articles';
import VaultFilters from '../filters';
import VaultsHeaderButtons from '../header-buttons';
import vaultsEmptySvg from 'assets/images/empty-states/vaults.svg'
import ReorderingContent from 'admin/views/reorder-content';


const VideosContent = ({
   isInsideModal,
   data,
   isFetchingByFilter,
   desktopType,
   isNewFetching,
   openDeleteConfirmModal,
   removeFile,
   selectedCards,
   selectCard,
   filters,
   handleAddNewVault,
   isFetching,
   isEmpty,
   isDownloading,
   downloadOriginalFile,
   contentType,
   screenWidth,
   onDeleteMessag,
   updateVaultMessageAction,
   updateMessageUnlockDetails,
   isMultipleSelect = false,
   isDemoMode,
   onFilterChange,
   onClearAllFilters,
   isMultipleAttachments,
   isBulkEdit,
   isReorderMode,
   onBulkEdit,
   setReorderMode,
   isEmptyByFilter,
   goTo,
   bundleId = null,
}) => {

   const filtersIntoModal = ['store', 'chat'].includes(contentType) && screenWidth > 1024

   const view = (
      <div
         className={ cx({
            'flex z-20 flex-1 gap-4': true,
            // 'pb-6': isInsideModal && (screenWidth < 1025 ? contentType === 'store' : contentType !== 'store'),
            'px-6 pb-6 max-ms:px-4': isInsideModal,
         })
         }
      >
         <VaultFilters
            contentType={ contentType }
            desktopType={ desktopType }
            onFilterChange={ onFilterChange }
            onClearAllFilters={ onClearAllFilters }
            type='video'
            filtersData={ filters }
            isInsideModal={ isInsideModal }
            isMobile={ screenWidth <= 1024 }
            onSearchChange={ (value) => {
               onFilterChange(value, 'search')
            } }
            isMultipleAttachments={ isMultipleAttachments }
         />
      </div>
   )

   if(isFetching){
      return (
         <div className='empty-height flex flex-col h-full'>
            <div className='flex flex-col h-full items-center justify-center'>
               <CircleLoader color='blue' />
            </div>
         </div>
      )
   }

   if(isEmpty){
      return (
         <div className='flex flex-1 items-center'>
            <EmptyState
               newImageSvg={ vaultsEmptySvg }
               noContentText='You don’t have any videos in Vault'
               buttonText={ isInsideModal ? null : 'Add new video' }
               className='h-full !justify-center'
               onAdd={ isInsideModal ? null : handleAddNewVault }
               linkButtonText='More info on Vault'
               linkButtonIcon='attention-outline'
               onLinkClick={ () => Intercom.showArticle(ARTICLE_ID_ON_THE_VAULT) }
               dontAddIntercomClassName
               mainButtonDisabled={ isDemoMode }
               buttonDisabled={ isDemoMode }
               svgClassName='!mt-0'
            />
         </div>
      )
   }

   return (
      <div className={ cx({
         'vault flex flex-col': true,
         'h-full': isInsideModal,
         'min-h-[calc(100vh-110px)]': !isInsideModal,
      }) }>
         <div className={
            cx({
               '': true,
               'py-2': isInsideModal,
               'p-6 pt-8 pb-4 flex flex-col w-full gap-2': !isInsideModal,
               '!pt-6 px-4': desktopType === 'mobile',
               '!py-0': filtersIntoModal,
               '!pt-0': desktopType === 'mobile' && isInsideModal,
            })
         }
         >
            {
               !isInsideModal && (
                  <VaultsHeaderButtons
                     isBulkEdit={ isBulkEdit }
                     isReorderMode={ isReorderMode }
                     onBulkEdit={ onBulkEdit }
                     setReorderMode={ setReorderMode }
                     handleAddNewVault={ handleAddNewVault }
                     type='video'
                     isDemoMode={ isDemoMode }
                  />
               )
            }
            {
               filtersIntoModal && document?.getElementById('video_vaults-filter-content') ?
                  createPortal(view, document?.getElementById('video_vaults-filter-content'))
                  : view
            }
         </div>
         <div className='vault-content h-full flex flex-col flex-1'>
            {
               !isFetchingByFilter && !isEmptyByFilter &&
               <ReorderingContent
                  contentType='video_vaults'
                  onSortEnd={ () => {} }
                  setDraggableItem={ (value) => {
                     // setDraggableItem(value)
                  } }
                  data={ data }
                  isInsideModal={ isInsideModal }
                  screenWidth={ screenWidth }
               >
                  {
                     data.map((item, index) => {
                        let isFilteredByUnsent = filters?.chatContentStatus?.includes('unsent') || !filters?.chatContentStatus
                        return (
                           <Card
                              key={ item.hash }
                              data={ item }
                              desktopType={ desktopType }
                              openDeleteConfirmModal={ () => openDeleteConfirmModal(item.id, true, !!item.attached_bundle_id, item.attached_bundle_name, item.attached_bundle_id) }
                              selectCard={ (_, bool) => selectCard(bool, item) }
                              isChecked={ selectedCards?.includes(item.id) }
                              isInsideModal={ isInsideModal }
                              removeFile={ removeFile }
                              isDownloading={ isDownloading }
                              downloadOriginalFile={ downloadOriginalFile }
                              onDeleteMessag={ onDeleteMessag }
                              updateVaultMessageAction={ (removedItem) => updateVaultMessageAction({ ...removedItem, removedItemId: item.id, isFilteredByUnsent }) }
                              updateMessageUnlockDetails={ updateMessageUnlockDetails }
                              isMultipleSelect={ isMultipleSelect }
                              isDemoMode={ isDemoMode }
                              goTo={ () => goTo('VIDEO_VAULT_DETAILS', item.id) }
                              disabled={ contentType === 'store' && item?.already_added_to_bundle_id !== null && item?.already_added_to_bundle_id !== bundleId }
                           />
                        )
                     })
                  }
               </ReorderingContent>
            }
            {
               isNewFetching && !isFetchingByFilter &&
                  <div
                     className='flex flex-1 w-full h-full justify-center items-center'>
                     <CircleLoader />
                  </div>
            }
            {
               !isNewFetching && isFetchingByFilter &&
               <div
                  className='flex flex-1 h-full w-full justify-center items-center'>
                  <CircleLoader  />
               </div>
            }
            {
               isEmptyByFilter && !isFetchingByFilter &&
               <div
                  className='flex flex-1 h-full w-full justify-center items-center'>
                  <EmptyState
                     type='filter'
                  />
               </div>
            }
         </div>
      </div>
   )
}

VideosContent.propTypes = {
   isInsideModal: PropTypes.bool,
   data: PropTypes.array,
   removeFile: PropTypes.func,
   isFetchingByFilter: PropTypes.bool,
   desktopType: PropTypes.string,
   isNewFetching: PropTypes.bool,
   openDeleteConfirmModal: PropTypes.func,
   selectedCards: PropTypes.array,
   selectCard: PropTypes.func,
   filters: PropTypes.object,
   handleAddNewVault: PropTypes.func,
   isEmpty: PropTypes.bool,
   isFetching: PropTypes.bool,
   isDownloading: PropTypes.bool,
   downloadOriginalFile: PropTypes.func,
   screenWidth: PropTypes.number,
   contentType: PropTypes.string,
   updateVaultMessageAction: PropTypes.func,
   onDeleteMessag: PropTypes.func,
   updateMessageUnlockDetails: PropTypes.func,
   isMultipleSelect: PropTypes.bool,
   isDemoMode: PropTypes.bool,
   onFilterChange: PropTypes.bool,
   onClearAllFilters: PropTypes.func,
   isMultipleAttachments: PropTypes.bool,
   isBulkEdit: PropTypes.bool,
   isReorderMode: PropTypes.bool,
   onBulkEdit: PropTypes.func,
   setReorderMode: PropTypes.func,
   isEmptyByFilter: PropTypes.bool,
   goTo: PropTypes.func,
   bundleId: PropTypes.any,
}


export default VideosContent;
