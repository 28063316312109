import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Router from 'admin/routes/router';
import { push } from 'connected-react-router';
import ReactDOM from 'react-dom';
import Notifications from 'admin/views/notifications';
import {
   screenWidthSelector,
   siteInfoSelector,
   authUserSelector,
} from 'admin/state/modules/common/selectors';
import {
   commentDeleteOperation,
   blockMemberOperation,
   commentLikeOperation,
} from 'admin/state/modules/comments/operations';
import {
   listSelector,
   isOpenReplyCommentSelector,
   replyModalStateSelector,
   replyCommentsSelector,
   replyChatMessageSelector,
   isOpenPopupSelector,
   isFetchingNotificationsSelector,
   isFetchingNewNotificationsSelector,
   isFetchingReplyCommentSelector,
   notificationsPanelSelector,
   isEmptyNotificationsSelector,
   isEmptyByFilterNotificationsSelector,
   isFetchingByFilterNotificationsSelector,
   unreadNotificationsCountSelector,
   unseenNotificationsCountSelector,
} from 'admin/state/modules/notifications/selectors';
import {
   openReplyComment,
   updateNotifications,
   updateReplyComments,
   setNotificationsPanelAction,
   sendCommentCompleted,
   setUnreadCountAction,
   setUnseenCountAction,
} from 'admin/state/modules/notifications/actions';
import {
   fetchNotificationsOperation,
   fetchNewNotificationsOperation,
   fetchReplyCommentOperation,
   fetchReplyChatMessageOperation,
   sendCommentOperation,
   fetchNotificationsByFilterOperation,
   markAllAsReadNotificationsOperations,
   markAllAsSeenNotificationsOperations,
} from 'admin/state/modules/notifications/operations';
import { initNotificationsSocket, initChatSocket } from 'admin/utils/socket';
import { groupConversationSelector } from 'admin/state/modules/chat/selectors';
import {
   onLikeMessageAction as onLikeChatMessage,
   addNewMessageAction as addNewMessage,
   updateMessageAction as updateMessage,
   updateConverstionAction as updateConverstion,
   updateUnreamPmMessageCountAction,
} from 'admin/state/modules/chat/actions';
import { currentLocationPathSelector } from 'admin/state/modules/router/selectors';
import {
   setModalInfo,
} from 'admin/state/modules/domain-redirects/actions';
import toast from "common/utils/toast";
import DateFormatter from 'common/utils/DateFormatter';
import { getStoryCommentById, getMessageAndConversationUnreadCount, getResourceById } from 'admin/api/AuthApi';
import cx from 'classnames';

class NotificationsProviderContainer extends Component {

   static propTypes = {
      screenWidth: PropTypes.number,
      list: PropTypes.object,
      replyComments: PropTypes.object,
      replyModalState: PropTypes.object,
      fetchNewNotifications: PropTypes.func,
      fetchNotificationsByFilter: PropTypes.func,
      goTo: PropTypes.func,
      isFetchingNew: PropTypes.bool,
      siteInfo: PropTypes.object,
      updateNotifications: PropTypes.func,
      commentLike: PropTypes.func,
      onDeleteComment: PropTypes.func,
      sendComment: PropTypes.func,
      groupConversation: PropTypes.object,
      notificationsPanel: PropTypes.object,
      setNotificationsPanel: PropTypes.func,
      markAllAsRead: PropTypes.func,
      markAllAsSeen: PropTypes.func,
      isFetching: PropTypes.bool,
      isEmpty: PropTypes.bool,
      isEmptyByFilter: PropTypes.bool,
      isFetchingByFilter: PropTypes.bool,
      unreadNotificationsCount: PropTypes.number,
      unseenNotificationsCount: PropTypes.object,
      onLikeChatMessage: PropTypes.func,
      sendCommentCompleted: PropTypes.func,
      authUser: PropTypes.object,
      updateMessage: PropTypes.func,
      addNewMessage: PropTypes.func,
      setUnreadCount: PropTypes.func,
      setUnseenCount: PropTypes.func,
      updateConverstion: PropTypes.func,
      updateMUnreamPmMessageCount: PropTypes.func,
      locationPath: PropTypes.string,
      setDomainRedirectModalInfo: PropTypes.func,
   }

   socket = null
   chatSocket = null
   state = {
      currentReply: {},
      isReplyUserName: null,
      isReplyToId: null,
      resourceType: '',
      resourceId: null,
      commentId: null,
      replyNotification: null,
      replayMessageConversation: {},
      replayMessageConversationUnreadCount: 0,
   }
   customStyle = {
      top: 0,
      height: '100%',
   }
   filter = { }

   componentDidMount(){
      this._initConnection();
      this._bindEvents();
      this.getNotificationsModalStyle();
   }

   componentWillUnmount() {
      const { setNotificationsPanel, notificationsPanel, screenWidth } = this.props
      if(screenWidth >= 1025 && !this.isRedirect) {
         setNotificationsPanel({
            isMobile: screenWidth < 1025,
            animationClass: {
               transform: notificationsPanel.isOpen ? 'translateX(0)' : 'translateX(100%)',
               WebkitTransform: notificationsPanel.isOpen ? 'translateX(0)' : 'translateX(100%)',
            },
         })
      }
   }

   componentDidUpdate(){
      this.getNotificationsModalStyle();
   }

   getNotificationsModalStyle = () => {
      const { screenWidth } = this.props;
      if(screenWidth > 1024) {
         this.customStyle = {
            top: '58px',
            height: 'calc(100% - 58px)',
         };
         let el = document.querySelector('.progress-bar');
         if(!!el) {
            this.customStyle = {
               top: '103px',
               height: 'calc(100% - 103px)',
            };
         }
      } else {
         this.customStyle = {
            top: 0,
            height: '100%',
         }
      }
   }


   _bindEvents = () => {
      const { socket } = this;
      socket.removeAllListeners('message:add');
      socket.removeAllListeners('message:update');
      socket.on('message:add', (data) => {
         const { updateNotifications, unreadNotificationsCount, setUnreadCount, setUnseenCount, unseenNotificationsCount } = this.props;
         if(!!data) {
            if(!!this.checkNotificationMatchesFilter(data)) {
               updateNotifications(data)
               setUnreadCount(unreadNotificationsCount + 1)
            } else {
               setUnseenCount(unseenNotificationsCount?.count + 1)
            }
         }
      });
      socket.on('message:update', (data) => {
         const { updateNotifications } = this.props;
         if(!!data) {
            updateNotifications(data)
         }
      });
   }

   _initConnection = () => {
      const { siteInfo: { site_name: siteName } } = this.props;
      this.socket = initNotificationsSocket(siteName);
      this.chatSocket = initChatSocket(siteName);
   }

   checkNotificationMatchesFilter = (data) => {
      let isSearchFilter = !!this.filter.hasOwnProperty('search') && !!this.filter.search;
      let isCategoryFilter = !!this.filter.hasOwnProperty('categories') && !!this.filter.categories;
      let isSortByFilter = !!this.filter.hasOwnProperty('sortBy') && !!this.filter.sortBy;
      let isMatchesSearch = true;
      let isMatchesCategory = true;
      let isMatchesSortBy = true;
      if(isSearchFilter) {
         let name = data.payload?.user?.username;
         let searchValue = this.filter.search;
         isMatchesSearch = !!name.toLowerCase().includes(searchValue.toLowerCase());
      }
      if(isCategoryFilter) {
         let category = 'comments';
         if(this.isChatMessage(data.category)) {
            category = 'chat_messages';
         }
         isMatchesCategory = !!this.filter.categories.includes(category);
      }
      if(isSortByFilter) {
         isMatchesSortBy = this.filter.sortBy === 'date_added:desc';
      }
      if(isSortByFilter) {
         isMatchesSortBy = this.filter.sortBy === 'date_added:desc';
      }
      return !!isMatchesSearch && !!isMatchesCategory && !!isMatchesSortBy
   }

   notificationsScroll = (e) => {
      const { scrollHeight, clientHeight, scrollTop } = e.target
      if(((scrollHeight - scrollTop) - clientHeight < 1)) {
         this.nextPage()
      }
   }

   nextPage =  () => {
      const {
         fetchNewNotifications,
         isFetchingNew,
         list: { next_page_url: nextPageUrl },
      } = this.props;
      if(!!nextPageUrl && !isFetchingNew) {
         let cursor = nextPageUrl.split('?')[1]?.slice(7);
         this.filter = {
            ...this.filter,
            cursor: cursor,
         };
         let query = this.generateQuery();
         fetchNewNotifications(query);
      } else {
         return
      }
   }

   onReadNotification = (data) => {
      const { updateNotifications, markAllAsRead } = this.props;
      if(data && !data.is_read && !!data.id) {
         markAllAsRead(data.id);
         updateNotifications(data);
      }
   }

   isChatMessage = (category) => {
      if(['group_chat_messages', 'group_chat_replies', 'chat_private_messages'].includes(category)) {
         return true;
      }
      return false;
   }

   onClickLike = async (data) => {
      const { updateNotifications, commentLike } = this.props;
      if(this.isChatMessage(data.category)) {
         let chatMessage = await this.checkDataExist(data, () => this.onReadChatMessage(data?.payload?.id));
         if(!!chatMessage) {
            this.chatMessageLike(data, chatMessage);
         }
      } else {
         if(data && data?.payload && this.socket) {
            let comment = await this.checkDataExist(data);
            if(!!comment) {
               data.payload.liked = !comment.admin_liked;
               data.payload.admin_liked = !comment.liked;
               updateNotifications(data);
               const { resourceId, commentId, type } = this.state;
               commentLike(resourceId, commentId, null, type, false);
            }
         }
      }
   }

   onClickReply = async (data) => {
      const { replyNotification } = this.state;
      if(!data || (replyNotification && replyNotification.id === data.id)) {
         this.setState({ replyNotification: null });
      } else {
         let isExist = await this.checkDataExist(data);
         if(!!isExist) {
            let newData = {
               ...data,
               payload: {
                  ...data.payload,
                  parentMessageId: data.payload.id,
               },
            }
            this.setState({ replyNotification: newData });
            if(this.isChatMessage(data.category)) {
               this.onReadChatMessage(data.payload.id);
            }
         }
      }
   }

   onReadChatMessage = async (id) => {
      if(id && this.chatSocket) {
         this.handleReadConverstionId(this.state.replayMessageConversation?.chat_room_id)
         this.chatSocket.emit('conversation:read', { conversationId: this.state.replayMessageConversation?.chat_room_id })
         this.chatSocket.emit('conversation:seen', { conversationId: this.state.replayMessageConversation?.chat_room_id, lastSeenTime: DateFormatter.convertToUtc(Date.now()) })
      }
   }

   handleReadConverstionId = (id) => {
      const { updateConverstion, updateMUnreamPmMessageCount } = this.props
      const { replayMessageConversationUnreadCount } = this.state
      updateConverstion({ conversationId: id, data: { unread_messages_count: 0 } })
      updateMUnreamPmMessageCount(-replayMessageConversationUnreadCount);
   }

   checkDataExist = async (data, callback) => {
      if(this.isChatMessage(data.category)) {
         let messageId = data?.payload?.id;
         this.setState({ commentId: messageId });
         return await getMessageAndConversationUnreadCount(messageId)
            .then((res) => {
               this.setState({ replayMessageConversation: res?.data?.message, replayMessageConversationUnreadCount: res?.data?.unread_messages_count }, () => {
                  if(Boolean(callback)) {
                     callback()
                  }
               })
               return res?.data?.message;
            })
            .catch(() => {
               toast.remove('The message has been deleted');
               return false;
            })
      } else {
         let comment = data?.payload;
         let type = '';
         let resourceId = '';
         let commentId = comment.id;
         if(comment.type === 'photoset') {
            type = 'photosets';
            resourceId = comment.photoset_id;
         }
         if(comment.type === 'video' || 'record_stream' === comment.type) {
            type = 'videos';
            resourceId = comment.video_id;
         }
         if(comment.type === 'story') {
            type = 'stories';
            resourceId = comment.story_id;
         }
         this.setState({ resourceId, commentId, type });
         return await getStoryCommentById(resourceId, commentId, type)
            .then((res) => res?.data)
            .catch(() => {
               toast.remove('The comment has been deleted');
               return false;
            })
      }
   }

   chatMessageLike = (data, message) => {
      const { onLikeChatMessage, updateNotifications } = this.props;
      let likeData = {
         conversationId: message.chat_room_id,
         isLiked: !data?.payload?.liked,
         messageId: message?.id,
         isMyLiked: true,
      };
      let updatedData = {
         ...data,
         payload: {
            ...data.payload,
            liked: !data.payload.liked,
         },
      }
      this.chatSocket.emit('message:like', { ...likeData });
      onLikeChatMessage(likeData);
      updateNotifications(updatedData)
   }

   replyCommentsToggleLike = (id) => {
      const { commentLike } = this.props;
      const { currentReply, resourceType, resourceId } = this.state;
      let parentId = id === currentReply.id ? null : currentReply.id;
      commentLike(resourceId, id, parentId, resourceType);
   }

   // Phase 2
   deleteReplyComment  = (id) => {
      this.deleteCommentId = id
      const { onDeleteComment } = this.props
      const { currentReply, resourceType, resourceId } = this.state;
      let parentId = id === currentReply.id ? null : currentReply.id;
      onDeleteComment(resourceId, id, parentId, resourceType)
   }

   generateQuery = () => {
      const query = [];
      Object.keys(this.filter).forEach((key) => {
         if(key){
            if(this.filter[key]) {
               key !== 'count' && key !== 'sortBy' && key !== 'cursor' ? query.push(`query[]=${ key }=${ this.filter[key] }`) : query.push(`${ key }=${ this.filter[key] }`);
            }
         }
      });
      return query;
   };

   onFilterChange = (value, key) => {
      let data = value
      if(!!Array.isArray(value)) {
         data = value.join(',')
      }
      this.filter = {
         ...this.filter,
         [key]: data,
      }
      delete this.filter.cursor;
      let query = this.generateQuery();
      const { fetchNotificationsByFilter } = this.props;
      let el = document.querySelector('.notification-content-list');
      if(el) {
         el.scrollTop = 0;
      }
      fetchNotificationsByFilter(query)
   }

   sendReplyMessage = async (text, data) => {
      const { sendComment } = this.props;
      let isExist = await this.checkDataExist(data);
      if(!!isExist) {
         if(this.isChatMessage(data.category)) {
            this.sendChatMessage(text, data);
         } else {
            const { resourceId, type } = this.state;
            let newData = {
               text,
               resource_id: resourceId,
               parent_id: data?.payload.id,
            };
            sendComment(newData, data, type, 'add');
         }
      }
   }

   sendChatMessage = async (text, data) => {
      const { sendCommentCompleted, authUser, updateMessage, addNewMessage  } = this.props;
      const parentMessageId = data.payload.id;
      const conversationId = data.payload.chat_room_id;
      let randomid = Math.random() * (700 - 2000) + 2000;
      var sentAt = DateFormatter.getCurentTimeMilliseconds()
      let newData = {
         parentMessageId: parentMessageId,
         conversationId: conversationId,
         sentAt,
         text,
      }
      let parent = data.payload;
      let newMessageData = {
         sent_at: sentAt,
         id: randomid,
         is_liked_by_user: false,
         likes_count_from_other_users: 0,
         parent: parentMessageId ? parent : null,
         user: authUser,
         text,
      };
      await addNewMessage({ id: conversationId, message: newMessageData, isMyMessage: true })
      this.chatSocket.emit('message:send', newData, data => {
         let message = {}
         if(data.error) {
            switch (data.error) {
               case 'message_is_too_long':
                  toast.error('Message must be 20000 characters or less')
                  break;
               default:
                  break;
            }
         } else {
            const { messageId, parentMessage } = data.data
            message = {
               text: text,
               id: messageId,
               parent: parentMessage,
            }
            newData = {
               parent_id: parentMessageId,
               conversationId: conversationId,
               created_at: sentAt,
               text,
            };
            sendCommentCompleted(newData);
            updateMessage({ conversationId, messageId: randomid,  message })
         }
      })
   }

   closeNotificationModal = () => {
      const { setNotificationsPanel } = this.props;
      this.filter = {};
      setNotificationsPanel({ isOpen: false });
   }

   goToResourceOrMemberPage = (id, isMember, resourceType, isComment) => {
      const { goTo } = this.props;
      if(!!isMember) {
         goTo(Router.route('MEMBERS_SHOW').getCompiledPath({ id }));
         return
      }
      this.checkResourceExist(id, resourceType, isComment)
   }


   checkResourceExist = (id, type, commentId) => {
      const { goTo } = this.props;
      let key = 'STORE_DETAILS';
      let resourceType = 'products';
      let query = '';
      if(type === 'photoset') {
         resourceType = 'photosets';
         key = 'PHOTOSETS_SHOW';
      }
      if(type === 'video') {
         resourceType = 'videos';
         key = 'VIDEOS_SHOW';
      }
      if(type === 'record_stream') {
         resourceType = 'videos';
         key = 'RECORDED_VIDEO';
         query = 'video_type=livestream'
      }
      if(type === 'story') {
         resourceType = 'stories';
         key = 'STORIES_COMMENTS';
      }
      if(type === 'poll') {
         resourceType = 'polls';
         key = 'POLLS_DETAILS';
      }
      if(!!commentId) {
         let commentResourceType = resourceType;
         if(type === 'record_stream') {
            commentResourceType = 'videos'
         }
         getStoryCommentById(id, commentId, commentResourceType)
            .then((res) => {
               goTo(Router.route(key).getCompiledPath({ id }))
            })
            .catch((err) => {
               toast.remove('The comment has been deleted');
            })
         return
      }
      getResourceById(id, resourceType, query)
         .then((res) => {
            goTo(Router.route(key).getCompiledPath({ id }))
         })
         .catch((err) => {
            if(type === 'record_stream'){
               toast.remove(`The recorded stream has been deleted`);
            } else {
               toast.remove(`The ${ type } has been deleted`);
            }

         })

   }

   onClickNotificationItemLinkButton = (notificationItem = {}) => {
      const { goTo, locationPath, setDomainRedirectModalInfo } = this.props;
      const { payload } = notificationItem

      switch (notificationItem.key) {
         case "404_error":
            const accessedUrl = payload?.resource_accessed_url
            if(!accessedUrl) return;
            if(Router.route('MY_SITE_DOMAIN_REDIRECTS').match(locationPath)) {
               setDomainRedirectModalInfo({
                  type: 'add-edit',
                  isOpen: true,
                  buttonDisabled: false,
                  item: {
                     from: accessedUrl.startsWith('/') ? accessedUrl.substring(1) : accessedUrl,
                  },
               })
            } else {
               goTo(Router.route('MY_SITE_DOMAIN_REDIRECTS').getCompiledPath(), `redirectFrom=${ encodeURIComponent(accessedUrl) }`)
            }

            break;

         default:
            break;
      }
   }


   render() {
      const {
         screenWidth,
         list,
         replyComments,
         goTo,
         isFetching,
         isFetchingNew,
         replyModalState,
         groupConversation,
         notificationsPanel,
         isEmpty,
         isEmptyByFilter,
         isFetchingByFilter,
         markAllAsRead,
         markAllAsSeen,
         unreadNotificationsCount,
         authUser,
         siteInfo,
      } = this.props;
      const { replyNotification } = this.state;
      if(!notificationsPanel.isOpen) {
         return null;
      }
      return (
         <>
            {
               ReactDOM.createPortal(
                  <div
                     className={ cx({
                        'fixed z-10 bottom-0 admin-right-notifications': true,
                        'shadow dark:shadow-dark': notificationsPanel?.isOpen,
                     }) }
                     style={ { ...notificationsPanel.animationClass, zIndex: '1000', ...this.customStyle } }
                  >
                     {
                        notificationsPanel.isOpen && (
                           <Notifications
                              isFetching={ isFetching }
                              isEmpty={ isEmpty }
                              isEmptyByFilter={ isEmptyByFilter }
                              isFetchingByFilter={ isFetchingByFilter }
                              filters={ this.filter }
                              notifications={ list?.data }
                              isMobile={ screenWidth < 701 }
                              isFetchingNew={ isFetchingNew }
                              onCloseModal={ this.closeNotificationModal }
                              onScroll={ this.notificationsScroll }
                              goToNotificationPreferences={ () => {
                                 goTo(Router.route('MY_ACCOUNT').getCompiledPath(), '?activeTab=notifications');
                                 if(screenWidth < 701) {
                                    this.closeNotificationModal()
                                 }
                              } }
                              onClickCard={ this.onReadNotification }
                              onClickLike={ this.onClickLike }
                              onClickReply={ this.onClickReply }
                              replyNotification={ replyNotification }
                              avatars={ groupConversation?.avatars }
                              userAvatar={ authUser.compressed_avatar_src }
                              onFilterChange={ this.onFilterChange }
                              markAllAsRead={ () => {
                                 markAllAsSeen();
                                 markAllAsRead();
                              } }
                              replyMessage={ replyComments }
                              replyModalState={ replyModalState }
                              sendMessage={ this.sendReplyMessage }
                              isDisabledMarkAllAsReadButton={ !unreadNotificationsCount }
                              goToResourceOrMemberPage={ this.goToResourceOrMemberPage }
                              screenWidth={ screenWidth }
                              goToOrderDetails={ (id) => {
                                 goTo(Router.route('ORDER_REQUEST_DETAILS').getCompiledPath({ id }))
                              } }
                              siteInfo={ siteInfo }
                              goToLink={ this.onClickNotificationItemLinkButton }
                           />
                        )
                     }
                  </div>,
                  document.querySelector(screenWidth < 1025 ? '#root' : '.layout-content')
               )
            }
         </>
      )
   }
}

const mapStateToProps = (state) => {
   return {
      screenWidth: screenWidthSelector(state),
      list: listSelector(state),
      isOpenReplyComment: isOpenReplyCommentSelector(state),
      replyModalState: replyModalStateSelector(state),
      replyComments: replyCommentsSelector(state),
      replyChatMessage: replyChatMessageSelector(state),
      authUser: authUserSelector(state),
      siteInfo: siteInfoSelector(state),
      isOpenPopupSelector: isOpenPopupSelector(state),
      isFetching: isFetchingNotificationsSelector(state),
      isFetchingNew: isFetchingNewNotificationsSelector(state),
      isFetchingReplyComment: isFetchingReplyCommentSelector(state),
      groupConversation: groupConversationSelector(state),
      notificationsPanel: notificationsPanelSelector(state),
      isEmpty: isEmptyNotificationsSelector(state),
      isEmptyByFilter: isEmptyByFilterNotificationsSelector(state),
      isFetchingByFilter: isFetchingByFilterNotificationsSelector(state),
      unreadNotificationsCount: unreadNotificationsCountSelector(state),
      unseenNotificationsCount: unseenNotificationsCountSelector(state),
      locationPath: currentLocationPathSelector(state),
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      openReplyComment: data => dispatch(openReplyComment(data)),
      fetchNotifications: (query) => dispatch(fetchNotificationsOperation(query)),
      fetchNewNotifications: (query) => dispatch(fetchNewNotificationsOperation(query)),
      fetchNotificationsByFilter: (query) => dispatch(fetchNotificationsByFilterOperation(query)),
      fetchReplyComment: (data) => dispatch(fetchReplyCommentOperation(data)),
      fetchReplyChatMessage: (messageId) => dispatch(fetchReplyChatMessageOperation(messageId)),
      updateNotifications: (data) => dispatch(updateNotifications(data)),
      updateReplyComments: (data, type, initial) => dispatch(updateReplyComments(data, type, initial)),
      sendComment: (data, replyMessage, resourceType, actionType) => dispatch(sendCommentOperation(data, replyMessage, resourceType, actionType)),
      sendCommentCompleted: (data, replyMessage, actionType) => dispatch(sendCommentCompleted(data, replyMessage, actionType)),
      commentLike: (id, commentId, parentID, resourceType, isReply) => dispatch(commentLikeOperation(id, commentId, parentID, resourceType, isReply)),
      onDeleteComment: (id, query, parentID, resourceType) => dispatch(commentDeleteOperation(id, query, parentID, resourceType)),
      blockMember: (id, data, name) => dispatch(blockMemberOperation(id, data, name)),
      markAllAsRead: (id) => dispatch(markAllAsReadNotificationsOperations(id)),
      markAllAsSeen: () => dispatch(markAllAsSeenNotificationsOperations()),
      setNotificationsPanel: data => dispatch(setNotificationsPanelAction(data)),
      updateMessage: data => dispatch(updateMessage(data)),
      addNewMessage: (data, search) => dispatch(addNewMessage(data, search)),
      onLikeChatMessage: data => dispatch(onLikeChatMessage(data)),
      setUnreadCount: unreadCount => dispatch(setUnreadCountAction(unreadCount)),
      setUnseenCount: unseenCount => dispatch(setUnseenCountAction(unseenCount)),
      updateConverstion: data => dispatch(updateConverstion(data)),
      updateMUnreamPmMessageCount: (count) => dispatch(updateUnreamPmMessageCountAction(count)),
      goTo: (location, search = '') => {
         dispatch(push({
            search: search,
            pathname: location,
         }))
      },
      setDomainRedirectModalInfo: (data) => dispatch(setModalInfo(data)),
   }
}


export default connect(mapStateToProps, mapDispatchToProps)(NotificationsProviderContainer);
