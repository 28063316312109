import PropTypes from 'prop-types';
import classNames from 'classnames';
import GenerateColor from 'common/utils/generateTextColor.js'
import Button from 'common/components/elements/buttons/primary';
import DateFormatter from 'common/utils/DateFormatter';
import { getMaxCountAnswerOptions } from 'common/utils/polls';
import IconLabel from 'common/components/elements/icon-label';
import { replaceSiteUrlMemberPartWithBoldHtml } from 'common/utils/utils';

const AdminNotificationItemV2 = ({
   notification,
   onClickCard,
   goToResourceOrMemberPage,
   siteInfo, // admin site info
   goToLink,
}) => {
   const { key, is_read, payload } = notification;

   const getNotificationState = () => {
      const { resource_name, resource_question, resource_member_name, resource_option_name, resource_options, resource_total_voters } = payload;

      switch (key) {
         case 'poll_published':
            return {
               title: `Poll has been published: ${ resource_name }`,
               description: resource_question,
               iconName: 'poll',
               iconColor: 'action',
               buttonText: 'Go to poll',
               resourceType: 'poll',
            }

         case 'poll_voted':

            return {
               title: `${ resource_member_name } has voted for:`,
               description: resource_option_name,
               iconName: 'poll',
               iconColor: 'action',
               buttonText: 'Go to poll',
               resourceType: 'poll',
            }

         case 'poll_vote_changed':
            return {
               title: `${ resource_member_name } changed their vote to:`,
               description: resource_option_name,
               iconName: 'poll',
               iconColor: 'action',
               buttonText: 'Go to poll',
               resourceType: 'poll',
            }

         case 'poll_24_hours_left':
         case 'poll_ended':
            let description = 'No votes received'
            const filteredMaxOptions = getMaxCountAnswerOptions(resource_options, 'percentage')

            if(filteredMaxOptions.length > 0) {
               const optionNames = filteredMaxOptions.reduce((acc, curr) => {
                  acc.push(curr.name)
                  return acc
               }, [])

               const isPlural = filteredMaxOptions.length > 1

               if('poll_24_hours_left' === key) {
                  description = `So far, ${ optionNames.join(', ') } ${ isPlural ? 'are' : 'is' } leading with ${ filteredMaxOptions[0].percentage }% of the vote`
               } else {
                  description = `${ resource_name } had ${ resource_total_voters } votes, and the winning response was ${ optionNames.join(', ') }, with ${ filteredMaxOptions[0].percentage }%`
               }
            }

            let title = `${ resource_name } ended`
            if('poll_24_hours_left' === key) {
               title = `${ resource_name } is ending in 24 hours`
            }

            return {
               title,
               description,
               iconName: 'poll',
               iconColor: 'action',
               buttonText: 'Go to poll',
               resourceType: 'poll',
            }

         case '404_error':
            const { resource_accessed_url } = payload;

            let siteUrl = replaceSiteUrlMemberPartWithBoldHtml(siteInfo.site_url)

            return {
               title: "404 Error",
               description: `404 error from ${ siteUrl }${ resource_accessed_url }`,
               iconName: 'attention',
               iconColor: 'error',
               buttonText: 'Add a URL redirect',
               onClickButton: () => {
                  goToLink(notification)
               },
            }

         default:
            return {}
      }
   }

   const notificationState = getNotificationState()

   return (
      <div
         className={ classNames({
            'px-4 pt-4 pb-2 mb-2 bg-panel dark:bg-panel-dark rounded-lg flex gap-2': true,
            'cursor-pointer': !is_read,
         }) }
         style={
            !is_read ?
               { backgroundColor: GenerateColor.generateRGBColor('#231FFF', 0.04) }
               :
               {}
         }
         role='presentation'
         onClick={ () => onClickCard(notification) }
      >
         {
            !is_read && (
               <div className='flex items-center h-6 mr-2'>
                  <div
                     className='w-2 h-2 rounded-full'
                     style={ { background: '#FF3636' } }
                  />
               </div>
            )
         }
         <IconLabel
            name={ notificationState.iconName }
            color={ notificationState.iconColor }
            size={ !is_read ? 'xl' : '2xl' }
            className={ classNames({
               'shrink-0': true,
               'w-14 h-14': is_read,
               'w-10 h-10': !is_read,
            }) }
         />
         <div
            className='w-full flex flex-col'
         >
            <span
               className='text-major font-bold leading-6'
            >
               {notificationState.title}
            </span>
            {
               notificationState.description && (
                  <span
                     className='text-major leading-5 mt-1'
                     dangerouslySetInnerHTML={ { __html: notificationState.description } }
                  />
               )
            }
            <Button
               text={ notificationState.buttonText }
               primaryColor
               backgroundColor='transparent'
               padding={ 0 }
               textMarginX='0'
               classNames='!w-fit mt-3'
               textSize='small'
               textClassName='!leading-5'
               onClick={ () => {
                  if(notificationState.onClickButton) {
                     notificationState.onClickButton()
                  } else {
                     goToResourceOrMemberPage(payload.resource_id, false, notificationState.resourceType)
                  }
               } }
            />
            <span
               className='text-xs leading-[15px] text-secondary dark:text-secondary-dark text-start mt-3'
            >
               {DateFormatter.getDateByTimzeonFormat(notification?.created_at)}
            </span>
         </div>
      </div>
   )
}

AdminNotificationItemV2.propTypes = {
   notification: PropTypes.object,
   onClickCard: PropTypes.func,
   goToResourceOrMemberPage: PropTypes.func,
   siteInfo: PropTypes.object,
   goToLink: PropTypes.func,
}

export default AdminNotificationItemV2;
