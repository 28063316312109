export const ARTICLE_ID_ON_CAST_MEMBERS = '2841331'
export const ARTICLE_ID_ON_MASS_MESSAGES = '5344653'
export const ARTICLE_ID_ON_IP_BLACK_WHITE_LIST = '2872599'
export const ARTICLE_ID_ON_DOMAIN_REDIRECTS = '2060721'
export const ARTICLE_ID_ON_PHOTOSETS = '4062883'
export const ARTICLE_ID_ON_PROMOTIONS = '3895897'
export const ARTICLE_ID_ON_STORIES = '3896144'
export const ARTICLE_ID_ON_WALLET_ACTIVITY = '5125402'
export const ARTICLE_ID_ON_CHAT_ACCESS_SETTINGS = '4993817'
export const ARTICLE_ID_ON_LIVESTREAM = '6971185'
export const ARTICLE_ID_ON_MAILING_LIST = '5354310'
export const ARTICLE_ID_ON_OUTGOING_NOTIFICATIONS = '8261180'
export const ARTICLE_ID_ON_AUTO_POSTS = '5946802'
export const ARTICLE_ID_ON_THE_VAULT = '5578020'
export const ARTICLE_ID_ON_HIDING_SCHEDULED_CONTENT = '5733981'
export const ARTICLE_ID_ON_WATERMARKS = '8587216'
export const ARTICLE_ID_ON_CUSTOM_PAGES = '1007376'
export const ARTICLE_ID_ON_LOGOS_AND_ICONS = '5460625'
export const ARTICLE_ID_ON_PAYOUT_METHODS = '1007435'
export const ARTICLE_ID_ON_STORE = '7126563'
export const ARTICLE_ID_ON_SHIPPING_ZONES = '8161102'
export const ARTICLE_ID_ON_AGE_VERIFICATION_PRIVACY_POLICY = '9362106'
export const ARTICLE_ID_ON_SEO = '7153529'
export const ARTICLE_ID_ON_SOCIAL_IMAGE = '9385640'
export const ARTICLE_ID_ON_CHAT = '4993817'
export const ARTICLE_ID_ON_PAYOUT_BALANCES = '9562018'
export const ARTICLE_ID_ON_PAYOUT_HOLDS = '9653247'
export const ARTICLE_ID_ON_CONTENT_CATEGORIES = '9740301'
export const ARTICLE_ID_ON_REPOST = '9922610'
export const ARTICLE_ID_ON_ORIGINAL_CONTENT_STORAGE = '9912315'
export const ARTICLE_ID_ON_BLUR_WHEN_LOCKED = '9926161'
export const ARTICLE_ID_ON_SAVED_REPLIES = '10138724'
export const ARTICLE_ID_ON_POLLS = '10276390'
export const ARTICLE_ID_ON_SFW_CHECKBOX = '10292085'
