import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons'
import './style.scss';
import Modal from '../../modal';
import Button from 'common/components/elements/buttons/primary';
import generateTextColor from 'common/utils/generateTextColor';
import cx from "classnames";
import DropDownModal from '../../drop-down-modal';
import BasicDateTimePicker from 'common/components/elements/inputs/date-picker';
import moment from 'moment';
import CheckboxLabel from 'common/components/elements/inputs/checkbox-label';
import DateFormatter from 'common/utils/DateFormatter';
import toast from 'common/utils/toast';
// import momentTimezone from 'moment-timezone';


const MessageDurationModal = ({
   onCloseUnsentDurationModal,
   durationInfo,
   onSaveDurationData,
   isFetchingChatSettingsUpdate,
}) => {

   const defaultDurations = [
      // { name: '1 min', value: 1, isDefault: false },
      { name: '30 min', value: 60 / 2, isDefault: false },
      { name: '1 hour',  value: 60, isDefault: false },
      { name: '2 hours',  value: 60 * 2, isDefault: false },
      { name: '6 hours', value: 60 * 6, isDefault: false },
      { name: '12 hours', value: 60 * 12, isDefault: false },
      { name: '1 day', value: 60 * 24, isDefault: false },
      { name: '2 days', value: 60 * 24 * 2, isDefault: false },
      { name: '3 days', value: 60 * 24 * 3, isDefault: false },
   ]

   const [animate, setAnimate] = useState('up')
   let timeout
   const [durations, setDurations] = useState(defaultDurations)
   const [buttonWithLoader, setButtonWithLoader] = useState('save')
   const [data, setData] = useState({
      chat_countdown_default_duration: +durationInfo?.chat_countdown_default_duration || null,
      chat_countdown_default_duration_checked: durationInfo?.chat_countdown_default_duration_checked,

      show_duration_countdown_to_member: !!durationInfo?.show_duration_countdown_to_member || false,
      duration_in_minutes: +durationInfo?.duration_in_minutes || +durationInfo?.chat_countdown_default_duration || null,
   })

   useLayoutEffect(() => {
      if(durationInfo) {
         // setData({ ...data, ...durationInfo })

         const currentDuration = durationInfo?.chat_countdown_default_duration || durationInfo?.duration_in_minutes // || durationInfo?.chat_unsent_duration

         const isCurrentDurationCustom = !(durations.find(t => t.value === currentDuration))

         if(currentDuration && isCurrentDurationCustom) {

            const durationInDays = Math.floor(currentDuration / (60 * 24))
            const durationInHours = Math.floor(currentDuration / 60)

            const newDuration = {
               isDefault: false,
               name: durationInHours < 24 ? currentDuration < 60 ? `${ currentDuration } min` : (`${ durationInHours } hour${ durationInHours === 1 ? '' : 's' }`) : (`${ durationInDays } day${ durationInDays === 1 ? '' : 's' }`),
               value: currentDuration,
            }
            const currentDurations = [...defaultDurations, newDuration].sort((a, b) => a.value - b.value)

            setDurations(currentDurations)
         }
      }

      return () => {
      };
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {

      return () => {
         clearTimeout(timeout)

         setDurations(defaultDurations)
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const onCloseModal = () => {

      if(window.innerWidth < 1024) {
         setAnimate('down')
         timeout = setTimeout(() => {
            onCloseUnsentDurationModal()
         }, 800);
         return
      }
      onCloseUnsentDurationModal()

   }

   const onChangeData = (key, data) => {

      setData((prev) => {
         let newState = {}

         if(['chat_countdown_default_duration_checked', 'show_duration_countdown_to_member'].includes(key)) {
            newState = {
               [key]: data,
            }
            if('chat_countdown_default_duration_checked' === key && !!data) { //&& !!prev?.chat_unsent_duration) {
               newState = {
                  ...newState,
                  chat_countdown_default_duration: prev?.duration_in_minutes,
               }
            }
            if('chat_countdown_default_duration_checked' === key && !data) { //&& !!prev?.chat_unsent_duration) {
               newState = {
                  ...newState,
                  chat_countdown_default_duration: null,
                  // chat_unsent_duration: null,
               }
            }
         }

         // if(['chat_unsent_duration'].includes(key)) {
         if(['duration_in_minutes'].includes(key)) {
            // eslint-disable-next-line no-unused-vars
            const { name, value, isDefault } = data

            newState = {
               [key]: value,
            }

            if(!!prev?.chat_countdown_default_duration_checked) {
               newState = {
                  ...newState,
                  chat_countdown_default_duration: value,
               }
            }
         }

         return {
            ...prev,
            ...newState,
         }
      })
   }

   const onClickSave = () => {
      const {
         chat_countdown_default_duration,
         chat_countdown_default_duration_checked,
         duration_in_minutes,
         show_duration_countdown_to_member,
      } = data
      if(onSaveDurationData) {
         onSaveDurationData({
            chat_countdown_default_duration,
            chat_countdown_default_duration_checked,
            duration_in_minutes,
            show_duration_countdown_to_member,
         }, () => onCloseModal())
      }
   }

   const onClickRemove = () => {

      if(onSaveDurationData) {
         onSaveDurationData({
            chat_countdown_default_duration: 0,
            chat_countdown_default_duration_checked: false,
            duration_in_minutes: 0,
            show_duration_countdown_to_member: true,
         }, () =>  onCloseModal())
      }
   }

   return (
      <Modal
         contentPosition={ window.innerWidth < 1024 ? 'bottom' : 'center' }
         onClose={ () => {
            onCloseModal()
         } }
         contentWidth={ window.innerWidth < 1024 ? '100%' : '375px' }
         animate={ window.innerWidth < 1024 ? animate : null }
         className='h-full overflow-hidden'
         closeOnClickOutside={ false }
         otherClassName={ cx({
            '!max-h-[80vh] lg:!max-h-[90vh] overflow-hidden ': true,

         }) }
      >
         <div
            className={ cx({
               [`flex flex-col h-full`]: true,
            }) }>
            <div
               className='h-14 w-full flex items-center justify-end pt-2 px-2'
            >
               <Button
                  backgroundColor='transparent'
                  iconName='close'
                  fontIconColor='secondary'
                  classNames='!w-fit !p-[10px]'
                  fontIconSize='extraLarge'
                  onClick={ () => {
                     onCloseModal()
                  } }
               />
            </div>
            <div className='flex flex-col px-4 pb-4 lg:px-6 lg:pb-6 items-center justify-center select-none gap-8'>
               <div
                  className='w-full flex flex-col items-center'
               >
                  <div
                     className={ `h-10 w-10 mx-auto flex items-center justify-center mb-3 rounded-full ` }
                     style={ {
                        backgroundColor: generateTextColor.generateRGBColor(window?.skinPrimaryColor, 0.12),
                     } }
                  >
                     <Icon
                        name={ 'duration' }
                        size={ '2xl' }
                        color={ `action` }
                     // primaryColor={ !isAdmin ? window?.skinSecondaryColor : window?.skinPrimaryColor }
                     />
                  </div>
                  <div className='text-center'>
                     <p className='text-major dark:text-major-dark text-xl font-semibold leading-6'>
                        Message duration
                     </p>
                     <p className='text-secondary dark:text-secondary-dark leading-5 mt-3'>
                        Set an amount of time after which you would like this message to be unsent
                     </p>
                  </div>
               </div>
               <div className='w-full flex flex-col gap-4'>
                  <DropDownModal
                     data={ durations }
                     descriptionClassName='text-base text-major font-medium mb-2'
                     initialValue={ {
                        name: data?.duration_in_minutes ? durations?.find(t => t.value  === data?.duration_in_minutes)?.name : 'Set duration',
                        value: data?.duration_in_minutes ? durations?.find(t => t.value  === data?.duration_in_minutes)?.value : 'placeholder',
                     } }
                     uniqueId='unsent-duration-drop-down-modal'
                     activeValue={ data?.duration_in_minutes }
                     onChange={ (data) => {
                        onChangeData('duration_in_minutes', data)
                     } }
                     optionsListClassName={ `top-11 border-divider` }
                     mainClassName='relative w-full'
                     maxHeight='120'
                     onInputClick={ () => {
                     } }
                     wrapperClassname=''
                     useModalForMobile
                     screenWidth={ window?.innerWidth }
                     mobileSize={ 1024 }
                     targetClassToIgnoreOnClick='.MuiDialog-container'
                     includeChildrenIntoList
                  >
                     <BasicDateTimePicker
                        name='date'
                        type='datetime'
                        label='Custom'
                        labelClassName='font-medium text-base !text-action cursor-pointer !m-0 px-3.5 py-2.5 hover:underline leading-tight w-full flex hover:bg-hover !h-10'
                        value={ null }
                        // minValue={ moment().add(24, 'hours').format('YYYY-MM-DD') }
                        minValue={ moment().format('YYYY-MM-DD') }
                        maxValue={ moment().add(30, 'days').format('YYYY-MM-DD') }
                        onChangeDate={ async (name, value) => {
                           // const convertToUtc = value ? DateFormatter.convertToUtc(value) : null
                           const convertToUserTz = value ? DateFormatter.convertToUserTz(value) : null
                           if(!convertToUserTz) return
                           const differenceInDays = moment(convertToUserTz).diff(moment(), 'days') //+ 1
                           const differenceInHours = moment(convertToUserTz).diff(moment(), 'hours') //+ 4
                           const differenceInMinutes = moment(convertToUserTz).diff(moment(), 'minutes') <= 0 ? 1 : moment(convertToUserTz).diff(moment(), 'minutes') //+ 240
                           // const differenceInSeconds = moment(convertToUserTz).diff(moment(), 'seconds')

                           if(moment(convertToUserTz).diff(moment(), 'minutes') <= 0) {
                              toast.error('Duration period cannot be in past')
                              return
                           }

                           // const userTimeZone = momentTimezone.tz.guess();
                           // const timezone = new Date(value).getTimezoneOffset();
                           // console.log({ convertToUserTz, differenceInDays, differenceInHours, differenceInMinutes, value, differenceInSeconds });

                           let newDuration = {}

                           if(!durations.find(t => t.value === differenceInMinutes)) {
                              newDuration = {
                                 isDefault: false,
                                 name: differenceInMinutes < 60 ? `${ differenceInMinutes } min` : differenceInHours >= 24 ? `${ differenceInHours < 48 ? '1' : differenceInDays } day${ differenceInDays === 1 ? '' : 's' }` : `${ differenceInHours } hour${ differenceInHours === 1 ? '' : 's' }`,
                                 value: differenceInMinutes,
                              }
                              const newDurations = [...defaultDurations, newDuration].sort((a, b) => a.value - b.value)

                              setDurations(newDurations)
                           } else {
                              newDuration = {
                                 isDefault: false,
                                 name: differenceInMinutes < 60 ? `${ differenceInMinutes } min` : differenceInHours >= 24 ? `${ differenceInHours < 48 ? '1' : differenceInDays } day${ differenceInDays === 1 ? '' : 's' }` : `${ differenceInHours } hour${ differenceInHours === 1 ? '' : 's' }`,
                                 value: differenceInMinutes,
                              }
                           }

                           onChangeData('duration_in_minutes', newDuration)

                        }  }
                        datePickerClassName={ `!hidden` }
                        onClickLabelOpenDatePicker
                     />
                  </DropDownModal>
                  <CheckboxLabel
                     label='Save this as default duration'
                     padding='0'
                     checked={ data?.chat_countdown_default_duration_checked }
                     onChange={ () => {
                        onChangeData('chat_countdown_default_duration_checked', !data?.chat_countdown_default_duration_checked)
                     } }
                     textColor='secondary'
                     name={ `chat_countdown_default_duration_checked` }
                     checkboxSize='5'
                  />
                  <CheckboxLabel
                     label='Show countdown to member'
                     padding='0'
                     checked={ data?.show_duration_countdown_to_member }
                     onChange={ () => {
                        onChangeData('show_duration_countdown_to_member', !data?.show_duration_countdown_to_member)
                     } }
                     textColor='secondary'
                     name={ `show_duration_countdown_to_member` }
                     checkboxSize='5'
                  />
               </div>
               <div className='flex gap-3'>
                  <div>
                     <Icon
                        name={ 'warning' }
                        size={ '2xl' }
                        color={ `warning` }
                     />
                  </div>
                  <p className='text-secondary leading-5'>
                     Free messages disappear after the set time. Paid messages start their countdown once read.
                  </p>
               </div>
               <div className='w-full'>
                  <Button
                     text='Save'
                     backgroundColor='action'
                     textSize='small'
                     primaryColor
                     classNames='py-[9px] px-3.5 !rounded-lg w-full'
                     textClassName='!leading-[18px]'
                     padding='none'
                     textMarginX='0'
                     onClick={ () => {
                        if((buttonWithLoader === 'remove' && isFetchingChatSettingsUpdate)) {
                           return
                        }
                        setButtonWithLoader('save')
                        onClickSave()
                     } }
                     disabled={ !data?.duration_in_minutes }
                     isLoading={ buttonWithLoader === 'save' && isFetchingChatSettingsUpdate }
                     loaderSize={ '6' }
                  />
                  {
                     (!!durationInfo?.duration_in_minutes) && <>
                        <Button
                           text='Remove duration'
                           backgroundColor='transparent'
                           textSize='small'
                           iconName='delete'
                           textColor='error'
                           fontIconColor='error'
                           iconPosition='left'
                           classNames='py-[9px] px-3.5 !rounded-lg w-full mt-3 h-9'
                           textClassName='!leading-[18px]'
                           padding='none'
                           onClick={ () => {
                              if((buttonWithLoader === 'save' && isFetchingChatSettingsUpdate)) {
                                 return
                              }
                              setButtonWithLoader('remove')
                              onClickRemove()
                           } }
                           disabled={ !data?.duration_in_minutes }
                           isLoading={ buttonWithLoader === 'remove' && isFetchingChatSettingsUpdate }
                           loaderSize={ '6' }
                           loaderColor='error'
                        />
                     </>
                  }
               </div>
            </div>
         </div>
      </Modal>
   )
}


MessageDurationModal.propTypes = {
   onCloseUnsentDurationModal: PropTypes.func,
   durationInfo: PropTypes.object,
   onSaveDurationData: PropTypes.func,
   isFetchingChatSettingsUpdate: PropTypes.bool,
};

MessageDurationModal.defaultProps = {

};

export default MessageDurationModal;
