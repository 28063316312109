/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import { makeRandomString } from 'common/utils/utils';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import './index.scss';
import './videojs-overlay';
import 'videojs-overlay/dist/videojs-overlay.css';
import "videojs-hotkeys";
import 'videojs-vr/dist/videojs-vr';

const Plugin = videojs.getPlugin('plugin');

class LoopPlugin extends Plugin {

   constructor(player, options) {
      super(player, options);



      player.on('ready', () => {
         player.customRepeat = false;
         const button = player.controlBar.addChild('button', {
            controlText: 'Loop',
            className: 'custom-loop-button',
            clickHandler: function(event) {
               player.customRepeat = !Boolean(player.customRepeat);
               if(player.customRepeat) {
                  button.$('.vjs-icon-placeholder').classList.remove('ams-icon-repeat-off');
                  button.$('.vjs-icon-placeholder').classList.add('ams-icon-repeat');
               } else {
                  button.$('.vjs-icon-placeholder').classList.add('ams-icon-repeat-off');
                  button.$('.vjs-icon-placeholder').classList.remove('ams-icon-repeat');
               }
            },
         });
         button.$('.vjs-icon-placeholder').classList.add('ams-icon');
         button.$('.vjs-icon-placeholder').classList.add('ams-icon-repeat-off');
      });
      player.on('ended', () => {
         if(player.customRepeat) {
            player.play();
         }
      });
   }
}

videojs.registerPlugin('loopPlugin', LoopPlugin);

const VideoJsHlsPlayer = ({
   src,
   poster,
   onVideoPlay,
   isAutoPlay,
   loop = true,
   userInfo,
   isVrPlaying,
}) => {
   const videoId = `video-${ makeRandomString() }`;
   let playEventFired = false;
   useEffect(() => {
      if(window.videoJsPlayer) {
         window.videoJsPlayer.dispose();
      }
      window.videoJsPlayer = videojs(videoId, {
         autoplay: isAutoPlay ? 'any' : false,
         // loop: loop,
         poster: poster,
         bigPlayButton: true,
         loadingSpinner: true,
         aspectRatio: '16:9',
         textTrackSettings: false,
         playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
         plugins: {
            loopPlugin: {},
            hotkeys: {
               alwaysCaptureHotkeys: true,
               seekStep: 10,
            },
         },
      });
      window.videoJsPlayer.on('play', function(e) {
         if(!playEventFired) {
            playEventFired = true;
            if(onVideoPlay) {
               onVideoPlay(e);
            }
         }
      });
      window.videoJsPlayer.src({
         src,
         type: 'application/x-mpegURL',
      });
      window.videoJsPlayer.hlsQualitySelector({
         displayCurrentQuality: true,
      });
      if(isVrPlaying && window.videoJsPlayer.vr){
         window.videoJsPlayer.vr({ projection: '360' });
      }
      if(window.videoJsPlayer.overlay && (userInfo?.username || userInfo?.email)){
         const overlay =  window.videoJsPlayer.overlay({
            debug: true,
            overlays: [],
         });
         const br = userInfo?.username && userInfo?.email ? '<br/>' : ''
         overlay.add({
            content: `${ userInfo.username || '' } ${ br } ${ userInfo.email || '' }`,
            align: 'bottom-left',
            start: 'play',
            end: 'pause',
         });
      }

   }, []);

   useEffect(() => {
      if(!window.videoJsPlayer) return;

      window.videoJsPlayer.poster(poster);
      window.videoJsPlayer.trigger('posterchange');
   }, [poster])

   return (
      <div className='video-player-wrapper'>
         <video id={ videoId } className='vjs-16-9 video-js vjs-default-skin vjs-big-play-centered' controls playsInline>
         </video>
      </div>
   );
};

VideoJsHlsPlayer.propTypes = {
   src: PropTypes.string,
   poster: PropTypes.string,
   onVideoPlay: PropTypes.func,
   isAutoPlay: PropTypes.bool,
   loop: PropTypes.bool,
   userInfo: PropTypes.object,
   isVrPlaying: PropTypes.bool,
};

export default VideoJsHlsPlayer;
