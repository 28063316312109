import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'common/components/elements/inputs/inputs';
import Button from 'common/components/elements/buttons/primary';
import IconLabel from 'common/components/elements/icon-label';
import toast from 'common/utils/toast';

const CreateEdit = ({
   name,
   onSaveEditChange,
   id,
   onCloseModal,
   isDisabled,
   type = 'tag',
   closeManageTagCreateEditModal,
   tagsError,
   createTagsError = () => {},
   intoTagsContainer = false,
}) => {
   const [newName, setName] = useState(name);
   let limit = newName ? newName.length : 0;
   const isCollection = type === 'collection'
   const [isLoading, setIsLoading] = useState(false)

   const saveAndCloseModal = () => {
      setIsLoading(true)
      if(!intoTagsContainer){
         onSaveEditChange(id, newName, (isSucceed) => {
            if(!isSucceed){
               setIsLoading(false)
               return
            }
            if(!!closeManageTagCreateEditModal) {
               closeManageTagCreateEditModal()
            }
         })
      } else {
         onSaveEditChange(newName, (isSucceed) => {
            if(!isSucceed){
               setIsLoading(false)
               return
            }
            if(!!closeManageTagCreateEditModal) {
               closeManageTagCreateEditModal()
            }
         })
      }
   }

   const getButtonDisabled = () => {
      if(!isCollection) {
         return newName?.length < 3
      }
      return !!isDisabled || newName === name || newName?.length < 3;
   }

   return (
      <div className='rounded-label  w-full bg-panel border-solid border border-divider flex flex-col items-center'>
         <div className=' w-full px-4 p-[21px] flex flex-col gap-[21px]'>
            <Button
               iconName='close'
               fontIconColor='major'
               classNames='h-[15px] w-[15px] '
               backgroundColor='transparent'
               padding='none'
               onClick={ () => {
                  if(!!tagsError?.name) createTagsError({ success: true, name: '' })
                  onCloseModal()
               }  }
               alignment='start'
            />
            <div className='flex flex-col justify-center items-center gap-3'>
               <IconLabel
                  color='action'
                  name={ !isCollection ? 'tag-new' : 'collection' }
                  size='2xl'
                  className='h-10 w-10'
               />
               <span className=' text-major font-bold text-[20px] leading-[30px]'>{ !!id  ? `Edit` : `Create new`} { isCollection ? 'collection' : 'tag' }</span>
            </div>
         </div>
         <div className=' px-6 mb-[32px] w-full'>
            <p className='text-base mb-2 font-medium text-major'>{ !isCollection ? 'Tag' : 'Collection'} name</p>
            <Input
               value={ newName }
               name={ `${ type }_name` }
               onChange={ (name, value) => {
                  if(value.length > 25){
                     toast.error('25 character limit has been reached', { toastId: 'max-limit-error' })
                     return
                  }
                  setName(value)
               } }
               description={ !Boolean(tagsError?.name)  ?  `${ limit } of 25 characters` : null }
               descriptionPosition='right'
               placeholder={ !id ? `New ${ type } name` : `Edit a ${ type } name` }
               autoComplete='off'
               height='10'
               errorMessage={ Boolean(tagsError?.name) ? tagsError?.name : null }
               descriptionMarginBottom={ `0` }
               onInputFocus={ () => {
                  if(!!tagsError?.name) createTagsError({ success: true, name: '' })
               } }
            />
         </div>
         <div className=' w-full  flex justify-center m-8 mb-6 mt-0 rounded-lg overflow-hidden h-9 px-5 '>
            <Button
               onClick={ saveAndCloseModal }
               text={ !!id ? 'Save changes' : `Create ${ type }` }
               textColor='action'
               disabled={ getButtonDisabled() }
               borderRadius='large'
               primaryColor
               textSize='small'
               isLoading={ isLoading }
            />
         </div>
      </div>
   );
};
CreateEdit.propTypes = {
   onSaveEditChange: PropTypes.func,
   onCloseModal: PropTypes.func,
   name: PropTypes.string,
   isDisabled: PropTypes.bool,
   id: PropTypes.any,
   type: PropTypes.string,
   closeManageTagCreateEditModal: PropTypes.func,
   tagsError: PropTypes.object,
   createTagsError: PropTypes.func,
   intoTagsContainer: PropTypes.bool,
};


export default React.memo(CreateEdit);
