const initialState = {
   isFetchingPollInfo: true,
   isFetchingCreate: false,
   isFetchingUpdate: false,
   createEditErrors: {},
   isFetchingList: true,
   isFetchingByFilter: false,
   isFetchingNextPage: false,
   listData: {
      data: [],
   },
   isFetchingActionWithPoll: false,
   isFetchingSecondButtonAction: false,
   isFetchingPollDetails: true,
   overlappingModal: {
      isOpen: false,
      data: {},
   },
   createEditPollVotersCount: 0,
};

export default initialState;
