import _ from "lodash";

export const getMaxCountAnswerOptions = (answerOptions = [], primaryKey = 'votes_count') => {

   const maxItem = _.maxBy(answerOptions, (el) => el[primaryKey])


   if(!Number(maxItem?.[primaryKey])) return []

   return answerOptions.filter((el) => el[primaryKey] === maxItem[primaryKey]) || []
}
