import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'common/components/modules/modal'
import './style.scss'
import Button from 'common/components/elements/buttons/primary'
import IconLabel from 'common/components/elements/icon-label'
import CheckboxLabel from 'common/components/elements/inputs/checkbox-label'
import VaultItem from '../vault-item'
import { useDispatch, useSelector } from 'react-redux'
import { fetchResourcesOperation } from 'admin/state/modules/resources/operations'
import { resourcesSelector } from 'admin/state/modules/resources/selectors'
import { history } from 'admin/state/store';
import ConfirmModal from 'common/components/modules/modal-wrapper';
import toast from 'common/utils/toast'
import { getVaultByIds, vaultMultipleAction } from 'admin/api/AuthApi'
import { screenWidthSelector } from 'admin/state/modules/common/selectors'
import cx from 'classnames'
import PageLoader from 'common/components/modules/page-loading'

const CreateMultipleVaultPopup = ({
   isMobile,
   closeModal = () => {},
   vaults = [],
}) => {
   const dispatch = useDispatch()
   const resources = useSelector(resourcesSelector)
   const screenWidth = useSelector(screenWidthSelector)
   const [data, setData] = useState([])
   const [initialData, setInitialData] = useState([])
   const [isOpenDiscardModal, setIsOpenDiscardModal] = useState(false)
   const [isDontShow, setIsDontShow] = useState(false)
   const [isLoadingSaveButton, setIsLoadingSaveButton] = useState(false)
   const [isFetching, setIsFetching] = useState(true)
   const dataRef = useRef(null);
   let isVideoNotReady = !!data?.find(item => item?.type === 'video' && item?.video_optimization_status !== 'completed')

   useEffect(() => {
      const unblock = history.block(({ pathname }, action) => {
         if('REPLACE' !== action){
            if(checkDifferents()) {
               unblock();
               history.push(pathname);
            } else {
               setIsOpenDiscardModal(true)
            }
            return false;
         }
      })

      return unblock;
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data, initialData, history]);

   useEffect(() => {
      initData()
      dispatch(fetchResourcesOperation())

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {
      document.addEventListener('visibilitychange', handleVisibilityChange, true);
      window.addEventListener('content-update', bindSocketEvents);
      return () => {
         document.removeEventListener('visibilitychange', handleVisibilityChange, true);
         window.removeEventListener('content-update', bindSocketEvents);
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const initData = async () => {

      try {
         const ids = vaults?.filter(it => !!it?.id).map(it => it.id).join(',')
         const { data } = await getVaultByIds(ids)

         let modifiedData = vaults?.filter(it => !it?.id).map(it => {
            return {
               ...it,
               title: "",
               description: "",
               casts: [],
               collections: [],
               tags: [],
               original_name: it?.prevFiles?.[0]?.original_name,
            }
         })

         data.forEach(it => {
            modifiedData.push({
               ...it,
               title: it?.title ||  "",
               description: it?.description || "",
               casts: it?.casts?.map(it => it?.id) || [],
               collections: it?.collections?.map(it => it?.id) || [],
               tags: it?.tags?.map(it => it?.id) || [],
               full_src: it?.image_full_src || it?.video_poster_full_src,
               video_optimization_status: it?.type === 'video' ? it?.video_optimization_status || 'in_optimization_queue' : null,
            })
         });

         // console.log({ modifiedData, vaults });

         dataRef.current = modifiedData
         setData([...modifiedData])
         setInitialData([...modifiedData])
         setIsFetching(false)
      } catch (error) {
         console.log(error);
      }
   }

   const bindSocketEvents = e => {
      if(e){
         const { detail: { payload: video } } = e
         let modifiedData = [...dataRef.current]

         if(modifiedData?.length){
            modifiedData = modifiedData.map(it => {
               if(it.id === video.id && video.video_optimization_status !== 'optimizing'){
                  return {
                     ...it,
                     video_optimization_status: video?.video_optimization_status,
                     full_src: video?.video_poster_full_src,
                  }
               }
               return it
            })
            dataRef.current = modifiedData
            setData([...modifiedData])
         }
      }
   }

   const handleVisibilityChange = () => {
      if(document.visibilityState === 'visible') {
         let dataClone = [...dataRef.current]
         dispatch(fetchResourcesOperation('', resources => {
            const resourcesTags = resources?.tags || [];
            const resourcesCasts = resources?.cast_members || [];
            const resourcesCollections = resources?.collections || [];
            const modifiedData = dataClone.map(it => {
               const tags = [...it?.tags].filter(tag => resourcesTags.find(el => el.id === (tag?.id || tag)))
               const casts = [...it?.casts].filter(cast => resourcesCasts.find(el => el.id === (cast?.id || cast)))
               const collections = [...it?.collections].filter(collection => resourcesCollections.find(el => el.id === (collection?.id || collection)))

               return { ...it, tags, collections, casts }
            })

            setData([...modifiedData])
         })
         )
      }
   }

   const checkDifferents = () => {
      return JSON.stringify(data) === JSON.stringify(initialData)
   }

   const onChangeData = (input, index) => {
      setData(prev => {
         const newData = prev.map((it, idx) => {
            if(idx === index) {
               return {
                  ...it,
                  ...input,
               }
            }
            return it
         })

         dataRef.current = newData

         return newData
      })
   }

   const onCloseModal = (bool = true) => {
      if(!checkDifferents() && bool) {
         setIsOpenDiscardModal(true)
         return
      }
      closeModal()
   }

   const onClickSaveData = async () => {
      setIsLoadingSaveButton(true)
      try {
         const { data: newVaults } = await vaultMultipleAction({ data })
         toast.success('Your changes have been saved')
         setIteminLocalStorage()
         closeModal(Object.values(newVaults))
      } catch (error) {
         console.log(error);
         toast.error('Something went wrong')
      } finally {
         setIsLoadingSaveButton(false)
      }
   }

   const setIteminLocalStorage = () => {
      let date = new Date().getTime()
      localStorage.setItem('dont_show_create_multiple_vault_popup_30_days', date)
   }

   return (
      <Modal
         contentPosition={ !isMobile ? 'center' : 'bottom' }
         className='create-multiple-vaults-popup h-full overflow-hidden'
         otherClassName={ cx({
            'h-full': true,
            'w-full': isMobile,
            '!w-fit': !isMobile,
            // '!min-w-116': isFetching && !isMobile,
         }) }
      >
         <div className='flex flex-col gap-4 h-full bg-snow'>
            <div className='flex flex-col select-none'>
               <div className='flex justify-end items-center h-14 pr-[18px]'>
                  <Button
                     classNames='h-5 !w-5'
                     padding='none'
                     iconName='close'
                     backgroundColor='transparent'
                     fontIconColor='grey-yoda'
                     onClick={ () => onCloseModal(true) }
                  />
               </div>
               <div className='flex flex-col gap-3 justify-center items-center '>
                  <IconLabel
                     name={ 'vault' }
                     color='action'
                     size='2xl'
                     className='w-10 h-10 flex items-center justify-center'
                  />
                  <div className='flex flex-col gap-4 items-center'>
                     <p className='text-xl font-semibold text-major'>Save to Vault</p>
                     <p className='text-secondary'>Fill the details to upload in Vault</p>
                  </div>
               </div>
            </div>
            {
               isFetching ?
                  <PageLoader
                     withLoader
                     customStyle={ cx({
                        'flex-1': true,
                        'min-w-[578px]': vaults?.length === 1 && !isMobile,
                        'min-w-[917px]': vaults?.length > 1 && !isMobile,
                     }) }
                  />

                  :
                  <div className={ cx({
                     'grid max-sm:!grid-cols-1 md:grid-cols-3 max-md:grid-cols-2 gap-4 flex-1 ams-custom-scrool overflow-y-auto px-6': true,
                     '!grid-cols-1': data?.length === 1,
                     '!grid-cols-2 ': data?.length === 2,
                  }) }>
                     {
                        !!data?.length && data.map((vault, index) => {
                           return (
                              <VaultItem
                                 key={ index }
                                 isMobile={ isMobile }
                                 onChangeData={ (input) => onChangeData(input, index) }
                                 type={ vault.type }
                                 title={ vault.title }
                                 description={ vault.description }
                                 casts={ vault.casts }
                                 tags={ vault.tags }
                                 collections={ vault.collections }
                                 src={ vault.full_src }
                                 duration={ vault?.video_duration }
                                 // duration={ 12345 }
                                 resources={ resources }
                                 videoOptimizationStatus={ vault?.video_optimization_status }
                              />
                           )
                        })
                     }
                  </div>
            }
            <div className='flex flex-col gap-8 items-center justify-center w-full p-6 pt-0'>
               <CheckboxLabel
                  label="Don't show this for 30 days"
                  name='dont_show_this_for_30_days'
                  checked={ isDontShow }
                  onChange={ () => setIsDontShow(!isDontShow) }
                  padding='0'
                  labelClassName='text-sm font-medium !w-fit'
               />
               <div className='flex flex-col gap-3 items-center w-full'>
                  <Button
                     text='Save'
                     padding='none'
                     textSize='base'
                     classNames='h-10 w-full px-3'
                     primaryColor
                     onClick={ onClickSaveData }
                     borderRadius='large'
                     disabled={ checkDifferents() && !isVideoNotReady }
                     isLoading={ isLoadingSaveButton }
                  />
                  <Button
                     text='Skip'
                     padding='none'
                     textSize='base'
                     backgroundColor='transparent'
                     textColor='secondary'
                     classNames='h-10 px-3'
                     onClick={ () => {
                        setIteminLocalStorage()
                        onCloseModal(true)
                     } }
                     borderRadius='large'
                     disabled={ false }
                     isLoading={ false }
                  />
               </div>
            </div>
         </div>
         {
            isOpenDiscardModal &&
            <ConfirmModal
               type='confirm'
               icon='cancel'
               iconColor='error'
               iconSize='2xl'
               title='Discard changes?'
               description='Changes you have made so far will not be saved'
               buttonClassName='mt-8'
               nonPrimaryColor
               textColor='error'
               isCloseAction
               isMobile={ screenWidth < 701 }
               action={ () => onCloseModal(false) }
               cancelButtonAction={ () => setIsOpenDiscardModal(false) }
               onCloseModal={ () => setIsOpenDiscardModal(false) }
            />
         }
      </Modal>
   )
}

CreateMultipleVaultPopup.propTypes = {
   isMobile: PropTypes.bool,
   closeModal: PropTypes.func,
   vaults: PropTypes.array,
}

export default CreateMultipleVaultPopup
