import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Filter from 'common/components/responsive/filter';
import Button from 'common/components/elements/buttons/primary';
import cx from 'classnames';
import MobileFilter from 'common/components/modules/mobile-filter';
import Search from 'common/components/elements/inputs/search';
import { useSelector } from 'react-redux';
import { resourcesSelector } from 'admin/state/modules/resources/selectors';

const INITIAL_FILTERS = {
   tags: { name: null,  value: [] },
   casts: { name: null,  value: [] },
   sortBy: { name: '', value: 'date_added:desc' },
   collections: { name: null,  value: [] },
   chatContentStatus: { name: null,  value: [] },
}

const VaultFilters = ({
   onFilterChange = () => {},
   desktopType,
   onClearAllFilters = () => {},
   isInsideModal,
   filtersData = {},
   isReorderMode = false,
   contentType = '',
   onSearchChange,
   isMobile,
   isMultipleAttachments,
}) => {

   const resources = useSelector(resourcesSelector)

   const allSorts = [
      { name: 'Newest', value: 'date_added:desc' },
      { name: 'Oldest ', value: 'date_added:asc' },
      { name: 'Name • A - Z', value: 'name:asc' },
      { name: 'Name • Z - A', value: 'name:desc' },
   ]

   const chatContentStatuses = [
      { name: 'Sent', value: 'sent' },
      { name: 'Sent locked', value: 'locked' },
      { name: 'Unlocked', value: 'unlocked' },
      { name: 'Unsent', value: 'unsent' },
   ]

   const [filters, setFilters] = useState({ ...INITIAL_FILTERS })

   const hideClearButton = filters?.sortBy?.value === 'date_added:desc' && !filters?.tags?.name && !filters?.casts?.name && !filters?.collections?.name && !filters?.chatContentStatus?.name

   useEffect(() => {
      if(isReorderMode) {
         setFilters({ ...INITIAL_FILTERS })
      }
   }, [isReorderMode])

   useEffect(() => {
      const getDefaultFilters = () => {
         let tags = { name: null,  value: [] }
         let casts = { name: null,  value: [] }
         let sortBy = { name: '', value: 'date_added:desc' }
         let collections = { name: null,  value: [] }
         let chatContentStatus = { name: null,  value: [] }

         function valueOfNumber(arr) {
            var i, n = arr.length;
            for(i = 0; i < n; ++i) {
               arr[i] = +arr[i];
            }
            return
         }

         if(filtersData.tags){
            let arr = filtersData.tags.split(',')
            valueOfNumber(arr)
            tags = { name: `${ arr.length } Selected`, value: arr }
         }
         if(filtersData.casts){
            let arr = filtersData.casts.split(',')
            valueOfNumber(arr)
            casts = { name: `${ arr.length } Selected`, value: arr }
         }
         if(filtersData.sortBy){
            sortBy = allSorts.find(i => i.value === filtersData.sortBy)
         }
         if(filtersData.collections){
            let arr = filtersData.collections.split(',')
            valueOfNumber(arr)
            collections = { name: `${ arr.length } Selected`, value: arr }
         }
         if(!!filtersData?.chatContentStatus?.length){
            let arr = filtersData?.chatContentStatus?.split(',')
            chatContentStatus = { name: `${ arr.length } Selected`, value: arr }
         }
         return { tags, casts, sortBy, collections, chatContentStatus }
      }
      if(!isReorderMode && !isInsideModal) {
         const defaultValue = getDefaultFilters()
         setFilters(defaultValue)
      }
      if(isMultipleAttachments && isInsideModal) {
         const defaultValue = getDefaultFilters()
         setFilters(defaultValue)
      }

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isMultipleAttachments])

   function onMultiplFilter(value, name, type, bool, callback){
      let data = []

      if(filters[type] && filters[type].value && Array.isArray(filters[type].value)) {
         data = filters[type].value
      }
      if(data && Array.isArray(data) && data.includes(value)) {
         data = data.filter(i => i !== value)
      } else {
         data = [...data, value]
      }

      setFilters({
         ...filters,
         [type]: {
            name: data.length === 0 ? null : `${ data.length } Selected`,
            value: data,
         },
      })
      if(bool) {
         onFilterChange(data, type)
      }
      if(callback) callback(data, type)
   }
   function onSimpleFilter(value, name, type){
      setFilters({
         ...filters,
         [type]: {
            name: value === 'date_added:desc' ? '' : name,
            value,
         },
      })
      onFilterChange(value, type)
   }
   function onClearFilter(isSimple, type, defaultValue){
      let emptyValue = isSimple ? {} : [];
      if(defaultValue) {
         emptyValue = defaultValue
      }
      if(type === 'sortBy') {
         emptyValue = {
            name: '',
            value: allSorts[0].value,
         }
      }
      setFilters({
         ...filters,
         [type]: emptyValue,
      })
      onFilterChange(isSimple ? '' : [], type)
   }

   const onClickClearAll = () => {
      setFilters({ ...INITIAL_FILTERS })
      onClearAllFilters()
   }

   const calculateFiltersCount = () => {
      let count = 0

      Object.keys(filters).forEach(key => {
         if(typeof filters[key].value === 'string') {
            if(key === 'sortBy' && filters[key].value === 'date_added:desc') {
               return;
            } else {
               count += 1
            }
         } else if(Array.isArray(filters[key].value)) {
            count += filters[key].value.length
         }
      })

      return count;
   }



   return (
      <>
         {
            !isMobile && (
               <div
                  className={ cx({
                     'flex flex-wrap  photoset-filter z-20 max-lg:flex-col !justify-between gap-2 w-full': true,
                  }) }
               >
                  <div
                     className={ cx({
                        'flex z-20 gap-2 !w-fit flex-wrap': true,
                        'overflow-x-auto photoset-filter-mobile max-w-full': desktopType === 'mobile',
                     }) }
                  >
                     <Filter
                        isMultiplSelect={ true }
                        isSearchable={ resources.tags && resources.tags.length > 10 }
                        activeSelectedValue={ filters.tags }
                        filterNameKey='name'
                        filterValueKey='id'
                        searchPlaceholder='Search tags'
                        name='Content tags'
                        type='filter'
                        data={ resources.tags || [] }
                        desktopType={ desktopType }
                        onFilterChange={ (value, name, bool) => onMultiplFilter(value, name, 'tags', true) }
                        onClickShowResult={ () => {} }
                        onClickClear={ () => {
                           onClearFilter(false, 'tags')
                        } }
                        height='10'
                        contentStyles={ {
                           left: '0px',
                           top: '44px',
                        } }
                        filterClassName='flex-none'
                        disabled={ isReorderMode }
                        contentClassName={ `!min-h-[30vh] ms:!min-h-[100%] !max-h-[250px] ` }
                     />
                     <Filter
                        isMultiplSelect={ true }
                        isSearchable={ resources.vault_collections && resources.vault_collections.length > 10 }
                        activeSelectedValue={ filters.collections }
                        filterNameKey='title'
                        filterValueKey='id'
                        searchPlaceholder='Search collections'
                        name='Collections'
                        type='filter'
                        data={ resources.vault_collections || [] }
                        desktopType={ desktopType }
                        onFilterChange={ (value, name, bool) => onMultiplFilter(value, name, 'collections', true) }
                        onClickShowResult={ () => {} }
                        onClickClear={ () => {
                           onClearFilter(false, 'collections')
                        } }
                        height='10'
                        contentStyles={ {
                           left: '0px',
                           top: '44px',
                        } }
                        filterClassName='flex-none'
                        disabled={ isReorderMode }
                        contentClassName={ `!min-h-[30vh] ms:!min-h-[100%] !max-h-[250px] ` }
                     />
                     <Filter
                        isMultiplSelect={ true }
                        isSearchable={ resources.cast_members && resources.cast_members.length > 10 }
                        activeSelectedValue={ filters.casts }
                        filterNameKey='screen_name'
                        filterValueKey='id'
                        searchPlaceholder='Search cast members'
                        name='Cast members'
                        type='filter'
                        data={ resources.cast_members || [] }
                        desktopType={ desktopType }
                        onFilterChange={ (value, name, bool) => onMultiplFilter(value, name, 'casts', true) }
                        onClickShowResult={ () => {} }
                        onClickClear={ () => {
                           onClearFilter(false, 'casts')
                        } }
                        height='10'
                        contentStyles={ {
                           left: '0px',
                           top: '44px',
                        } }
                        filterClassName='flex-none'
                        disabled={ isReorderMode }
                        contentClassName={ `!min-h-[30vh] ms:!min-h-[100%] !max-h-[250px] ` }
                     />
                     {
                        contentType === 'chat' &&
                        <Filter
                           isMultiplSelect
                           activeSelectedValue={ filters.chatContentStatus }
                           name='Chat status'
                           type='filter'
                           data={ chatContentStatuses }
                           desktopType={ desktopType }
                           onFilterChange={ (value, name, bool) => onMultiplFilter(value, name, 'chatContentStatus', true) }
                           onClickShowResult={ () =>  {} }
                           onClickClear={ () => {
                              onClearFilter(false, 'chatContentStatus')
                           } }
                           height='10'
                           contentStyles={ {
                              left: '0px',
                              top: '44px',
                           } }
                           filterClassName='flex-none'
                           className='intercom-chat-status-filter'
                        />
                     }
                     {
                        !hideClearButton && (
                           <div className='h-10 mx-2 whitespace-nowrap inline-block'>
                              <Button
                                 text='Clear all'
                                 textColor='secondary'
                                 iconName='cancel'
                                 fontIconColor='secondary'
                                 fontIconSize='large'
                                 iconPosition='left'
                                 backgroundColor='transparent'
                                 textSize='base'
                                 onClick={ onClickClearAll }
                                 classNames='h-full px-2 flex items-center'
                                 padding='none'
                              />
                           </div>

                        )
                     }
                  </div>
               </div>
            )
         }
         <div className={
            cx({
               'w-full search-inside-modal flex gap-3 h-fit justify-end': true,
               '!w-fit': !isMobile,
               'max-xxl:flex-wrap-reverse': !isMobile && isInsideModal,
            })
         }>
            {
               !isMobile &&
               <div className='z-10 flex flex-row gap-2'>
                  <Filter
                     name='Sorting'
                     type='sort'
                     data={ allSorts }
                     desktopType={ desktopType }
                     onFilterChange={ (value, name) => onSimpleFilter(value, name, 'sortBy') }
                     onClickShowResult={ () =>  onFilterChange(filters.sortBy.value, 'sortBy') }
                     activeSelectedValue={ {
                        name: filters?.sortBy?.name,
                        value: filters?.sortBy?.value,
                     } }
                     onClickClear={ () => {
                        const defaultValue = {
                           name: 'Newest',
                           value: 'date_added:desc',
                        }
                        onClearFilter(true, 'sortBy', defaultValue);
                     } }
                     height='10'
                     contentStyles={ {
                        right: '0px',
                        top: '44px',
                        // left: '4px',
                     } }
                     filterClassName='flex-none'
                     disabledState={ isReorderMode ? ['name:asc', 'name:desc', 'comments_count:desc', 'comments_count:asc', 'likes_count:desc', 'views_count:desc'] : [] }
                     hasClearButton={ false }
                     hasFilterButtonContent={ false }
                     isAutoSave
                     labelTooltipText='This view cannot be reordered'
                  />
               </div>
            }
            <div className={
               cx({
                  'max-w-[320px] mt-0 w-[320px]': !isMobile,
                  'w-full': isMobile,
               })
            }>
               <Search
                  onChange={ onSearchChange }
                  placeholder='Search'
                  isOutSideControll
                  value={ filtersData?.search }
                  disabled={ isReorderMode }
               />
            </div>
            {
               isMobile && (
                  <MobileFilter
                     sortOptions={ allSorts }
                     selectedSortValue={ filters.sortBy.value }
                     sortName='sortBy'
                     disabledSortOptions={ isReorderMode ? ['name:asc', 'name:desc', 'comments_count:desc', 'comments_count:asc', 'likes_count:desc', 'views_count:desc'] : [] }
                     disabledSortOptionTooltipText='This view cannot be reordered'
                     onFilterChange={ (value, name, filterName, isMultiple) => {
                        if(!isMultiple) {
                           onSimpleFilter(value, name, filterName)
                        } else {
                           onMultiplFilter(value, name, filterName, true)
                        }
                     } }
                     showClearAllFilters={ !hideClearButton }
                     onClickClearAllFilters={ onClickClearAll }
                     filtersCount={ calculateFiltersCount() }
                     filtersList={ [
                        {
                           title: 'Content tags',
                           isMultiple: true,
                           filterName: 'tags',
                           optionNameKey: 'name',
                           optionValueKey: 'id',
                           options: resources.tags || [],
                           selectedFilter: filters.tags,
                           isSearchable: resources.tags && resources.tags.length > 10,
                           searchPlaceholder: 'Search tags',
                           isEmpty: resources.tags && resources.tags.length === 0,
                           disabled: isReorderMode,
                        },
                        {
                           title: 'Collections',
                           isMultiple: true,
                           filterName: 'collections',
                           optionNameKey: 'title',
                           optionValueKey: 'id',
                           options: resources.vault_collections || [],
                           selectedFilter: filters.collections,
                           isSearchable: resources.vault_collections && resources.vault_collections.length > 10,
                           searchPlaceholder: 'Search collections',
                           isEmpty: resources.vault_collections && resources.vault_collections.length === 0,
                           disabled: isReorderMode,
                        },
                        {
                           title: 'Cast members',
                           isMultiple: true,
                           filterName: 'casts',
                           optionNameKey: 'screen_name',
                           optionValueKey: 'id',
                           options: resources.cast_members || [],
                           selectedFilter: filters.casts,
                           isSearchable: resources.cast_members && resources.cast_members.length > 10,
                           searchPlaceholder: 'Search cast members',
                           isEmpty: resources.cast_members && resources.cast_members.length === 0,
                           disabled: isReorderMode,
                        },
                        {
                           hide: !isInsideModal || contentType === 'store',
                           title: 'Chat status',
                           isMultiple: true,
                           filterName: 'chatContentStatus',
                           optionNameKey: 'name',
                           optionValueKey: 'value',
                           options: chatContentStatuses,
                           selectedFilter: filters.chatContentStatus,
                           className: 'intercom-chat-status-filter',
                        },
                     ] }
                  />
               )
            }
         </div>
      </>
   );
};

VaultFilters.propTypes = {
   onFilterChange: PropTypes.func,
   desktopType: PropTypes.string,
   onClearAllFilters: PropTypes.func,
   isInsideModal: PropTypes.bool,
   filtersData: PropTypes.object,
   isReorderMode: PropTypes.bool,
   contentType: PropTypes.string,
   onSearchChange: PropTypes.func,
   isMobile: PropTypes.bool,
   isMultipleAttachments: PropTypes.bool,
};

export default React.memo(VaultFilters);
