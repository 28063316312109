import PropTypes from 'prop-types';
import CheckboxLabel from "common/components/elements/inputs/checkbox-label";
import Button from 'common/components/elements/buttons/primary';
import Intercom from 'common/utils/Intercom';
import { ARTICLE_ID_ON_SFW_CHECKBOX } from 'common/utils/intercom-articles';

const SfwCheckbox = ({
   label,
   checked,
   name,
   onChange,
   overrideCheckboxLabelProps = {},
}) => {

   return (
      <CheckboxLabel
         padding='0'
         checkboxSize='5'
         label={ label }
         textSize='base'
         lineHeight='20px'
         checked={  checked }
         name={ name }
         onChange={ onChange }
         { ...overrideCheckboxLabelProps }
      >
         <div
            className='w-full flex items-center gap-2'
         >
            <span
               className='text-major leading-5'
            >
               { label }
            </span>
            <Button
               iconName='help-outline'
               classNames='!w-fit'
               backgroundColor='transparent'
               padding={ 0 }
               fontIconSize='extraLarge'
               fontIconColor='placeholder'
               onClick={ (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  Intercom.showArticle(ARTICLE_ID_ON_SFW_CHECKBOX)
               } }
               dataToolTipContent='Learn more about SFW content'
            />
         </div>
      </CheckboxLabel>
   )
}

SfwCheckbox.propTypes = {
   label: PropTypes.string,
   checked: PropTypes.bool,
   name: PropTypes.string,
   onChange: PropTypes.func,
   overrideCheckboxLabelProps: PropTypes.object,
}

export default SfwCheckbox;
