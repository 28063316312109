import PropTypes from 'prop-types'
import React, { memo, useState } from 'react'
import ConfirmModal from 'common/components/modules/modal-wrapper';
import CheckboxLabel from 'common/components/elements/inputs/checkbox-label';


const WarningModal = ({
   isMobile,
   type,
   onClickAction,
   photosSortOption = '',
   isLoading = false,
}) => {
   const details = {
      photoset: {
         title: 'Changes will affect unlocked photosets',
         description: 'Any changes will also be be made to previous unlocks of this photoset',
         name: 'dont_show_add_or_delete_photos_warning',
      },
      video: {
         title: 'Unlocks will receive updated video',
         description: 'Any members who unlocked the previous version of this video will see the new version in their “My Unlocks” page.',
         name: 'dont_show_replace_video_warning',
      },
      photos_sort: {
         title: 'Change & save order?',
         description: `Are you sure you want to change the order of this photoset? The new order will be saved, and the action cannot be reversed.`,
         name: 'dont_show_photos_sort_warning',
         buttonText: 'Yes, change & save the order',
         hideCloseButton: true,
         hideCheckbox: true,
      },
   }

   const [value, setValue] = useState()

   return (
      <ConfirmModal
         onCloseModal={ _ => onClickAction('close', value) }
         isCloseAction={ !isLoading }
         type='confirm'
         icon='warning'
         withoutCloseButton={ details[type]?.hideCloseButton }
         iconColor='warning'
         title={ details[type].title }
         description={ details[type].description }
         cancelText='Cancel'
         buttonText={ details[type]?.buttonText || 'Ok, continue' }
         buttonClassName='mt-8'
         action={ () => onClickAction('action', value) }
         iconSize='2xl'
         isMobile={ isMobile }
         isPreventDeafault={ false }
         isLoading={ isLoading }
         cancelButtonProperties={ {
            disabled: isLoading,
         } }
      >
         {
            !details[type]?.hideCheckbox &&
            <CheckboxLabel
               label='Don’t show again'
               onChange={ (_, value) => {
                  setValue(value)
               } }
               name={ details[type].name }
               textColor='secondary'
               checked={ value }
               className='!w-fit mx-auto mt-4'
               padding='0'
            />
         }
      </ConfirmModal>
   )
}

WarningModal.propTypes = {
   isMobile: PropTypes.bool,
   type: PropTypes.string,
   onClickAction: PropTypes.func,
   photosSortOption: PropTypes.any,
   isLoading: PropTypes.bool,
}

WarningModal.defaultProps = {
   type: 'photoset',
}

export default memo(WarningModal)
