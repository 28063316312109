const getSettingsGroup = () => {
   const data = {
      // Polls, Achievements
      content: ['unlock', 'new_video_published', 'pre_release_video', 'new_photoset_published', 'pre_release_photoset'], //new_blog_post_published
      store: ['store_item_sold', 'bundle_sold', 'new_bundle_published'], //new_bundle_published, bundle_sold, <custom-category> sold
      membership: ['membership_new_join', 'membership_new_join_with_public_promotion', 'membership_cancellation', 'membership_expirations'],
      // membership: ['membership_new_join', 'membership_new_join_with_public_promotion', 'membership_cancellation', 'membership_rebill_failure', 'membership_expiration_due_to_failed_rebill', 'membership_expirations'],
      tip: ['tip', 'tip_during_livestream', 'tip_on_content'],
      promotion: ['new_promotion', 'promotion_24_hours_left'],
      livestream: ['livestream_scheduled', 'livestream_started'], //livestream_cancelled, livestream_scheduled
      poll: ['new_poll_published', 'poll_24_hours_left', 'poll_ended'],
   }
   return data
}
const isCustomNotification = (slug) => {
   return ['custom_store_category'].includes(slug)
}

const getCustomNotificationSlug = (type) => {
   let slug = '';
   switch (type) {
      case 'custom_store_category':
         slug = '<custom-category>';
         break;
      case 'custom_tag':
         slug = '<tag-name>';
         break;

      default:
         break;
   }
   return slug;
}

const getBaseVariablesList = (info = {
   url: '', siteName: '', screenName: '', adminsState: '',
}, excludedVariables = []) => {
   const urlLength = info.url.length
   const variables = [
      { text: '<site-name>', valueLimit: info.siteName.length, base: true },
      { text: '<site-link>', valueLimit: urlLength, base: true },
      { text: '<member-username>', valueLimit: 30, base: true },
      { text: '<admin-screen-name>', valueLimit: info.screenName.length, base: true },
      { text: '<I/we>', valueLimit: 'multiple' === info.adminsState ? 2 : 1, base: true },
      { text: '<my/our>', valueLimit: 'multiple' === info.adminsState ? 3 : 2, base: true },
      { text: '<I’m/we’re>', valueLimit: 'multiple' === info.adminsState ? 5 : 3, base: true },
      { text: '<I’ve/we’ve>', valueLimit: 'multiple' === info.adminsState ? 5 : 4, base: true },
      { text: '<me/us>', valueLimit: 2, base: true },
   ]
      .filter(it => !excludedVariables.includes(it.text));

   return variables
}
const getVariablesValue = (info = {
   url: '', siteName: '', screenName: '', adminsState: '', contentLink: '', pageId: '', title: '',
}) => {
   let variablesValue = {
      '<I/we>': 'multiple' === info.adminsState ? 'We' : 'I',
      '<my/our>': 'multiple' === info.adminsState ? 'Our' : 'My',
      '<I’m/we’re>': 'multiple' === info.adminsState ? 'We’re' : 'I’m',
      '<me/us>': 'multiple' === info.adminsState ? 'Us' : 'Me',
      '<I’ve/we’ve>': 'multiple' === info.adminsState ? 'We’ve' : 'I’ve',
      '<admin-screen-name>': info.screenName,
      '<link-of-content>': info.contentLink || info.url,
      '<promotion-link>': info.url + '/promotions/' + info.pageId || '',
      '<livestream-link>': info.url + '/livestream/',
      '<site-name>': info.siteName,
      '%site-name%': info.siteName,
   }
   if(info.title){
      variablesValue = {
         ...variablesValue,
         '<item-name>': info.title,
         '%item-name%': info.title,
      }
   }

   return variablesValue;
}
const getIndividualVariablesList = (info = {
   url: '', siteName: '', screenName: '', adminsState: '',
}) => {
   const urlLength = info.url.length
   const variables = [
      { text: '<percentage-discount>', valueLimit: 12 },
      { text: '<promotion-name>', valueLimit: 50 },
      { text: '<content-title>', valueLimit: 100 },
      { text: '<content-type>', valueLimit: 'recorded stream'.length },
      { text: '<link-of-content>', valueLimit: info?.url.length + '/recorded-streams/'.length + 6 },
      { text: '<membership-name>', valueLimit: 50 },
      { text: '<expiry-date>', valueLimit: 28 },
      { text: '<expiry-date>', valueLimit: 28 },
      { text: '<payment-processor>', valueLimit: 6 },
      { text: '<item-name>', valueLimit: 50 },
      { text: '<livestream-link>', valueLimit: urlLength + '/livestream/'.length },
      { text: '<promotion-link>', valueLimit: urlLength + '/promotions/'.length + 6 },
      { text: '<decline-reason>', valueLimit: null },
      { text: '<tip-amount>', valueLimit: 6 },
      { text: '<scheduled-livestream-date>', valueLimit: 27 },
      { text: '<livestream-name>', valueLimit: 43 },
      { text: '<livestream-description>', valueLimit: 2000 },
      { text: '<scheduled-publish-date>', valueLimit: 30 },
      { text: '<poll-question>', valueLimit: 50 },
      { text: '<poll-name>', valueLimit: 50 },
   ];

   return variables;
}
const getVariablesList = (info = {
   url: '', siteName: '', screenName: '', adminsState: '',
}) => {
   const baseVariablesList = getBaseVariablesList(info);
   const individualVariables = getIndividualVariablesList(info);
   const variables = [...baseVariablesList, ...individualVariables];

   return variables;
}
const getSubjectVariablesList = (variableList, slug) => {
   let allowedVariables = [
      '<I/we>', '<my/our>', '<I’m/we’re>', '<me/us>', '<I’ve/we’ve>',
      '<site-name>', '<member-username>', '<admin-screen-name>', '<content-type>', '<item-name>', '<promotion-name>', '<percentage-discount>', '<tip-amount>',
      '<poll-question>', '<poll-name>',
   ]
   // const notAllowedVariables = [
   //    '<site-link>', '<site-name>', '<link-of-content>', '<promotion-link>', '<livestream-link>',  '<livestream-name>', '<livestream-description>', '<content-title>',
   // ]
   variableList = variableList.filter(variable => allowedVariables.includes(variable.text));
   return variableList;
}

const getAutoTweetVariablesList = (variableList) => {
   const notAllowedVariables = [
      '<site-link>', '<member-username>', '<livestream-name>', '<livestream-description>', '<link-of-content>', '<scheduled-publish-date>', '<promotion-link>',
   ]
   variableList = variableList.filter(variable => !notAllowedVariables.includes(variable.text));
   return variableList;
}
const getSettingsInfo = () => {
   const data = {
      unlock: {
         title: 'Unlock',
         description: 'Sent when someone unlocks content',
         variables: ['<content-title>', '<content-type>', '<link-of-content>'],
      },
      new_video_published: {
         title: 'New video published',
         description: 'Sent when a new video is published',
         variables: ['<content-title>',  '<link-of-content>'],
      },
      pre_release_video: {
         title: 'Prereleased video',
         description: 'Sent when prerelease is available - sent only to those with access to stream or unlock the prerelease',
         variables: ['<content-title>',  '<link-of-content>', '<scheduled-publish-date>'],
      },
      pre_release_photoset: {
         title: 'Prereleased photoset',
         description: 'Sent when prerelease is available - sent only to those with access to stream or unlock the prerelease',
         variables: ['<content-title>',  '<link-of-content>', '<scheduled-publish-date>'],
      },
      new_photoset_published: {
         title: 'New photoset published',
         description: 'Sent when a new photoset is published',
         variables: ['<content-title>',  '<link-of-content>'],
      },
      new_blog_post_published: {
         title: 'New blog post published',
         description: 'Sent when a new blog post is published',
      },
      new_bundle_published: {
         title: 'New bundle published',
         description: 'Sent when a new bundle is published',
         variables: ['<item-name>'],
      },
      bundle_sold: {
         title: 'Bundle sold',
         description: 'Sent when someone unlocks a bundle',
         variables: ['<item-name>'],
      },
      store_item_sold: {
         title: 'Store item sold',
         description: 'Sent when someone buys a store item',
         variables: ['<item-name>'],
      },
      custom_store_category: { //Custom notification
         title: '<custom-category> sold',
         description: 'Sent when someone buys something from <custom-category>',
         variables: ['<item-name>'],
      },
      membership_new_join: {
         title: 'New join',
         description: 'Sent when someone joins your site not using a promotion',
      },
      membership_new_join_with_public_promotion: {
         title: 'New join with public promotion',
         description: 'Sent when user gets public promo deal',
         variables: ['<percentage-discount>', '<promotion-link>'],
      },
      membership_cancellation: {
         title: 'Cancelation',
         description: 'Sent to member when they cancel their subscription (turn rebills off)',
         variables: ['<expiry-date>'],
      },
      membership_rebill_failure: {
         title: 'Rebill failure',
         description: 'Sent to member if a subscription payment fails to rebill',
         variables: ['<decline-reason>', '<payment-processor>'],
      },
      membership_expiration_due_to_failed_rebill: {
         title: 'Expiration due to failed rebill',
         description: 'Sent to member when membership expires after 3 failed rebills',
         variables: ['<decline-reason>'],
      },
      membership_expirations: {
         title: 'Expirations',
         description: 'Sent to member when their subscription expires',
      },
      tip: {
         title: 'Tip',
         description: 'Sent when someone tips',
         variables: ['<tip-amount>'],

      },
      tip_during_livestream: {
         title: 'Tip during livestream',
         description: 'Sent when someone tips during a livestream',
         variables: ['<tip-amount>'],
      },
      tip_on_content: {
         title: 'Tip on content',
         description: 'Sent when member sends a tip for specific content',
         variables: ['<content-title>', '<link-of-content>', '<tip-amount>', '<content-type>'],
      },
      new_promotion: {
         title: 'New promotion',
         description: 'Sent when promotion is published on your site',
         variables: ['<promotion-name>', '<percentage-discount>'],
      },
      promotion_24_hours_left: {
         title: '24 hours left',
         description: 'Sent when there is only 24 hours left to get the promotion',
         variables: ['<promotion-name>', '<percentage-discount>'],
      },
      livestream_scheduled: {
         title: 'Livestream scheduled',
         description: 'Sent when new livestream is scheduled',
         variables: ['<livestream-name>', '<livestream-description>'],
      },
      livestream_started: {
         title: 'Livestream started',
         description: 'Sent when new livestream is started',
         variables: ['<livestream-name>', '<livestream-description>'],
      },
      new_poll_published: {
         title: 'New poll published',
         description: 'Sent when a poll is published',
         variables: ['<poll-question>', '<poll-name>'],
      },
      poll_24_hours_left: {
         title: '24 hours left',
         description: 'Sent when there is only 24 hours left to submit the vote',
         variables: ['<poll-question>', '<poll-name>'],
      },
      poll_ended: {
         title: 'Poll ended',
         description: 'Sent when a poll ends',
         variables: ['<poll-question>', '<poll-name>'],
      },
   }
   return data
}
const getIsCannotBeTurnedOff = (name, slug) => {
   return 'individual_private_message' === name && !['membership_new_join_with_public_promotion'].includes(slug);
}

export {
   getSettingsInfo,
   getSettingsGroup,
   isCustomNotification,
   getCustomNotificationSlug,
   getVariablesList,
   getBaseVariablesList,
   getIndividualVariablesList,
   getVariablesValue,
   getSubjectVariablesList,
   getAutoTweetVariablesList,
   getIsCannotBeTurnedOff,
}
