import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';

// import WaveSurfer from 'https://unpkg.com/wavesurfer.js@beta'
import Button from 'common/components/elements/buttons/primary';
import { getDuration } from 'common/utils/audioRecording';
import './style.scss'
import Icon from 'common/components/elements/icons'

const useWavesurfer = (containerRef, trackRef, option) => {
   const [wavesurfer, setWavesurfer] = useState(null)

   // Initialize wavesurfer when the container mounts
   // or any of the props change
   useEffect(() => {
      if(!containerRef.current) return
      if(!trackRef?.current) return
      let ws = null;
      try {
         ws = WaveSurfer.create({
            ...option,
            container: containerRef.current,
            cursorColor: 'transparent',
            barGap: 0.5,
            barWidth: 2,
            barRadius: 2,
            barMinHeight: 2,
            fillParent: true,
            normalize: true,
            cursorWidth: 1,
            responsive: true,
            hideScrollbar: true,
         })
         // if(option.url){
         //    console.log(111)
         // }
         ws.load(trackRef?.current);

         setWavesurfer(ws)

      } catch (error) {
         console.log(error)
      }

      return () => {
         if(ws){
            ws.destroy()
         }
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   return wavesurfer
}
const Waveform = ({
   type,
   url,
   duration,
   height = 24,
   colorState = {
      waveColor: '#3440541f',
      progressColor: '#344054',
   },
   textColor,
   isUnlocked,
   isAdmin,
   price,
}) => {

   let waveformRef = useRef();
   let trackRef = useRef();
   const [playing, setPlaying] = useState(false);
   const [currentTime, setCurrentTime] = useState(getDuration(duration, true))
   const wavesurfer = useWavesurfer(waveformRef, trackRef, { url: url, height, ...colorState })
   const onPlayClick = useCallback(() => {
      let { activeWavesurfer } = window;
      if(activeWavesurfer && !wavesurfer.isPlaying()){
         activeWavesurfer.pause()
      }
      if(wavesurfer.isPlaying()){
         wavesurfer.pause();
      } else {
         if(window?.activeWavesurfer){
            window?.activeWavesurfer.pause()
         }
         wavesurfer.play();
         window.activeWavesurfer = wavesurfer;
      }
      // wavesurfer.empty()
   }, [wavesurfer])

   useEffect(() => {
      if(!wavesurfer) return
      setPlaying(false)

      const subscriptions = [
         wavesurfer.on('play', () => {
            setCurrentTime('00:00')

            setPlaying(true)
         }),
         wavesurfer.on('pause', () => {
            setPlaying(false)
         }),
         wavesurfer.on('audioprocess', (currentTime) => {
            setCurrentTime(getDuration(currentTime, true))
         }),
      ]

      return () => {
         subscriptions.forEach((unsub) => {
            if(unsub && 'function' === typeof unsub){
               unsub()
            }
         })
         if(wavesurfer && wavesurfer.destroy){
            wavesurfer.destroy();
         }
         window.activeWavesurfer = null
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [wavesurfer])

   const defaultTextColor = 'text-secondary dark:text-secondary-dark ';
   return (
      <div
         role='presentation'
         onClick={ (e) => {
            e.stopPropagation()
         } }
         className='flex flex-1 rounded-[32px] justify-between items-center h-full'>
         {
            isAdmin && Boolean(price) && (
               <div
                  data-tooltip-content={  isUnlocked ? `Unlocked for ${ price } USD` : `${ price } USD to unlock` }
                  data-tooltip-id='ams-top-tooltip'
                  className='flex h-6 w-6 items-center justify-center rounded-full'
               >
                  <Icon
                     color='secondary dark:text-secondary-dark '
                     name={ isUnlocked ? 'unlocked' : 'lock' }
                     size='base'
                  />
               </div>
            )
         }
         <div
            className='flex h-6 w-6'>
            <Button
               onClick={ onPlayClick }
               iconName={  playing ? 'pause' : 'play-default' }
               textMarginX='0'
               padding={ 0 }
               fontIconColor={ textColor ? textColor : 'secondary' }
               classNames='h-full'
               backgroundColor='transparent'
               fontIconSize='extraLarge'
               darkIconColor={ textColor ? textColor : 'secondary-dark' }
               waveActive={ false }
            />
         </div>
         <audio controls ref={ trackRef } src={ url } onPlay={ () => {} } className='hidden'>
            <source type={ type } />
            <track kind='captions' />
         </audio>
         <div className='flex flex-1 min-w-[100px] mx-[10px] h-full items-center'>
            <div
               style={ {
                  height: `${ height }px`,
               } }
               id='waveform'
               className='w-full'
               ref={ waveformRef }
            />
         </div>
         <span
            className={ `text-sm leading-[18px] ${ textColor ? `text-${ textColor }` : defaultTextColor } select-none` }>{currentTime}</span>
      </div>

   )
}
Waveform.propTypes = {
   duration: PropTypes.any,
   url: PropTypes.string,
   type: PropTypes.string,
   height: PropTypes.number,
   colorState: PropTypes.object,
   textColor: PropTypes.string,
   isUnlocked: PropTypes.bool,
   isAdmin: PropTypes.bool,
   price: PropTypes.any,
}
export default Waveform;
