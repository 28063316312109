const initialState = {
   isFetching: true,
   isEmpty: false,
   isFilterFetching: false,
   isEmptyByFilter: false,
   isNewFetching: true,
   isEmptyByScroll: false,
   isFetchingAddNewCastMember: false,
   isFetchingDelete: false,
   isFetchingNew: false,
   isSuccessAddNewCastMember: true,
   isFetchingUpdateCastMember: true,
   deleteCastMember: false,
   castMembersData: {
      data: [],
   },
   file: {},
   castMember: {},
   errors: {},
   adminCastMember: {},
   canNotDeleteModal: {
      isOpen: false,
      title: null,
      description: null,
      connected_content: null,
   },
};

export default initialState;
