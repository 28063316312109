import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import Icon from 'common/components/elements/icons';
import NumberFormatter from 'admin/utils/NumberFormatter'
import { Link } from 'react-router-dom';
import Router from 'admin/routes/router';
import moment from 'moment';
import Button from 'common/components/elements/buttons/primary';
import Modal from 'common/components/modules/modal';
import DateFormatter from 'common/utils/DateFormatter';
import { generateUriSegment } from 'common/utils/mediaRouteHelper';
import { getProtocol, makeTweetableUrl } from 'common/utils/utils'
import TruncateText from 'common/components/elements/truncate-text';
import { getNickName } from 'common/utils/helper';
import MediaChip from 'common/components/modules/media-chip';

import cx from 'classnames';
import CheckBox from 'common/components/elements/inputs/checkbox';
import RadioLabel from 'common/components/elements/inputs/radio-label';
import ChatMessageContentState from 'admin/views/media-modules/chat-message-state';
import ContentPreviewIntoModal from '../content-preview-into-modal';
import { getPhotoCollectionPhotosById } from 'admin/api/AuthApi';
import ContentRepostModal from '../content-repost-modal';
import { updatePhotosetListAction } from 'admin/state/modules/photosets/actions';
import { useDispatch } from 'react-redux';
import QueryParams from 'admin/utils/QueryParams';
import Intercom from 'common/utils/Intercom';
import { ARTICLE_ID_ON_REPOST } from 'common/utils/intercom-articles';
// import Photoset from 'sites/views/media-modules/individual-page/media-content/photoset/index.jsx';


const PhotosetCard = ({
   data: {
      cover_photo: src,
      photos_count: count,
      views_count: viewsCount,
      likes_count: likesCount,
      comments_count: commentsCount,
      title,
      is_published: isPublished,
      is_future_publish_enabled: isPublishEnabled,
      publish_date: scheduledDate,
      id,
      stream_access,
      who_can_stream,
      total_tip,
      messages,
      // reordered_photos: photos,
      // photos,
      photos_count,
      ends_in,
      last_repost_date,
      notify_members_on_publish,
      send_tweet_on_publish,
      if_free_for_member_by_access,
   } = {},
   points = false,
   desktopType,
   openDeleteConfirmModal,
   downloadOriginalFile,
   isDownloading,
   isInsideModal,
   onSelectThisPhotoset,
   isSelected,
   isBulkEdit,
   isBulkEditSelected,
   onSelectPhotosets,
   onClickDetailsLink,
   siteUrl,
   onClickOpenTips,
   onClickSetId,
   showNewStyles,
   showCheckboxes,
   selectedItemsIds,
   disabled,
   pinPhotoset,
   isPinned,
   isMultipleSelect,
   updateMessage,
   onDeleteMessag,
   contentType,
   isDemoMode,
   updateMessageUnlockDetails,
   isMultipleAttachments,
   onShare,
   isReorderMode = false,
}) => {
   const dispatch = useDispatch()
   const [openOptionsModal, setOpenOptionsModal] = useState(false)
   const [previewModal, setPreviewModal] = useState({
      isOpen: false,
      data: {},
   })
   let calculateTimeLeft = () => DateFormatter.calculateTimeLeft(ends_in)
   const [timeLeft, setTimeLeft] = useState(ends_in ? calculateTimeLeft() : null);
   const [isLoadingPreviewButton, setIsLoadingPreviewButton] = useState(false);
   const [photos, setPhotos] = useState([])

   const numberFormatter = new NumberFormatter();
   const [isOpenRepostModal, setIsOpenRepostModal] = useState(false);


   let statusName = 'scheduled'
   if(!isPublishEnabled) {
      statusName = !isPublished ?  'unpublished' : 'published'
   }
   function closeOnClickOutside(e) {
      if(!e.target.closest(`.photoset-${ id }`)) {
         setOpenOptionsModal(false);
      }
   }

   useEffect(() => {
      window.addEventListener('click', closeOnClickOutside, true);
      return () => {
         window.removeEventListener('click', closeOnClickOutside, true);
      };
   // eslint-disable-next-line
   }, []);

   useEffect(() => {
      let timer
      if(timeLeft !== null) {
         timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
         }, 1000);
      }

      return () => clearInterval(timer);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [timeLeft]);

   const status = {
      published: {
         iconName: 'public',
         color: 'placeholder dark:text-placeholder-dark',
         text: 'Published',
      },
      unpublished: {
         iconName: 'unpublish',
         color: 'placeholder dark:text-placeholder-dark',
         text: 'Unpublished',
      },
      scheduled: {
         iconName: 'clock',
         color: 'placeholder dark:text-placeholder-dark',
         text: 'Scheduled',
         date: scheduledDate,
      },
   }



   let tweetUrl = null;
   if(statusName !== 'unpublished') {
      const url = `${ title }  ${ getProtocol() }${ siteUrl }/photosets/${ id }`;
      tweetUrl = makeTweetableUrl(` ${ getNickName() }`, url);
   }

   const onClickShare = () => {
      if(onShare) {
         onShare()
      }
   }

   const isHasDuration = () => {
      return !['scheduled', 'unpublished']?.includes(statusName) && Boolean(ends_in)
   }

   const openPreviewModal = async () => {
      setIsLoadingPreviewButton(true)
      try {
         let newPhotos = photos || []
         if(!newPhotos?.length) {
            const { data } = await getPhotoCollectionPhotosById(id)
            newPhotos = await data.map(it => it?.cf_path)
            setPhotos([...newPhotos])
         }

         setPreviewModal({
            isOpen: true,
            data: {
               photos: newPhotos,
               title,
               id,
            },
         })
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoadingPreviewButton(false)
      }
   }

   const onClickOpenRepostModal = (bool) => {
      if(bool) {
         QueryParams.addQueryParam('repost', '')
      } else {
         QueryParams.removeQueryParam('repost')
      }
      setIsOpenRepostModal(bool)
   }

   const showTimeFromNow = !isInsideModal && statusName === 'published' && !isBulkEdit

   return (
      <>
         <div
            className='adminDashboard__PhotosetCard rounded-lg relative h-auto overflow-hidden group/card'
            data-tooltip-content='This content has already been added to a different bundle'
            data-tooltip-id={ disabled ? 'ams-top-tooltip' : null }
         >
            <Link
               onClick={ (isInsideModal || isBulkEdit) ? (e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if(onClickDetailsLink) {
                     onClickDetailsLink()
                  }
                  isInsideModal ? !disabled && onSelectThisPhotoset() : onSelectPhotosets(!isBulkEditSelected)
               } : (e) => {
                  // e.preventDefault()
                  e.stopPropagation()
                  if(onClickDetailsLink){
                     onClickDetailsLink()
                  }
               } }
               to={ Router.route('PHOTOSETS_SHOW').getCompiledPath({ id: generateUriSegment(id, title) }) }
               className='text-major-dark h-auto'
            >
               <div className={ cx({
                  'h-auto w-full cursor-pointer': true,
                  '!cursor-default': disabled,
               }) }>
                  <div className='aspect-ratio-padding' />
                  <div className='absolute top-0 left-0 w-full photosets-top-content-height'>
                     <div className='text-major-dark h-full rounded-t-lg overflow-hidden' >
                        {
                           (statusName !== 'published' || !!timeLeft) && (
                              <div className='non-published-bg z-2 bg-major-60 dark:bg-grey-hover-dark-60 rounded-t-lg absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center'>
                                 <Icon
                                    name={ isHasDuration() ? 'clock' : status[statusName].iconName }
                                    color='major-dark'
                                    size='2xl'
                                 />
                                 <span className='mt-1 text-major-dark text-lg leading-7 font-semibold select-none'>
                                    {isHasDuration() ? 'Unpublishes in' :  status[statusName].text } { status[statusName].time }
                                 </span>
                                 {
                                    statusName !== 'unpublished' && (
                                       <span className=' text-major-dark block mt-1 select-none text-center'>
                                          {
                                             isHasDuration() ?
                                                timeLeft
                                                :
                                                <>
                                                   { DateFormatter.convertToUserTz(status[statusName].date, 'MMM DD, YYYY hh:mm A') } ({DateFormatter.getZoonFullName()})
                                                </>
                                          }
                                       </span>
                                    )
                                 }
                              </div>
                           )
                        }
                        <div style={ { backgroundImage: `url(${ src })`, filter: 'blur(24px)', zIndex: '-1' } } className=' bg-cover bg-center w-full h-full' />
                        <img src={ src } alt='card' className='h-full rounded-t-lg w-full object-contain object-center absolute top-0 left-0' />
                        {/* <Photoset
                           data={ photos || [] }
                           count={ photos_count }
                           addView={ () => {} }
                           showFullScreen={ false }
                        /> */}
                        <div className='absolute top-0 left-0 w-full rounded-t-lg h-full card-shadow **p-2 flex flex-col justify-between group/card'>
                           {
                              !messages?.length && (contentType === 'chat' || isMultipleAttachments) && isInsideModal &&
                              <div className='hidden absolute top-0 left-0 w-full h-full group-hover/card:!flex justify-center items-center bg-major-60 dark:bg-grey-hover-dark-60 !z-[3]'>
                                 <Button
                                    text='Preview'
                                    iconName='play-default'
                                    textColor='divider'
                                    iconPosition='left'
                                    backgroundColor='transparent'
                                    classNames='!w-fit !h-9 border border-grey-main items-center'
                                    borderRadius='large'
                                    padding='0 px-3.5 py-2'
                                    textSize='small'
                                    textClassName='mr-0 !leading-[18px]'
                                    isLoading={ isLoadingPreviewButton }
                                    onClick={ (e) => {
                                       e.preventDefault();
                                       e.stopPropagation();
                                       openPreviewModal()
                                    } }
                                 />
                              </div>
                           }
                           <div
                              className={ `z-[4] flex p-2 pb-3 justify-${ !isInsideModal && !isBulkEdit && statusName === 'unpublished' ? 'end' : 'between' }` }
                              style={ {
                                 background: 'linear-gradient(0deg,rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%)',
                              } }
                           >
                              {
                                 (isInsideModal || isBulkEdit) &&
                                 (
                                    isMultipleSelect ?
                                       <CheckBox
                                          isChecked={ selectedItemsIds.includes(id) || isBulkEditSelected || isSelected }
                                          onChange={ () => { } }
                                          disabled={ disabled }
                                          className={ `w-6 h-6` }
                                          classNames={ `!z-40 w-5 h-5 !items-center ${ disabled ? '!cursor-default' : '' }` }
                                       />
                                       :
                                       <RadioLabel
                                          checked={ selectedItemsIds.includes(id) || isSelected }
                                          className={ '!p-0 ' }
                                          radioParentClassName='ml-0'
                                          disabled={ disabled }
                                          labelClassName='!w-5 !h-5'
                                       />
                                 )
                              }
                              {
                                 !isBulkEdit && !isInsideModal && statusName !== 'unpublished' && (
                                    <span className='text-major-dark text-xs'>
                                       {/* { moment(DateFormatter.convertToUserTz(scheduledDate)).fromNow()} */}
                                    </span>
                                 )
                              }
                              <MediaChip
                                 countOrDuration={ count }
                                 type='photoset'
                              />
                           </div>
                           {
                              messages  && messages?.length > 0 && (
                                 <ChatMessageContentState
                                    chatMessages={ messages }
                                    onDeleteMessag={ onDeleteMessag }
                                    updateMessage={ (messageId, isDeleteAll) => updateMessage({ ids: [id], data: null, messageId, isDeleteAll }) }
                                    contentType='photoset'
                                    onClickPreview={ () => openPreviewModal() }
                                    updateMessageUnlockDetails={ updateMessageUnlockDetails }
                                    resourceId={ id }
                                    isLoading={ isLoadingPreviewButton }
                                    isFreeForMemberByAccess={ if_free_for_member_by_access }
                                 />
                              )
                           }
                           {
                              !isReorderMode && <>
                                 <div
                                    className='self-start flex w-full p-2 pt-3'
                                    style={ {
                                       background: 'linear-gradient(180deg,rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%)',
                                    } }
                                 >
                                    <div className='flex items-center'>
                                       <Icon name='views' color='major-dark' size='xl' />
                                       <p className='ml-1 text-sm leading-5'>{ numberFormatter.withCommas(viewsCount) }</p>
                                    </div>
                                    <div className='flex items-center ml-3'>
                                       <Icon name='heart-filled' color='major-dark' size='xl' />
                                       <p className='ml-1 text-sm leading-5'>{ numberFormatter.withCommas(likesCount) }</p>
                                    </div>
                                    <div className='flex items-center ml-3'>
                                       <Icon name='comment' color='major-dark' size='xl' />
                                       <p className='ml-1 text-sm leading-5'>{ numberFormatter.withCommas(commentsCount) }</p>
                                    </div>
                                    <div
                                       style={ { minWidth: '80px' } }
                                       className={ cx({
                                          'pl-3 **m-auto text-sm': true,
                                          '!cursor-default': disabled,
                                       }) }
                                    >
                                       <Button
                                          text={ `${ numberFormatter.withCommas(total_tip) } USD` }
                                          textColor='major-dark'
                                          backgroundColor='transparent'
                                          textSize='small'
                                          iconName='tip'
                                          iconPosition='left'
                                          fontIconColor='major-dark'
                                          fontIconSize='extraLarge'
                                          fontWeight='300'
                                          padding='none'
                                          lineHeight='leading-5'
                                          classNames={ disabled ? '!cursor-default' : '' }
                                          onClick={ (e) => {
                                             if(disabled) {
                                                return
                                             }
                                             e.preventDefault()
                                             e.stopPropagation()
                                             onClickSetId(id)
                                             onClickOpenTips()

                                          } }
                                          ignoreHover
                                       />

                                    </div>
                                 </div>
                              </>
                           }
                        </div>
                     </div>
                  </div>
               </div>
            </Link>
            <div className='photosetCard__bottom bg-major-dark  !py-[6.5px] !h-13 !max-h-[52px] !pl-3 !pr-2 flex'>
               <div className='flex justify-between items-center w-full'>
                  <div className='flex **gap-2 items-center w-full'>
                     {
                        isPinned &&
                           <Icon
                              name={ `pin-35` }
                              color={ `warning` }
                              darkColor={ `warning-dark` }
                              className={ `h-5 w-5 min-h-[20px] min-w-[16px] **rotate-[35deg] mr-2 self-start mt-[2px]` }
                              size={ `xl` }
                              data-tooltip-content={ 'Pinned' }
                              data-tooltip-id={ 'ams-top-tooltip' }
                           />
                     }
                     <div className={ cx({
                        'w-full flex flex-col h-full': true,
                     }) }>

                        <TruncateText
                           className='pr-1'
                           text={ title }
                           textClass={ `font-bold text-xl text-major dark:text-major-dark ${ disabled ? '!text-disabled' : '' } ${ showTimeFromNow ? 'leading-6' : 'leading-6' }` }
                           textSize='20px'
                           fontWeight='700'
                           width={ '100%' }
                        />
                        {
                           showTimeFromNow &&
                           <p className={ `flex items-center **ml-2 text-xs text-center select-none leading-[15px] text-placeholder ${ disabled ? '!text-disabled' : '' }` }>
                              <>{status[statusName].text} </>
                              {
                                 moment(DateFormatter.convertToUserTz(scheduledDate)).fromNow()
                              }
                           </p>
                        }
                     </div>
                  </div>
                  {points && (
                     <div
                        className={ `flex justify-center items-center photoset-${ id } cursor-pointer` }
                     >
                        <Button
                           onClick={ () => setOpenOptionsModal(!openOptionsModal) }
                           backgroundColor='transparent'
                           fontIconColor={ disabled ? 'disabled' : 'secondary' }
                           darkIconColor='secondary-dark'
                           iconName='options-vertical'
                           fontIconSize='doubleLarge'
                           padding='0'
                        />
                     </div>
                  )}
               </div>
            </div>
         </div>
         {
            openOptionsModal && (
               <OptionsModal
                  closeModal={ () => setOpenOptionsModal(false) }
                  openDeleteConfirmModal={ () => {
                     openDeleteConfirmModal();
                     setTimeout(() => {
                        setOpenOptionsModal(false)

                     }, 100)
                  } }
                  desktopType={ desktopType }
                  id={ id }
                  title={ title }
                  downloadOriginalFile={ downloadOriginalFile }
                  isDownloading={ isDownloading }
                  onClickDetailsLink={ onClickDetailsLink }
                  tweetUrl={ statusName === 'unpublished' ? null : tweetUrl }
                  onClickShare={ () => {
                     onClickShare();
                     setOpenOptionsModal(false)
                  } }
                  pinPhotoset={ (id, bool) => {
                     pinPhotoset(id, bool)
                     setOpenOptionsModal(false)
                  } }
                  isPinned={ isPinned }
                  isDemoMode={ isDemoMode }
                  onClickOpenRepostModal={ onClickOpenRepostModal }
                  isPublished={ isPublished && !isPublishEnabled }
                  availableRepostDate={ !!last_repost_date ? DateFormatter.getDiff(DateFormatter.addHoursToDate(last_repost_date))?.days <= 1 ? DateFormatter.addHoursToDate(last_repost_date) : null : null }
               />
            )
         }
         {
            previewModal.isOpen &&
            <ContentPreviewIntoModal
               type='photoset'
               isMobile={ desktopType === 'mobile' }
               data={ previewModal.data }
               closeModal={ () => {
                  setPreviewModal({ isOpen: false, data: {} })
               } }
               galleryProps={ {
                  showFullScreen: false,
                  classNameIconDiv: 'next-icon-classes bg-major-20 backdrop-blur-[10px]',
                  triggerImageLoad: true,
               } }
            />
         }
         {
            isOpenRepostModal &&
               <ContentRepostModal
                  onCloseModal={ onClickOpenRepostModal }
                  insideModalSlug='new_photoset_published'
                  resourceId={ id }
                  callbackFunction={ (date) => {
                     dispatch(updatePhotosetListAction({ date, id }))
                  } }
                  initialData={ {
                     notify_members_on_publish,
                     send_tweet_on_publish,
                  } }
                  isDemoMode={ isDemoMode }
               />
         }
      </>
   );
};

const CardButtomContent = ({
   access,
   title,
   className,
}) => {

   return (
      <>
         <div
            className={ `cursor-pointer flex items-center justify-center ${ className }` }
            data-tooltip-content={ access.text }
            data-tooltip-id='content-card-tooltip'
         >
            {
               title && (
                  <span className='text-secondary dark:text-secondary-dark text-sm font-medium select-none mr-1'>{title}</span>
               )
            }
            <div className='flex items-center justify-center w-5 h-5'>
               <Icon
                  name={ access.icon  }
                  color={ access.color }
                  size='lg'
               />
            </div>
         </div>

      </>
   )
}
const OptionsModal = ({
   /* eslint-disable */
   desktopType,
   closeModal,
   openDeleteConfirmModal,
   id,
   title,
   downloadOriginalFile,
   isDownloading,
   onClickDetailsLink,
   tweetUrl,
   onClickShare,
   pinPhotoset,
   isPinned,
   isDemoMode,
   isPublished,
onClickOpenRepostModal,
availableRepostDate
   /* eslint-enable */
}) => {
   let calculateTimeLeft = () => DateFormatter.calculateTimeLeft(availableRepostDate)
   const [timeLeft, setTimeLeft] = useState(availableRepostDate ? calculateTimeLeft() : null)

   useEffect(() => {
      let timer
      if(timeLeft !== null) {
         timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
         }, 1000);
      }

      return () => clearInterval(timer);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [timeLeft]);

   const view = (
      <div
         className={ `shadow dark:shadow-dark photoset-${ id } flex flex-col w-full bg-panel dark:bg-panel-dark py-1 items-center justify-between ${ desktopType === 'mobile' ? '' : 'border dark:border-divider-dark border-divider rounded-lg' }` }>
         <div className='flex w-full items-center justify-between'>
            <Link
               onClick={ onClickDetailsLink ? () => onClickDetailsLink() : null }
               to={ Router.route('PHOTOSETS_SHOW').getCompiledPath({ id: generateUriSegment(id, title) }) }
               className='w-full '
            >
               <Button
                  text='Edit'
                  textSize='small'
                  textColor='major dark:text-major-dark'
                  classNames='h-10 py-2.5 px-4 hover:bg-hover'
                  iconName='pencil'
                  fontIconColor='secondary'
                  darkIconcolor='secondary-dark'
                  iconPosition='left'
                  alignment='end'
                  padding='none'
                  backgroundColor='transparent'
                  fontIconSize='extraLarge'
                  onClick={ () => {} }
                  textMarginX='0 ml-3'
               />
            </Link>
         </div>
         <div className='flex w-full items-center justify-between'>
            <Button
               text='Copy sharing link'
               textSize='small'
               textColor='major dark:text-major-dark'
               classNames='h-10 py-2.5 px-4 hover:bg-hover'
               iconName='copy'
               fontIconColor='secondary'
               darkIconcolor='secondary-dark'
               iconPosition='left'
               alignment='end'
               padding='none'
               backgroundColor='transparent'
               fontIconSize='extraLarge'
               onClick={ () => onClickShare() }
               textMarginX='0 ml-3'
            />
         </div>
         {
            !!tweetUrl && (
               <div className='flex w-full items-center justify-between'>
                  <a
                     href={ tweetUrl }
                     target='_blank'
                     rel='noreferrer'
                     className='h-full w-full'
                     onClick={ (e) => {
                        if(isDemoMode) return;
                        window.open(
                           tweetUrl,
                           "twitterwindow",
                           "height=450, width=550, toolbar=0, location=0, menubar=0, directories=0,scrollbars=0"
                        );
                        e.preventDefault();
                        e.stopPropagation();
                     } }
                  >
                     <Button
                        text='Tweet this'
                        textSize='small'
                        textColor='major dark:text-major-dark'
                        classNames='h-10 py-2.5 px-4 hover:bg-hover'
                        iconName='twitter-x'
                        fontIconColor='secondary'
                        darkIconcolor='secondary-dark'
                        iconPosition='left'
                        alignment='end'
                        padding='none'
                        backgroundColor='transparent'
                        fontIconSize='extraLarge'
                        onClick={ () => {} }
                        disabled={ isDownloading || isDemoMode }
                        dataToolTipContent={ isDemoMode ? 'Not available in demo' : undefined }
                        textMarginX='0 ml-3'
                     />
                  </a>
               </div>
            )
         }
         <div
            className={ `flex w-full h-10 py-2.5 px-4 items-center gap-1 ${ !isPublished || !!timeLeft ? '' : 'cursor-pointer hover:bg-hover' }` }
            role='presentation'
            onClick={ (e) => {
               if(!isPublished || !!timeLeft || isDemoMode) return
               onClickOpenRepostModal(true)
            } }
            data-tooltip-id='ams-top-tooltip'
            data-tooltip-content={ isDemoMode ? 'Not available in demo' : (!isPublished ? 'You can repost only if the content status is Published' : timeLeft ? `You can repost this again in ${ timeLeft }` : null) }
         >
            <Button
               text='Repost'
               iconName='repost'
               textClassName='ml-2'
               backgroundColor='transparent'
               padding='none'
               textSize='small'
               textColor='major dark:text-major-dark'
               classNames='h-full !w-fit'
               fontIconColor='secondary'
               darkIconcolor='secondary-dark'
               iconPosition='left'
               alignment='end'
               fontIconSize='extraLarge'
               // onClick={ () => onClickOpenRepostModal(true) }
               // dataToolTipContent={ !isPublished ? 'You can repost only if the content status is Published' : timeLeft ? `You can repost this again in ${ timeLeft }` : null }
               disabled={ !isPublished || !!timeLeft || isDemoMode }
               textMarginX='0 ml-3'
            />
            <Button
               fontIconSize='base'
               backgroundColor='transparent'
               onClick={ (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  Intercom.showArticle(ARTICLE_ID_ON_REPOST)
               } }
               classNames='!w-fit !h-fit !ml-0.5'
               iconName='help-outline'
               fontIconColor='placeholder'
               padding='none'
            />
         </div>
         <div className='flex w-full items-center justify-between'>
            <Button
               text={ isPinned ? 'Unpin this photoset' : 'Pin this photoset' }
               textSize='small'
               textColor='major dark:text-major-dark'
               classNames='h-10 py-2.5 px-4 intercom-pin-content hover:bg-hover'
               iconName={ isPinned ? 'unpin' : 'pin' }
               fontIconColor='secondary'
               darkIconcolor='secondary-dark'
               iconPosition='left'
               alignment='end'
               padding='none'
               backgroundColor='transparent'
               fontIconSize='extraLarge'
               onClick={ () => pinPhotoset(id, isPinned) }
               disabled={ isDownloading }
               textMarginX='0 ml-3'
            />
         </div>
         <div className='flex w-full items-center justify-between'>
            <Button
               text='Download original files'
               textSize='small'
               textColor='major dark:text-major-dark'
               classNames='h-10 py-2.5 px-4 hover:bg-hover'
               iconName='download'
               fontIconColor='secondary'
               darkIconcolor='secondary-dark'
               iconPosition='left'
               alignment='end'
               padding='none'
               backgroundColor='transparent'
               fontIconSize='extraLarge'
               onClick={ () => downloadOriginalFile(id) }
               disabled={ isDownloading || isDemoMode }
               dataToolTipContent={ !!isDemoMode ? 'Not available in demo' : '' }
               textMarginX='0 ml-3'
            />
         </div>
         <div
            className='flex w-full items-center justify-between'>
            <Button
               text='Delete this photoset'
               onClick={ openDeleteConfirmModal }
               iconName='delete'
               textSize='small'
               fontIconSize='extraLarge'
               iconPosition='left'
               classNames='h-10 py-2.5 px-4 hover:bg-hover'
               backgroundColor='transparent'
               fontIconColor='error'
               darkIconColor='error-dark'
               textColor='error dark:text-error-dark'
               padding='none'
               alignment='end'
               disabled={ isDemoMode }
               dataToolTipContent={ !!isDemoMode ? 'Not available in demo' : '' }
               textMarginX='0 ml-3'
            />
         </div>
      </div>
   )
   if(desktopType === 'mobile'){
      return (
         <Modal
            onClose={ closeModal }
            roundedModal='lg'
         >
            {view}
         </Modal>
      );
   } else {
      return (
         <div
            style={ { bottom: '-40px', right: '35px' } }
            className='absolute z-30 bottom-0'>
            {view}
         </div>
      )
   }

}
PhotosetCard.propTypes = {
   data: PropTypes.object,
   points: PropTypes.bool,
   desktopType: PropTypes.string,
   openDeleteConfirmModal: PropTypes.func,
   downloadOriginalFile: PropTypes.func,
   isDownloading: PropTypes.bool,
   isInsideModal: PropTypes.bool,
   onSelectThisPhotoset: PropTypes.func,
   isSelected: PropTypes.bool,
   isBulkEdit: PropTypes.bool,
   onSelectPhotosets: PropTypes.func,
   isBulkEditSelected: PropTypes.bool,
   onClickDetailsLink: PropTypes.func,
   siteUrl: PropTypes.string,
   onClickOpenTips: PropTypes.func,
   onClickSetId: PropTypes.func,
   showNewStyles: PropTypes.bool,
   showCheckboxes: PropTypes.bool,
   selectedItemsIds: PropTypes.array,
   disabled: PropTypes.bool,
   pinPhotoset: PropTypes.func,
   isPinned: PropTypes.bool,
   isMultipleSelect: PropTypes.bool,
   onDeleteMessag: PropTypes.func,
   updateMessage: PropTypes.func,
   contentType: PropTypes.string,
   isDemoMode: PropTypes.bool,
   updateMessageUnlockDetails: PropTypes.func,
   isMultipleAttachments: PropTypes.bool,
   onShare: PropTypes.func,
   isReorderMode: PropTypes.bool,
};

PhotosetCard.defaultProps = {
   isMultipleSelect: true,
   contentType: '',
   isDemoMode: false,
}

CardButtomContent.propTypes = {
   title: PropTypes.string,
   className: PropTypes.string,
   access: PropTypes.object,
};

export default React.memo(PhotosetCard);
