export const SET_IS_FETCHING_INIT_INFO = "polls/SET_IS_FETCHING_INIT_INFO"
export const FETCH_CREATE_POLL = "polls/FETCH_CREATE_POLL"
export const FETCH_CREATE_EDIT_POLL_FAILED = "polls/FETCH_CREATE_EDIT_POLL_FAILED"
export const RESET_ERROR_FIELDS = "polls/RESET_ERROR_FIELDS"
export const GET_POLLS_START = "polls/GET_POLLS_START"
export const GET_POLLS_COMPLETED = "polls/GET_POLLS_COMPLETED"
export const SET_IS_FETCHING_ACTION_WITH_POLL = "polls/SET_IS_FETCHING_ACTION_WITH_POLL"
export const DELETE_POLL_COMPLETED = "polls/DELETE_POLL_COMPLETED"
export const SET_IS_FETCHING_POLL_DETAILS = "polls/SET_IS_FETCHING_POLL_DETAILS"
export const SET_OVERLAPPING_POLL_MODAL_ACTION = "poll/SET_OVERLAPPING_POLL_MODAL_ACTION"
export const CHANGE_POLL_STATUS_COMPLETED = "poll/CHANGE_POLL_STATUS_COMPLETED"
export const UPDATE_VOTERS_COUNT = "poll/UPDATE_VOTERS_COUNT"
export const FETCH_UPDATE_POLL = "poll/FETCH_UPDATE_POLL"
