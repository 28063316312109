const initialState = {
   // images
   vaultImagesData: {},
   isFetchingImages: true,
   isEmptyImages: false,
   isFetchingImagesByFilter: false,
   isEmptyImagesByFilter: false,
   isNewFetchingImages: false,
   vaultImagesIsInited: false,
   // videos
   vaultVideosData: {},
   uploadingVideos: [],
   isInsideModal: false,
   isFetchingVideos: true,
   isEmptyVideos: false,
   isFetchingVideosByFilter: false,
   isEmptyVideosByFilter: false,
   isNewFetchingVideos: false,
   vaultVideosIsInited: false,
   // all vault
   allVaultData: [],
   isFetchingAllVault: true,
   isEmptyAllVault: false,
   isEmptyAllVaultByFilter: false,
   isFetchingAllVaultByFilter: false,
   isNewfetchingAllVaults: false,
   allVaultIsInited: false,

   errors: null,
   filtersInsideModal: {},
   filters: {},
};

export default initialState;
